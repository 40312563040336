import { Component, computed, effect, inject, input, OnInit, signal } from '@angular/core';

import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';
import { MainRequestOfferHistoryTabsEnum } from 'src/app/_common/enums/main-request-offer-history-tabs.enum';
import { ChatRoom } from 'src/app/_common/models/chat-room.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { MedEventService } from 'src/app/_common/services/medevent/medevent.service';
import { MessagingService } from 'src/app/_common/services/messaging/messaging.service';
import { MhpBaseSideModalComponent } from '../../../_common/components/mhp-base-side-modal/mhp-base-side-modal.component';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { MainRequestOfferHistoryDiscussionComponent } from './main-request-offer-history-discussion/main-request-offer-history-discussion.component';

/**
 * Side modal component for displaying offer history and discussion.
 * Features:
 * - Tabs for history and discussion views
 * - Real-time chat room integration
 * - Package information display
 * - Automatic chat room creation/connection
 */
@Component({
	selector: 'app-main-request-offer-history',
	imports: [ToCipPipe, MhpSvgIconComponent, MainRequestOfferHistoryDiscussionComponent, MhpBaseSideModalComponent],
	templateUrl: './main-request-offer-history.component.html',
})
export class MainRequestOfferHistoryComponent extends MhpBaseSideModalComponent implements OnInit {
	private readonly _messagingSvc = inject(MessagingService);
	private readonly medEventService = inject(MedEventService);

	/** Required offer details */
	readonly offer = input.required<Offer>();

	/** Available tabs enum */
	readonly mainRequestOfferHistoryTabs = MainRequestOfferHistoryTabsEnum;

	/** Chat room signal */
	readonly offerRoom = signal<ChatRoom | null>(null);

	/** Available tab definitions */
	readonly tabs: MHPTab<MainRequestOfferHistoryTabsEnum>[] = [
		// History tab temporarily disabled
		// { text: 'History', value: MainRequestOfferHistoryTabsEnum.History },
		{ text: 'Discussion', value: MainRequestOfferHistoryTabsEnum.Discussion },
	];

	/** Currently selected tab */
	tabSelected: MHPTab<MainRequestOfferHistoryTabsEnum> = this.tabs[0];

	constructor() {
		super();

		// if a message arrives for this discussion, mark it as read when the chat is opened
		effect(async () => {
			if (this.hasMessageForCurrentOffer()) await this.medEventService.markAsRead(this.offer().id);
		});
	}

	/** Initialize chat room */
	async ngOnInit(): Promise<void> {
		this.offerRoom.set(await this._messagingSvc.getOrCreateChatRoomFromOffer(this.offer()));
	}

	/** emits true if a message has arrived for this discussion */
	hasMessageForCurrentOffer = computed<boolean>(() => this.medEventService.hasUnreadMessagesForChat(this.offer().id)());
}
