@if (this.user(); as user) {
  <div class="mhp-dashboard">
    <div class="mhp-dashboard-header">
      <h2 class="text-[28px] font-semibold">Hello {{ user.firstName }}</h2>
      <div class="flex mt-6 flex-col space-y-6 lg:flex-row lg:space-x-6 lg:space-y-0">
        <div class="mhp-dashboard-card">
          <div class="mhp-dashboard-card-icon">
            <ng-icon name="saxCardsBulk" />
          </div>
          <div class="mr-auto">
            <h3 class="text-3xl font-semibold">{{ 583640 | currency : 'EUR' : 'symbol' : '.0-2' }}</h3>
            <p class="text-[var(--text-secondary)]">
              @switch (user.function) {
                @case (this.userFunctions.Importer) { Purchases this month }
                @case (this.userFunctions.Exporter) { Sales this month }
                @case (this.userFunctions.ImporterExporter) { Purchases & Sales this month }
              }
            </p>
          </div>
          <div class="h-full shrink-0">
            <div class="flex items-center">
              <ng-icon
                [class]="true ? 'text-green-500' : 'text-red-500'"
                [name]="true ? 'bootstrapArrowUp' : 'bootstrapArrowDown'"
                size="1rem"
              />
              <span class="ml-2">{{ 15 }} %</span>
            </div>
          </div>
        </div>

        <div class="mhp-dashboard-card ">
          <div class="mhp-dashboard-card-icon">
            <app-mhp-svg-icon class="fill-blue-500" icon="jar-of-pills" />
          </div>
          <div class="mr-auto">
            <h3 class="text-3xl font-semibold">{{ 1421 | number : '' : 'en-US' }}</h3>
            <p class="text-[var(--text-secondary)]">
              @switch (user.function) {
                @case (this.userFunctions.Importer) { Product ordered this month }
                @case (this.userFunctions.Exporter) { Product sold this month }
                @case (this.userFunctions.ImporterExporter) { Product ordered & sold this month }
              }
            </p>
          </div>
          <div class="h-full shrink-0">
            <div class="flex items-center">
              <ng-icon
                [class]="true ? 'text-green-500' : 'text-red-500'"
                [name]="true ? 'bootstrapArrowUp' : 'bootstrapArrowDown'"
                size="1rem"
              />
              <span class="ml-2">{{ 12 }} %</span>
            </div>
          </div>
        </div>

        <div class="mhp-dashboard-card">
          <div class="mhp-dashboard-card-icon">
            <ng-icon name="saxBuildingsBulk" />
          </div>
          <div class="mr-auto">
            <h3 class="text-3xl font-semibold">{{ 4 | number : '' : 'en-US' }}</h3>
            <p class="text-[var(--text-secondary)]">
              @switch (user.function) {
                @case (this.userFunctions.Importer) { Exporters this month }
                @case (this.userFunctions.Exporter) { Importers this month }
                @case (this.userFunctions.ImporterExporter) { Exporters & Importers this month }
              }
            </p>
          </div>
          <div class="h-full shrink-0">
            <div class="flex items-center">
              <ng-icon
                [class]="true ? 'text-green-500' : 'text-red-500'"
                [name]="true ? 'bootstrapArrowUp' : 'bootstrapArrowDown'"
                size="1rem"
              />
              <span class="ml-2">{{ 20 }} %</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row pt-8 pb-6 grow-0 justify-between items-center">
      <span class="font-semibold">All requests</span>

      <div class="flex flex-row items-center space-x-4 shrink-0">
        <!-- <app-mhp-button
          prefix="saxArrowDown1Outline"
          text="Status"
          type="tertiary"
        />
        <app-mhp-button
          prefix="saxCandleOutline"
          text="More filters"
          type="tertiary"
        /> -->

        @if ([
          this.userFunctions.Importer,
          this.userFunctions.ImporterExporter
        ].includes(user.function)) {
          <app-mhp-button
            prefix="saxAddOutline"
            text="New request"
            type="primary"
            [routerLink]="[ this.sitemap.newRequest.route ]"
          />
        }
      </div>
    </div>

    <div class="mt-4">
      <app-main-dashboard-requests />
    </div>
  </div>
}
