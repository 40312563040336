@switch (this.icon()) {
  @case ('close') {
    <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="this.class()" d="M4.99805 5L18.998 19" stroke="#3D4754" stroke-width="1.5" stroke-linecap="round"/>
      <path [class]="this.class()" d="M18.998 5L4.99805 19" stroke="#3D4754" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
  }

  @case ('jar-of-pills') {
    <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="this.class()" opacity="0.5" d="M6 3.5C6 3.03406 6 2.80109 6.07612 2.61732C6.17761 2.37229 6.37229 2.17761 6.61732 2.07612C6.80109 2 7.03406 2 7.5 2H16.5C16.9659 2 17.1989 2 17.3827 2.07612C17.6277 2.17761 17.8224 2.37229 17.9239 2.61732C18 2.80109 18 3.03406 18 3.5C18 3.96594 18 4.19891 17.9239 4.38268C17.8224 4.62771 17.6277 4.82239 17.3827 4.92388C17.1989 5 16.9659 5 16.5 5H7.5C7.03406 5 6.80109 5 6.61732 4.92388C6.37229 4.82239 6.17761 4.62771 6.07612 4.38268C6 4.19891 6 3.96594 6 3.5Z" fill="black"/>
      <path [class]="this.class()" fill-rule="evenodd" clip-rule="evenodd" d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V13.25H14C14.4142 13.25 14.75 13.5858 14.75 14C14.75 14.4142 14.4142 14.75 14 14.75H12.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V14.75H10C9.58579 14.75 9.25 14.4142 9.25 14C9.25 13.5858 9.58579 13.25 10 13.25H11.25V12C11.25 11.5858 11.5858 11.25 12 11.25Z" fill="black"/>
      <path [class]="this.class()" opacity="0.5" d="M15.8782 15.6671L13.6671 17.8782C12.7513 18.794 12.7822 20.3098 13.7362 21.2638C14.6902 22.2178 16.206 22.2487 17.1218 21.3329L19.3329 19.1218C20.2487 18.206 20.2178 16.6902 19.2638 15.7362C18.3098 14.7822 16.794 14.7513 15.8782 15.6671Z" fill="black"/>
      <path [class]="this.class()" d="M9.00021 5L5.50143 7.79902C4.79757 8.36211 4.31189 9.13989 4.108 10H19.8958C19.6956 9.14182 19.2152 8.3645 18.517 7.79931L16.1597 5.89102L15.0184 5H9.00021Z" fill="black"/>
      <path [class]="this.class()" d="M4.92732 20.1125C4.42455 19.5092 4.11265 18.774 4.02539 18H11.8042C11.2442 19.3096 11.4686 20.8608 12.3847 22H8.95713C8.38603 22 8.10047 22 7.82977 21.9628C7.02682 21.8526 6.27629 21.5011 5.67759 20.9548C5.47575 20.7706 5.29293 20.5513 4.92732 20.1125Z" fill="black"/>
      <path [class]="this.class()" opacity="0.5" d="M4 17.5518V10.9225C4 10.6087 4.03683 10.2994 4.10779 10H19.8955C19.9643 10.295 20 10.5995 20 10.9083V14.3842C18.4889 13.1693 16.2529 13.1712 14.8177 14.6064L12.6067 16.8175C12.2534 17.1707 11.987 17.5725 11.8042 18H4.02518C4.00847 17.8518 4 17.7022 4 17.5518Z" fill="black"/>
      <path [class]="this.class()" d="M18.8208 19.6339C18.7777 19.5243 18.7225 19.3974 18.6525 19.2566C18.4263 18.8018 18.0462 18.2044 17.4208 17.5791C16.7955 16.9537 16.198 16.5736 15.7432 16.3473C15.6025 16.2773 15.4758 16.2222 15.3663 16.1791L14.1401 17.4052L14.6094 17.5048L14.6207 17.5078C14.636 17.5119 14.6646 17.5202 14.705 17.5338C14.7858 17.5611 14.9133 17.6098 15.0751 17.6903C15.3978 17.8508 15.8595 18.139 16.3602 18.6397C16.8608 19.1404 17.149 19.602 17.3095 19.9247C17.3899 20.0864 17.4386 20.2139 17.4659 20.2947C17.4795 20.335 17.4878 20.3637 17.4919 20.3789L17.4948 20.3902L17.5946 20.8602L18.8208 19.6339Z" fill="black"/>
    </svg>
  }

  @case ('from-to') {
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 27">
        <path id="Vector 1" d="M28.3536 19.6464C28.5488 19.8417 28.5488 20.1583 28.3536 20.3536L25.1716 23.5355C24.9763 23.7308 24.6597 23.7308 24.4645 23.5355C24.2692 23.3403 24.2692 23.0237 24.4645 22.8284L27.2929 20L24.4645 17.1716C24.2692 16.9763 24.2692 16.6597 24.4645 16.4645C24.6597 16.2692 24.9763 16.2692 25.1716 16.4645L28.3536 19.6464ZM11 20.5C10.7239 20.5 10.5 20.2761 10.5 20C10.5 19.7239 10.7239 19.5 11 19.5V20.5ZM26.9375 20.5C26.6614 20.5 26.4375 20.2761 26.4375 20C26.4375 19.7239 26.6614 19.5 26.9375 19.5V20.5ZM24.8125 19.5C25.0886 19.5 25.3125 19.7239 25.3125 20C25.3125 20.2761 25.0886 20.5 24.8125 20.5V19.5ZM22.6875 20.5C22.4114 20.5 22.1875 20.2761 22.1875 20C22.1875 19.7239 22.4114 19.5 22.6875 19.5V20.5ZM20.5625 19.5C20.8386 19.5 21.0625 19.7239 21.0625 20C21.0625 20.2761 20.8386 20.5 20.5625 20.5V19.5ZM18.4375 20.5C18.1614 20.5 17.9375 20.2761 17.9375 20C17.9375 19.7239 18.1614 19.5 18.4375 19.5V20.5ZM16.3125 19.5C16.5886 19.5 16.8125 19.7239 16.8125 20C16.8125 20.2761 16.5886 20.5 16.3125 20.5V19.5ZM14.1875 20.5C13.9114 20.5 13.6875 20.2761 13.6875 20C13.6875 19.7239 13.9114 19.5 14.1875 19.5V20.5ZM12.0625 19.5C12.3386 19.5 12.5625 19.7239 12.5625 20C12.5625 20.2761 12.3386 20.5 12.0625 20.5V19.5ZM28 20.5H26.9375V19.5H28V20.5ZM24.8125 20.5H22.6875V19.5H24.8125V20.5ZM20.5625 20.5H18.4375V19.5H20.5625V20.5ZM16.3125 20.5H14.1875V19.5H16.3125V20.5ZM12.0625 20.5H11V19.5H12.0625V20.5Z" fill="#696F96"/>
      </g>
    </svg>
  }

  @case('spinner') {
    <svg class="animate-spin mr-4 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  }

  @case('pill') {
    <svg [style]="{ height: this.size()+'px', width: this.size()+'px' }" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="fill-gray-900 dark:fill-white" fill-rule="evenodd" clip-rule="evenodd" d="M14.1322 3.46024C11.1852 0.513254 6.40722 0.513254 3.46024 3.46024C0.513254 6.40722 0.513254 11.1852 3.46024 14.1322L9.86778 20.5398C12.8148 23.4867 17.5928 23.4867 20.5398 20.5398C23.4867 17.5928 23.4867 12.8148 20.5398 9.86778L14.1322 3.46024ZM4.5209 4.5209C6.8821 2.1597 10.7104 2.1597 13.0716 4.5209L15.9689 7.41825C15.9653 7.42974 15.9616 7.44148 15.9578 7.45347C15.8679 7.73472 15.7151 8.15053 15.468 8.66312C14.9744 9.68744 14.1032 11.101 12.6019 12.6023C11.1005 14.1037 9.68709 14.9747 8.66292 15.4683C8.1504 15.7153 7.73465 15.868 7.45346 15.9578C7.44149 15.9616 7.42977 15.9654 7.41829 15.9689L4.5209 13.0716C2.1597 10.7104 2.1597 6.8821 4.5209 4.5209ZM8.58965 17.1403L10.9284 19.4791C13.2896 21.8403 17.1179 21.8403 19.4791 19.4791C21.8403 17.1179 21.8403 13.2896 19.4791 10.9284L17.1402 8.58958C17.0521 8.80835 16.9461 9.05118 16.8193 9.31437C16.2594 10.4761 15.2939 12.0316 13.6625 13.663C12.0312 15.2943 10.4757 16.2598 9.31411 16.8195C9.05105 16.9463 8.80833 17.0522 8.58965 17.1403Z" />
    </svg>
  }

  @case('void-circle') {
    <svg width="1.75rem" height="1.75rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/tick-circle">
        <g id="tick-circle">
          <path
            class="stroke-blue-600"
            id="Vector"
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  }
}