import { Component, HostListener, output } from '@angular/core';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';

/**
 * Base side modal component providing slide-in drawer functionality.
 * Features:
 * - Right side slide-in panel
 * - Fixed width (42rem) with full height
 * - Close button in header
 * - Separate header and body sections with content projection
 * - Scrollable body content
 * - Dark mode support
 *
 * Usage:
 * ```html
 * <app-mhp-base-side-modal (close)="onClose()">
 *   <div slot="header">Header content here</div>
 *   Main content here
 * </app-mhp-base-side-modal>
 * ```
 */
@Component({
	selector: 'app-mhp-base-side-modal',
	imports: [MhpSvgIconComponent],
	templateUrl: './mhp-base-side-modal.component.html',
	styleUrls: ['./mhp-base-side-modal.component.scss'],
})
export class MhpBaseSideModalComponent {
	/** Event emitted when the user confirms the modal */
	onConfirm = output<boolean>();

	/** Event emitted when the modal should close (legacy) */
	close = output<void>();

	/** Event emitted when the modal should close */
	stateClose = output<boolean>();

	/** Handle ESC key for closing */
	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	/** Handle confirm action */
	public submitConfirm(): void {
		this.onConfirm.emit(true);
		this.handleClose();
	}

	/** Handle close action */
	public handleClose(): void {
		this.close.emit();
		this.stateClose.emit(true);
	}
}
