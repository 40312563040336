<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
	<div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

	<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
		<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
			<div
				class="p-6 relative flex flex-col flex-start items-start transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-md sm:max-w-lg"
			>
			<div class="flex flex-col justify-center items-center space-y-8 w-full py-8">
				<div class="flex flex-col">
					<span class="mb-6 text-center text-xl font-semibold text-gray-900 dark:text-white">Importing data from your stock</span>

					<div class="flex flex-col justify-center items-center mb-8 w-full space-y-4">
						<app-mhp-progressbar
							class="w-full min-w-[28rem]"
							[progress]="(this.importingItems() / this.totalItems()) * 100"
						/>
						<span class="text-center text-sm text-gray-500">{{ this.importingItems() }} of {{ this.totalItems() }} lines</span>
					</div>

					<span class="text-center text-sm text-gray-500">Do not close the window or navigate away from this page</span>
				</div>
			</div>
		</div>
	</div>
</div>
