import { OfferResponseTypeEnum } from '../enums/offer-response-type.enum';
import { UserFunctionEnum } from '../enums/user-function.enum';
import { CounterOffer } from '../models/counter-offer.model';
import { ExporterStockEntry } from '../models/exporter-stock-entry.model';
import { Offer } from '../models/offer.model';
import { PackageBatch } from '../models/package-batch.model';

export class OfferExtension {
	/** Returns the batch number or 'To order' if applicable */
	static getOfferLabel(offer: Offer): string {
		return offer.batchNumber ?? (offer.isToOrder ? 'To order' : '');
	}

	/** Checks if the offer is accepted based on user function */
	static isOfferAccepted(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return (
			userFunction === UserFunctionEnum.Importer ? offer.importerResponse === OfferResponseTypeEnum.Accepted
			: userFunction === UserFunctionEnum.Exporter ? offer.exporterResponse === OfferResponseTypeEnum.Accepted
			: false
		);
	}

	/** Checks if the offer is accepted by the importer */
	static importerAccepted(offer: Offer): boolean {
		return offer.importerResponse === OfferResponseTypeEnum.Accepted;
	}

	/** Checks if the offer is accepted by the exporter */
	static exporterAccepted(offer: Offer): boolean {
		return offer.exporterResponse === OfferResponseTypeEnum.Accepted;
	}

	/** Checks if an offer is ready for purchase */
	static isReadyToPurchase(offer: Offer): boolean {
		return (
			!offer.confirmationDate && this.exporterAccepted(offer) && this.importerAccepted(offer) && !!offer.batchNumber
		);
	}

	/** Gets the first valid batch from stock entries */
	static selectBatchNumberFromEntriesRule(entries: ExporterStockEntry[]): PackageBatch | null {
		return entries.find(e => e.batch)?.batch ?? null;
	}

	/** Checks if the offer has been declined by either party */
	static isOfferDeclined(offer: Offer): boolean {
		return (
			offer.exporterResponse === OfferResponseTypeEnum.Declined ||
			offer.importerResponse === OfferResponseTypeEnum.Declined
		);
	}

	/** Determines if an offer is ready to be ordered */
	static isReadyToOrder(offer: Offer): boolean {
		return (
			!this.isOfferDeclined(offer) &&
			!offer.orderingDate &&
			!!offer.confirmationDate &&
			!this.isWaitingForBatch(offer) &&
			(!!offer.importerValidatedBatchAt || !offer.batchUpdatedAt)
		);
	}

	/** Checks if offer is waiting for batch details */
	static isWaitingForBatch(offer: Offer): boolean {
		return !!offer.confirmationDate && (!offer.expirationDate || offer.quantity === 0 || !offer.batchNumber);
	}

	/** Validates if a new offer can be sent */
	static isValidNewOffer(offer: Offer): boolean {
		return !offer.createdAt && !!(offer.batchNumber || offer.isToOrder) && offer.quantity > 0;
	}

	/** Determines if counter offer is possible for the current user */
	static isCounterOfferPossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		if (this.isOfferDeclined(offer)) return false;

		const otherFunction =
			userFunction === UserFunctionEnum.Importer ? UserFunctionEnum.Exporter : UserFunctionEnum.Importer;

		return (
			!this.isOfferAccepted(offer, userFunction) &&
			(this.isOfferAccepted(offer, otherFunction) || userFunction === UserFunctionEnum.Importer)
		);
	}

	/** Checks if an offer's state has changed */
	static hasStateChanged(offer: Offer): boolean {
		return !!(<CounterOffer>offer).parentOfferId || this.importerAccepted(offer) || this.isImporterDeclined(offer);
	}

	/** Checks if offer is declined by importer */
	private static isImporterDeclined(offer: Offer): boolean {
		return offer.importerResponse === OfferResponseTypeEnum.Declined;
	}

	/** Checks if offer is in pending state for a given user function */
	static isInPending(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return !this.hasStateChanged(offer) && userFunction === UserFunctionEnum.Importer;
	}

	/** Checks if offer is in review state for a given user function */
	static isInReview(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return !offer.confirmationDate && !this.isOfferDeclined(offer);
	}

	/** Checks if offer is in confirmed state */
	static isInConfirmed(offer: Offer): boolean {
		return !!offer.confirmationDate && !offer.orderingDate && !this.isOfferDeclined(offer);
	}

	/** Checks if offer is in shipping state */
	static isInShipping(offer: Offer): boolean {
		return !!offer.orderingDate && !this.isOfferDeclined(offer);
	}

	/** Checks if importer can approve the offer */
	static canImporterApprove(offer: Offer): boolean {
		return !this.isWaitingForBatch(offer) && !this.isReadyToOrder(offer);
	}

	/** Checks if the offer can be accepted by the given user function */
	static isAcceptPossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		if (this.isOfferDeclined(offer)) {
			return false;
		}

		if (userFunction === UserFunctionEnum.Importer) {
			return !this.isOfferAccepted(offer, UserFunctionEnum.Importer);
		}

		if (userFunction === UserFunctionEnum.Exporter) {
			return this.importerAccepted(offer) && !this.exporterAccepted(offer) && !offer.confirmationDate;
		}

		return false;
	}

	/** Checks if the offer can be purchased by the given user function */
	static isPurchasePossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return this.isReadyToPurchase(offer) && userFunction === UserFunctionEnum.Importer;
	}

	/** Checks if the offer can be ordered by the given user function */
	static isOrderPossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return this.isReadyToOrder(offer) && userFunction === UserFunctionEnum.Importer;
	}

	/** Checks if the offer is wiating for an action */
	static isActionPossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return (
			OfferExtension.isAcceptPossible(offer, userFunction) ||
			OfferExtension.isCounterOfferPossible(offer, userFunction) ||
			OfferExtension.isPurchasePossible(offer, userFunction) ||
			OfferExtension.isOrderPossible(offer, userFunction) ||
			OfferExtension.isEditRequired(offer, userFunction)
		);
	}

	static isEditRequired(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return this.isEditPossible(offer, userFunction) && !offer.batchNumber;
	}

	static isEditPossible(offer: Offer, userFunction: UserFunctionEnum): boolean {
		return (
			!this.isOfferDeclined(offer) &&
			!!offer.confirmationDate &&
			!offer.orderingDate &&
			userFunction === UserFunctionEnum.Exporter
		);
	}
}
