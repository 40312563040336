import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { bootstrapArrowDown, bootstrapArrowUp } from '@ng-icons/bootstrap-icons';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsBulk, saxCardsBulk } from '@ng-icons/iconsax/bulk';

import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Company } from 'src/app/_common/models/company.model';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MainDashboardRequestsComponent } from './main-dashboard-requests/main-dashboard-requests.component';

@Component({
	selector: 'app-main-dashboard',
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpButtonComponent,
		MainDashboardRequestsComponent,
		MhpSvgIconComponent,
	],
	providers: [
		provideIcons({
			bootstrapArrowUp,
			bootstrapArrowDown,
			saxCardsBulk,
			saxBuildingsBulk,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styleUrl: './main-dashboard.component.scss',
	templateUrl: './main-dashboard.component.html',
})
export class MainDashboardComponent implements OnInit {
	private readonly _apiCompanySvc = inject(ApiCompanyService);
	private readonly _accountSvc = inject(AccountService);

	public readonly sitemap = SITEMAP;
	public readonly userFunctions = UserFunctionEnum;

	public readonly exporters = signal<Company[]>([]);
	public readonly user = computed<User | null>(this._accountSvc.user);

	public ngOnInit(): void {
		// Only fetch if we don't have exporters yet
		if (!this.exporters().length)
			this._apiCompanySvc.getAll().then(response => {
				if (response.ok) this.exporters.set(response.body || []);
			});
	}
}
