import { Component, computed, inject, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline, saxBuildingsOutline, saxMoreOutline, saxSmsOutline } from '@ng-icons/iconsax/outline';

import { CommonModule, CurrencyPipe, DatePipe, I18nPluralPipe } from '@angular/common';
import { Company } from 'src/app/_common/models/company.model';
import { ExporterQuota } from 'src/app/_common/models/exporter-quota.model';
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { Package } from 'src/app/_common/models/package.model';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { toDictionary } from 'src/app/_common/utils/toDictionary';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpCompanyComponent } from '../../_common/components/mhp-company/mhp-company.component';
import { ToCipPipe } from '../../_common/pipes/to-cip/to-cip.pipe';
import { MainPackageRequestProductModalComponent } from './main-package-request-product-modal/main-package-request-product-modal.component';
import { MainPackageRequest } from './main-package-request-product-modal/main-package-request-product-modal.model';

@Component({
	selector: 'app-main-package',
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		ToCipPipe,
		MhpButtonComponent,
		MhpCompanyComponent,
		MainPackageRequestProductModalComponent,
		DatePipe,
		CurrencyPipe,
		I18nPluralPipe,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxMoreOutline,
			saxSmsOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './main-package.component.html',
})
// TODO: rename to ProductComponent to match the route /product/:cip
export class MainPackageComponent {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly companyService = inject(CompanyService);
	private readonly _apiPackageSvc = inject(ApiPackageService);

	public readonly sitemap = SITEMAP;

	readonly distinctExporters = computed(
		() =>
			this.stocks()
				.map(stock => stock.exporterId)
				.filter((value, index, self) => self.indexOf(value) === index).length
	);

	readonly exporters = computed(this.companyService.allCompanies);

	readonly package = signal<Package | null>(null);

	readonly productsSum = computed(() =>
		this.stocks().reduce(
			(acc, stock) => acc + ((stock as ExporterStockEntry)?.quantity ?? 0 + ((stock as ExporterQuota)?.quota ?? 0)),
			0
		)
	);

	readonly requestProductModal = signal(false);
	selectedManifestItem: MainPackageRequest | null = null;
	selectedExporter: Company | null = null;
	readonly stocks = signal<(ExporterStockEntry | ExporterQuota)[]>([]);
	readonly quotasWithStocks = signal<Record<number, ExporterQuota>>([]);
	readonly quotasWithoutStock = signal<ExporterQuota[]>([]);

	readonly cip = signal('');
	// TODO: View switching feature in development - currently supports list (1) and grid (2) views
	readonly view = signal(1);

	constructor() {
		this.activatedRoute.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.cip.set(paramMap.get('cip')!);

			const product =
				this.cip().length === 13 ? await this._apiPackageSvc.getByCIP13(+paramMap.get('cip')!)
				: this.cip().length === 7 ? await this._apiPackageSvc.getByCIP7(+paramMap.get('cip')!)
				: null;

			this.package.set(product);

			if (product) {
				const stocks: ExporterStockEntry[] = await this._apiPackageSvc.getStocksForCIP13(product.cip13);
				const stockCip13 = new Set(stocks.map(stock => stock.packageCip13));
				const quotas = await this._apiPackageSvc.getQuotasForCIP13(product.cip13);
				const quotasWithStock = quotas.filter(quota => stockCip13.has(quota.packageCip13));
				const quotasWithoutStock = quotas.filter(quota => !stockCip13.has(quota.packageCip13));

				this.quotasWithStocks.set(toDictionary(quotasWithStock, quota => quota.packageCip13));
				this.quotasWithoutStock.set(quotasWithoutStock);
				this.stocks.set([...stocks, ...quotasWithoutStock]);
			}
		});
	}

	getExporter = (id: number): Company | null => this.companyService.getCompanyById(id);

	requestStock(stock: ExporterStockEntry) {
		this.selectedManifestItem = stock;
		this.selectedExporter = this.getExporter(stock.exporterId);
		this.requestProductModal.set(true);
	}

	getQuota(stockOrQuota: ExporterStockEntry | ExporterQuota): ExporterQuota | null {
		let quota = stockOrQuota as ExporterQuota;
		if (quota.quota) {
			return quota;
		} else {
			quota = this.quotasWithStocks()[stockOrQuota.packageCip13];
			if (quota) {
				return this.quotasWithStocks()[stockOrQuota.packageCip13];
			} else return null;
		}
	}

	castToStock(stockOrQuota: ExporterStockEntry | ExporterQuota): ExporterStockEntry | null {
		return stockOrQuota as ExporterStockEntry;
	}
}
