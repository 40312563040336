import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [],
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent {

  constructor() {}

}
