import { CommonModule } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { iconoirRefreshDouble } from '@ng-icons/iconoir';
import {
	saxArrowDown1Outline,
	saxCandleOutline,
	saxAddOutline,
	saxDocumentTextOutline,
	saxSend2Outline,
	saxEyeOutline,
	saxNote2Outline,
	saxImportOutline,
	saxBook1Outline,
	saxBookmark2Outline,
} from '@ng-icons/iconsax/outline';

@Component({
	selector: 'app-mhp-button',
	standalone: true,
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			iconoirRefreshDouble,
			saxAddOutline,
			saxArrowDown1Outline,
			saxBook1Outline,
			saxBookmark2Outline,
			saxCandleOutline,
			saxDocumentTextOutline,
			saxEyeOutline,
			saxImportOutline,
			saxNote2Outline,
			saxSend2Outline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrl: './mhp-button.component.scss',
	templateUrl: './mhp-button.component.html',
})
export class MhpButtonComponent {
	public readonly prefix = input<string>();
	public readonly suffix = input<string>();
	public readonly text = input<string>('');
	public readonly type = input<'primary' | 'secondary' | 'tertiary'>('primary');
	public readonly size = input<'normal' | 'large'>('normal');
	public readonly disabled = input(false);
}
