import { Component, HostListener, inject, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SITEMAP, SiteMap } from '../../sitemap';
import { Package } from '../../models/package.model';
import { ApiSearchService } from '../../services/api/api-search/api-search.service';
import { ApiPackageService } from '../../services/api/api-package/api-package.service';
import { MhpLoaderComponent } from "../mhp-loader/mhp-loader.component";
import { MhpSearchModalItemComponent } from "./mhp-search-modal-item/mhp-search-modal-item.component";
import { bestSellers } from './best-sellers'; // best sellers packages for demo
import { AutoFocusDirective } from '../../directives/auto-focus/auto-focus.directive';

@Component({
	selector: 'app-mhp-search-modal',
	standalone: true,
	imports: [
		FormsModule,
		AutoFocusDirective,
		MhpLoaderComponent,
		MhpSearchModalItemComponent
	],
	styleUrl: './mhp-search-modal.component.scss',
	templateUrl: './mhp-search-modal.component.html'
})
export class MhpSearchModalComponent {

	public stateClose = output<boolean>();

	private _search: ApiSearchService = inject(ApiSearchService);
	private _package: ApiPackageService = inject(ApiPackageService);

	public sitemap: SiteMap = SITEMAP;
	public searchPackages = signal<Package[]>(bestSellers);
	public searchText: string = '';
	public clickedInside: boolean = false;
	public isLoading: boolean = false;
	public isSearching: boolean = false;

	public async search(): Promise<void> {
		if(!this.searchText)
			return;

		this.searchPackages = signal<Package[]>([]);
		this.isLoading = true;
		this.isSearching = true;

		try {
			this.searchPackages.set((this.searchText.match(/[0-9]{7}/)
				? [(await this._package.getByCIP7(+this.searchText)).body!]
				: this.searchText.match(/[0-9]{13}/)
					? [(await this._package.getByCIP13(+this.searchText)).body!]
					: (await this._search.searchPackages(this.searchText)).body) || []);
		}

		catch (err: unknown) {}

		this.isLoading = false;
	}

	public parseSearch(): void {
		if(this.searchText.match(/^[0-9]{5} ([0-9]{3} ){2}[0-9] [0-9]/g))
			this.searchText = this.searchText.split(' ').join('');
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if(e.keyCode === 27)
			this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if(!this.clickedInside)
			this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
