import { inject, Injectable } from '@angular/core';
import { ExporterQuotaEntry } from '../../models/exporter-quota-entry.model';
import { TableHandler } from '../../utils/table-handler';
import { ApiPackageService } from '../api/api-package/api-package.service';

@Injectable({
	providedIn: 'root',
})
export class QuotaTableService extends TableHandler<ExporterQuotaEntry> {
	constructor() {
		super(
			inject(ApiPackageService),
			'update-quota',
			row =>
				<ExporterQuotaEntry>{
					packageCip13: +row['CIP 13'],
					name: row['Product name'],
					quota: +row['Quota'],
				}
		);
	}
}
