import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { MhpProgressbarComponent } from 'src/app/_common/components/mhp-progressbar/mhp-progressbar.component';

@Component({
	selector: 'offers-loading',
	imports: [MhpProgressbarComponent],
	templateUrl: './offers-loading.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffersLoadingComponent {
	public readonly importingItems = input.required<number>();
	public readonly totalItems = input.required<number>();
}
