import { Component, computed, ElementRef, EventEmitter, inject, input, signal, viewChild } from '@angular/core';
import { MhpButtonComponent } from '../../../../_common/components/mhp-button/mhp-button.component';
import { MhpInputComponent } from '../../../../_common/components/mhp-input/mhp-input.component';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import dayjs from 'dayjs';
import { getGravatarUrl } from 'src/app/_common/utils/get-gravatar-url.util';

@Component({
	selector: 'app-main-request-offer-history-discussion',
	standalone: true,
	imports: [MhpButtonComponent, MhpInputComponent],
	templateUrl: './main-request-offer-history-discussion.component.html',
	styleUrl: './main-request-offer-history-discussion.component.scss',
})
export class MainRequestOfferHistoryDiscussionComponent {
	// readonly _messaging = inject(MessagingService);
	// readonly _apiMessaging = inject(ApiMessagingService);
	readonly _company = inject(CompanyService);

	// readonly room = input.required<CompanyChatRoom>();
	// readonly otherParticipant = computed(() =>
	// 	this.room().participants.find(participant => participant.id !== this._company.currentCompany()!.id)
	// );

	// readonly fetchedMessages = signal<CompanyChatMessage[]>([]);
	// readonly streamedMessages = signal<CompanyChatMessage[]>([]);

	// readonly messages = computed(() => {
	// 	// Explicitly declaring these as const to keep signal references distinct per call
	// 	const fetched = this.fetchedMessages();
	// 	const streamed = this.streamedMessages();

	// 	return fetched.concat(streamed).sort((a, b) => +dayjs(a.createdAt).diff(dayjs(b.createdAt)));
	// });

	shouldUpdateView = new EventEmitter<void>();

	readonly draftMessage = signal<string | undefined>(undefined);
	protected readonly getGravatarUrl = getGravatarUrl;

	public async sendMessage() {}

	public scrollToBottom() {
		// Delay 100ms to allow for view to update
		setTimeout(() => {
			let el = document.querySelector('.mhp-messaging-conversation-messages');

			if (el) {
				el.scrollTop = el.scrollHeight;
			}
		}, 100);
	}

	conversationEl = viewChild<ElementRef>('conversation');

	public async ngOnInit() {
		// Scroll conversation to bottom
		this.shouldUpdateView.subscribe(() => {
			this.scrollToBottom();
		});

		// this._messaging.onNewMessage.subscribe(message => {
		// 	console.log(`Received new message.`, message);

		// 	if (message.roomId === this.room().id) {
		// 		this.streamedMessages.update((messages: CompanyChatMessage[]): CompanyChatMessage[] => [...messages, message]);
		// 		this.shouldUpdateView.emit();
		// 	}
		// });

		// const stream = await this._messaging.getMessages(this.room().id);

		// if (stream) {
		// 	stream.subscribe({
		// 		next: message => {
		// 			this.fetchedMessages.update((msgs: CompanyChatMessage[]) => [...msgs, message]);
		// 		},
		// 		complete: () => {
		// 			console.log(`Completed download for messages in room ${this.room().id}`, this.fetchedMessages());
		// 			this.shouldUpdateView.emit();
		// 		},
		// 		error: console.error,
		// 	});
		// }
	}

	public async ngAfterViewInit() {
		this.scrollToBottom();
	}
}
