/**
 * Action on an offer
 */
export enum OfferActionEnum {
	Create = 'Create',
	CounterOffer = 'CounterOffer',
	Accept = 'Accept',
	ValidateBatch = 'ValidateBatch',
	Purchase = 'Purchase',
	Order = 'Order',
	Update = 'Update',
	Decline = 'Decline',
}
