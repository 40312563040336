import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { bootstrapPencilSquare } from '@ng-icons/bootstrap-icons';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';

import { derivedAsync } from 'ngxtension/derived-async';
import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';
import { MainStockOfferEnumTabs } from 'src/app/_common/enums/main-stock-offer-tabs.enum';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { environment } from '../../../environments/environment';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpPackageComponent } from '../../_common/components/mhp-package/mhp-package.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpTabsComponent } from '../../_common/components/mhp-tabs/mhp-tabs.component';
import { ExporterQuota } from '../../_common/models/exporter-quota.model';
import { ExporterStockEntry } from '../../_common/models/exporter-stock-entry.model';
import { CompanyService } from '../../_common/services/company/company.service';
import { sortByLocale } from '../../_common/utils/sorting.util';

@Component({
	selector: 'app-main-stock-offer',
	imports: [
		RouterModule,
		CommonModule,
		MhpButtonComponent,
		MhpSvgIconComponent,
		CurrencyPipe,
		MhpTabsComponent,
		MhpPackageComponent,
	],
	providers: [
		provideIcons({
			bootstrapPencilSquare,
			saxInfoCircleOutline,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './main-stock-offer.component.html',
})
export class MainStockOfferComponent {
	private readonly _accountSvc = inject(AccountService);
	private _companySvc = inject(CompanyService);
	private readonly _apiExporterSvc = inject(ApiExporterService);

	public readonly sitemap = SITEMAP;

	public readonly tabs: MHPTab<MainStockOfferEnumTabs>[] = [
		{ text: 'All', value: MainStockOfferEnumTabs.All },
		{ text: 'My stock', value: MainStockOfferEnumTabs.MyStock },
		{ text: 'My quotas', value: MainStockOfferEnumTabs.MyQuotas },
	];

	public readonly user = computed<User | null>(this._accountSvc.user);

	public readonly stockQuotas = derivedAsync<ExporterQuota[]>(async () => {
		const exporterId = this._companySvc.currentCompany()?.id;

		if (!exporterId) {
			return [];
		}

		const quotas = await this._apiExporterSvc.getQuotas(exporterId);

		return quotas;
	});

	public readonly stockItems = derivedAsync<ExporterStockEntry[]>(async () => {
		const exporterId = this._companySvc.currentCompany()?.id;

		if (!exporterId) throw new Error('Exporter ID not found');

		return await this._apiExporterSvc.getStock(exporterId);
	});

	public mergedStockItems = computed<(ExporterStockEntry & ExporterQuota)[]>(() => {
		const stockItems = this.stockItems() ?? [];
		const stockQuotas = this.stockQuotas() ?? [];

		return <(ExporterStockEntry & ExporterQuota)[]>(
			[...stockItems, ...stockQuotas].sort((a, b) => sortByLocale(a.packageShortName, b.packageShortName))
		);
	});

	public tabSelected = signal(this.tabs[0]);

	public readonly quotasVisible = computed<boolean>(() => {
		return this.tabSelected() == this.tabs[0] || this.tabSelected() == this.tabs[2];
	});

	public readonly stocksVisible = computed<boolean>(() => {
		return this.tabSelected() == this.tabs[0] || this.tabSelected() == this.tabs[1];
	});

	public viewItems = computed<(ExporterStockEntry & ExporterQuota)[]>(() => {
		switch (this.tabSelected()) {
			case this.tabs[0]:
				return this.mergedStockItems();
			case this.tabs[1]:
				return <(ExporterStockEntry & ExporterQuota)[]>this.stockItems();
			case this.tabs[2]:
				return <(ExporterStockEntry & ExporterQuota)[]>this.stockQuotas();
			default:
				throw new Error('Invalid tab selected');
		}
	});

	public userFunctions = UserFunctionEnum;
	public stockDumpUri = computed(
		() => environment.API_URL + `/exporter/${this._companySvc.currentCompany()?.id}/stock/dump`
	);
	public quotaDumpUri = computed(
		() => environment.API_URL + `/exporter/${this._companySvc.currentCompany()?.id}/quota/dump`
	);
}
