import crypto from 'crypto-js';
import { Component, computed, inject } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxEditOutline } from '@ng-icons/iconsax/outline';

import { CompanyService } from 'src/app/_common/services/company/company.service';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ToUserFunctionPipe } from 'src/app/_common/pipes/to-user-function/to-user-function.pipe';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { MhpCompanyComponent } from '../../_common/components/mhp-company/mhp-company.component';
import { Company } from 'src/app/_common/models/company.model';

@Component({
	selector: 'app-main-account',
	imports: [NgIconComponent, MhpInputComponent, ToUserFunctionPipe, MhpCompanyComponent],
	providers: [
		provideIcons({
			saxEditOutline,
		}),
		provideNgIconsConfig({ size: '1.25rem' }),
	],
	styleUrl: './main-account.component.scss',
	templateUrl: './main-account.component.html',
})
export class MainAccountComponent {
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);

	public readonly company = computed<Company | null>(this._companySvc.currentCompany);
	public readonly user = computed<User | null>(this._accountSvc.user);

	constructor() {}

	public getGravatarUrl(email: string, size: number = 64): string {
		const trimmedEmail: string = email.trim().toLowerCase();
		const hash: string = crypto.SHA256(trimmedEmail).toString();

		return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=identicon`;
	}
}
