<div class="mhp-button-group-component">

  @for (button of this.buttons(); track button) {
  <button
    class="mhp-button-group-component-button"
    [ngClass]="this.choice() === button.value ? 'active' : ''"
    (click)="this.choose(button.value)">
    {{ button.text }}

    @if (button.ping) {
    <span class="relative flex h-2 w-2 ml-3">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-full w-full bg-blue-500"></span>
    </span>
    }
  </button>
  }

</div>