import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AppInsightsService {
	private readonly connectionString: string = environment.appInsights.connectionString;

	public appInsights: ApplicationInsights;

	constructor() {
		this.appInsights = new ApplicationInsights({
			config: {
				connectionString: this.connectionString,
				enableAutoRouteTracking: true,
			},
		});

		this.appInsights.loadAppInsights();
	}

	public logPageView(name?: string, url?: string): void {
		this.appInsights.trackPageView({ name: name, uri: url });
	}

	public logEvent(name: string, properties?: { [key: string]: any }): void {
		this.appInsights.trackEvent({ name: name }, properties);
	}

	public logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
		this.appInsights.trackMetric({ name: name, average: average }, properties);
	}

	public logException(exception: Error, severityLevel?: number): void {
		this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
	}

	public logTrace(message: string, properties?: { [key: string]: any }): void {
		this.appInsights.trackTrace({ message: message }, properties);
	}
}
