<app-mhp-base-modal
  [title]="'Edit Offer'"
  [showCloseButton]="true"
  (stateClose)="handleClose()"
	[actionButtonType]="'primary'"
	[actionButtonPrefix]="'saxSave2Outline'"
	actionButtonText="Yes, I update this offer"
	(action)="save()"
>

  <div class="flex flex-col gap-4 border rounded-2xl p-6 w-full">

		<app-mhp-company [company]="exporter()" />

		<div class="mb-6">{{ offer().shortName }}</div>

    <div class="flex flex-col">
      <span class="mb-2 text-sm text-[var(--text-secondary)]">Batch Number</span>
      <app-mhp-input
        type="text"
        [(value)]="batchNumber"
      />
    </div>

    <div class="flex flex-col">
      <span class="mb-2 text-sm text-[var(--text-secondary)]">Expiration Date</span>
      <app-mhp-input
        type="date"
        [(value)]="expirationDate"
      />
    </div>

    <div class="flex flex-col">
      <span class="mb-2 text-sm text-[var(--text-secondary)]">Quantity</span>
      <app-mhp-input
        [min]="1"
        [stepper]="true"
        suffix="Units"
        type="number"
        [(value)]="quantity"
      />
    </div>

    <div class="flex flex-col">
      <span class="mb-2 text-sm text-[var(--text-secondary)]">Unit Cost</span>
      <app-mhp-input
        [min]="0"
        [step]="0.01"
        [stepper]="true"
        suffix="€"
        type="number"
        [(value)]="price"
      />
    </div>

  </div>
</app-mhp-base-modal>
