<div class="relative z-10" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="side-modal-aside">
    <div class="side-modal-content">
      <div class="side-modal-header">
        <div class="flex flex-row mb-6">
          <button class="flex flex-row justify-center item-center" (click)="handleClose()">
            <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
          </button>
        </div>

        <ng-content select="[slot=header]"></ng-content>
      </div>

      <div class="side-modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>