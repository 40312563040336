<header class="fixed top-0 w-full z-10 bg-white dark:bg-gray-800">
  <nav class="flex items-center justify-between px-6 py-4 lg:px-24 mhp-header-nav" aria-label="Global">
    <div class="inline-flex flex-row items-center lg:flex-1">
      <a [routerLink]="[ this.user() ? this.sitemap.dashboard.route : this.sitemap.main.route ]" class="flex -m-1.5 p-1.5">
        <span class="sr-only">MedHubPlace</span>
        <app-mhp-svg-logo class="w-[149px] h-[40px]" />
      </a>

      <app-mhp-label class="ml-2 mt-1" label="Beta" />
    </div>

    <div class="flex lg:hidden">
      <button (click)="this.isMenuOpened = true" type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-white">
        <span class="sr-only">Open main menu</span>
        <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
    </div>
    <div class="hidden lg:flex lg:gap-x-6">
      <a
        [routerLink]="[ this.sitemap.dashboard.route ]"
        [ngClass]="this.router.url === this.sitemap.dashboard.route ? 'bg-blue-50 dark:bg-blue-900': ''"
        class="mhp-header-nav-link"
      >
        <ng-icon
          class="mr-2"
          [ngClass]="this.router.url === this.sitemap.dashboard.route ? 'text-blue-500' : 'text-gray-900 dark:text-white'"
          [name]="this.router.url === this.sitemap.dashboard.route ? 'saxHome2Bulk' : 'saxHome2Outline'"
        />
        Dashboard
      </a>

      @if (this.user() && [userFunctions.Exporter, userFunctions.ImporterExporter].includes(this.user()!.function)) {
        <a
          [routerLink]="[ this.sitemap.stockOffer.route ]"
          [ngClass]="this.router.url === this.sitemap.stockOffer.route ? 'bg-blue-50 dark:bg-blue-900': ''"
          class="mhp-header-nav-link"
        >
          <ng-icon
            class="mr-2"
            [ngClass]="this.router.url === this.sitemap.stockOffer.route ? 'text-blue-500' : 'text-gray-900 dark:text-white'"
            [name]="this.router.url === this.sitemap.stockOffer.route ? 'saxBookBulk' : 'saxBook1Outline'"
          />
          My product catalog
        </a>
      }

      @if (this.user() && [userFunctions.Importer, userFunctions.ImporterExporter].includes(this.user()!.function)) {
        <a
          class="mhp-header-nav-link"
          (click)="this.openSearchModal()"
        >
          <ng-icon class="mr-2" name="saxSearchNormal1Outline" />
          Find a product
        </a>
      }
    </div>

    <div class="items-center hidden lg:flex lg:flex-1 lg:justify-end">
      <app-mhp-theme-switch class="mr-6" />

      @if(this.user()) {
        <app-main-header-account [user]="this.user()!" />
      }

      @else {
        <a
          [routerLink]="[ this.sitemap.auth.route ]"
          class="text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >Log in <span aria-hidden="true">&rarr;</span></a>
      }
    </div>
  </nav>

  @if (this.isMenuOpened) {
    <div class="lg:hidden">
      <div
        class="fixed inset-0 z-10"
        (click)="this.isMenuOpened = false"
      ></div>

      <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white dark:bg-gray-800 px-6 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex flex-row justify-between items-start">
          <a
            class="flex"
            [routerLink]="[ this.user() ? this.sitemap.dashboard.route : this.sitemap.main.route ]"
            (click)="this.isMenuOpened = false"
          >
            <span class="sr-only">MedHubPlace</span>
            <app-mhp-svg-logo class="w-72" />
          </a>

          <button (click)="this.isMenuOpened = false" type="button" class="-mx-2.5 rounded-md p-2.5 text-gray-700 dark:text-white">
            <span class="sr-only">Close menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a
                class="mhp-header-nav-mobile-link"
                [ngClass]="this.router.url === this.sitemap.dashboard.route ? 'bg-blue-50 dark:bg-blue-900': ''"
                [routerLink]="[ this.sitemap.dashboard.route ]"
                (click)="this.isMenuOpened = false"
              >
                <ng-icon
                  [ngClass]="this.router.url === this.sitemap.dashboard.route ? 'text-blue-500' : 'text-gray-900 dark:text-white'"
                  [name]="this.router.url === this.sitemap.dashboard.route ? 'saxHome2Bulk' : 'saxHome2Outline'"
                />
                <span>Dashboard</span>
              </a>

              @if (this.user() && [userFunctions.Exporter, userFunctions.ImporterExporter].includes(this.user()!.function)) {
                <a
                  class="mhp-header-nav-mobile-link"
                  [ngClass]="this.router.url === this.sitemap.stockOffer.route ? 'bg-blue-50 dark:bg-blue-900': ''"
                  [routerLink]="[ this.sitemap.stockOffer.route ]"
                  (click)="this.isMenuOpened = false"
                >
                  <ng-icon
                    [ngClass]="this.router.url === this.sitemap.stockOffer.route ? 'text-blue-500' : 'text-gray-900 dark:text-white'"
                    [name]="this.router.url === this.sitemap.stockOffer.route ? 'saxBookBulk' : 'saxBook1Outline'"
                  />
                  <span>My product catalog</span>
                </a>
              }

              @if (this.user() && [userFunctions.Importer, userFunctions.ImporterExporter].includes(this.user()!.function)) {
                <a
                  class="mhp-header-nav-mobile-link"
                  (click)="this.openSearchModal(); this.isMenuOpened = false"
                >
                  <ng-icon name="saxSearchNormal1Outline" />
                  <span>Find a product</span>
                </a>
              }
            </div>

            <div class="py-6">
              @if(!this.user()) {
                <a
                  [routerLink]="[ this.sitemap.auth.route ]"
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-medium leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
                >Log in</a>
              }

              @else {
                <app-main-header-account [user]="this.user()!" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</header>

@if (this.searchModal) {
  <app-mhp-search-modal (stateClose)="this.searchModal = false" />
}
