import { Component, input, Input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline } from '@ng-icons/iconsax/outline';

@Component({
	selector: 'app-new-request-button',
	standalone: true,
	imports: [NgIconComponent],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './new-request-button.component.html',
})
export class NewRequestButtonComponent {
	public readonly disabled = input<boolean>(false);
	public readonly text = input<string>('');
	public readonly title = input<string>('');
}
