import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import {
	saxArrowRight3Outline,
	saxBuildingsOutline,
	saxExport1Outline,
	saxTruckOutline,
} from '@ng-icons/iconsax/outline';

import { MHPButton } from 'src/app/_common/components/mhp-button-group/mhp-button.interface';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { ImporterRequest } from '../../_common/models/importer-request.model';
import { Offer } from '../../_common/models/offer.model';

import { filter, from, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { OfferStatusTabEnum } from 'src/app/_common/enums/offer-status-tab.enum';
import { ImporterRequestExtension } from 'src/app/_common/extensions/importer-request.extension';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { RequestEventDispatcher } from 'src/app/_common/services/request/request.event.dispatcher';
import { RequestService } from 'src/app/_common/services/request/request.service';
import { MhpButtonGroupComponent } from '../../_common/components/mhp-button-group/mhp-button-group.component';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpStatusLabelComponent } from '../../_common/components/mhp-status-label/mhp-status-label.component';

import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { SITEMAP } from 'src/app/_common/sitemap';
import { MhpTaskCardComponent } from '../../_common/components/mhp-task-card/mhp-task-card.component';
import { CsvExportService } from '../../_common/services/csv-export/csv-export.service';
import { OffersSummaryComponent } from './offers-summary/offers-summary.component';
import { OffersWaitingComponent } from './offers-waiting/offers-waiting.component';

@Component({
	selector: 'app-main-request',
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpButtonComponent,
		MhpButtonGroupComponent,
		MhpStatusLabelComponent,
		OffersSummaryComponent,
		OffersWaitingComponent,
		MhpTaskCardComponent,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxTruckOutline,
			saxExport1Outline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './main-request.component.html',
})
export class MainRequestComponent implements OnInit, OnDestroy {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly router = inject(Router);
	private readonly requestService = inject(RequestService);
	private readonly accountService = inject(AccountService);
	private readonly csvExportService = inject(CsvExportService);
	readonly offerService = inject(OffersService);

	protected readonly sitemap = SITEMAP;

	readonly RequestTypeEnum = RequestTypeEnum;
	readonly UserFunctionEnum = UserFunctionEnum;
	readonly OfferStatusTabEnum = OfferStatusTabEnum;
	readonly OfferExtension = OfferExtension;
	readonly CompanyExtension = CompanyExtension;
	readonly userFunction = computed<UserFunctionEnum>(() => this.accountService.userFunction());

	displayedId = '';
	public request: ImporterRequest = null!;

	readonly statusLabel: MHPButton<OfferStatusTabEnum>[] = [
		{ text: 'Required Actions', value: OfferStatusTabEnum.waiting, ping: false },
		{ text: 'All', value: OfferStatusTabEnum.all, ping: false },
	];

	selectedTab: OfferStatusTabEnum = OfferStatusTabEnum.waiting;

	company = computed(() => this.request.exporters![0]);

	private readonly onDestroy$ = new Subject<void>();

	ngOnInit() {
		this.activatedRoute.paramMap
			.pipe(
				map(paramMap => {
					this.updateSelectedStatusFromParams(paramMap);
					return paramMap.get('id');
				}),
				filter(id => id !== null && id != this.request?.id),
				switchMap(id => this.loadAndSubscribeToRequest(id!))
			)
			.subscribe();

		this.requestService.onReconnected$
			.pipe(takeUntil(this.onDestroy$))
			.pipe(switchMap(id => this.loadAndSubscribeToRequest(this.request?.id)))
			.subscribe();
	}

	private updateSelectedStatusFromParams(paramMap: ParamMap): void {
		const tab = paramMap.get('tab');
		if (tab) {
			this.selectedTab = OfferStatusTabEnum[tab as keyof typeof OfferStatusTabEnum];
			this.setPing(this.selectedTab, false);
		} else {
			this.selectedTab = OfferStatusTabEnum.all;
		}
	}

	private loadAndSubscribeToRequest(id: string): Observable<void> {
		return from(this.requestService.getRequestAndSubscribeToChanges(id)).pipe(
			map(dispatcher => {
				this.subscribeToNewOfferEvents(dispatcher);
				return dispatcher.request;
			}),
			filter((request: ImporterRequest) => !!request),
			tap((request: ImporterRequest) => {
				this.sortRequestItems(request);
				this.request = request;
				this.displayedId = ImporterRequestExtension.getUIRequestId(request.id, request.userRequestId);
			}),
			switchMap(request => from(this.offerService.initRequest(request)))
		);
	}

	private subscribeToNewOfferEvents(dispatcher: RequestEventDispatcher): void {
		dispatcher.newOfferEvent$.pipe(takeUntil(this.onDestroy$)).subscribe(async offer => {
			this.setNotificationBadgeOnButton(offer, true);
			this.offerService.onOfferUpdatedOrAdded(offer);
		});
	}

	private setNotificationBadgeOnButton(offer: Offer, value: boolean): void {
		if (!offer) return;

		if (this.selectedTab !== OfferStatusTabEnum.waiting && OfferExtension.isActionPossible(offer, this.userFunction()))
			this.setPing(OfferStatusTabEnum.waiting, value);

		if (this.selectedTab !== OfferStatusTabEnum.all) this.setPing(OfferStatusTabEnum.all, value);
	}

	private setPing(status: OfferStatusTabEnum, value: boolean): void {
		const button = this.statusLabel.find(s => s.value === status);
		if (!button) throw Error(`Button ${status} not found`);
		button.ping = value;
	}

	private sortRequestItems(request: ImporterRequest): void {
		request.items = request.items.sort((a, b) => a.package.shortName!.localeCompare(b.package.shortName!));
	}

	public gotoTab(offerStatus: OfferStatusTabEnum | undefined, useRouter = false): boolean {
		if (offerStatus == undefined || this.selectedTab === offerStatus) {
			return false;
		}

		if (useRouter) {
			this.router.navigate(['../' + OfferStatusTabEnum[offerStatus]], {
				relativeTo: this.activatedRoute,
			});
		} else {
			this.selectedTab = offerStatus;
		}

		return true;
	}

	async validateEditedOffers() {
		await this.offerService.validateEditedOffers();
	}

	/**
	 * Export offers to CSV file
	 */
	exportOffersToCsv(): void {
		this.csvExportService.exportOffersToCsv(
			this.offerService.currentRequestOffers(),
			this.offerService.requestItemsDico(),
			this.userFunction(),
			this.displayedId
		);
	}

	ngOnDestroy() {
		if (this.request?.id) {
			this.requestService.unsubscribeToRequestChanges(this.request.id);
		}
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
