import { Component, computed, inject } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxEditOutline } from '@ng-icons/iconsax/outline';

import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { User } from 'src/app/_common/models/user.model';
import { ToUserFunctionPipe } from 'src/app/_common/pipes/to-user-function/to-user-function.pipe';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpCompanyComponent } from '../../_common/components/mhp-company/mhp-company.component';

@Component({
	selector: 'app-main-account',
	imports: [NgIconComponent, MhpInputComponent, ToUserFunctionPipe, MhpCompanyComponent],
	providers: [
		provideIcons({
			saxEditOutline,
		}),
		provideNgIconsConfig({ size: '1.25rem' }),
	],
	styleUrl: './main-account.component.scss',
	templateUrl: './main-account.component.html',
})
export class MainAccountComponent {
	private readonly _accountSvc = inject(AccountService);
	readonly user = computed<User>(this._accountSvc.user);
	readonly CompanyExtension = CompanyExtension;

	constructor() {}
}
