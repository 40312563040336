import { CommonModule } from '@angular/common';
import { Component, HostBinding, input } from '@angular/core';
import { MhpTooltipComponent } from '../mhp-tooltip/mhp-tooltip.component';
/**
 * This component provides a structure with two ng-content slots:
 * 1. One for the label (with select="[label]")
 * 2. One for the content/field (with select="[content]")
 *
 * @export
 * @class MhpLabeledFieldComponent
 */
@Component({
	selector: 'app-mhp-labeled-field',
	standalone: true,
	imports: [CommonModule, MhpTooltipComponent],
	templateUrl: './mhp-labeled-field.component.html',
})
export class MhpLabeledFieldComponent {
	@HostBinding('class') class = 'flex flex-col w-full gap-4';

	label = input.required<string>();
	info = input<string>();
}
