<app-mhp-base-modal
  [actionButtonText]="buttonText()"
  [actionButtonPrefix]="'saxBookmark2Outline'"
  (action)="this.submitConfirm()"
  (close)="this.close()">
  <div class="flex flex-col space-y-8">
    <div class="flex flex-col space-y-4">
      <h3 class="text-xl font-semibold">
        <ng-content select="[slot='question']"></ng-content>
      </h3>
      <p class="opacity-60">
        <ng-content select="[slot='remark']"></ng-content>
      </p>
    </div>

    <app-mhp-from-to [from]="this.user()" [to]="[this.exporter()]" />

    <div class="flex flex-col">
      <span class="mb-4 text-sm text-[var(--text-secondary)]">Products</span>
      <div class="flex flex-row items-center">
        <div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0">
          <app-mhp-svg-icon icon="pill" [size]="16" />
        </div>

        @if (this.offers().length == 1) {
        <span class="mr-4">{{ this.offers()[0].shortName }}</span>
        } @else {
        <span class="mr-4">{{ this.offers().length }} reference{{ this.offers().length > 1 ? 's' : '' }}</span>
        }
      </div>
    </div>
  </div>
</app-mhp-base-modal>