import { CommonModule } from '@angular/common';
import { Component, computed, HostListener, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBookBulk, saxHome2Bulk, saxSearchNormal1Bulk } from '@ng-icons/iconsax/bulk';
import {
	saxBook1Outline,
	saxHome2Outline,
	saxNotificationOutline,
	saxSearchNormal1Outline,
	saxSmsOutline,
} from '@ng-icons/iconsax/outline';

import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { SITEMAP } from 'src/app/_common/sitemap';

import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { MedEventService } from 'src/app/_common/services/medevent/medevent.service';
import { MhpLabelComponent } from '../../_common/components/mhp-label/mhp-label.component';
import { MhpSearchModalComponent } from '../../_common/components/mhp-search-modal/mhp-search-modal.component';
import { MhpSvgLogoComponent } from '../../_common/components/mhp-svg-logo/mhp-svg-logo.component';
import { MhpThemeSwitchComponent } from '../../_common/components/mhp-theme-switch/mhp-theme-switch.component';
import { MainHeaderAccountComponent } from './main-header-account/main-header-account.component';

@Component({
	selector: 'app-main-header',
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpSvgLogoComponent,
		MhpThemeSwitchComponent,
		MhpSearchModalComponent,
		MainHeaderAccountComponent,
		MhpLabelComponent,
	],
	providers: [
		provideIcons({
			saxBookBulk,
			saxSmsOutline,
			saxHome2Bulk,
			saxSearchNormal1Bulk,
			saxBook1Outline,
			saxHome2Outline,
			saxSearchNormal1Outline,
			saxNotificationOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styleUrl: './main-header.component.scss',
	templateUrl: './main-header.component.html',
})
export class MainHeaderComponent {
	readonly router = inject(Router);
	private readonly accountService = inject(AccountService);
	readonly sitemap = SITEMAP;
	UserFunctionEnum = UserFunctionEnum;
	user = computed<User>(this.accountService.user);
	userFunction = computed<UserFunctionEnum>(this.accountService.userFunction);
	isAuthentified = computed<boolean>(this.accountService.isAuthentified);
	readonly medEventService = inject(MedEventService);
	isMenuOpened: boolean = false;
	searchModal: boolean = false;

	@HostListener('document:keydown', ['$event'])
	triggerOpenSearchModal(e: any): void {
		if (e.ctrlKey && e.key === 'k') {
			e.preventDefault();
			this.openSearchModal();
		}
	}

	openSearchModal(): void {
		if (!this.accountService.isAuthentified()) {
			this.router.navigate([this.sitemap.login.route]);
			return;
		}

		this.searchModal = true;
	}

	hasUnreadMessages = computed(() => {
		return this.medEventService.hasUnreadMessages();
	});
}
