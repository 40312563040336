import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, model } from '@angular/core';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MainRequestOfferHistoryComponent } from '../main-request-offer-history/main-request-offer-history.component';
import { OffersRowComponent } from './offer-row/offer-row.component';

@Component({
	selector: 'offers-group',
	templateUrl: './offers-group.component.html',
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
	standalone: true,
	imports: [CommonModule, MainRequestOfferHistoryComponent, OffersRowComponent],
	animations: [
		trigger('expandCollapse', [
			transition(':enter', [
				style({ height: '0', opacity: 0, transform: 'translateY(-20px)' }),
				animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*', opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, transform: 'translateY(0)' }),
				animate(
					'500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({ height: '0', opacity: 0, transform: 'translateY(-20px)' })
				),
			]),
		]),
	],
})
export class OffersGroupComponent {
	offers = input.required<Offer[]>();
	expanded = model<boolean>(true);
	offerHistory: Offer | null = null;
	readonly accountService = inject(AccountService);

	isImporter = computed(() => this.accountService.userFunction() === UserFunctionEnum.Importer);
	public readonly offersSortedByName = computed(() =>
		this.offers().sort((o1, o2) => o1.shortName?.localeCompare(o2.shortName))
	);
}
