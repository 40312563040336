import { Component, input, TemplateRef } from '@angular/core';
import { Company } from "../../models/company.model";
import { MhpSvgIconComponent } from "../mhp-svg-icon/mhp-svg-icon.component";
import { MhpCompanyComponent } from "../mhp-company/mhp-company.component";

@Component({
	selector: 'app-mhp-companies',
	standalone: true,
	imports: [
		MhpSvgIconComponent,
		MhpCompanyComponent
	],
	templateUrl: './mhp-companies.component.html'
})
export class MhpCompaniesComponent {

	public companies = input.required<Company[]>();
	public listDirection = input<'row' | 'column'>('row');
	public emptyTemplate = input<TemplateRef<any> | string | null>(null);

}
