// noinspection SpellCheckingInspection

import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';

import * as iconsaxBulk from '@ng-icons/iconsax/bulk';
import * as iconsaxOutline from '@ng-icons/iconsax/outline';
import * as iconoir from '@ng-icons/iconoir';

export type iconsRef = keyof typeof iconsaxBulk | keyof typeof iconsaxOutline | keyof typeof iconoir;

@Component({
	selector: 'app-mhp-button',
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons(iconsaxBulk),
		provideIcons(iconsaxOutline),
		provideIcons(iconoir),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrl: './mhp-button.component.scss',
	templateUrl: './mhp-button.component.html',
})
export class MhpButtonComponent {
	public prefix = input<iconsRef>();
	public suffix = input<iconsRef>();
	public text = input<string>('');
	public type = input<'primary' | 'secondary' | 'tertiary'>('primary');
	public size = input<'normal' | 'large'>('normal');
	public disabled = input(false);
}
