import { Component, computed } from '@angular/core';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxNote2Outline } from '@ng-icons/iconsax/outline';
import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';

import {
	CounterOfferForm,
	MainRequestMakeOfferModalComponent,
} from '../../offer-modals/main-request-make-offer-modal/main-request-make-offer-modal.component';
import { OfferActionBase } from '../offer-action-base/offer-action-base';

@Component({
	selector: 'offer-action-counter',
	imports: [MhpButtonComponent, MainRequestMakeOfferModalComponent],
	templateUrl: './offer-action-counter.component.html',
	providers: [
		provideIcons({
			saxNote2Outline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OfferActionCounterComponent extends OfferActionBase {
	item = computed(() => this.offerService.getImporterRequestItem(this.offer().itemId));

	counterOffer(form: CounterOfferForm) {
		console.log('counterOffer', form);
		this.offerService.sendCounterOffer(this.offers()[0], form.quantity, form.price);
	}
}
