<h2 class="text-2xl text-center font-semibold mb-8">Confirm and complete the information before to send</h2>

<div class="flex flex-col p-6 space-y-8 border rounded-2xl bg-white dark:bg-gray-900/75">

	<span class="font-semibold">Purchase order</span>

	<app-mhp-from-to [from]="user()" [to]="[exporter()]" />

	<app-mhp-labeled-field label="PO number">
		<app-mhp-input
			[(model)]="userRequestId" [padding]="4" type="text"
			placeholder="Enter your PO number" />
	</app-mhp-labeled-field>

	<!-- VJO 12/12/24 11:42 "Tu peux le cacher pour le moment, on n'a pas encore développé cette fonctionnalité"

					<div class="flex flex-row px-4 py-3 items-center justify-center border rounded-xl">
					<span class="w-full text-sm text-[var(--text-secondary)]">Open to others if stock unsure, no commitment</span>
					<app-mhp-switch />
				</div> -->

	<app-mhp-labeled-field label="Products requested">
		<div class="flex items-center">
			<div class="flex w-full space-x-4">
				<div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0">
					<app-mhp-svg-icon icon="pill" [size]="16" />
				</div>
				<div class="flex flex-col">
					<span>{{ packageItems().length }} references</span>
					<span class="text-sm">{{ packageItemsSum() | currency : 'EUR': 'symbol' : '.0-2' }}</span>
				</div>
			</div>
			<app-mhp-button
				class="shrink-0"
				text="See the list"
				type="secondary"
				(click)="viewProductList.emit()" />
		</div>
	</app-mhp-labeled-field>

	<app-mhp-labeled-field label="Expiry date">
		<app-mhp-input
			[(model)]="expiryDate" type="date" [padding]="4"
			placeholder="Enter an expiry date" />
	</app-mhp-labeled-field>

	<!-- 
	VJO: this for a future feature, it is present in the Figma mockup
	
	<app-mhp-labeled-field label="Minimum order price"
		info="Minimum order price is the minimum amount of money you are willing to spend on this order.">
		<app-mhp-input
			[model]="minOrderPrice.toString()" (onModelChange)="minOrderPrice.set(+$event)" type="number" [padding]="4"
			suffix="€" [min]="0" [stepper]="true"
			placeholder="Enter a minimum order price" />
	</app-mhp-labeled-field> -->

	<app-mhp-labeled-field label="Delivery terms">
		<app-mhp-input
			[(model)]="deliveryTerms" type="text" [padding]="4"
			placeholder="Specify the delivery terms (EXW, FOB, CFR, etc.)" />
	</app-mhp-labeled-field>

	<app-mhp-labeled-field label="Payment terms">
		<app-mhp-input
			[(model)]="paymentTerms" type="text" [padding]="4"
			placeholder="Specify the payment terms (Net 30, Net 60, Net 90, etc.)" />
	</app-mhp-labeled-field>

</div>