<div class="flex flex-row items-center space-x-2">
  <div class="flex flex-row items-center shrink-0">
    <img
      class="mr-4 rounded-full h-9 w-9 bg-gray-200 dark:bg-gray-700 overflow-hidden"
      [src]="this.getGravatarUrl(this.from()?.email!)"
      alt="Exporter"
    />
    <div class="flex flex-col">
      <span class="text-sm mb-1 text-gray-500">From</span>
      <span class="text-gray-900 dark:text-white">{{ this.from()?.firstName }}</span>
    </div>
  </div>

  <app-mhp-svg-icon icon="from-to" />

  <div class="flex flex-row items-center shrink-0">
    <img
      class="mr-4 rounded-full h-9 w-9 bg-gray-200 dark:bg-gray-700 overflow-hidden"
      [src]="this.to() ? this.getGravatarUrl(this.to()?.displayName!) : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'"
      alt="Exporter"
    />
    <div class="flex flex-col">
      <span class="text-sm mb-1 text-gray-500">To</span>
      <span class="text-gray-900 dark:text-white">{{ this.to()?.displayName }}</span>
    </div>
  </div>
</div>