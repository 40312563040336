import { I18nPluralPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { derivedAsync } from 'ngxtension/derived-async';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpTaskCardComponent } from '../../../_common/components/mhp-task-card/mhp-task-card.component';
import { ExporterTasks } from '../../../_common/models/exporter-tasks.model';
import { ApiExporterService } from '../../../_common/services/api/api-exporter/api-exporter.service';
import { CompanyService } from '../../../_common/services/company/company.service';

@Component({
	selector: 'app-main-dashboard-exporter-tasks',
	standalone: true,
	imports: [MhpTaskCardComponent, MhpButtonComponent, I18nPluralPipe, MhpSvgIconComponent],
	templateUrl: './main-dashboard-exporter-tasks.component.html',
	styleUrl: './main-dashboard-exporter-tasks.component.scss',
})
export class MainDashboardExporterTasksComponent {
	protected readonly _companySvc = inject(CompanyService);
	private readonly _exporterSvc = inject(ApiExporterService);
	private readonly _router = inject(Router);

	protected readonly tasks = derivedAsync(async () => {
		return (await this._exporterSvc.getTasks()) ?? <ExporterTasks>{};
	});

	protected getCompanyAvatar(companyId: number) {
		const company = this._companySvc.getCompanyById(companyId);
		return CompanyExtension.getGravatarUrl(company || undefined);
	}

	protected getTasksCount(tasks?: ExporterTasks) {
		return (tasks?.pendingImporterRequests.length ?? 0) + (tasks?.pendingRequestActions.length ?? 0);
	}

	protected async navigateToRequest(reqId: string) {
		await this._router.navigate(['/request', reqId, 'waiting']);
	}

	protected async navigateToMakeAnOffer(reqId: string) {
		await this._router.navigate(['/make-an-offer', reqId]);
	}
}
