<label class="flex items-center cursor-pointer">
  <input type="checkbox" [(ngModel)]="this.themeColor" (change)="this.updateThemeColor()" class="sr-only peer">
  <div
    class="relative w-11 h-6 bg-gray-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 dark:border-gray-600 peer-checked:bg-blue-600">
    <div
      class="flex flex-row justify-center items-center absolute top-[2px] start-[2px] bg-white border-gray-300 border rounded-full h-5 w-5 transition-all bg-white dark:border-gray-700 dark:bg-gray-800"
      [ngClass]="this.themeColor ? 'translate-x-full rtl:-translate-x-full border-white dark:border-gray-800' : ''">
      @if (this.themeColor) { <ng-icon class="text-gray-900 dark:text-gray-100" name="bootstrapMoonFill" /> }
      @else { <ng-icon class="text-gray-900 dark:text-gray-100" name="bootstrapSunFill" /> }
    </div>
  </div>
</label>