<div class="update-screen">
	<div id="header" class="header">
		<div id="header-left">
				<app-mhp-button text="Cancel" type="secondary" [routerLink]="this.sitemap.catalog.route" />
		</div>

		<div id="header-right"></div>
	</div>

	<div class="flex flex-col justify-center">
		<h2 class="text-2xl text-center font-semibold mb-8">What would you like to request?</h2>

		<div class="flex flex-col space-y-4">
			<mhp-suggestions-card
				icon="cardboard-box"
				iconBg="bg-[#54c7e320]"
				title="Import my stock"
				text="You want to inform us of changes to your stock and update it."
				[routerLink]="[this.sitemap.updateStockQuota.route + '/stock/upload']"
			/>

			<mhp-suggestions-card
				icon="pie-chart"
				iconBg="bg-[#3971ff20]"
				title="Import my quotas"
				text="You want to inform us of changes to your quotas and update them."
				[routerLink]="[this.sitemap.updateStockQuota.route + '/quota/upload']"
			/>
		</div>
	</div>
</div>
