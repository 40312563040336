<button
  class="flex justify-end"
  (click)="onModalOpen()"
>
  <app-mhp-button
    prefix="saxTruckOutline"
    text="Order {{ lengthText() }}"
    type="primary"
    class="shrink-0"
  />
</button>

@if (modalVisible) {
  <app-main-request-confirm-offers
    [exporter]="exporter()"
    [offers]="offers()"
    buttonText="Order and dispatch these products"
		(onConfirm)="onConfirm()"
    (stateClose)="onModalClose()"
  >
    <span slot="question">Do you confirm that your order can be dispatched?</span>
  </app-main-request-confirm-offers>
}
