import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Company } from '../../models/company.model';
import { ApiCompanyService } from '../api/api-company/api-company.service';
import { toDictionary } from '../../utils/toDictionary';

/**
 * Provides session storage for company data.
 * @class CompanyService
 */
@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	private readonly _companyApiSvc = inject(ApiCompanyService);
	private readonly companyKey = 'company';

	private _currentCompany = signal<Company | null>(JSON.parse(sessionStorage.getItem(this.companyKey)!));
	private _allCompanies = signal<Company[]>([]);

	public currentCompany = computed(this._currentCompany);
	public allCompanies = computed(this._allCompanies);

	private _allCompaniesById = computed(() => toDictionary(this._allCompanies(), c => c.id));

	public getCompanyById(id: number): Company {
		const company = this._allCompaniesById()[id];

		if (!company) throw new Error(`Company with id ${id} not found`);

		return company;
	}

	private onCompanySet = effect(() => {
		sessionStorage.setItem(this.companyKey, JSON.stringify(this._currentCompany()));
	});

	constructor() {
		this._companyApiSvc
			.getAll()
			.then(companies => {
				if (companies.ok) {
					this._allCompanies.set(companies.body!);
				}
			})
			.catch((err: any) => console.error(err))
			.finally(() => {});
	}

	public async updateCurrentCompany() {
		const newCompany = await this._companyApiSvc.getOwn();

		if (newCompany.ok) {
			this._currentCompany.set(newCompany.body!);
		}
	}

	public resetCompany(): void {
		sessionStorage.removeItem(this.companyKey);
	}
}
