@if( offersService.waitingForActionOffersCount() > 0 )
{
<!-- Exporter has one single group that contains all offers with a required action -->

<offers-group *ngIf="userFunction() === UserFunctionEnum.Exporter" [offers]="offersService.waitingForActionOffers()" />

<!-- Importer has one group per item, with all offers from different exporters for that item -->

@if (userFunction() === UserFunctionEnum.Importer && Object.keys(waitingOffersPerItem()).length > 0)
{
@for( entry of waitingOffersPerItem() | keyvalue; track entry.key )
{
<offers-group-for-item [offers]="entry.value" class="block mb-4" />
}
}
}
@else
{
<!-- Icon for no offer to display -->

<div class="flex flex-col items-center justify-center py-48 space-y-4">
	<app-mhp-svg-icon icon="empty-required" [size]="80" />
	<p class="text-[var(--text-secondary)]">No offer required action from you at the moment.</p>
</div>
}