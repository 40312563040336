import { Component, computed, HostBinding, inject, input, model, OnInit } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowDown1Outline, saxArrowUp2Outline } from '@ng-icons/iconsax/outline';
import { AccountService } from 'src/app/_common/services/account/account.service';

/**
 * Component to display the header of a group of offers.
 */
@Component({
	selector: 'offers-group-header',
	standalone: true,
	styles: [
		`
			:host {
				@apply flex flex-row items-center p-4;
			}

			:host.borderb {
				@apply border-b;
			}
		`,
	],
	imports: [NgIconComponent],
	templateUrl: 'offers-group-header.component.html',
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OffersGroupHeaderComponent implements OnInit {
	private readonly accountService = inject(AccountService);
	readonly userFunction = computed(() => this.accountService.userFunction());
	offersCount = input.required<number>();
	expanded = model.required<boolean>();
	title = input.required<string>();
	description = input.required<string>();

	@HostBinding('class.borderb') borderb = true;

	ngOnInit(): void {
		// bottom border is set only when the group is expanded, fot not replicating the border of the group
		this.expanded.subscribe(expanded => {
			this.borderb = expanded;
		});
	}
}
