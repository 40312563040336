import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CounterOffer } from 'src/app/_common/models/counter-offer.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { ResponseOffer } from 'src/app/_common/models/response-offer.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiOfferService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly OFFER_ROUTE: string = 'offer';

	constructor() {}

	public getOffersByImporter(importerId: number): Promise<HttpResponse<Offer[]>> {
		return firstValueFrom(
			this.http.get<Offer[]>(`${this.API_URL}/${this.OFFER_ROUTE}?importerId=${importerId}`, { observe: 'response' })
		);
	}

	public getOffersByExporter(exporterId: number): Promise<HttpResponse<Offer[]>> {
		return firstValueFrom(
			this.http.get<Offer[]>(`${this.API_URL}/${this.OFFER_ROUTE}?exporterId=${exporterId}`, { observe: 'response' })
		);
	}

	public getOffersByRequest(requestId: string, currentOffersOnly: boolean = true): Promise<HttpResponse<Offer[]>> {
		return firstValueFrom(
			this.http.get<Offer[]>(
				`${this.API_URL}/${this.OFFER_ROUTE}?requestId=${requestId}&currentOffersOnly=${currentOffersOnly}`,
				{ observe: 'response' }
			)
		);
	}

	public sendBookOffer(offerId: string): Promise<HttpResponse<void>> {
		return firstValueFrom(
			this.http.post<void>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/confirmed`, null, {
				observe: 'response',
			})
		);
	}

	public getCounterOffersByOffer(offerId: string): Promise<HttpResponse<Offer[]>> {
		return firstValueFrom(
			this.http.get<Offer[]>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/counter`, { observe: 'response' })
		);
	}

	public getResponseOffersByOfferId(offerId: string): Promise<HttpResponse<ResponseOffer[]>> {
		return firstValueFrom(
			this.http.get<ResponseOffer[]>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/response`, { observe: 'response' })
		);
	}

	public sendOffer(offer: Offer): Promise<HttpResponse<Offer>> {
		return firstValueFrom(this.http.post<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}`, offer, { observe: 'response' }));
	}

	public sendResponseOffer(uuid: string, responseOffer: ResponseOffer): Promise<HttpResponse<ResponseOffer>> {
		return firstValueFrom(
			this.http.post<ResponseOffer>(`${this.API_URL}/${this.OFFER_ROUTE}/${uuid}/response`, responseOffer, {
				observe: 'response',
			})
		);
	}

	public sendCounterOffer(uuid: string, counterOffer: CounterOffer): Promise<HttpResponse<CounterOffer>> {
		return firstValueFrom(
			this.http.post<CounterOffer>(`${this.API_URL}/${this.OFFER_ROUTE}/${uuid}/counter`, counterOffer, {
				observe: 'response',
			})
		);
	}
}
