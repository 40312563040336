<div class="mhp-progressbar-component">
  <div class="mhp-progressbar-component-bar">
    <div
      class="absolute h-full rounded-full bg-blue-500"
      [style]="{ 'width': this.progress() + '%' }"
    ></div>
  </div>

  @if (this.showStats()) {
    <span class="shrink-0">{{ this.progress() }} %</span>
  }
</div>