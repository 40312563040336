import { computed, effect, inject, Injectable, Signal, signal } from '@angular/core';

import { environment } from 'src/environments/environment';
import { MedEvent, MedEventType } from '../../models/med-event.model';
import { toDictionary } from '../../utils/toDictionary';
import { AccountService } from '../account/account.service';
import { SignalRClientBase } from '../signalr/signalr.client.base';

@Injectable({
	providedIn: 'root',
})
export class MedEventService extends SignalRClientBase {
	private readonly accountService = inject(AccountService);

	private medEvents = signal<MedEvent[]>([]);

	private unreadChats = computed(() =>
		toDictionary(
			this.medEvents().filter(m => m.type == MedEventType.UnreadMessage),
			e => e.objectId
		)
	);

	private initialized = false;

	constructor() {
		// call the base constructor, but do not start at construction
		super(environment.API_URL + '/hub/medevent', false);

		// Handle New MedEvent received
		this._hubConnection.on('NewMedEvent', (medEvent: MedEvent) => {
			this.medEvents.set([...this.medEvents(), medEvent]);
		});

		// Handle New MedEvent consumed
		this._hubConnection.on('ConsumedMedEvent', (medEvent: MedEvent) => {
			this.medEvents.set(this.medEvents().filter(e => e.id !== medEvent.id));
		});

		// handle reconnection
		this.onReconnected$.subscribe(async () => {
			await this.loadWaitingCompanyMedEvents();
		});

		// the medevent must be started when the user is authenticated, and is stopped when he is signed out
		effect(async () => {
			if (this.accountService.isAuthentified()) {
				await this.startConnection();
				await this.loadWaitingCompanyMedEvents();
			} else {
				await this.stopConnection();
			}
		});
	}

	/** Load unconsumed events of the company */
	async loadWaitingCompanyMedEvents(): Promise<void> {
		await this.waitConnectionPromise;
		const companyMedEvents = await this._hubConnection.invoke<MedEvent[]>('LoadWaitingCompanyMedEvents');
		this.medEvents.set(companyMedEvents);
	}

	/**
	 * Send read message to the chat room
	 */
	async markAsRead(offerId: string): Promise<any> {
		const medEvent = this.unreadChats()[offerId];
		if (!medEvent) return;

		await this._hubConnection.invoke('ConsumeMedEvent', medEvent.id);
	}

	/**
	 * Check if there are unread messages for a specific offer
	 * @param offerId The ID of the offer to check
	 * @returns Signal<boolean> True if there are unread messages, false otherwise
	 */
	hasUnreadMessagesForChat(offerId: string): Signal<boolean> {
		return computed(() => this.unreadChats()[offerId] !== undefined);
	}

	/**
	 * Check if there are unread messages for the company
	 */
	hasUnreadMessages = computed(() => Object.keys(this.unreadChats()).length > 0);
}
