import { Component, inject } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import * as saxCloseCircleBold from '@ng-icons/iconsax/bold';

import { ToastService } from '../_common/services/toast/toast.service';
import { ToastTypeEnum } from '../_common/enums/toast-type.enum';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-toast',
	imports: [CommonModule, NgIconComponent],
	providers: [provideIcons(saxCloseCircleBold), provideNgIconsConfig({ size: '1.5rem' })],
	templateUrl: './toast.component.html',
	styleUrl: './toast.component.scss',
})
export class ToastComponent {
	private readonly toastSvc = inject(ToastService);

	public readonly toastTypeEnum = ToastTypeEnum;

	public readonly text = this.toastSvc.text;
	public readonly type = this.toastSvc.type;
	public readonly isClosed = this.toastSvc.isClosed;

	constructor() {}

	public close = () => this.toastSvc.close();
}
