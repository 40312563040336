import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SiteMap, SITEMAP } from '../_common/sitemap';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';

@Component({
  selector: 'app-forbidden',
  standalone: true,
  imports: [
    RouterModule,
    MhpButtonComponent
  ],
  templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent {

  public sitemap: SiteMap = SITEMAP;

  constructor() {}

}
