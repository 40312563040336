<button
  class="flex justify-end"
  (click)="onModalOpen()">
  <app-mhp-button
    prefix="saxTruckOutline"
    text="Request shipping {{ lengthText() }}"
    type="primary"
    class="shrink-0" />
</button>

@let company = exporter();
@if (company && modalVisible) {
<app-main-request-confirm-offers
  [exporter]="company"
  [offers]="offers()"
  buttonText="Proceed with shipping"
  (onConfirm)="onConfirm()"
  (stateClose)="onModalClose()">
  <span slot="question">Do you confirm your request for product shipping ?</span>
</app-main-request-confirm-offers>
}