import { Pipe, PipeTransform } from '@angular/core';
import { OfferResponseTypeEnum } from '../../enums/offer-response-type.enum';
import { ImporterRequestItem } from '../../models/importer-request-item.model';
import { Offer } from '../../models/offer.model';
import { Package } from '../../models/package.model';
import { ResponseOffer } from '../../models/response-offer.model';

// [TODO]: Don't forget to normalize naming with features

@Pipe({
	name: 'isOfferReadyToBook',
	standalone: true,
})
export class IsOfferReadyToBookPipe implements PipeTransform {
	public transform(offer: Offer & ImporterRequestItem & Package): boolean {
		return offer.responses.find((o: ResponseOffer) => o.importerResponse?.type === OfferResponseTypeEnum.Accepted)! &&
			offer.responses.find((o: ResponseOffer) => o.exporterResponse?.type === OfferResponseTypeEnum.Accepted)!
			? true
			: false;
	}
}
