import { Component, HostListener, input, Input, output } from '@angular/core';
import { iconsRef, MhpButtonComponent } from '../mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';

/**
 * Base modal component providing common modal functionality.
 * Features:
 * - Centered modal layout with backdrop
 * - Optional close button
 * - Optional configurable action button
 * - Content projection for custom content
 * - Dark mode support
 */
@Component({
	selector: 'app-mhp-base-modal',
	imports: [MhpButtonComponent, MhpSvgIconComponent],
	templateUrl: './mhp-base-modal.component.html',
})
export class MhpBaseModalComponent {
	/** Modal title text */
	title = input<string>();

	/** Whether to show the close button */
	showCloseButton = input(true);

	/** Text for the action button */
	actionButtonText = input<string>('');

	/** Type of the action button */
	actionButtonType = input<'primary' | 'secondary'>('primary');

	/** activation of the action button */
	actionButtonDisabled = input(false);

	/** Icon prefix for the action button */
	@Input() actionButtonPrefix?: iconsRef;

	/** Event emitted when the action button is clicked */
	action = output<void>();

	/** Event emitted when the user confirms the modal */
	onConfirm = output<boolean>();

	/** Event emitted when the modal should close (legacy) */
	close = output<void>();

	/** Event emitted when the modal should close */
	stateClose = output<boolean>();

	/** Handle ESC key for closing */
	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	/** Handle confirm action */
	public submitConfirm(): void {
		this.onConfirm.emit(true);
		this.handleClose();
	}

	/** Handle close action */
	public handleClose(): void {
		this.close.emit();
		this.stateClose.emit(true);
	}
}
