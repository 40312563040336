<div class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
  <div class="text-center">
    <p class="text-xl font-semibold text-blue-500">403</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-white">Forbidden</h1>
    <p class="mt-6 text-base leading-7 text-gray-600 dark:text-white">Sorry, we couldn't find the page you're looking for.</p>

    <div class="mt-10 flex items-center justify-center gap-x-6">
      <app-mhp-button
        [routerLink]="[ this.sitemap.main.route ]"
        text="Go back home"
      />
    </div>
  </div>
</div>