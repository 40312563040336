@switch (this.label()) {

@default {
<div class="mhp-label text-[var(--text-secondary)] bg-white/90 dark:bg-gray-900/75">
  <span class="text-sm">{{ this.label() }}</span>
</div>
}

@case ('In demand') {
<div class="mhp-label text-purple-700 bg-purple-200">
  <ng-icon class="mr-2" name="matTrendingUpOutline" />
  <span class="text-sm">In demand</span>
</div>
}

@case ('New') {
<div class="mhp-label text-white bg-blue-500">
  <span class="text-sm">New</span>
</div>
}

@case ('Beta') {
<div class="mhp-label bg-amber-400 bg-opacity-80">
  <span class="text-sm">Beta</span>
</div>
}
}