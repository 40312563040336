import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { User } from 'src/app/_common/models/user.model';
import { UserDto } from 'src/app/_common/dto/user.dto';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiUserService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly USER_ROUTE: string = 'user';

	constructor() {}

	public getUser(): Promise<HttpResponse<User>> {
		return firstValueFrom(this.http.get<User>(`${this.API_URL}/${this.USER_ROUTE}/self`, { observe: 'response' }));
	}

	public editUser(userDto: UserDto): Promise<HttpResponse<User>> {
		return firstValueFrom(
			this.http.put<User>(`${this.API_URL}/${this.USER_ROUTE}/self`, userDto, { observe: 'response' })
		);
	}
}
