import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxAddOutline, saxClockOutline, saxMessageOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { filter, from, map, Observable, switchMap, tap } from 'rxjs';

import { CommonModule, DatePipe } from '@angular/common';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';
import { MhpInputComponent } from '../_common/components/mhp-input/mhp-input.component';
import { MhpPackageComponent } from '../_common/components/mhp-package/mhp-package.component';
import { MhpProgressbarComponent } from '../_common/components/mhp-progressbar/mhp-progressbar.component';
import { MhpTaskCardComponent } from '../_common/components/mhp-task-card/mhp-task-card.component';
import { MhpTooltipComponent } from '../_common/components/mhp-tooltip/mhp-tooltip.component';
import { CompanyExtension } from '../_common/extensions/company.extension';
import { ImporterRequestExtension } from '../_common/extensions/importer-request.extension';
import { Company } from '../_common/models/company.model';
import { ImporterRequest } from '../_common/models/importer-request.model';
import { Offer } from '../_common/models/offer.model';
import { Package } from '../_common/models/package.model';
import { RequestItem } from '../_common/models/request-item.model';
import { OffersService } from '../_common/services/offers/offers.service';
import { RequestService } from '../_common/services/request/request.service';
import { SITEMAP } from '../_common/sitemap';
import { MainRequestOfferHistoryComponent } from '../main/main-request/main-request-offer-history/main-request-offer-history.component';
import { MainRequestSendOfferConfirmModalComponent } from '../main/main-request/offer-modals/main-request-send-offer-confirm-modal/main-request-send-offer-confirm-modal.component';
import { ChooseFillModeComponent } from './choose-fill-mode/choose-fill-mode.component';

@Component({
	selector: 'app-make-an-offer',
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgIconComponent,
		MhpButtonComponent,
		DatePipe,
		MainRequestOfferHistoryComponent,
		MhpProgressbarComponent,
		ChooseFillModeComponent,
		MhpInputComponent,
		MhpTaskCardComponent,
		MhpPackageComponent,
		MainRequestSendOfferConfirmModalComponent,
		MhpTooltipComponent,
	],
	providers: [
		provideIcons({
			saxAddOutline,
			saxClockOutline,
			saxMessageOutline,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styleUrls: ['./make-an-offer.component.scss'],
	templateUrl: './make-an-offer.component.html',
	standalone: true,
})
export class MakeAnOfferComponent {
	private readonly _activatedRoute = inject(ActivatedRoute);
	private readonly _requestSvc = inject(RequestService);
	private readonly _router = inject(Router);
	readonly offerService = inject(OffersService);
	readonly CompanyExtension = CompanyExtension;
	displayFillModeDialog: boolean = true;
	pendingOffersPerProduct = computed(this.offerService.pendingOffersPerProduct);

	triggerCount = signal<number>(0);

	incrementTriggerCount(): void {
		this.triggerCount.set(this.triggerCount() + 1);
	}

	/** The total price of all offers */
	offerTotalAmount = computed(() => {
		this.triggerCount();

		const offers = this.pendingOffersPerProduct();
		if (!offers) return 0;

		return Object.values(offers)
			.flat()
			.reduce((total, offer) => {
				return total + offer.quantity * offer.price;
			}, 0);
	});

	/** The number of items with at least an offer with quantity > 0 */
	filledItemsCount = computed(() => {
		this.triggerCount();

		return Object.values(this.pendingOffersPerProduct()).filter(
			itemOffers => itemOffers.filter(offer => offer.quantity > 0).length > 0
		).length;
	});

	fillProgress = computed(() => (this.filledItemsCount() / this.request.items.length) * 100);

	protected readonly sitemap = SITEMAP;

	csvFileUploaded: boolean = false;
	exporter?: Company;
	offerSelected: Offer | null = null;
	packageSelected: Package | null = null;
	displayedId: string = '';
	offerHistoryPanelDisplayed: boolean = false;
	sendOfferConfirmModalDisplayed: boolean = false;
	private _request: ImporterRequest = null!;

	constructor() {
		dayjs.extend(customParseFormat);
	}

	get request(): ImporterRequest {
		return this._request;
	}

	set request(request: ImporterRequest) {
		this._request = request;
		this.displayedId = ImporterRequestExtension.getUIRequestId(request.id, request.userRequestId);
	}

	updateBatchNumber(offer: Offer, value: string): void {
		const strValue = value || '';
		offer.isToOrder = strValue.toUpperCase() === 'TO ORDER' || !strValue;
		offer.batchNumber = offer.isToOrder ? null : strValue;
	}

	setExpirationDate(offer: Offer, value: string): void {
		offer.expirationDate = value || null;
	}

	setQuantity(offer: Offer, value: string): void {
		offer.quantity = value ? Math.max(0, parseFloat(value)) : 0;
		this.incrementTriggerCount();
	}

	setPrice(offer: Offer, value: string): void {
		offer.price = value ? Math.max(0, parseFloat(value)) : 0;
		this.incrementTriggerCount();
	}

	formatNumber(value: number): string {
		return value?.toString() ?? '';
	}

	async sendOffer(): Promise<void> {
		await this.offerService.sendValidNewOffers();
		await this._router.navigate([this.sitemap.request.route + '/' + this.request.id + '/all']);
	}

	async quit(): Promise<void> {
		await this._router.navigate([this.sitemap.request.route + '/' + this.request.id + '/all']);
	}

	ngOnInit(): void {
		this._activatedRoute.paramMap
			.pipe(
				map(paramMap => paramMap.get('requestId')),
				filter(id => id !== null && id != this.request?.id),
				switchMap(id => this.loadAndSubscribeToRequest(id!))
			)
			.subscribe();
	}

	private loadAndSubscribeToRequest(id: string): Observable<void> {
		return from(this._requestSvc.getRequestAndSubscribeToChanges(id)).pipe(
			map(requestEventDispatcher => {
				return requestEventDispatcher.request;
			}),
			filter((request: ImporterRequest) => !!request),
			tap((request: ImporterRequest) => {
				this.sortRequestItems(request);
				this.request = request;
			}),
			switchMap(request => from(this.offerService.initRequest(request)))
		);
	}

	private sortRequestItems(request: ImporterRequest): void {
		request.items = request.items.sort((a: RequestItem, b: RequestItem) =>
			a.package.shortName!.localeCompare(b.package.shortName!)
		);
	}

	async selectFillMode(prefill: boolean): Promise<void> {
		this.displayFillModeDialog = false;
		if (prefill) {
			await this.prefillOffersWithStock();
		}
	}

	async prefillOffersWithStock(): Promise<void> {
		await this.offerService.prefillOffersWithStock(this.request.items!);
	}
}
