export const SITEMAP = {
	main: { path: '', route: '/' },
	account: { path: 'account', route: '/account' },
	dashboard: { path: 'dashboard', route: '/dashboard' },
	catalog: { path: 'catalog', route: '/catalog' },
	package: { path: 'product', route: '/product' },
	exportCatalog: { path: 'export-catalog', route: '/export-catalog' },
	request: { path: 'request', route: '/request' },

	makeAnOffer: { path: 'make-an-offer', route: '/make-an-offer' },
	newRequest: { path: 'new-request', route: '/new-request' },
	newStockOffer: { path: 'new-stock-offer', route: '/new-stock-offer' },
	updateStockQuota: { path: 'update-stock-quota', route: '/update-stock-quota' },

	admin: { path: 'admin', route: '/admin' },

	auth: { path: 'auth', route: '/auth' },
	login: { path: 'login', route: '/auth/login' },

	maintenance: { path: 'maintenance', route: '/maintenance' },

	forbidden: { path: 'forbidden', route: '/forbidden' },
	unauthorized: { path: 'unauthorized', route: '/unauthorized' },
} as const;
