import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ImporterRequestDto } from 'src/app/_common/dto/importer-request.dto';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';

import { environment } from 'src/environments/environment';
import { Offer } from "../../../models/offer.model";
import { ImporterRequestItem } from "../../../models/importer-request-item.model";
import { ExporterStockEntry } from "../../../models/exporter-stock-entry.model";

@Injectable({
	providedIn: 'root'
})
export class ApiImporterService {

	private API_URL: string = environment.API_URL;
	private IMPORTER_ROUTE: string = 'importer';

	constructor(private http: HttpClient) {}

	public postRequest(dto: ImporterRequestDto): Promise<HttpResponse<ImporterRequest | null>> {
		return firstValueFrom(this.http.post<ImporterRequest | null>(
			`${this.API_URL}/${this.IMPORTER_ROUTE}/request`,
			dto,
			{ observe: 'response' }
		));
	}

	public getRequestById(id: string): Promise<HttpResponse<ImporterRequest | null>> {
		return firstValueFrom(this.http.get<ImporterRequest | null>(
			`${this.API_URL}/${this.IMPORTER_ROUTE}/request/${id}`,
			{observe: 'response'}
		));
	}

	public getRequestOffersById(id: string): Promise<HttpResponse<Offer[] | null>> {
		return firstValueFrom(this.http.get<Offer[] | null>(
			`${this.API_URL}/${this.IMPORTER_ROUTE}/request/${id}/offers`,
			{observe: 'response'}
		));
	}

	public getRequestsByImporter(id: number): Promise<HttpResponse<ImporterRequest[] | null>> {
		return firstValueFrom(this.http.get<ImporterRequest[] | null>(
			`${this.API_URL}/${this.IMPORTER_ROUTE}/request?importerId=${id}`,
			{observe: 'response'}
		));
	}

	public getRequestsByExporter(id: number): Promise<HttpResponse<ImporterRequest[] | null>> {
		return firstValueFrom(this.http.get<ImporterRequest[] | null>(
			`${this.API_URL}/${this.IMPORTER_ROUTE}/request?exporterId=${id}`,
			{observe: 'response'}
		));
	}

	compareAvailableStocks(requestId: string, exporterId: number): Promise<HttpResponse<ExporterStockEntry[] | null>> {
		return firstValueFrom(this.http.get<ExporterStockEntry[] | null>(
			`${this.API_URL}/${this.IMPORTER_ROUTE}/request/${requestId}/exporter-stocks/${exporterId}`,
			{observe: 'response'}
		));
	}
}
