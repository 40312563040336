import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { ManifestItemSearchCriteria } from 'src/app/_common/models/manifest-item-search-criteria.model';
import { ManifestItem } from 'src/app/_common/models/manifest-item.model';
import { environment } from 'src/environments/environment';
import { ExporterManifestEntry } from '../../../models/exporter-manifest-entry.model';
import { ExporterQuotaEntry } from '../../../models/exporter-quota-entry.model';
import { ExporterQuota } from '../../../models/exporter-quota.model';
import { ExporterStockEntry } from '../../../models/exporter-stock-entry.model';
import { ExporterTasks } from '../../../models/exporter-tasks.model';
import { StockOffer } from '../../../models/stock-offer.model';

/**
 * Service handling API operations for exporter entities.
 *
 * Provides methods for:
 * - Searching and managing manifest items
 * - Managing exporter stock (get, post)
 * - Handling stock offers and quotas
 * - Processing importer requests
 * - Managing exporter tasks
 *
 * Note: All operations are only available to and used by authorized exporters.
 */
@Injectable({
	providedIn: 'root',
})
export class ApiExporterService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly EXPORTER_ROUTE: string = 'exporter';

	constructor() {}

	/**
	 * Searches for manifest items based on provided criteria.
	 * Only available to exporters.
	 */
	findManifestItems(
		searchCriteria: ManifestItemSearchCriteria,
		skippedItemsCount: number,
		wantedItemsCount: number
	): Promise<ManifestItem[]> {
		let params = new HttpParams()
			.append('skippedItemsCount', skippedItemsCount.toString())
			.append('wantedItemsCount', wantedItemsCount.toString());

		searchCriteria.types.forEach(type => {
			params = params.append('types', type);
		});

		if (searchCriteria?.cip13OrProductShortNamePart) {
			params = params.append('cip13OrProductShortNamePart', searchCriteria.cip13OrProductShortNamePart);
		}

		return firstValueFrom(
			this.http.get<ManifestItem[]>(`${this.API_URL}/${this.EXPORTER_ROUTE}/manifest/items`, {
				params,
			})
		);
	}

	/**
	 * Retrieves the current stock for an exporter.
	 * Only available to exporters.
	 */
	getStock(): Promise<ExporterStockEntry[]> {
		return firstValueFrom(this.http.get<ExporterStockEntry[]>(`${this.API_URL}/${this.EXPORTER_ROUTE}/stock`));
	}

	/**
	 * Updates the exporter's stock with new manifest entries.
	 * Only available to exporters.
	 */
	postStock(dto: ExporterManifestEntry[]): Promise<HttpResponse<ExporterManifestEntry[] | null>> {
		return firstValueFrom(
			this.http.post<ExporterManifestEntry[] | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/stock`, dto, {
				observe: 'response',
			})
		);
	}

	/**
	 * Gets stock offers filtered by exporter or importer IDs.
	 * Only available to exporters.
	 */
	getStockOffer(exporterId?: number, importerId?: number): Promise<HttpResponse<StockOffer[]>> {
		let uri = `${this.API_URL}/${this.EXPORTER_ROUTE}/stock/offer`;

		if (exporterId && importerId) {
			uri += `?exporterId=${exporterId}&importerId=${importerId}`;
		} else if (exporterId) {
			uri += `?exporterId=${exporterId}`;
		} else if (importerId) {
			uri += `?importerId=${importerId}`;
		}

		return firstValueFrom(this.http.get<StockOffer[]>(uri, { observe: 'response' }));
	}

	/**
	 * Creates a new stock offer.
	 * Only available to exporters.
	 */
	postStockOffer(stockOffer: StockOffer): Promise<HttpResponse<StockOffer | null>> {
		return firstValueFrom(
			this.http.post<StockOffer | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/stock/offer`, stockOffer, {
				observe: 'response',
			})
		);
	}

	/**
	 * Updates stock quotas for the exporter.
	 * Only available to exporters.
	 */
	putStockQuotas(stockQuotas: ExporterQuotaEntry[]): Promise<HttpResponse<ExporterQuotaEntry[] | null>> {
		return firstValueFrom(
			this.http.put<ExporterQuotaEntry[] | null>(`${this.API_URL}/${this.EXPORTER_ROUTE}/stock/quota`, stockQuotas, {
				observe: 'response',
			})
		);
	}

	/**
	 * Retrieves requests for the exporter with the specified ID.
	 * Only available to exporters.
	 */
	getRequests(): Promise<ImporterRequest[]> {
		return firstValueFrom(this.http.get<ImporterRequest[]>(`${this.API_URL}/${this.EXPORTER_ROUTE}/requests`));
	}

	/**
	 * Gets tasks assigned to the exporter.
	 * Only available to exporters.
	 */
	getTasks(): Promise<ExporterTasks> {
		return firstValueFrom(this.http.get<ExporterTasks>(`${this.API_URL}/${this.EXPORTER_ROUTE}/tasks`));
	}

	/**
	 * Retrieves available stocks for a specific request.
	 * Only available to exporters.
	 */
	getAvailableStocksForRequest(requestId: string): Promise<ExporterStockEntry[]> {
		return firstValueFrom(
			this.http.get<ExporterStockEntry[]>(`${this.API_URL}/${this.EXPORTER_ROUTE}/stockforrequest/${requestId}`)
		);
	}

	/**
	 * Retrieves available quotas for a specific request.
	 * Only available to exporters.
	 */
	getAvailableQuotasForRequest(requestId: string): Promise<ExporterQuota[]> {
		return firstValueFrom(
			this.http.get<ExporterQuota[]>(`${this.API_URL}/${this.EXPORTER_ROUTE}/quotasforrequest/${requestId}`)
		);
	}
}
