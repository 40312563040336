import { Component, computed, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsOutline, saxMoreOutline } from '@ng-icons/iconsax/outline';

import { MhpStatusLabelComponent } from '../../../_common/components/mhp-status-label/mhp-status-label.component';
import { SITEMAP } from 'src/app/_common/sitemap';
import { Company } from 'src/app/_common/models/company.model';
import { ApiImporterService } from '../../../_common/services/api/api-importer/api-importer.service';
import { ImporterRequest } from '../../../_common/models/importer-request.model';
import { CompanyService } from '../../../_common/services/company/company.service';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { MhpCompaniesComponent } from '../../../_common/components/mhp-companies/mhp-companies.component';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { ImporterRequestExtension } from 'src/app/_common/extensions/importer-request.extension';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';

@Component({
	selector: 'app-main-dashboard-requests',
	imports: [
		CommonModule,
		RouterModule,
		MhpStatusLabelComponent,
		MhpButtonComponent,
		MhpCompaniesComponent,
		MhpSvgIconComponent,
	],
	providers: [
		provideIcons({
			saxMoreOutline,
			saxBuildingsOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './main-dashboard-requests.component.html',
})
export class MainDashboardRequestsComponent {
	private readonly _apiImporterSvc = inject(ApiImporterService);
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);

	public readonly sitemap = SITEMAP;

	public readonly requests = signal<ImporterRequest[]>([]);
	public readonly user = computed<User | null>(this._accountSvc.user);
	public requestTypes = RequestTypeEnum;
	public userFunctions = UserFunctionEnum;
	public getUIRequestId = ImporterRequestExtension.getUIRequestId;

	constructor() {
		const company: Company | null = this._companySvc.currentCompany();

		if (company && this.user()) {
			switch (this.user()!.function) {
				case UserFunctionEnum.Importer:
					this._apiImporterSvc
						.getRequestsByImporter(company.id)
						.then(r => this.requests.set(r.body!))
						.catch(console.error);

					break;

				case UserFunctionEnum.Exporter:
					this._apiImporterSvc
						.getRequestsByExporter(company.id)
						.then(r => this.requests.set(r.body!))
						.catch(console.error);

					break;
			}
		}
	}
}
