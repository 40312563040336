import { Component, signal, Signal } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxCardsBulk, saxBuildingsBulk } from '@ng-icons/iconsax/bulk';
import { bootstrapArrowDown, bootstrapArrowUp } from '@ng-icons/bootstrap-icons';

import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { AccountService } from 'src/app/_common/services/account/account.service';

import { MhpButtonGroupComponent } from "../../_common/components/mhp-button-group/mhp-button-group.component";
import { MHPButton } from 'src/app/_common/components/mhp-button-group/mhp-button.interface';
import { MhpButtonComponent } from "../../_common/components/mhp-button/mhp-button.component";
import { MainDashboardRequestsComponent } from "./main-dashboard-requests/main-dashboard-requests.component";
import { MainDashboardProductsComponent } from "./main-dashboard-products/main-dashboard-products.component";
import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { Company } from 'src/app/_common/models/company.model';
import { MhpSvgIconComponent } from "../../_common/components/mhp-svg-icon/mhp-svg-icon.component";

@Component({
	selector: 'app-main-dashboard',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpButtonGroupComponent,
		MhpButtonComponent,
		MainDashboardRequestsComponent,
		MainDashboardProductsComponent,
		MhpSvgIconComponent
	],
	providers: [
		provideIcons({
			bootstrapArrowUp,
			bootstrapArrowDown,
			saxCardsBulk,
			saxBuildingsBulk
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	styleUrl: './main-dashboard.component.scss',
	templateUrl: './main-dashboard.component.html'
})
export class MainDashboardComponent {

	public sitemap: SiteMap = SITEMAP;

	public exporters = signal<Company[]>([]);
	public user!: User | null;
	public userFunctions = UserFunctionEnum;

	public buttons: MHPButton[] = [
		{ text: 'Requests', value: 1 },
		{ text: 'Products', value: 2 }
	];

	public viewSelected: MHPButton = this.buttons[0];

	constructor(
		private _apiCompany: ApiCompanyService,
		private _account: AccountService
	) {
		this._account.currentUser.subscribe((user: User | null): User | null => this.user = user!);

		this._apiCompany.getAll()
			.then((response) => {
				if(response.ok)
					this.exporters.set(response.body || []);
			})
	}

}
