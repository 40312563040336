<div class="flex flex-row items-center">
	<div class="w-full max-w-96">
		<app-mhp-input
			type="text"
			prefix="saxSearchNormal1Outline"
			clearable
			[(model)]="productName"
			placeholder="Search a product in the list" />
	</div>
</div>
