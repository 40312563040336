import { HttpResponse } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { User } from '../../models/user.model';
import { SITEMAP } from '../../sitemap';
import { ApiAuthService } from '../api/api-auth/api-auth.service';
import { ApiUserService } from '../api/api-user/api-user.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	private readonly _router = inject(Router);
	private readonly _authSvc = inject(ApiAuthService);
	private readonly _apiUserSvc = inject(ApiUserService);
	private readonly _cookieSvc = inject(CookieService);

	private readonly sitemap = SITEMAP;
	private readonly userKey: string = 'User';
	private readonly authenticationKey: string = '.AspNetCore.Cookies';

	readonly _user = signal<User>(this.getStoredUser());

	readonly user = computed<User>(() => {
		const user = this._user();
		if (!user)
			throw new Error(
				'User is not defined, add isAuthenticated guard on the component or call isAuthentified before accessing user'
			);
		return user;
	});

	private readonly emptyUserData = <User>{};

	private readonly _isAuthentified = signal<boolean>(false);

	readonly isAuthentified = computed<boolean>(this._isAuthentified);

	readonly userFunction = computed(() => this.user().function);

	constructor() {}

	private updateAccount(user: User): void {
		this._user.set(user);
		sessionStorage.setItem(this.userKey, JSON.stringify(user));
	}

	private getStoredUser(): User {
		try {
			const userSerialized = sessionStorage.getItem(this.userKey);
			if (!userSerialized) return this.emptyUserData;
			return JSON.parse(userSerialized);
		} catch (error) {
			throw new Error('Error getting stored user: ' + error);
		}
	}

	/**
	 * Checks if the authentication cookie is expired
	 * @returns true if the cookie is valid and not expired, false otherwise
	 */
	async checkIfUserAuthentified(): Promise<boolean> {
		// The expiration of cookie has been set at the same expiration than the authentication expiration
		// on the server side
		try {
			const hasAuthCookie = this._cookieSvc.check(this.authenticationKey);
			if (hasAuthCookie) {
				const userSerialized = sessionStorage.getItem(this.userKey);
				if (!userSerialized) {
					const user = await this._apiUserSvc.getUser();
					this.updateAccount(user);
				} else {
					this._user.set(JSON.parse(userSerialized));
				}
			}
			this._isAuthentified.set(hasAuthCookie);
		} catch (error) {
			console.error('Error checking if user is authenticated:', error);
			this._isAuthentified.set(false);
			this.resetAccount();
		}

		return this._isAuthentified();
	}

	private resetAccount(): void {
		this._cookieSvc.delete(this.authenticationKey);
		sessionStorage.removeItem(this.userKey);
		this._user.set(this.emptyUserData);
		this._isAuthentified.set(false);
	}

	/**
	 * Sign out the user from the api server
	 */
	async signOut(): Promise<void> {
		// Sign out the user from the api server
		const signOutResponse: HttpResponse<string> = await this._authSvc.signOut();
		if (signOutResponse.status !== 200) console.error('Sign out failed with status:', signOutResponse.status);

		// reset current logged user from the current service
		this.resetAccount();

		// navigate to the main page
		this._router.navigate([this.sitemap.main.route]);
	}
}
