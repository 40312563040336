import { Component } from '@angular/core';

@Component({
	selector: 'app-admin',
	imports: [],
	templateUrl: './admin.component.html',
})
export class AdminComponent {
	constructor() {}
}
