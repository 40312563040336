<button
  class="flex justify-end" (click)="onModalOpen()"
>
  <app-mhp-button
    prefix="saxNote2Outline"
    text="Counter offer"
    type="tertiary"
  />
</button>

@if (modalVisible) {
	<app-main-request-make-offer-modal
		[exporter]="exporter()"
		[offer]="offer()"
		[item]="item()"
		(onConfirm)="counterOffer($event)"
		(stateClose)="onModalClose()"
	/>
}
