import { Component, inject, signal } from '@angular/core';
import { ApiExporterService } from '../_common/services/api/api-exporter/api-exporter.service';
import { ApiPackageService } from '../_common/services/api/api-package/api-package.service';
import { Router, RouterLink } from '@angular/router';
import { CompanyService } from '../_common/services/company/company.service';
import { derivedAsync } from 'ngxtension/derived-async';
import { ExporterStockEntry } from '../_common/models/exporter-stock-entry.model';
import { Package } from '../_common/models/package.model';
import { Company } from '../_common/models/company.model';
import { AccountService } from '../_common/services/account/account.service';
import { ApiCompanyService } from '../_common/services/api/api-company/api-company.service';
import { ApiImporterService } from '../_common/services/api/api-importer/api-importer.service';
import { SITEMAP } from '../_common/sitemap';
import { RequestTypeEnum } from '../_common/enums/request-type.enum';
import { UserFunctionEnum } from '../_common/enums/user-function.enum';
import { PackageItem } from '../_common/models/package-item.model';
import { User } from '../_common/models/user.model';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';
import { MhpSuggestionsCard } from '../_common/components/mhp-new-request-button/mhp-suggestions-card.component';

@Component({
	selector: 'app-update-stock-quota',
	imports: [MhpButtonComponent, RouterLink, MhpSuggestionsCard],
	templateUrl: './update-stock-quota.component.html',
	styleUrl: './update-stock-quota.component.scss',
})
export class UpdateStockQuotaComponent {
	protected readonly sitemap = SITEMAP;
}
