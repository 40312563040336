<app-mhp-base-modal
  [title]="'Can you confirm that you want to send to this offer?'"
  [actionButtonText]="'Send offers (' + this.validOffersCount() + ' products)'"
  [actionButtonPrefix]="'saxSend2Outline'"
  (action)="this.submit()"
  (close)="this.close()"
>
  <div class="flex flex-col w-full space-y-6">
    <div class="mb-8">
      <app-mhp-from-to [from]="this.user()!" [to]="[this.exporter()]" />
    </div>

    @if (false) {
      <div class="flex flex-col">
        <span class="mb-2 text-sm text-[var(--text-secondary)]">Total price</span>
        <span class="text-3xl ">{{ 30000 | currency : 'EUR' : 'symbol' : '.0-2' }}</span>
      </div>
    }
  </div>
</app-mhp-base-modal>
