<button
  class="flex justify-end text-red-500 hover:text-red-600"
  (click)="onModalOpen()"
>
  <ng-icon name="saxCloseCircleBold" size="1.75rem" />
</button>

@if (modalVisible) {
  <app-main-request-accept-decline-offer-modal
    [offer]="offer()"
    [type]="ModalResponseTypeEnum.Declined"
		(onConfirm)="onConfirm()"
    (stateClose)="onModalClose()"
  />
}
