<div class="flex flex-{{ this.listDirection() }} space-x-4">
	@if (this.companies().length === 1) {
		<app-mhp-company [company]="this.companies()[0]" />
	}

	@else {
		@for (company of this.companies(); track $index) {
			<app-mhp-company [company]="company" [displayNameAsTooltip]="true" />
		}

		@empty {
			<div [innerHTML]="this.emptyTemplate()"></div>
		}
	}
</div>
