import { Component, computed, inject } from '@angular/core';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { OffersGroupForItemComponent } from '../offers-group-all-items/offers-group-all-items.component';

/**
 * Component to display a summary of all the offers, organized in three groups:
 * - under review, where prices and quantities are being negotiated,
 * - confirmed, where both parties agreed on the terms of the offer,
 * - and declined, where the offer was refused by the importer or the exporter.
 */
@Component({
	selector: 'app-offers-summary',
	templateUrl: './offers-summary.component.html',
	styleUrls: ['./offers-summary.component.scss'],
	standalone: true,
	imports: [OffersGroupForItemComponent],
})
export class OffersSummaryComponent {
	private readonly _offersService = inject(OffersService);
	private readonly _accountService = inject(AccountService);
	isExporter = computed(() => this._accountService.user()!.function === UserFunctionEnum.Exporter);

	underReviewOffers = computed(() => this._offersService.underReviewOffers());
	confirmedOffers = computed(() => this._offersService.confirmedOffers());
	declinedOffers = computed(() => this._offersService.declinedOffers());
}
