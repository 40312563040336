import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxSearchNormal1Outline, saxHome2Outline, saxBook1Outline } from '@ng-icons/iconsax/outline';
import { saxSearchNormal1Bulk, saxHome2Bulk, saxBookBulk } from '@ng-icons/iconsax/bulk';

import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';

import { MhpThemeSwitchComponent } from "../../_common/components/mhp-theme-switch/mhp-theme-switch.component";
import { MhpSvgLogoComponent } from "../../_common/components/mhp-svg-logo/mhp-svg-logo.component";
import { MhpSearchModalComponent } from "../../_common/components/mhp-search-modal/mhp-search-modal.component";
import { MainHeaderAccountComponent } from "./main-header-account/main-header-account.component";
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { MhpLabelComponent } from "../../_common/components/mhp-label/mhp-label.component";

@Component({
	selector: 'app-main-header',
	standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgIconComponent,
    MhpSvgLogoComponent,
    MhpThemeSwitchComponent,
    MhpSearchModalComponent,
    MainHeaderAccountComponent,
    MhpLabelComponent
  ],
	providers: [
		provideIcons({
			saxBookBulk,
			saxHome2Bulk,
			saxSearchNormal1Bulk,
			saxBook1Outline,
			saxHome2Outline,
			saxSearchNormal1Outline,
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	styleUrl: './main-header.component.scss',
	templateUrl: './main-header.component.html'
})
export class MainHeaderComponent {

	public sitemap: SiteMap = SITEMAP;

	public isMenuOpened: boolean = false;
	public isSubMenuOpened: boolean[] = [];
	public currentURL: string = '';
	public searchModal: boolean = false;
	public user: User | null = null;
	public userFunctions = UserFunctionEnum;

	constructor(
		private _account: AccountService,
		public router: Router
	) {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	@HostListener('document:keydown', ['$event'])
	public triggerOpenSearchModal(e: any): void {
		if(e.ctrlKey && e.key === 'k') {
			e.preventDefault();
			this.openSearchModal();
		}
	}

	public openSearchModal(): void {
		if(!this.user) {
			this.router.navigate([ this.sitemap.login.route ]);
			return;
		}

		this.searchModal = true;
	}

}
