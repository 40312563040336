import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, computed, effect, inject, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { bootstrapPencilSquare } from '@ng-icons/bootstrap-icons';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';
import { InfiniteScrollDirective } from 'src/app/_common/directives/infinite-scroll.directive';
import { MainStockOfferEnumTabs } from 'src/app/_common/enums/main-stock-offer-tabs.enum';
import { ManifestItemTypeEnum } from 'src/app/_common/enums/manifest-item-type.enum';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { ManifestItem } from 'src/app/_common/models/manifest-item.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { environment } from '../../../environments/environment';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpPackageComponent } from '../../_common/components/mhp-package/mhp-package.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpTabsComponent } from '../../_common/components/mhp-tabs/mhp-tabs.component';
import { ItemsState } from './main-catalog.model';

@Component({
	selector: 'app-main-catalog',
	imports: [
		RouterModule,
		CommonModule,
		MhpButtonComponent,
		MhpSvgIconComponent,
		CurrencyPipe,
		MhpTabsComponent,
		MhpPackageComponent,
		InfiniteScrollDirective,
		ScrollingModule,
	],
	providers: [
		provideIcons({
			bootstrapPencilSquare,
			saxInfoCircleOutline,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './main-catalog.component.html',
})
export class MainCatalogComponent implements OnInit {
	private readonly _accountSvc = inject(AccountService);
	private readonly _apiExporterSvc = inject(ApiExporterService);
	protected userCompany = computed(() => this._accountSvc.user().company);

	readonly sitemap = SITEMAP;

	readonly tabs: MHPTab<MainStockOfferEnumTabs>[] = [
		{ text: 'All', value: MainStockOfferEnumTabs.All },
		{ text: 'My stock', value: MainStockOfferEnumTabs.MyStock },
		{ text: 'My quotas', value: MainStockOfferEnumTabs.MyQuotas },
	];

	readonly userFunction = computed<UserFunctionEnum>(this._accountSvc.userFunction);

	tabSelected = signal(this.tabs[0]);

	readonly quotasVisible = computed<boolean>(() => {
		return this.tabSelected() == this.tabs[0] || this.tabSelected() == this.tabs[2];
	});

	readonly stocksVisible = computed<boolean>(() => {
		return this.tabSelected() == this.tabs[0] || this.tabSelected() == this.tabs[1];
	});

	private readonly _wantedItemsCount = 500;

	hasManifestItems = false;
	private readonly _manifestItemsState: ItemsState = {
		items: signal<ManifestItem[]>([]),
		isLoading: false,
		isLoaded: false,
		hasAll: false,
	};

	private readonly _stocksState: ItemsState = {
		items: signal<ManifestItem[]>([]),
		isLoading: false,
		isLoaded: false,
		hasAll: false,
	};

	private readonly _quotasState: ItemsState = {
		items: signal<ManifestItem[]>([]),
		isLoading: false,
		isLoaded: false,
		hasAll: false,
	};

	viewItems = computed<ManifestItem[]>(() => {
		switch (this.tabSelected()) {
			case this.tabs[0]:
				return this._manifestItemsState.items();
			case this.tabs[1]:
				return this._stocksState.items();
			case this.tabs[2]:
				return this._quotasState.items();
			default:
				throw new Error('Invalid tab selected');
		}
	});

	userFunctions = UserFunctionEnum;
	stockDumpUri = computed(() => environment.API_URL + `/exporter/${this.userCompany().id}/stock/dump`);
	quotaDumpUri = computed(() => environment.API_URL + `/exporter/${this.userCompany().id}/quota/dump`);

	constructor() {
		effect(() => {
			if (this.tabSelected() == this.tabs[1] && !this._stocksState.isLoaded) {
				this.loadStocks();
			} else if (this.tabSelected() == this.tabs[2] && !this._quotasState.isLoaded) {
				this.loadQuotas();
			}
		});
	}

	async ngOnInit() {
		await this.loadManifestItems();
		this.hasManifestItems = this._manifestItemsState.items().length > 0;
	}

	public async loadMore(): Promise<void> {
		if (this.tabSelected() == this.tabs[0]) {
			await this.loadManifestItems();
		} else if (this.tabSelected() == this.tabs[1]) {
			await this.loadStocks();
		} else if (this.tabSelected() == this.tabs[2]) {
			await this.loadQuotas();
		}
	}

	private async loadManifestItems(): Promise<void> {
		await this.loadItems(this._manifestItemsState, [ManifestItemTypeEnum.Stock, ManifestItemTypeEnum.Quota]);
	}

	private async loadStocks(): Promise<void> {
		await this.loadItems(this._stocksState, [ManifestItemTypeEnum.Stock]);
	}

	private async loadQuotas(): Promise<void> {
		await this.loadItems(this._quotasState, [ManifestItemTypeEnum.Quota]);
	}

	private async loadItems(state: ItemsState, manifestItemTypes: ManifestItemTypeEnum[]): Promise<void> {
		if (state.isLoading || state.hasAll) return;

		const exporterId = this.userCompany().id;
		if (!exporterId) throw new Error('Exporter ID not found');

		state.isLoading = true;
		const newItems = await this._apiExporterSvc.findManifestItems(
			{ types: manifestItemTypes },
			state.items().length,
			this._wantedItemsCount
		);
		state.hasAll = newItems.length < this._wantedItemsCount;
		state.items.update(i => [...i, ...newItems]);
		state.isLoading = false;
		state.isLoaded = true;
	}
}
