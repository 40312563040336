<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="p-6 relative flex flex-col flex-start items-start transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        (click)="this.clickedInside = true"
      >
        <div class="mb-8">
          <button
            class="flex flex-row mb-6 justify-center item-center"
            (click)="this.close()"
          >
            <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
          </button>
  
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
						@switch (this.type()) {
							@case (this.offerResponseTypes.Accepted) { Are you sure you want to accept this offer? }
							@case (this.offerResponseTypes.Declined) { Are you sure you want to decline this offer? }
						}
					</h3>
        </div>

        @if (this.isLoading) {
					<div class="flex flex-col justify-center items-center w-full">
						<app-mhp-loader />
					</div>
				}

				@else {
					<div class="flex flex-col w-full space-y-6">
						<div class="flex flex-col w-full space-y-8">
							<div class="flex flex-col w-full space-y-6 p-4 border rounded-2xl border-gray-200 dark:border-gray-700">
								<app-mhp-company [company]="this.exporter()" />
	
								@if (this.package()) {
									<span class="text-gray-900 dark:text-white">{{ this.package().shortName }}</span>
								}
	
								<div class="rounded-2xl overflow-hidden">
									<table class="w-full">
										<thead>
											<tr class="text-gray-500 bg-gray-100 dark:bg-gray-950">
												<th class="px-4 py-2 text-sm text-left font-medium">Batch</th>
												<th class="px-4 py-2 text-sm text-left font-medium">Expiration date</th>
												<th class="px-4 py-2 text-sm text-left font-medium">Qty</th>
												<th class="px-4 py-2 text-sm text-left font-medium">Unit cost</th>
											</tr>
										</thead>
										<tbody>
											<tr class="text-gray-900 bg-gray-50 dark:text-white dark:bg-gray-900">
												<td class="px-4 py-6 text-sm text-left font-medium">{{ this.offer().batchNumber }}</td>
												<td class="px-4 py-6 text-sm text-left font-medium">{{ this.offer().minExpiration | date : 'd MMM y' : '' : "en-US" }}</td>
												<td class="px-4 py-6 text-sm text-left font-medium">{{ this.offer().quantity }}</td>
												<td class="px-4 py-6 text-sm text-left font-medium">{{ this.offer().price | currency : 'EUR': 'symbol' : '.2-2' }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
		
							<!-- [TODO]: Preview to working on comment when is necessary -->
							<!-- <div class="flex flex-col">
								<span class="mb-4 text-sm text-gray-500">Comments (optional)</span>
								<app-mhp-input
									placeholder="Enter your comment..."
									type="textarea"
								/>
							</div> -->
						</div>
	
						<app-mhp-button
							prefix="saxSend2Outline"
							size="large"
							[text]="this.type() === this.offerResponseTypes.Accepted ? 'Yes, I accept this offer' : 'Yes, I decline this offer'"
							(click)="this.submit()"
						/>
					</div>
				}
      </div>
    </div>
  </div>
</div>
