import { CommonModule, DatePipe } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxImportOutline, saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';
import { saxTickCircleBold } from '@ng-icons/iconsax/bold';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { SITEMAP } from '../_common/sitemap';

import { Company } from '../_common/models/company.model';
import { PackageItem } from '../_common/models/package-item.model';
import { ImporterRequest } from '../_common/models/importer-request.model';
import { AccountService } from '../_common/services/account/account.service';
import { ApiImporterService } from '../_common/services/api/api-importer/api-importer.service';
import { MhpSuggestionsCard } from '../_common/components/mhp-new-request-button/mhp-suggestions-card.component';
import { MhpCompanySelectorComponent } from '../_common/components/mhp-company-selector/mhp-company-selector.component';
import { MhpFromToComponent } from '../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';
import { MhpInputComponent } from '../_common/components/mhp-input/mhp-input.component';
import { UserFunctionEnum } from '../_common/enums/user-function.enum';
import { MhpSvgIconComponent } from '../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { RequestTypeEnum } from '../_common/enums/request-type.enum';
import { MhpPackageComponent } from '../_common/components/mhp-package/mhp-package.component';
import { TableHandler } from '../_common/utils/table-handler';
import { CompanyService } from '../_common/services/company/company.service';
import { MhpFileUploaderComponent } from '../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { ApiPackageService } from '../_common/services/api/api-package/api-package.service';

interface NewRequestProps {
	exporter?: Company;
	requestType?: RequestTypeEnum;
	step?: number;
	userRequestId?: string;
}

@Component({
	selector: 'app-new-request',
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgIconComponent,
		MhpSuggestionsCard,
		MhpButtonComponent,
		MhpCompanySelectorComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpSvgIconComponent,
		MhpPackageComponent,
		MhpFileUploaderComponent,
	],
	providers: [
		DatePipe,
		provideIcons({
			saxImportOutline,
			saxInfoCircleOutline,
			saxTickCircleBold,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './new-request.component.html',
	styleUrl: './new-request.component.scss',
})
export class NewRequestComponent implements OnInit {
	private readonly router = inject(Router);
	private readonly _account = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _apiImporter = inject(ApiImporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);

	protected _tableSvc: TableHandler<PackageItem> = new TableHandler<PackageItem>(
		this._apiPackageSvc,
		'new-request',
		row =>
			<PackageItem>{
				packageCip13: +row['CIP 13'],
				packageName: row['Product name'],
				quantity: +(row['Quantities'] ?? '').replace(/[^\d,.]/g, '') || null,
				minQuantity: +(row['Qty min'] ?? '').replace(/[^\d,.]/g, '') || null,
				quota: null,
				multiple: +(row['Multiple'] ?? '').replace(/[^\d,.]/g, '') || null,
				batchNumber: row['Batch number'] || null,
				minExpiration: row['Min expiration date'],
				price: +(row['Price'] ?? '').replace(/[^\d,.]/g, '') || null,
			}
	);

	protected readonly sitemap = SITEMAP;
	protected readonly requestTypes = RequestTypeEnum;
	protected readonly userFunctions = UserFunctionEnum;
	protected readonly packageItems = this._tableSvc.items;
	step: number = 0;
	requestType: RequestTypeEnum = RequestTypeEnum.PurchaseOrder;
	exporter?: Company;
	userRequestId?: string;

	protected readonly packageItemsSum = computed(() =>
		this.packageItems().length == 0 ?
			0
		:	Math.round(
				this.packageItems()
					.map((item: PackageItem) => (item.price ?? 0) * (item.quantity ?? 0))
					.reduce((a, b) => a + b) * 100
			) / 100
	);

	protected readonly user = this._account.user;
	protected readonly exporters = this._companySvc.allCompanies;

	// TODO: make exporter not nullable in order to get a more robust code
	public csvFileUploaded: boolean = false;

	/// downloadable template url for stock csv file
	public readonly purchaseOrderTemplateCsvPath = '/assets/download/purchase-order.template.csv';

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Initializes the component, and the table service
	 */
	ngOnInit(): void {
		this._tableSvc.initFromLocalStorage();
	}

	public submit(): void {
		this._apiImporter
			.postRequest({
				type: this.requestType!,
				userRequestId: this.userRequestId!,
				exporters: [this.exporter!],
				items: this.packageItems().map((packageItem: PackageItem) => {
					packageItem.minExpiration = dayjs(packageItem.minExpiration, TableHandler.dateParsingFormats, true).format(
						'YYYY-MM-DD'
					);
					return packageItem;
				}),
			})
			.then((response: HttpResponse<ImporterRequest | null>) => {
				if (response.ok) {
					this.reset();
					this.router.navigate([this.sitemap.request.route, response.body!.id]);
				}
			})
			.catch(err => console.error(err));
	}

	public updateExporter(exporter: Company): void {
		this.exporter = exporter;
	}

	public processingFiles(file: File | null): void {
		if (file) {
			this._tableSvc.processFile(file);
			this.nextStep();
		}

		this.csvFileUploaded = !!file;
	}

	public checkErrorCell(packageItem: PackageItem, index: number): boolean {
		const packageItems = [...this.packageItems()];
		return packageItems.splice(index, 1).includes(packageItem);
	}

	public deleteItem(index: number): void {
		this.packageItems().splice(index, 1);
	}

	public updateRequestType(requestType: number): void {
		this.requestType = requestType;
		this.nextStep();
	}

	public setStep(step: number): void {
		this.step = step;
	}

	public nextStep(): void {
		if (this.step === 4) return;
		this.step = this.step + 1;
	}

	public previousStep(): void {
		if (this.step === 0) return;
		this.step = this.step - 1;
	}

	public reset(): void {
		this._tableSvc.clearCsvFile();
		this.step = 0;
		this.requestType = RequestTypeEnum.PurchaseOrder;
	}

	public canContinue(): boolean {
		if (this.step === 3 && this.user()?.function === this.userFunctions.Exporter) return false;

		switch (this.step) {
			case 1:
				return this.csvFileUploaded;
			case 2:
				return !!this.packageItems().length;
			case 3:
				return !!this.exporter;
			case 4:
				return true;
		}

		return false;
	}
}
