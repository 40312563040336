import { Component, input } from '@angular/core';

@Component({
	selector: 'app-mhp-loader',
	standalone: true,
	imports: [],
	templateUrl: './mhp-loader.component.html',
})
export class MhpLoaderComponent {
	public readonly text = input('Loading...');
}
