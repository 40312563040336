import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { bootstrapArrowDown, bootstrapArrowUp } from '@ng-icons/bootstrap-icons';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsBulk, saxCardsBulk } from '@ng-icons/iconsax/bulk';

import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MainDashboardExporterTasksComponent } from './main-dashboard-exporter-tasks/main-dashboard-exporter-tasks.component';
import { MainDashboardImporterTasksComponent } from './main-dashboard-importer-tasks/main-dashboard-importer-tasks.component';
import { MainDashboardRequestsComponent } from './main-dashboard-requests/main-dashboard-requests.component';

@Component({
	selector: 'app-main-dashboard',
	imports: [
		CommonModule,
		RouterModule,
		MhpButtonComponent,
		MainDashboardRequestsComponent,
		MainDashboardExporterTasksComponent,
		MainDashboardImporterTasksComponent,
	],
	providers: [
		provideIcons({
			bootstrapArrowUp,
			bootstrapArrowDown,
			saxCardsBulk,
			saxBuildingsBulk,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styles: [
		`
			:host {
				@apply flex flex-col gap-8;
			}
		`,
	],
	templateUrl: './main-dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainDashboardComponent {
	private readonly companyService = inject(CompanyService);
	private readonly _accountSvc = inject(AccountService);
	public readonly sitemap = SITEMAP;
	public readonly exporters = computed(this.companyService.allCompanies);
	public readonly user = computed<User>(this._accountSvc.user);
	protected readonly UserFunctions = UserFunctionEnum;
}
