import { Component, effect, inject, signal } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { bootstrapPencilSquare } from '@ng-icons/bootstrap-icons';
import { saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';

import { SITEMAP } from 'src/app/_common/sitemap';
import { Company } from 'src/app/_common/models/company.model';
import { ExporterStock } from 'src/app/_common/models/exporter-stock.model';
import { Package } from '../../_common/models/package.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { ApiPackageService } from '../../_common/services/api/api-package/api-package.service';
import { ToCipPipe } from '../../_common/pipes/to-cip/to-cip.pipe';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpTabsComponent } from '../../_common/components/mhp-tabs/mhp-tabs.component';
import { MainStockOfferEnumTabs } from 'src/app/_common/enums/main-stock-offer-tabs.enum';
import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';

@Component({
	selector: 'app-main-stock-offer',
	standalone: true,
	imports: [
		RouterModule,
		CommonModule,
		NgIconComponent,
		MhpButtonComponent,
		ToCipPipe,
		MhpSvgIconComponent,
		CurrencyPipe,
		MhpTabsComponent,
	],
	providers: [
		provideIcons({
			bootstrapPencilSquare,
			saxInfoCircleOutline,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './main-stock-offer.component.html',
})
export class MainStockOfferComponent {
	private readonly _accountSvc = inject(AccountService);
	private readonly _apiCompanySvc = inject(ApiCompanyService);
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);

	public readonly sitemap = SITEMAP;

	public readonly offers = signal<ExporterStock | null>(null);
	public readonly packages = signal<Package[]>([]);
	public readonly user = signal<User | null>(this._accountSvc.user());

	public readonly tabs: MHPTab<MainStockOfferEnumTabs>[] = [
		{ text: 'All', value: MainStockOfferEnumTabs.All },
		{ text: 'My stock', value: MainStockOfferEnumTabs.MyStock },
		{ text: 'My quotas', value: MainStockOfferEnumTabs.MyQuotas },
	];

	public tabSelected: MHPTab<MainStockOfferEnumTabs> = this.tabs[0];

	public get quotasVisible(): boolean {
		return this.tabSelected == this.tabs[0] || this.tabSelected == this.tabs[2];
	}

	public get stocksVisible(): boolean {
		return this.tabSelected == this.tabs[0] || this.tabSelected == this.tabs[1];
	}

	constructor() {
		effect(async () => {
			const company: Company | null = (await this._apiCompanySvc.getOwn()).body;

			this.offers.set((await this._apiExporterSvc.getById(company!.id)).body || null);

			if (this.offers()) {
				this.packages.set(
					(await this._apiPackageSvc.getByCIP13s(this.offers()!.items!.map(offer => offer.batch.cip13))).body || []
				);
			}
		});
	}

	public getPackage(cip13: number): Package | undefined {
		return this.packages().find(p => p.cip13 === cip13);
	}
}
