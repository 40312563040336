import { Component, inject, Signal, signal } from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import { RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { bootstrapPencilSquare } from '@ng-icons/bootstrap-icons';
import { saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';

import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { Company } from 'src/app/_common/models/company.model';
import { ExporterStock } from 'src/app/_common/models/exporter-stock.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { Package } from "../../_common/models/package.model";
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { ApiPackageService } from "../../_common/services/api/api-package/api-package.service";
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { ToCipPipe } from "../../_common/pipes/to-cip/to-cip.pipe";
import { MhpButtonComponent } from "../../_common/components/mhp-button/mhp-button.component";
import { MhpSvgIconComponent } from "../../_common/components/mhp-svg-icon/mhp-svg-icon.component";

@Component({
	selector: 'app-main-stock-offer',
	standalone: true,
  imports: [
    RouterModule,
    NgIconComponent,
    MhpButtonComponent,
    ToCipPipe,
    MhpSvgIconComponent,
    CurrencyPipe
  ],
	providers: [
		provideIcons({
      bootstrapPencilSquare,
			saxInfoCircleOutline,
      saxTrashOutline
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	templateUrl: './main-stock-offer.component.html',
})
export class MainStockOfferComponent {
  private _account = inject(AccountService);
  private _apiCompany = inject(ApiCompanyService);
  private _apiOffer = inject(ApiOfferService);
  private _apiExporter = inject(ApiExporterService);
  private _apiPackage = inject(ApiPackageService);

	public sitemap: SiteMap = SITEMAP;

	public offers = signal<ExporterStock | null>(null);
  public packages = signal<Package[]>([]);
	public user: User | null = null;

	constructor() {
		this._account.currentUser.subscribe(async (user: User | null) => {
			this.user = user;

			const company: Company | null = (await this._apiCompany.getOwn()).body;

			this.offers.set((await this._apiExporter.getById(company!.id)).body || null);

      if (this.offers()) {
        this.packages.set((await this._apiPackage.getByCIP13s(this.offers()!.items!.map(offer => offer.batch.cip13))).body || []);
      }
		});
	}

  public getPackage(cip13: number): Package | undefined {
    return this.packages().find(p => p.cip13 === cip13);
  }
}
