import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SITEMAP, SiteMap } from '../_common/sitemap';

import { MainHeaderComponent } from './main-header/main-header.component';

@Component({
	selector: 'app-main',
	standalone: true,
	imports: [
		RouterModule,
		MainHeaderComponent
	],
	styleUrl: './main.component.scss',
	templateUrl: './main.component.html'
})
export class MainComponent {

	public sitemap: SiteMap = SITEMAP;

	constructor() {}

}
