import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxCloseCircleBold } from '@ng-icons/iconsax/bold';

import {
	MainRequestAcceptDeclineOfferModalComponent,
	ModalResponseTypeEnum,
} from '../../offer-modals/main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component';
import { OfferActionBase } from '../offer-action-base/offer-action-base';

@Component({
	selector: 'offer-action-decline',
	standalone: true,
	imports: [NgIconComponent, MainRequestAcceptDeclineOfferModalComponent, CommonModule],
	templateUrl: './offer-action-decline.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		provideIcons({
			saxCloseCircleBold,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OfferActionDeclineComponent extends OfferActionBase {
	ModalResponseTypeEnum = ModalResponseTypeEnum;

	async onConfirm() {
		await this.offerService.declineOffers(this.offers());
	}
}
