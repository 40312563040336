import { CommonModule } from '@angular/common';
import { Component, computed, HostListener, inject, input, output } from '@angular/core';

import { Company } from 'src/app/_common/models/company.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpBaseModalComponent } from '../../../../_common/components/mhp-base-modal/mhp-base-modal.component';
import { MhpFromToComponent } from '../../../../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpSvgIconComponent } from '../../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';

/**
 * Modal component for confirming multiple offers.
 * Features:
 * - Displays overview of multiple offers
 * - Shows total number of offers being confirmed
 * - Displays exporter and user information
 * - Support for custom confirmation button text
 * - Content projection for question and remark text
 */
@Component({
	selector: 'app-main-request-confirm-offers',
	imports: [CommonModule, MhpFromToComponent, MhpSvgIconComponent, MhpBaseModalComponent],
	templateUrl: './main-request-confirm-offers.component.html',
})
export class MainRequestConfirmOffersComponent {
	private readonly _accountSvc = inject(AccountService);

	/** Required exporter company details */
	public readonly exporter = input.required<Company>();

	/** Required array of offers to confirm */
	public readonly offers = input.required<Offer[]>();

	/** Event emitted on confirmation */
	public readonly onConfirm = output<boolean>();

	/** Event emitted on close */
	public readonly stateClose = output<boolean>();

	/** Current user details */
	public readonly user = computed<User | null>(this._accountSvc.user);

	/** Custom button text */
	public readonly buttonText = input.required<string>();

	/** Handle confirmation submit */
	public submitConfirm() {
		this.onConfirm.emit(true);
		this.close();
	}

	/** Handle ESC key for closing */
	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	/** Handle close action */
	public close(): void {
		this.stateClose.emit(true);
	}
}
