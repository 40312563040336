<button
  class="flex flex-row items-center p-4 max-w-md w-full space-x-6 rounded-xl text-left border text-gray-900 border-gray-200 bg-white dark:text-white dark:border-gray-700 dark:bg-gray-800 disabled:opacity-60"
  [disabled]="this.disabled()"
>

  <div class="h-[5.875rem] w-[5.875rem] shrink-0 rounded-lg bg-gray-200 dark:bg-gray-900">

  </div>

  <div class="flex flex-col flex-start w-full">
    <span class="font-medium">{{ this.title() }}</span>
    <span class="text-sm text-gray-500">{{ this.text() }}</span>
  </div>

  <div>
    <ng-icon name="saxArrowRight3Outline" />
  </div>

</button>