import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
	selector: 'app-notification-indicator',
	templateUrl: './mhp-notification-indicator.component.html',
	standalone: true,
	imports: [CommonModule],
})
export class NotificationIndicatorComponent {}
