import { AfterViewInit, Component, computed, inject, OnChanges, OnInit, Signal, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline, saxBuildingsOutline, saxMoreOutline, saxSmsOutline } from '@ng-icons/iconsax/outline';

import { Package } from 'src/app/_common/models/package.model';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { ToCipPipe } from "../../_common/pipes/to-cip/to-cip.pipe";
import { MhpLoaderComponent } from "../../_common/components/mhp-loader/mhp-loader.component";
import { MhpButtonComponent } from "../../_common/components/mhp-button/mhp-button.component";
import { MhpViewSwitchComponent } from "../../_common/components/mhp-view-switch/mhp-view-switch.component";
import { MhpCompanyComponent } from "../../_common/components/mhp-company/mhp-company.component";
import { MainPackageRequestProductModalComponent } from './main-package-request-product-modal/main-package-request-product-modal.component';
import { Company } from 'src/app/_common/models/company.model';
import { ApiCompanyService } from 'src/app/_common/services/api/api-company/api-company.service';
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { CurrencyPipe, DatePipe, I18nPluralPipe } from "@angular/common";
import { getExporterById } from 'src/app/_common/utils/get-exporter.util';

@Component({
	selector: 'app-main-package',
	standalone: true,
  imports: [
    RouterModule,
    NgIconComponent,
    ToCipPipe,
    MhpLoaderComponent,
    MhpButtonComponent,
    MhpViewSwitchComponent,
    MhpCompanyComponent,
    MainPackageRequestProductModalComponent,
    DatePipe,
    CurrencyPipe,
    I18nPluralPipe,
  ],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxMoreOutline,
			saxSmsOutline
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	templateUrl: './main-package.component.html',
})
export class MainPackageComponent {

	public cip: string = '';
	public package = signal<Package | null>(null);
	public exporters = signal<Company[]>([]);
	public stocks = signal<ExporterStockEntry[]>([]);

	public requestProductModal = signal(false);
	public isLoading: boolean = false;
	public view: number = 1;

  public productsSum = computed(() => this.stocks().reduce((acc, stock) => acc + (stock.quantity ?? 0), 0));
  public distinctExporters = computed(() => this.stocks().map(stock => stock.exporterId).filter((value, index, self) => self.indexOf(value) === index).length);

  protected selectedStock = signal<ExporterStockEntry | null>(null);

	constructor(
		private activatedRoute: ActivatedRoute,
		private _apiCompany: ApiCompanyService,
		private _apiPackage: ApiPackageService
	) {
		this.activatedRoute.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.isLoading = true;
			this.cip = paramMap.get('cip')!;

			this.package.set(this.cip.length === 13
				? (await this._apiPackage.getByCIP13(+paramMap.get('cip')!)).body
				: this.cip.length === 7
					? (await this._apiPackage.getByCIP7(+paramMap.get('cip')!)).body
					: null);

			this.stocks.set(((await this._apiPackage.getByCIP13WithStocks(this.package()?.cip13!)).body)!);

			this.isLoading = false;
		});

		this._apiCompany.getAll()
			.then((response) => {
				if(response.ok)
					this.exporters = signal(response.body || []);
			});
	}

  public getExporter = (id: number): Company | undefined => getExporterById(this.exporters(), id);

  public requestStock(stock: ExporterStockEntry) {
    this.requestProductModal.set(true);
    this.selectedStock.set(stock);
  }
}
