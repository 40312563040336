import { CommonModule } from '@angular/common';
import { Component, computed, HostListener, inject, input, OnInit, output, signal } from '@angular/core';
import { MhpBaseModalComponent } from 'src/app/_common/components/mhp-base-modal/mhp-base-modal.component';
import { MhpFromToComponent } from 'src/app/_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { MhpLabelComponent } from 'src/app/_common/components/mhp-label/mhp-label.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';

import { Company } from 'src/app/_common/models/company.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { RequestItem } from 'src/app/_common/models/request-item.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';

/** Form data for counter offer */
export class CounterOfferForm {
	quantity: number = 0;
	price: number = 0;
}

/**
 * Modal component for making counter offers.
 * Features:
 * - Form for updating quantity and price
 * - Displays original offer details for reference
 * - Shows exporter and user information
 * - Form validation and submission
 * - Persists form values during modal lifecycle
 */
@Component({
	selector: 'app-main-request-make-offer-modal',
	imports: [
		CommonModule,
		MhpFromToComponent,
		MhpInputComponent,
		MhpLabelComponent,
		MhpSvgIconComponent,
		MhpBaseModalComponent,
	],
	templateUrl: './main-request-make-offer-modal.component.html',
})
export class MainRequestMakeOfferModalComponent implements OnInit {
	private readonly _accountSvc = inject(AccountService);

	/** Required exporter company details */
	public readonly exporter = input.required<Company>();

	/** Required request item details */
	public readonly item = input.required<RequestItem>();

	/** Required original offer details */
	public readonly offer = input.required<Offer>();

	/** Event emitted with counter offer form data */
	public readonly onConfirm = output<CounterOfferForm>();

	/** Event emitted on close */
	public readonly stateClose = output<boolean>();

	/** Form price value */
	public readonly price = signal<string>('');

	/** Form quantity value */
	public readonly quantity = signal<string>('');

	/** Current user details */
	public readonly user = computed<User>(this._accountSvc.user);

	/** Initialize form values */
	public ngOnInit(): void {
		this.price.set(this.offer().price.toString());
		this.quantity.set(this.offer().quantity.toString());
	}

	/** Handle form submission */
	public submitConfirm() {
		const counterOfferForm = <CounterOfferForm>{
			quantity: +this.quantity(),
			price: +this.price(),
		};
		this.onConfirm.emit(counterOfferForm);
		this.close();
	}

	/** Handle ESC key for closing */
	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	/** Handle close action */
	public close(): void {
		this.stateClose.emit(true);
	}
}
