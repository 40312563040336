import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MhpBaseModalComponent } from 'src/app/_common/components/mhp-base-modal/mhp-base-modal.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { Offer } from 'src/app/_common/models/offer.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { MhpCompanyComponent } from '../../../../_common/components/mhp-company/mhp-company.component';

@Component({
	selector: 'app-main-request-edit-offer-modal',
	templateUrl: './main-request-edit-offer-modal.component.html',
	standalone: true,
	imports: [CommonModule, FormsModule, MhpBaseModalComponent, MhpInputComponent, MhpCompanyComponent],
})
export class MainRequestEditOfferModalComponent extends MhpBaseModalComponent {
	offer = input.required<Offer>();
	isOpen = model<boolean>(false);

	private readonly _offerService = inject(OffersService);

	private readonly _companyService = inject(CompanyService);

	readonly exporter = computed(() => this._companyService.getCompanyById(this.offer().exporterId));

	batchNumber = model<string>('');
	expirationDate = model<string>('');
	quantity = model<string>('0');
	price = model<string>('0');

	constructor() {
		super();
		// Initialize form values whenever offer changes
		effect(() => {
			if (this.offer()) {
				this.batchNumber.set(this.offer().batchNumber ?? '');
				this.expirationDate.set(this.offer().expirationDate ?? '');
				this.quantity.set(this.offer().quantity?.toString() ?? '0');
				this.price.set(this.offer().price?.toString() ?? '0');
			}
		});
	}

	async save() {
		if (!this.batchNumber() || !this.expirationDate() || !this.quantity() || !this.price()) {
			return;
		}

		const updatedOffer = {
			...this.offer(),
			batchNumber: this.batchNumber(),
			expirationDate: this.expirationDate(), // Keep as string
			quantity: parseInt(this.quantity(), 10),
			price: parseFloat(this.price()),
		};

		await this._offerService.updateOffer(updatedOffer);

		this.handleClose();
	}

	override handleClose() {
		this.isOpen.set(false);
		super.handleClose();
	}
}
