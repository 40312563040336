import { computed, effect, signal } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import csv from 'csvtojson';
import { ApiExporterService } from '../services/api/api-exporter/api-exporter.service';
import { ApiPackageService } from '../services/api/api-package/api-package.service';
import { derivedAsync } from 'ngxtension/derived-async';

/**
 * Service for handling CSV
 * @export
 * @class TableHandlerService
 * @template TItem Type of the items in the table
 */
export class TableHandler<TItem extends { packageCip13: number }> {
	private readonly csvDataKey = 'csv-data';
	private readonly csvFileKey = 'csv-file';

	readonly acceptedFiles: string[] = ['.csv'];

	isFileInputFilled: boolean = false;

	static readonly dateParsingFormats = [
		'DD/MM/YYYY',
		'DD/MM/YY',
		'D/M/YYYY',
		'D/M/YY',
		'MM/YYYY',
		'MM/YY',
		'M/YYYY',
		'M/YY',
		'YYYY-MM-DD',
	];

	readonly file = signal<Blob | null>(null);
	readonly items = signal<TItem[]>([]);
	readonly validCip13s = derivedAsync<Set<number>>(async () => {
		return await this._apiPackageSvc.validateCip13s(
			this.items()
				.map(entry => entry.packageCip13)
				.filter(cip13 => !!cip13)
		);
	});

	getTypedKey(key: string): string {
		return `${this.tableType}-${key}`;
	}

	/**
	 * Creates an instance of TableHandlerService.
	 * @param tableType Sets the table type to be manipulated by the service.
	 * @param itemsMapFunc Maps the CSV row to the desired item type.
	 */
	constructor(
		private readonly _apiPackageSvc: ApiPackageService,
		readonly tableType: string,
		readonly itemsMapFunc: (row: any) => TItem = row => row as TItem
	) {
		dayjs.extend(customParseFormat);

		effect(async () => {
			localStorage.setItem(this.getTypedKey(this.csvDataKey), JSON.stringify(this.items()));
			localStorage.setItem(this.getTypedKey(this.csvFileKey), (await this.file()?.text()) ?? '');
		});
	}

	initFromLocalStorage(): void {
		if (localStorage.getItem(this.getTypedKey(this.csvDataKey))) {
			const i = JSON.parse(localStorage.getItem(this.getTypedKey(this.csvDataKey))!);
			console.log('Items: ', i);
			this.items.set(i);
		}

		if (localStorage.getItem(this.getTypedKey(this.csvFileKey))) {
			const f = localStorage.getItem(this.getTypedKey(this.csvFileKey));
			console.log('File: ', f);
			this.file.set(new Blob([f!]));
		}
	}

	processFile(file: Blob): void {
		if (!file) throw new Error('File is null');

		this.file.set(file);

		let reader: FileReader = new FileReader();
		reader.readAsText(file);

		reader.onload = async () => {
			const csvStr = reader.result as string;

			const csvRow = await csv({
				noheader: false,
				output: 'json',
			}).fromString(csvStr.replaceAll(',', '.').replaceAll(';', ','));

			this.items.set(csvRow.map(this.itemsMapFunc));
		};
	}

	clearCsvFile(): void {
		this.file.set(null);
		this.isFileInputFilled = false;
		this.items.set([]);

		localStorage.removeItem(this.csvDataKey);
		localStorage.removeItem(this.csvFileKey);
	}
}
