<div class="mhp-request">

	@if (this.request) {
		<div class="flex flex-col">
			<div class="flex flex-col w-full mb-6">
				<div class="flex flex-row items-center mb-6 text-[var(--text-secondary)]">
					<a [routerLink]="[ '/dashboard' ]">MedHubPlace</a>
					<ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
					<a>Request #{{ this.displayedId }}</a>
				</div>

				<div class="flex flex-col space-y-4 md:space-y-0 md:flex-row">
					<div class="flex flex-row items-center w-full">
						<h2 class="mr-4 text-4xl font-semibold">
							@switch (this.request.type) {
								@case (this.RequestTypeEnum.PurchaseOrder) { Purchase order }
								@case (this.RequestTypeEnum.RequestOffer) { Request Offer }
							}
						</h2>
						<app-mhp-status-label [status]="3" />
					</div>
					<div class="flex flex-row text-right shrink-0 space-x-4 justify-end">
					</div>
				</div>
			</div>

			<div class="flex flex-row items-center mb-8">
				<div class="flex flex-row w-full">
					<app-mhp-button-group
						[buttons]="this.statusLabel"
						[choice]="this.selectedStatus"
						(choiceChange)="this.gotoTab($event, true)"
					/>
				</div>

				<div class="flex flex-row shrink-0 space-x-4">
					<!-- On laisse pour l'instant le temps que l'écran pre-fill soit fait coté exporter -->
					@if (
						this.user()!.function === this.UserFunctionEnum.Exporter
						&& this.selectedStatus === OfferStatusTabEnum.pending
					) {
						<!-- Pre-fill with my stock -->
						<app-mhp-button
							class="shrink-0"
							prefix="saxImportOutline"
							text="Pre-fill with my stock"
							type="tertiary"
							(click)="this.requestPreFillConfirmModal = true"
						/>

						<!-- Send Offer -->
						<app-mhp-button
							class="shrink-0"
							prefix="saxSend2Outline"
							text="Send offer ({{ validNewOffersAmount }} products)"
							type="primary"
							(click)="this.sendOfferConfirmModal = true"
							[disabled]="validNewOffersAmount === 0"
						/>
					}

				</div>
			</div>

			<div class="flex flex-col space-y-4">
				@switch (this.selectedStatus) {
					@case (OfferStatusTabEnum.pending) {
						@for (item of this.request.items; track item.itemId) {
							@let offersForProduct = this.pendingOffersPerProduct()[item.packageCip13] || [];

							<app-main-request-item-per-product
								[item]="item"
								[requestId]="this.request.id"
								[offers]="offersForProduct"
							/>
						}

						@empty {
							<div class="flex flex-col items-center justify-center p-6">
								<app-mhp-svg-icon icon="empty-product" [size]="80"/>
								<p class="mt-4 text-[var(--text-secondary)]">You haven't received any offers yet</p>
							</div>
						}
					}

					@case (OfferStatusTabEnum.waiting) {
						<app-offers-waiting />
					}

					@case (OfferStatusTabEnum.summary) {
						<app-offers-summary />
					}
				}
			</div>
		</div>
	}

	@else {
		The request you are looking for is not available.
	}

</div>

@if (this.requestPreFillConfirmModal && this.user()!.function === this.UserFunctionEnum.Exporter) {
	<app-main-request-pre-fill-confirm-modal
		[(isOpen)]="this.requestPreFillConfirmModal"
		[(response)]="this.prefillConfirmation"
	/>
}

@if (this.sendOfferConfirmModal && this.user()!.function === this.UserFunctionEnum.Exporter) {
	<app-main-request-send-offer-confirm-modal
		[exporter]="this.request.importer"
		[request]="this.request"
		[validOffersCount]="this.validNewOffersAmount"
		[(isOpen)]="this.sendOfferConfirmModal"
		(submit)="this.onOffersSent()" />
}

@if ( this.isImporting() ) {
	<offers-loading
		[importingItems]="importingItems()"
		[totalItems]="totalItems()" />
}
