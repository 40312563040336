import { Component, input, TemplateRef } from '@angular/core';
import { Company } from '../../models/company.model';
import { MhpCompanyComponent } from '../mhp-company/mhp-company.component';

@Component({
	selector: 'app-mhp-companies',
	standalone: true,
	imports: [MhpCompanyComponent],
	templateUrl: './mhp-companies.component.html',
})
export class MhpCompaniesComponent {
	public readonly companies = input.required<Company[]>();
	public readonly listDirection = input<'row' | 'column'>('row');
	public readonly emptyTemplate = input<TemplateRef<any> | string | null>(null);
}
