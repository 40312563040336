import crypto from "crypto-js";
import { Component, inject } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from "@ng-icons/core";
import { saxEditOutline } from "@ng-icons/iconsax/outline";

import { CompanyService } from "src/app/_common/services/company/company.service";
import { Company } from "src/app/_common/models/company.model";
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ToUserFunctionPipe } from "src/app/_common/pipes/to-user-function/to-user-function.pipe";
import { MhpInputComponent } from "src/app/_common/components/mhp-input/mhp-input.component";
import { MhpCompanyComponent } from "../../_common/components/mhp-company/mhp-company.component";

@Component({
	selector: 'app-main-account',
	standalone: true,
	imports: [
    NgIconComponent,
    MhpInputComponent,
    ToUserFunctionPipe,
    MhpCompanyComponent
	],
	providers: [
		provideIcons({
			saxEditOutline
		}),
		provideNgIconsConfig({ size: '1.25rem' })
	],
	styleUrl: './main-account.component.scss',
	templateUrl: './main-account.component.html',
})
export class MainAccountComponent {

	private _account: AccountService = inject(AccountService);
	private _company: CompanyService = inject(CompanyService);

	public company = this._company.currentCompany()!;
	public user: User | null = null;

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public getGravatarUrl(email: string, size: number = 64): string {
		const trimmedEmail: string = email.trim().toLowerCase();
		const hash: string = crypto.SHA256(trimmedEmail).toString();

		return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=identicon`;
	}

}
