import { Component, EventEmitter, HostListener, inject, Input, model, OnInit, Output } from '@angular/core';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpSvgIconComponent } from "../../../_common/components/mhp-svg-icon/mhp-svg-icon.component";
import { MhpButtonComponent } from "../../../_common/components/mhp-button/mhp-button.component";
import { MhpFromToComponent } from "../../../_common/components/mhp-from-to/mhp-from-to.component";
import { MhpInputComponent } from "../../../_common/components/mhp-input/mhp-input.component";
import { Company } from 'src/app/_common/models/company.model';
import { MhpLabelComponent } from "../../../_common/components/mhp-label/mhp-label.component";
import { ImporterRequestItem } from 'src/app/_common/models/importer-request-item.model';
import { Package } from 'src/app/_common/models/package.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { CommonModule } from '@angular/common';
import { CounterOffer } from 'src/app/_common/models/counter-offer.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { MhpLoaderComponent } from "../../../_common/components/mhp-loader/mhp-loader.component";

@Component({
	selector: 'app-main-request-make-offer-modal',
	standalone: true,
	imports: [
		MhpButtonComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpLabelComponent,
		MhpSvgIconComponent,
		MhpLoaderComponent,
		CommonModule,
	],
	templateUrl: './main-request-make-offer-modal.component.html'
})
export class MainRequestMakeOfferModalComponent implements OnInit {

	private _apiOffer = inject(ApiOfferService);
	private _account = inject(AccountService);
	private _company = inject(CompanyService);

	@Input() public exporter!: Company;
	@Input() public item!: ImporterRequestItem;
	@Input() public offer!: Offer;
	@Input() public package!: Package;

	@Output() public stateClose: EventEmitter<boolean> = new EventEmitter();

	public price = model<string>('');
	public quantity = model<string>('');
	public expirationDate = model<string>('');

	public clickedInside: boolean = false;
	public importingItems: number = 0;
	public isLoading: boolean = false;
	public user: User | null = null;
	public totalItems: number = 0;

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public ngOnInit(): void {
		this.price.set(this.offer.price.toString());
		this.quantity.set(this.offer.quantity.toString());
	}

	public submit(): void {
		this.isLoading = true;

		this._apiOffer.sendCounterOffer(this.offer.id, {
			requestId: this.offer.requestId,
			itemId: this.item.itemId,
			exporterId: this._company.currentCompany()?.id!,
			packageCip13: this.package.cip13,
			batchNumber: this.offer.batchNumber,
			minExpiration: this.expirationDate(),
			quantity: +this.quantity(),
			minQuantity: this.offer.minQuantity,
			multiple: this.offer.multiple,
			price: +this.price(),
			parentOffer: this.offer,
			initiatedById: this.offer.exporterId,
		})
			.then((response) => {
				if(response.ok) {
					this.close();
				}
			})
			.catch((err: any) => console.error(err))
			.finally(() => this.isLoading = false);
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if(e.keyCode === 27)
			this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if(!this.clickedInside)
			this.stateClose.emit(true);

		this.clickedInside = false;
	}

}
