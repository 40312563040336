import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, model, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { saxArrowRight3Outline, saxBuildingsOutline } from '@ng-icons/iconsax/outline';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';

import { User } from 'src/app/_common/models/user.model';
import { ImporterRequest } from '../../_common/models/importer-request.model';
import { Offer } from '../../_common/models/offer.model';
import { Package } from '../../_common/models/package.model';
import { ImporterRequestItem } from '../../_common/models/importer-request-item.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { MHPButton } from 'src/app/_common/components/mhp-button-group/mhp-button.interface';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiImporterService } from '../../_common/services/api/api-importer/api-importer.service';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';

import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpButtonGroupComponent } from '../../_common/components/mhp-button-group/mhp-button-group.component';
import { MhpLoaderComponent } from '../../_common/components/mhp-loader/mhp-loader.component';
import { MhpStatusLabelComponent } from '../../_common/components/mhp-status-label/mhp-status-label.component';
import {
	MainRequestPreFillConfirmModalComponent,
	PreFillModalResponse,
} from './main-request-pre-fill-confirm-modal/main-request-pre-fill-confirm-modal.component';
import { MainRequestItemPerProductComponent } from './main-request-item-per-product/main-request-item-per-product.component';
import { MainRequestItemPerCompanyComponent } from './main-request-item-per-company/main-request-item-per-company.component';
import { MainRequestSendOfferConfirmModalComponent } from './main-request-send-offer-confirm-modal/main-request-send-offer-confirm-modal.component';
import { CompanyService } from '../../_common/services/company/company.service';
import { OffersLoadingComponent } from './offers-loading/offers-loading.component';

@Component({
	selector: 'app-main-request',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpButtonComponent,
		MhpButtonGroupComponent,
		MhpLoaderComponent,
		MhpStatusLabelComponent,
		MainRequestItemPerProductComponent,
		MainRequestPreFillConfirmModalComponent,
		MainRequestItemPerProductComponent,
		MainRequestItemPerCompanyComponent,
		MainRequestSendOfferConfirmModalComponent,
		OffersLoadingComponent,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './main-request.component.html',
})
export class MainRequestComponent {
	private readonly _activatedRoute = inject(ActivatedRoute);
	private readonly _apiImporterSvc = inject(ApiImporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _accountSvc = inject(AccountService);
	private readonly _offersSvc = inject(OffersService);
	private readonly _companySvc = inject(CompanyService);

	public readonly requestTypes = RequestTypeEnum;
	public readonly userFunctions = UserFunctionEnum;

	public readonly importingItems = signal<number>(0);
	public readonly totalItems = signal<number>(0);

	public id = computed(() => {
		const id = this.request()?.id;
		return id ? id.substring(id.length - 10) : null;
	});

	public readonly request = signal<ImporterRequest | null>(null);
	public readonly fetchedOffers = computed<Offer[]>(() =>
		this._offersSvc.currentOffers().sort((prev: Offer) => +prev.batchNumber)
	);
	public readonly prefillConfirmation = model<PreFillModalResponse>(PreFillModalResponse.None);
	public readonly exporterOffers = signal<{ [key: number]: WritableSignal<Offer[]> }>({});

	public readonly exporterOffersArray = computed(() =>
		Object.values(this.exporterOffers()).reduce(
			(acc: Offer[], offers: WritableSignal<Offer[]>) =>
				acc.concat(offers().filter(o => (o.batchNumber || o.isToOrder) && o.quantity > 0)),
			[]
		)
	);

	public readonly offersCount = computed(() => this.exporterOffersArray().length);

	public readonly importerOffers = computed<{ [key: string]: Offer[] }>(() =>
		this.fetchedOffers()
			.filter((offer: Offer) => !offer.parentOfferId && !offer.responses.length)
			.reduce<{ [key: string]: Offer[] }>((acc: { [key: string]: Offer[] }, offer: Offer) => {
				acc[offer.packageCip13] = acc[offer.packageCip13] || [];
				acc[offer.packageCip13].push(offer);
				return acc;
			}, {})
	);

	public readonly offerPackageBundlesByExporter = model<(Offer & Package & ImporterRequestItem)[]>([]);
	public readonly packages = signal<Package[]>([]);
	public readonly company = computed(() => this.request()?.exporters![0]);
	public readonly isImporting = signal<boolean>(false);
	public readonly user = signal<User | null>(this._accountSvc.user());

	// refacto: use enum for status, not integer values
	public readonly statusLabel: MHPButton[] = [
		{ text: 'Pending', value: 1 },
		{ text: 'Under review', value: 2 },
		{ text: 'Confirmed', value: 3 },
		{ text: 'In shipping', value: 4 },
	];

	// refacto: rename filter to selectedStatusButton
	public filter: MHPButton = this.statusLabel[1];
	public isLoading: boolean = false;
	public requestPreFillConfirmModal: boolean = false;
	public sendOfferConfirmModal: boolean = false;

	constructor() {
		/// Listen for prefill confirmation, and if confirmed, prefill offers with stock
		effect(
			async () => {
				if (this.prefillConfirmation() === PreFillModalResponse.Confirmed) {
					this.prefillConfirmation.set(PreFillModalResponse.None), 100;
					await this.prefillOffersWithStock();
				}
			},
			{ allowSignalWrites: true }
		);

		this._activatedRoute.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.isLoading = true;

			try {
				const id = paramMap.get('id');
				const request = id ? await this._apiImporterSvc.getRequestById(id) : null;

				if (request && request.ok) {
					this.request.set(request.body!);
					this.packages.set(
						(await this._apiPackageSvc.getByCIP13s(request.body!.items!.map(item => item.packageCip13))).body ?? []
					);
					const offers = request.ok ? await this._offersSvc.getOffersByRequestId(id!) : null;

					if (offers) {
						if (
							(this.user()!.function === UserFunctionEnum.Exporter && this.fetchedOffers().length === 0) ||
							(this.user()!.function === UserFunctionEnum.Importer && this.fetchedOffers().length)
						)
							this.filter = this.statusLabel[0];

						this.updateOffers();
					}

					// add an empty offer for each product
					for (const item of request.body!.items!)
						this.exporterOffers()[item.packageCip13] = signal([
							this._offersSvc.createNewOfferForItem(this.request()!.id, item),
						]);
				}
			} finally {
				this.isLoading = false;
			}
		});

		effect(() => this.updateOffers(), {
			allowSignalWrites: true,
		});
	}

	public updateOffers(): void {
		this.offerPackageBundlesByExporter.set(
			this.fetchedOffers().map((offer: Offer) => {
				const stockEntry: ImporterRequestItem = this.request()!.items!.find(
					i => i.packageCip13 === offer.packageCip13
				)!;
				const packageDetails: Package = this.getPackage(offer.packageCip13)!;

				return { ...stockEntry, ...offer, ...packageDetails };
			})
		);
	}

	public updateOffersForProduct(cip13: number, offers: Offer[]): void {
		if (this.exporterOffers()[cip13]) this.exporterOffers()[cip13].set(offers);
		else this.exporterOffers()[cip13] = signal(offers);
	}

	public getPackage = (cip13: number): Package | undefined => this.packages().find(p => p.cip13 === cip13);

	public onOffersSent(): void {
		this.sendOfferConfirmModal = false;
		this._offersSvc.refresh();
		this.exporterOffers.set({});
		this.filter = this.statusLabel[1];
	}

	/**
	 * @description Prefill offers with stock
	 */
	public async prefillOffersWithStock(): Promise<void> {
		try {
			this.importingItems.set(0);
			this.isImporting.set(true);

			const request = this.request();
			if (!request || !request.items || request.items.length === 0) return;

			// Get response from API for stocks comparison
			const companyId = this._companySvc.currentCompany()!.id;
			const resp = await this._apiImporterSvc.compareAvailableStocks(request.id, companyId);

			if (!resp || !resp.ok) {
				console.error(`Failed to fetch available stocks for request ${request.id} and company ${companyId}`);
				return;
			}

			this.totalItems.set(resp.body?.length!);

			// Compare w/ our request's items, match them to a suitable candidate, and return a tuple for each match (skip if no match)
			const matches = request
				.items!.map(i => {
					const stockEntry = resp.body!.filter(s => s.batch.cip13 === i.packageCip13);
					return stockEntry ? { item: i, match: stockEntry } : null;
				})
				.filter(x => x?.match.length !== 0);

			// Create offers for each match and store them in the state
			const offers: { [key: number]: WritableSignal<Offer[]> } = {};

			for (const m of matches) {
				const packageCip13 = m!.item.packageCip13;

				for (const stockEntry of m!.match) {
					// let qtyAcc = 0
					//
					// //  Accumulate quantity of items that are less than the requested quantity, accounting for batches.
					// if (x.quantity! + qtyAcc > m!.item.quantity) {
					//   const diff = x.quantity! + qtyAcc - m!.item.quantity
					//   x.quantity = x.quantity! - diff
					//   qtyAcc = diff
					// } else {
					//   qtyAcc += x.quantity!
					// }

					const newOffers: Offer[] = [];

					newOffers.push({
						requestId: request.id,
						itemId: m!.item.itemId,
						exporterId: this._companySvc.currentCompany()!.id,
						packageCip13: stockEntry.batch.cip13,
						batchNumber: stockEntry.batch.id,
						minExpiration: stockEntry.batch.expiration!,
						quantity: stockEntry.quantity,
						price: stockEntry.price!,
					} as Offer);

					// https://github.com/MedHubCompany/MedHubPlace/issues/91
					// "When I do a prefill, I have at minimum one empty line with the quota information in quantity and as batch number "To order".
					// The price and expiration can be empty"
					if (stockEntry.quota && stockEntry.quota > 0) {
						const quotaOffer = this._offersSvc.createNewOfferForItem(this.request()!.id, m!.item);
						quotaOffer.isToOrder = true;
						quotaOffer.quantity = stockEntry.quota;
						newOffers.push(quotaOffer);
					}

					// empty lines must be removed and replaced by the new offer
					if (
						offers[packageCip13] &&
						offers[packageCip13]() &&
						offers[packageCip13]().length > 0 &&
						offers[packageCip13]()[0].isToOrder
					)
						offers[packageCip13].update(offers => {
							offers = [...offers, ...newOffers];
							return offers;
						});
					else offers[packageCip13] = signal(newOffers);

					// if (qtyAcc >= m!.item.quantity) break
					setTimeout(() => this.importingItems.set(this.importingItems() + 1), 100);
				}
			}

			this.exporterOffers.set(offers);
		} finally {
			this.isImporting.set(false);
		}
	}
}
