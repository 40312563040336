<div class="inline-flex grow flex-col">
	<div class="text-[var(--text-secondary)]">{{ item().packageCip13 }}</div>
	<div class="font-semibold">{{ item().package.shortName }}</div>
</div>
<div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
	<app-mhp-label *ngIf="item().minQuantity" label="Qty min: {{ item().minQuantity }}" />
	<app-mhp-label label="Qty: {{ item().quantity }}" />
	<app-mhp-label
		*ngIf="item().price"
		label="Unit cost: {{ item().price | currency: 'EUR' : 'symbol' : '.2-2' }}"
	/>
	<app-mhp-label *ngIf="item().multiple" label="Multiple: {{ item().multiple }}" />
	<app-mhp-label label="Expiration: {{ item().minExpiration | date: 'd MMM y' : '' : 'en-US' }}" />
</div>
