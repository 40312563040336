@if (!this.request) {
The request you are looking for is not available.
}
@else {
<div class="flex flex-col w-full mb-6">
	<div class="flex items-center mb-6 text-[var(--text-secondary)]">
		<a [routerLink]="[ '/dashboard' ]">MedHubPlace</a>
		<ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
		<a>Request #{{ this.displayedId }}</a>
	</div>

	<div class="flex flex-col space-y-4 md:space-y-0 md:flex-row">
		<div class="flex items-center w-full">
			<h2 class="mr-4 text-3xl font-semibold">
				@switch (this.request.type) {
				@case (this.RequestTypeEnum.PurchaseOrder) { Purchase order }
				@case (this.RequestTypeEnum.RequestOffer) { Request Offer }
				}
			</h2>
			<app-mhp-status-label [status]="this.request.status" />
		</div>
		<div *ngIf="userFunction() === UserFunctionEnum.Exporter" class="flex shrink-0 justify-end">
			<app-mhp-button prefix="saxDocumentTextOutline" text="Make an offer" type="primary"
				[routerLink]="[ this.sitemap.makeAnOffer.route, this.request.id ]" />
		</div>
	</div>

	<app-mhp-task-card class="mt-6 bg-white dark:bg-gray-900/75"
		[title]="offerService.waitingForActionOffersCount() > 0 ?
			'You have received a response from ' + offerService.otherCompany().displayName + '. ' +
			offerService.waitingForActionOffersCount() + ' item(s) require(s) your attention and action.'
			: 'You don\'t have to do anything, just wait for feedback from ' + offerService.otherCompany().displayName + '.'"
		[task]="{ id: this.request.id, userRequestId: this.request.userRequestId, expiryDate: this.request.expiryDate, paymentTerms: this.request.paymentTerms, deliveryTerms: this.request.deliveryTerms}"
		[companyIcon]="CompanyExtension.getGravatarUrl(offerService.otherCompany())" />

</div>

<div class="flex items-center mt-4 mb-8">
	<div class="flex w-full justify-between">
		<!-- Tab selection -->
		<app-mhp-button-group
			[buttons]="this.statusLabel"
			[choice]="this.selectedTab"
			(choiceChange)="this.gotoTab($event, true)" />

		<!-- Export button -->
		<app-mhp-button
			prefix="saxExport1Outline" type="tertiary" (click)="exportOffersToCsv()" />
	</div>
</div>

<div class="flex flex-col space-y-4">
	@switch (this.selectedTab)
	{
	@case (OfferStatusTabEnum.waiting) {
	<app-offers-waiting />
	}

	@case (OfferStatusTabEnum.all) {
	<app-offers-summary />
	}
	}
</div>
}