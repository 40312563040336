import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { User } from 'src/app/_common/models/user.model';
import { ApiServiceBase } from '../api-service-base';

@Injectable({
	providedIn: 'root',
})
export class ApiUserService extends ApiServiceBase {
	private readonly USER_ROUTE: string = 'user';

	constructor() {
		super();
	}

	public getUser(): Promise<User> {
		return firstValueFrom(this.http.get<User>(`${this.API_URL}/${this.USER_ROUTE}/self`));
	}
}
