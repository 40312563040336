import {
	AfterViewInit,
	Component,
	computed,
	ElementRef,
	inject,
	input,
	OnDestroy,
	OnInit,
	signal,
	viewChild,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxSend2Bold } from '@ng-icons/iconsax/bold';
import { Subscription } from 'rxjs';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { ChatRoom } from 'src/app/_common/models/chat-room.model';
import { Company } from 'src/app/_common/models/company.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MessagingService } from 'src/app/_common/services/messaging/messaging.service';
import { MhpSvgIconComponent } from '../../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MainRequestOfferHistoryDiscussionMessageComponent } from './main-request-offer-history-discussion-message/main-request-offer-history-discussion-message.component';

@Component({
	selector: 'app-main-request-offer-history-discussion',
	imports: [
		CommonModule,
		FormsModule,
		NgIconComponent,
		MainRequestOfferHistoryDiscussionMessageComponent,
		MhpSvgIconComponent,
	],
	providers: [provideIcons({ saxSend2Bold }), provideNgIconsConfig({ size: '1.5rem' })],
	templateUrl: './main-request-offer-history-discussion.component.html',
	styleUrl: './main-request-offer-history-discussion.component.scss',
})
export class MainRequestOfferHistoryDiscussionComponent implements OnInit, AfterViewInit, OnDestroy {
	private readonly _accountSvc = inject(AccountService);
	private readonly _messagingSvc = inject(MessagingService);
	protected userCompany = computed(() => this._accountSvc.user().company);

	private _newMessagesSubscription: Subscription | undefined;

	readonly room = input.required<ChatRoom>();
	readonly online = computed(() => this.room().id);

	readonly otherParticipant = computed<Company | undefined>(() =>
		this.room().participants.find(participant => participant.id !== this.userCompany().id)
	);

	readonly conversationEl = viewChild<ElementRef>('conversation');
	readonly draftMessage = signal<string | undefined>(undefined);

	readonly CompanyExtension = CompanyExtension;

	/**
	 * Send a message to the chat room
	 */
	async sendMessage(): Promise<void> {
		const messageToSend: string | undefined = this.draftMessage();

		if (messageToSend) {
			this._messagingSvc.sendMessage(this.room(), messageToSend);

			// reset message input box
			this.draftMessage.set('');
		}
	}

	/**
	 * Scroll the conversation to the bottom
	 */
	scrollToBottom(): void {
		// Delay 100ms to allow for view to update
		setTimeout(() => {
			let el = document.querySelector('.main-request-offer-history-discussion-messages-container');

			if (el) {
				el.scrollTop = el.scrollHeight;
			}
		}, 100);
	}

	/**
	 * On init, get history and subscribe to the new messages of the chat room
	 */
	async ngOnInit(): Promise<void> {
		await this.joinChatRoomAndLoadHistory();

		// in case of reconnection, join the chat room again to relead the history
		await this._messagingSvc.onReconnected$.subscribe(() => this.joinChatRoomAndLoadHistory());
	}

	/**
	 * Join the chat room and load the history of the chat room
	 */
	private async joinChatRoomAndLoadHistory() {
		// reload message if in online mode
		if (this.online()) {
			const chatRoomEventDispatcher = await this._messagingSvc.joinChatRoom(this.room().id);

			this._newMessagesSubscription?.unsubscribe();
			this._newMessagesSubscription = chatRoomEventDispatcher.newRoomMessage$.subscribe(message => {
				this.room().messages.push(message);
				this.scrollToBottom();
			});

			this.room().messages = chatRoomEventDispatcher.messageHistory;
		}

		this.scrollToBottom();
	}

	/**
	 * Unsubscribe to the new messages of the chat room
	 */
	public ngOnDestroy(): void {
		this._newMessagesSubscription?.unsubscribe();
		if (this.online()) this._messagingSvc.leaveChatRoom(this.room().id);
	}

	/**
	 * After view init, scroll to the bottom of the conversation if required
	 */
	async ngAfterViewInit(): Promise<void> {
		this.scrollToBottom();
	}
}
