import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsOutline } from '@ng-icons/iconsax/outline';
import { CompanyExtension } from '../../extensions/company.extension';
import { Company } from '../../models/company.model';

@Component({
	selector: 'app-mhp-company',
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			saxBuildingsOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrl: './mhp-company.component.scss',
	templateUrl: './mhp-company.component.html',
})
export class MhpCompanyComponent {
	readonly displayNameAsTooltip = input(false);
	readonly company = input<Company | null>();
	readonly displayCountry = input(false);
	readonly CompanyExtension = CompanyExtension;
}
