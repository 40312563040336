import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { User } from '../../models/user.model';
import { SITEMAP } from '../../sitemap';
import { UserFunctionEnum } from '../../enums/user-function.enum';
import { AccountService } from '../../services/account/account.service';

export const isExporterGuard: CanActivateFn = (route, state) => {
	const router: Router = inject(Router);
	const _account: AccountService = inject(AccountService);

	const user: User | null = _account.user();

	if (user && [UserFunctionEnum.Exporter, UserFunctionEnum.ImporterExporter].includes(user.function)) return true;

	router.navigate([SITEMAP.dashboard.route]);
	return false;
};
