<button
  class="flex justify-end"
  (click)="onModalOpen()"
>
  <app-mhp-button
    prefix="saxBookmark2Outline"
    text="Purchase {{ lengthText() }}"
    type="primary"
  />
</button>

@if (modalVisible) {
  <app-main-request-confirm-offers
    [exporter]="exporter()"
    [offers]="offers()"
    buttonText="Yes, I want to purchase this product"
		(onConfirm)="onConfirm()"
    (stateClose)="onModalClose()"
  >
    <span slot="question">Do you confirm that you want to purchase this product?</span>
  </app-main-request-confirm-offers>
}
