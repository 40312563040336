export enum UserFunctionEnum {

	Importer = 1,
	Exporter = 2,

	ImporterExporter = Importer + Exporter, // 3

	Broker = 4,

	BrokerImporter = Broker + Importer, // 5
	BrokerExporter = Broker + Exporter, // 6

}