import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxEditOutline, saxMessageOutline } from '@ng-icons/iconsax/outline';
import { MhpCompanyComponent } from 'src/app/_common/components/mhp-company/mhp-company.component';
import { MhpPackageComponent } from 'src/app/_common/components/mhp-package/mhp-package.component';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { Company } from 'src/app/_common/models/company.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { OfferActionsComponent } from '../../offer-actions/offer-actions.component';

@Component({
	selector: 'offer-row',
	templateUrl: './offer-row.component.html',
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
	standalone: true,
	imports: [CommonModule, MhpPackageComponent, MhpCompanyComponent, NgIconComponent, OfferActionsComponent],
	providers: [
		provideIcons({
			saxMessageOutline,
			saxEditOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OffersGroupContentComponent {
	offer = input.required<Offer>();
	allowEdit = input<boolean>(false);
	onOpenHistory = output();
	onEdit = output();
	CompanyExtension = CompanyExtension;
	readonly offerService = inject(OffersService);
	readonly companyService = inject(CompanyService);
	readonly accountService = inject(AccountService);
	isImporter = computed(() => this.accountService.user()?.function === UserFunctionEnum.Importer);
	batchNumber = computed(() => (this.offer().batchNumber ? this.offer().batchNumber : ''));
	statusLabel = computed(() => this.offerService.getOfferStatusLabel(this.offer()));
	exporter = computed(() => CompanyExtension.getExporterById(this.exporters(), this.offer().exporterId));
	private readonly exporters = computed<Company[]>(this.companyService.allCompanies);
	allowEditOffer = computed(
		() => this.allowEdit() && OfferExtension.isEditPossible(this.offer(), this.accountService.user()!.function)
	);

	clickHistoryButton() {
		this.onOpenHistory.emit();
	}

	clickEditButton() {
		this.onEdit.emit();
	}
}
