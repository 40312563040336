import { Component, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { matTrendingUpOutline } from '@ng-icons/material-icons/outline';

@Component({
	selector: 'app-mhp-label',
	standalone: true,
	imports: [
		NgIconComponent
	],
	providers: [
		provideIcons({
			matTrendingUpOutline
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	styleUrl: './mhp-label.component.scss',
	templateUrl: './mhp-label.component.html'
})
export class MhpLabelComponent {

	public label = input('');

}
