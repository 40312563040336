<div class="tasks">
	<div class="title">
		<h3>Tasks to do</h3>
		<span class="badge">{{ getTasksCount(tasks()) }}</span>
	</div>

	<div class="tasks-list">
		@if (tasks(); as tasks) {
		@if (getTasksCount(tasks) === 0) {
		<div class="empty">
			<app-mhp-svg-icon icon="checklist" [size]="80" />
			<p>No new client requests or tasks for now.</p>
		</div>
		} @else {
		@for (task of tasks.pendingRequestActions; track task.id) {
		@let company = this._companySvc.getCompanyById(task.exporterIds[0]);
		@if( company )
		{
		<app-mhp-task-card
			class="bg-white dark:bg-gray-800/50"
			title="You have received a response from {{ company.displayName }}. {{ task.count | i18nPlural:{'=1': '# product requires', 'other': '# products require' } }} your attention."
			[task]="task"
			[companyIcon]="getCompanyAvatar(company.id)">
			<app-mhp-button
				#action
				prefix="saxTaskOutline"
				text="Take actions"
				type="primary"
				class="shrink-0"
				(click)="navigateToRequest(task.id)" />
		</app-mhp-task-card>
		}
		}
		}
		}
	</div>
</div>