import { Component, computed, HostListener, inject, input, model, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from 'src/app/_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { Company } from 'src/app/_common/models/company.model';
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { PackageItem } from 'src/app/_common/models/package-item.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiImporterService } from 'src/app/_common/services/api/api-importer/api-importer.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { OfferResponseTypeEnum } from '../../../_common/enums/offer-response-type.enum';
import { Offer } from '../../../_common/models/offer.model';
import { Package } from '../../../_common/models/package.model';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { ApiOfferService } from '../../../_common/services/api/api-offer/api-offer.service';

@Component({
	selector: 'app-main-package-request-product-modal',
	imports: [RouterModule, MhpButtonComponent, MhpFromToComponent, MhpInputComponent, MhpSvgIconComponent, ToCipPipe],
	styleUrl: './main-package-request-product-modal.component.scss',
	templateUrl: './main-package-request-product-modal.component.html',
})
export class MainPackageRequestProductModalComponent implements OnInit {
	private readonly _accountSvc = inject(AccountService);
	private readonly _apiImporterSvc = inject(ApiImporterService);
	private readonly _offerSvc = inject(ApiOfferService);

	public readonly sitemap = SITEMAP;
	public readonly requestTypes = RequestTypeEnum;

	public readonly product = input.required<Package>();
	public readonly request = model<Offer>();
	public readonly exporter = input.required<Company>();
	public readonly stock = input.required<ExporterStockEntry>();
	public readonly isOpen = model(false);
	public readonly price = model<string>('');
	public readonly quantity = model<string>('');
	public readonly quota = model<string>('');
	public readonly requestId = model<string | null>(null);
	public readonly user = computed<User | null>(this._accountSvc.user);

	public step: number = 1;

	constructor() {}

	public ngOnInit(): void {
		this.price.set(this.stock().price?.toString() ?? '0');
		this.quantity.set(this.stock().quantity?.toString() ?? '0');
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) {
			this.isOpen.set(false);
		}
	}

	public close(): void {
		this.isOpen.set(false);
	}

	public async submit() {
		const quantityStr = this.quantity();
		const quotaStr = this.quota();
		const price = this.price()!;

		const requestRes = await this._apiImporterSvc.postRequest({
			userRequestId: null,
			type: this.requestTypes.RequestOffer,
			exporters: [this.exporter()],
			items: [
				{
					packageCip13: this.product().cip13!,
					batchNumber: this.stock().batch?.id! || null,
					minExpiration: this.stock().batch?.expiration ?? '',
					quantity: quantityStr ? +quantityStr : null,
					quota: quotaStr ? +quotaStr : null,
					price: price ? +price : null,
					packageName: this.product().shortName! || null,
				} as PackageItem,
			],
		});

		if (!requestRes.ok) {
			return;
		}

		this.requestId.set(requestRes.body?.id!);

		const request = requestRes.body!;
		const requestItem = request.items[0];

		try {
			const offerRes = await this._offerSvc.sendOffer(<Offer>{
				requestId: request.id,
				itemId: requestItem.itemId,
				exporterId: this.exporter().id,
				isToOrder: !!this.stock().quantity,
				packageCip13: requestItem.packageCip13,
				batchNumber: requestItem.batchNumber ?? null,
				expirationDate: requestItem.minExpiration,
				quantity: requestItem.quantity,
				minQuantity: 0,
				multiple: 0,
				price: requestItem.price ?? 0,
				importerResponse: OfferResponseTypeEnum.Accepted,
				importerResponded: new Date(Date.now()).toISOString(),
			});
		} catch (e) {
			console.error(e);
		}

		this.step++;
	}
}
