import { I18nPluralPipe } from '@angular/common';
import { computed, inject, Injectable } from '@angular/core';
import { OfferActionEnum } from '../../enums/offer-action.enum';
import { UserFunctionEnum } from '../../enums/user-function.enum';
import { OfferExtension } from '../../extensions/offer.extension';
import { Offer } from '../../models/offer.model';
import { AccountService } from '../account/account.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
	providedIn: 'root',
})
export class OfferActionToastService {
	private readonly _accountSvc = inject(AccountService);
	private readonly _toastSvc = inject(ToastService);
	private readonly _i18nPluralPipe = inject(I18nPluralPipe);

	private readonly _userFunction = computed(() => this._accountSvc.userFunction());

	private readonly _pluralMessagesMap: { [key in OfferActionEnum]: (offers: Offer[]) => { [key: string]: string } } = {
		[OfferActionEnum.Create]: (offers: Offer[]) => this.getCreateOffersMessages(offers),
		[OfferActionEnum.CounterOffer]: (_: Offer[]) => ({
			'=1': 'Your counteroffer has been successfully sent and is now awaiting a response.',
			other: 'Your counteroffers have been successfully sent and are now awaiting a response.',
		}),
		[OfferActionEnum.Accept]: (offers: Offer[]) => this.getAcceptOffersMessages(offers),
		[OfferActionEnum.ValidateBatch]: (_: Offer[]) => ({
			'=1': 'The batch number has been accepted and you can now request the shipping.',
			other: 'The batch numbers have been accepted and you can now request the shipping.',
		}),
		[OfferActionEnum.Purchase]: (offers: Offer[]) => this.getPurchaseOffersMessages(offers),
		[OfferActionEnum.Order]: (_: Offer[]) => ({
			'=1': 'Your shipping request has been sent. The offer is now closed on the platform.',
			other: 'Your shipping requests have been sent. The offers are now closed on the platform.',
		}),
		[OfferActionEnum.Update]: (offers: Offer[]) => this.getUpdateOffersMessages(offers),
		[OfferActionEnum.Decline]: (_: Offer[]) => ({
			'=1': 'The offer has been declined and is now in "rejected".',
			other: 'The offers have been declined and are now in "rejected".',
		}),
	};

	public showSuccessfulActionMessage(action: OfferActionEnum, offers: Offer[]) {
		const messagesMap = this._pluralMessagesMap[action](offers);
		const message = this._i18nPluralPipe.transform(offers.length, messagesMap);
		this._toastSvc.success(message);
	}

	private getCreateOffersMessages(offers: Offer[]): { [key: string]: string } {
		const distinctRequestIds = offers
			.map(offer => offer.requestId)
			.filter((value, index, array) => array.indexOf(value) === index);

		return {
			other: this._i18nPluralPipe.transform(distinctRequestIds.length, {
				'=1': 'Your offer has been sent and is now in under review.',
				other: 'Yours offers have been sent and are now in under review.',
			}),
		};
	}

	private getAcceptOffersMessages(offers: Offer[]): { [key: string]: string } {
		if (this._userFunction() === UserFunctionEnum.Exporter) {
			return {
				'=1': 'You confirmed availability and the offer is now waiting for purchase.',
				other: 'You confirmed availability and the offers are now waiting for purchase.',
			};
		}

		if (offers.every(OfferExtension.exporterAccepted)) {
			return {
				'=1': 'The offer has been accepted and you can now purchase the product.',
				other: 'The offers have been accepted and you can now purchase the products.',
			};
		}

		return {
			'=1': 'The offer has been accepted and is now awaiting availability confirmation.',
			other: 'The offers have been accepted and are now awaiting availability confirmation.',
		};
	}

	private getPurchaseOffersMessages(offers: Offer[]): { [key: string]: string } {
		let actionMessage = '';
		const waitingForBatchCount = offers.filter(OfferExtension.isWaitingForBatch).length;
		if (waitingForBatchCount > 0) {
			actionMessage =
				offers.length === 1 ?
					'is now awaiting for the the batch number'
				:	this._i18nPluralPipe.transform(waitingForBatchCount, {
						'=1': 'one is now awaiting for the the batch number',
						other: '# are now waiting for their batch numbers',
					});
		} else {
			actionMessage = offers.length === 1 ? 'you can now request the shipping' : 'you can now request the shippings';
		}

		return {
			'=1': `The offer has been purchased and ${actionMessage}.`,
			other: `The offers have been purchased and ${actionMessage}.`,
		};
	}

	private getUpdateOffersMessages(offers: Offer[]): { [key: string]: string } {
		const waitingForBatchValidationCount = offers.filter(OfferExtension.isWaitingForBatchValidation).length;
		if (waitingForBatchValidationCount > 0) {
			const waitingBatchCountMessage = this._i18nPluralPipe.transform(waitingForBatchValidationCount, {
				'=1': 'one is now waiting a confirmation and shipment request',
				other: `# are now waiting their confirmation and shipment requests`,
			});

			return {
				'=1': 'The batch has been updated and is now waiting a confirmation and shipment request.',
				other: `The batches have been updated and ${waitingBatchCountMessage}.`,
			};
		}

		return {
			'=1': 'The offer has been updated.',
			other: 'The offers have been updated.',
		};
	}
}
