import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { OffersGroupHeaderForIteComponent } from '../offers-group/offers-group-header-for-item/offers-group-header-for-item.component';
import { OffersGroupComponent } from '../offers-group/offers-group.component';

@Component({
	selector: 'offers-group-for-item',
	templateUrl: './offers-group-for-item.component.html',
	standalone: true,
	imports: [CommonModule, OffersGroupComponent, OffersGroupHeaderForIteComponent],
})
export class OffersGroupForItemComponent {
	readonly accountService = inject(AccountService);
	readonly offerService = inject(OffersService);

	offers = input.required<Offer[]>();

	firstOffer = computed(() => this.offers()[0]);

	item = computed(() =>
		this.firstOffer() ? this.offerService.getImporterRequestItem(this.firstOffer().itemId) : null
	);

	offerHistory: Offer | null = null;

	isImporter = computed(() => this.accountService.user()?.function === UserFunctionEnum.Importer);
}
