import { Pipe, PipeTransform } from '@angular/core';
import { Offer } from '../../models/offer.model';
import { ImporterRequestItem } from '../../models/importer-request-item.model';
import { Package } from '../../models/package.model';
import { ResponseOffer } from '../../models/response-offer.model';
import { OfferResponseTypeEnum } from '../../enums/offer-response-type.enum';

@Pipe({
	name: 'findDeclinedResponseOffer',
	standalone: true,
})
export class FindDeclinedResponseOfferPipe implements PipeTransform {
	public transform(offer: Offer & ImporterRequestItem & Package): boolean {
		return offer.responses.find((o: ResponseOffer) => o.importerResponse?.type === OfferResponseTypeEnum.Declined)! ||
			offer.responses.find((o: ResponseOffer) => o.exporterResponse?.type === OfferResponseTypeEnum.Declined)!
			? true
			: false;
	}
}
