import { Component, input } from '@angular/core';

@Component({
	selector: 'app-mhp-svg-logo',
	standalone: true,
	imports: [],
	templateUrl: './mhp-svg-logo.component.html'
})
export class MhpSvgLogoComponent {

	public showBranding = input(false);

}
