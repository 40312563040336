import { Component, input } from '@angular/core';

@Component({
	selector: 'app-mhp-progressbar',
	standalone: true,
	imports: [],
	styleUrl: './mhp-progressbar.component.scss',
	templateUrl: './mhp-progressbar.component.html'
})
export class MhpProgressbarComponent {

	public progress = input<number>(0);
	public showStats = input<boolean>(false);

}
