import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ExporterQuota } from 'src/app/_common/models/exporter-quota.model';

import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { PackageBatch } from 'src/app/_common/models/package-batch.model';
import { Package } from 'src/app/_common/models/package.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiPackageService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly PACKAGE_ROUTE: string = 'package';

	constructor() {}

	public getByCIP13(cip13: number): Promise<Package> {
		return firstValueFrom(this.http.get<Package>(`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}`));
	}

	public getByCIP13s(cip13s: number[]): Promise<HttpResponse<Package[]>> {
		return firstValueFrom(
			this.http.post<Package[]>(`${this.API_URL}/${this.PACKAGE_ROUTE}`, cip13s, { observe: 'response' })
		);
	}

	public getByCIP7(cip7: number): Promise<Package> {
		return firstValueFrom(this.http.get<Package>(`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip7}`));
	}

	public getByCIP13AndBatch(cip13: number, batch: string): Promise<HttpResponse<PackageBatch | null>> {
		return firstValueFrom(
			this.http.get<PackageBatch | null>(`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}/batch/${batch}`, {
				observe: 'response',
			})
		);
	}

	public getStocksForCIP13(cip13: number): Promise<ExporterStockEntry[]> {
		return firstValueFrom(this.http.get<ExporterStockEntry[]>(`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}/stocks`));
	}

	public getQuotasForCIP13(cip13: number): Promise<ExporterQuota[]> {
		return firstValueFrom(this.http.get<ExporterQuota[]>(`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}/quotas`));
	}

	public async validateCip13s(cip13s: number[]): Promise<number[]> {
		return await firstValueFrom(this.http.post<number[]>(`${this.API_URL}/${this.PACKAGE_ROUTE}/validate`, cip13s));
	}
}
