import { Component, HostListener, input, model, output, signal } from '@angular/core';
import { MhpSvgIconComponent } from "../../../_common/components/mhp-svg-icon/mhp-svg-icon.component";
import { MhpButtonComponent } from "../../../_common/components/mhp-button/mhp-button.component";
import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { RouterModule } from '@angular/router';
import { MhpProgressbarComponent } from "../../../_common/components/mhp-progressbar/mhp-progressbar.component";
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { ApiImporterService } from 'src/app/_common/services/api/api-importer/api-importer.service';
import { User } from 'src/app/_common/models/user.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Offer } from "../../../_common/models/offer.model";

@Component({
  selector: 'app-main-request-pre-fill-confirm-modal',
  standalone: true,
  imports: [
    RouterModule,
    MhpButtonComponent,
    MhpSvgIconComponent,
    MhpProgressbarComponent,
  ],
  templateUrl: './main-request-pre-fill-confirm-modal.component.html'
})
export class MainRequestPreFillConfirmModalComponent {

  public sitemap: SiteMap = SITEMAP;

  public request = input.required<ImporterRequest>();
  public isOpen = model<boolean>();

  public offers = model<Offer[]>([]);

  public clickedInside: boolean = false;
  public step: number = 0;
  public importingItems: number = 0;
  public totalItems: number = 0;
  public user: User | null = null;

  constructor(
    private _company: CompanyService,
    private _apiImporter: ApiImporterService
  ) {
    dayjs.extend(customParseFormat);
  }

  // [TODO]: Make pre-fill exporter stock on request
  public async submit() {
    // Get response from API for stocks comparison
    const resp = await this._apiImporter.compareAvailableStocks(this.request().id, this._company.currentCompany()!.id)

    if (!resp || !resp.ok) {
      console.error('Failed to fetch available stocks for comparison')
      return
    }

    this.totalItems = resp.body?.length!;

    // Compare w/ our request's items, match them to a suitable candidate, and return a tuple for each match (skip if no match)
    const matches = this.request().items!.map(i => {
      const match = resp.body!.filter(s => s.batch.cip13 === i.packageCip13)
      return match ? {item: i, match} : null
    }).filter(x => x?.match.length !== 0)

    // Create offers for each match and store them in the state
    const offers: Offer[] = [];

    for (const m of matches) {
      for (const x of m!.match) {
        // let qtyAcc = 0
        //
        // //  Accumulate quantity of items that are less than the requested quantity, accounting for batches.
        // if (x.quantity! + qtyAcc > m!.item.quantity) {
        //   const diff = x.quantity! + qtyAcc - m!.item.quantity
        //   x.quantity = x.quantity! - diff
        //   qtyAcc = diff
        // } else {
        //   qtyAcc += x.quantity!
        // }

        offers.push({
          requestId: this.request().id,
          itemId: m!.item.itemId,
          exporterId: this._company.currentCompany()!.id,
          packageCip13: x.batch.cip13,
          batchNumber: x.batch.id,
          minExpiration: x.batch.expiration!,
          quantity: x.quantity,
          price: x.price!
        } as Offer);

        // if (qtyAcc >= m!.item.quantity) break
        setTimeout(() => this.importingItems++, 100);
      }
    }

    this.offers.set(offers);
    this.isOpen.set(false);
  }

  public nextStep(): void {
    this.step++;
  }

  @HostListener('document:keydown', ['$event'])
  public triggerClosing(e: any): void {
    if (e.keyCode === 27 && this.step !== 1)
      this.isOpen.set(false);
  }

  @HostListener('document:click')
  public close(): void {
    if (!this.clickedInside && this.step !== 1)
      this.isOpen.set(false);

    this.clickedInside = false;
  }

}
