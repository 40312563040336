import { inject } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from '../loader/loader.service';

export class SignalRClientBase {
	private readonly _onReconnectedSubject = new Subject<void>();
	private loaderService = inject(LoaderService);

	/**
	 * Observable to listen to reconnection events
	 */
	public readonly onReconnected$: Observable<void> = this._onReconnectedSubject.asObservable();

	protected hubUrl: string = '';

	protected waitConnectionPromise: Promise<void> = null!; // set in the constructor in lockHubInvocations

	protected unlockHubInvocations: (value?: void | PromiseLike<void>) => void = () => {};

	private lockHubInvocations() {
		this.waitConnectionPromise = new Promise<void>(resolve => {
			this.unlockHubInvocations = resolve;
		});
	}

	protected _hubConnection: HubConnection;

	constructor(hubUrl: string, start: boolean = true) {
		this.hubUrl = hubUrl;

		this.lockHubInvocations();

		// 1. create the hub connection
		this._hubConnection = new HubConnectionBuilder()
			.withUrl(hubUrl)
			.configureLogging(LogLevel.Information)
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: retryContext => Math.min(100 * retryContext.previousRetryCount, 2000),
			})
			.build();

		// 2. handle connection events
		this._hubConnection.onreconnecting(msg => {
			// calls to the hub are stopped until the connection is reestablished
			this.waitConnectionPromise = new Promise<void>(() => {});

			console.log('Reconnecting...', { message: msg });

			this.loaderService.setMessage('Reconnecting to the server... ');
			this.loaderService.start();
		});

		this._hubConnection.onreconnected(connectionId => {
			this.unlockHubInvocations();

			console.log('Reconnected. Connection ID:', connectionId, 'State:', this._hubConnection.state);

			this.loaderService.stop();
			this._onReconnectedSubject.next();
		});

		this._hubConnection.onclose(error => {
			console.log('Connection closed', { error });
		});

		// 2. start the connection
		if (start) {
			this.startConnection();
		}
	}

	/** Start the connection to receive notification, and invoke operations */
	protected async startConnection(): Promise<void> {
		if (this._hubConnection.state !== HubConnectionState.Connected) {
			try {
				if (HubConnectionState.Disconnected !== this._hubConnection.state) return;

				console.log(`Attempting to connect to the messaging hub ${this.hubUrl}...`);

				await this._hubConnection.start();

				console.log(`Connected to the messaging hub ${this.hubUrl} State: ${this._hubConnection.state}`);

				this.unlockHubInvocations();
			} catch (err) {
				console.log('Error while establishing connection :(', err);

				setTimeout(() => this.startConnection(), 5000);
			}
		}
	}

	/** Stop the connection to the hub */
	protected async stopConnection(): Promise<void> {
		if (HubConnectionState.Disconnected === this._hubConnection.state) return;
		console.log(`Stopping the connection to the messaging hub ${this.hubUrl}...`);
		await this._hubConnection.stop();
		console.log(`Stopped the connection to the messaging hub ${this.hubUrl}. State: ${this._hubConnection.state}`);
	}
}
