import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserFunctionEnum } from '../../enums/user-function.enum';
import { AccountService } from '../../services/account/account.service';
import { SITEMAP } from '../../sitemap';

export const isImporterGuard: CanActivateFn = (route, state) => {
	const router: Router = inject(Router);
	const _account: AccountService = inject(AccountService);

	if (
		_account.isAuthentified() &&
		[UserFunctionEnum.Importer, UserFunctionEnum.ImporterExporter].includes(_account.userFunction())
	)
		return true;

	router.navigate([SITEMAP.dashboard.route]);
	return false;
};
