<h1 class="font-semibold text-center text-3xl">Welcome back</h1>

<div class="flex flex-col gap-4">
	<button (click)="provide('Google')">
		<app-mhp-svg-icon icon="google" [size]="16" /> Continue with Google
	</button>

	<button (click)="provide('Microsoft')">
		<app-mhp-svg-icon icon="microsoft" [size]="16" /> Continue with Microsoft
	</button>
</div>

<div class="flex items-center gap-4">
	<div class="flex-1 h-[1px] bg-gray-200 dark:bg-gray-700"></div>
	<div class="text-[var(--text-secondary)]">Not yet on MedHubPlace?</div>
	<div class="flex-1 h-[1px] bg-gray-200 dark:bg-gray-700"></div>
</div>

<button (click)="bookDemo()">
	<ng-icon name="saxCalendar1Outline" /> Book a demo
</button>