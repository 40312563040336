<app-mhp-base-modal
	[title]="'Edit Offer'"
	[showCloseButton]="true"
	(stateClose)="handleClose()"
	[actionButtonType]="'primary'"
	[actionButtonPrefix]="'saxSave2Outline'"
	actionButtonText="Yes, I update this offer"
	(action)="save()">

	<div class="flex flex-col gap-6 border rounded-2xl p-6 w-full">

		<app-mhp-company [company]="exporter()" />

		<div class="flex flex-col gap-0 mb-2">
			<div class="">{{ offer().shortName }}</div>
			<span class="text-sm text-[var(--text-secondary)]">Unit Cost: {{offer().price}} €</span>
		</div>

		<app-mhp-labeled-field label="Batch Number">
			<app-mhp-input type="text" [padding]="4" placeholder="Enter your batch number" [(model)]="batchNumber" />
		</app-mhp-labeled-field>

		<app-mhp-labeled-field label="Expiration Date">
			<app-mhp-input
				type="date" [padding]="4"
				[(model)]="expirationDate" />
		</app-mhp-labeled-field>

		<app-mhp-labeled-field label="Quantity">
			<app-mhp-input type="number" [padding]="4"
				[min]="1" [stepper]="true" suffix="Units"
				[(model)]="quantity" />
		</app-mhp-labeled-field>

	</div>
</app-mhp-base-modal>