import { Component, computed, inject, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { saxArrowRight3Outline, saxBuildingsOutline } from '@ng-icons/iconsax/outline';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';

import { MHPButton } from 'src/app/_common/components/mhp-button-group/mhp-button.interface';
import { ToCipPipe } from "../../_common/pipes/to-cip/to-cip.pipe";

import { MhpButtonComponent } from "../../_common/components/mhp-button/mhp-button.component";
import { MhpButtonGroupComponent } from "../../_common/components/mhp-button-group/mhp-button-group.component";
import { MhpLoaderComponent } from "../../_common/components/mhp-loader/mhp-loader.component";
import { MhpStatusLabelComponent } from "../../_common/components/mhp-status-label/mhp-status-label.component";
import { MhpProgressbarComponent } from "../../_common/components/mhp-progressbar/mhp-progressbar.component";
import { MainRequestPreFillConfirmModalComponent } from "./main-request-pre-fill-confirm-modal/main-request-pre-fill-confirm-modal.component";
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { CommonModule } from '@angular/common';
import { ImporterRequest } from "../../_common/models/importer-request.model";
import { ApiImporterService } from "../../_common/services/api/api-importer/api-importer.service";
import { Offer } from "../../_common/models/offer.model";
import { Package } from "../../_common/models/package.model";
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { MainRequestItemPerProductComponent } from "./main-request-item-per-product/main-request-item-per-product.component";
import { MainRequestItemPerCompanyComponent } from "./main-request-item-per-company/main-request-item-per-company.component";
import { ImporterRequestItem } from "../../_common/models/importer-request-item.model";
import { MainRequestSendOfferConfirmModalComponent } from "./main-request-send-offer-confirm-modal/main-request-send-offer-confirm-modal.component";
import { ApiOfferService } from "../../_common/services/api/api-offer/api-offer.service";
import { CompanyService } from "../../_common/services/company/company.service";
import { ResponseOffer } from 'src/app/_common/models/response-offer.model';

@Component({
	selector: 'app-main-request',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		ToCipPipe,
		MhpButtonComponent,
		MhpButtonGroupComponent,
		MhpLoaderComponent,
		MhpProgressbarComponent,
		MhpStatusLabelComponent,
		MainRequestItemPerProductComponent,
		MainRequestPreFillConfirmModalComponent,
		MainRequestItemPerProductComponent,
		MainRequestItemPerCompanyComponent,
		MainRequestSendOfferConfirmModalComponent
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	templateUrl: './main-request.component.html'
})
export class MainRequestComponent {
	private _account = inject(AccountService);
	private activatedRoute = inject(ActivatedRoute);
	private _importer = inject(ApiImporterService);
	private _package = inject(ApiPackageService);
  private _offers = inject(ApiOfferService);

	public id = computed(() => {
		const id = this.request()?.id;
		return id ? id.substring(id.length - 10) : null;
	})

	public request = signal<ImporterRequest | null>(null);
	public fetchedOffers = signal<Offer[]>([]);
  public prefilledOffers = signal<Offer[]>([]);
  public nonEmptyPrefilledOffers = computed(() => this.prefilledOffers().filter(offer => offer.quantity > 0));
  public offers = computed(() => [...this.fetchedOffers(), ...this.prefilledOffers()]);
	public packages = signal<Package[]>([]);
	public company = computed(() => this.request()?.exporters![0]);

	public isLoading: boolean = false;
	public requestPreFillConfirmModal: boolean = false;
	public sendOfferConfirmModal: boolean = false;
	public user: User | null = null;
	public requestTypes = RequestTypeEnum;
	public userFunctions = UserFunctionEnum;

	public statusLabel: MHPButton[] = [
		{ text: 'Pending', value: 1 },
		{ text: 'Under review', value: 2, ping: true },
		{ text: 'Booked', value: 3 },
		{ text: 'Ordered', value: 4 }
	];
	public filter: MHPButton = this.statusLabel[1];

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);

		this.activatedRoute.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.isLoading = true;

			try {
				const id = paramMap.get('id');
				const request = id ? await this._importer.getRequestById(id) : null;

				if (request && request.ok) {
					this.request.set(request.body!);
					this.packages.set((await this._package.getByCIP13s(request.body!.items!.map(item => item.packageCip13))).body ?? []);
					const offers = request.ok ? await this._offers.getOffersByRequest(id!) : null;

					if (offers && offers.ok) {
						this.fetchedOffers.set(offers.body!
							.sort((prev: Offer, next: Offer) => new Date(prev.createdAt).getTime() - new Date(next.createdAt).getTime())
							.reverse()
							.reduce((unique: Offer[], offer: Offer) =>
								unique.find((o) => o.batchNumber === offer.batchNumber && new Date(o.createdAt) > new Date(offer.createdAt)) ? unique : [ ...unique, offer ], [])
						);

            if (this.fetchedOffers().length === 0 && this.user!.function === UserFunctionEnum.Exporter) {
              this.filter = this.statusLabel[0];
            }
					}
				}
			}
			finally {
				this.isLoading = false;
      }
		});
	}

	public getPackage(cip13: number): Package | undefined {
		return this.packages().find(p => p.cip13 === cip13);
	}

	public getOfferPackageBundlesByExporter(exporterId: number): (Offer & Package & ImporterRequestItem)[] {
		return this.fetchedOffers()
			.filter(offer => offer.exporterId === exporterId || offer.exporterId === this.request()?.exporters![0].id!)
			.reduce((unique: Offer[], offer: Offer) =>
				unique.find((o) => o.batchNumber === offer.batchNumber && new Date(o.createdAt) > new Date(offer.createdAt)) ? unique : [ ...unique, offer ], [])
			.map((o: Offer) => ({ ...this.request()!.items!.find(i => i.packageCip13 === o.packageCip13)!, ...o, ...this.getPackage(o.packageCip13)! }));
	}

	public async refresh(): Promise<void> {
		window.location.reload();
	}

  onOffersSent($event: void) {
    this.sendOfferConfirmModal = false;
    this.fetchedOffers.set([...this.fetchedOffers(), ...this.prefilledOffers()]);
    this.prefilledOffers.set([]);
    this.filter = this.statusLabel[1];
  }
}
