import { Component, HostListener, input, model, OnInit, output, signal } from '@angular/core';
import { MhpSvgIconComponent } from "../../../_common/components/mhp-svg-icon/mhp-svg-icon.component";
import { MhpFromToComponent } from "../../../_common/components/mhp-from-to/mhp-from-to.component";
import { MhpInputComponent } from "../../../_common/components/mhp-input/mhp-input.component";
import { MhpButtonComponent } from "../../../_common/components/mhp-button/mhp-button.component";
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { Company } from 'src/app/_common/models/company.model';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { ToCipPipe } from "../../../_common/pipes/to-cip/to-cip.pipe";
import { request } from 'http';
import { Package } from 'src/app/_common/models/package.model';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { CommonModule } from '@angular/common';
import { Offer } from "../../../_common/models/offer.model";
import { ApiOfferService } from "../../../_common/services/api/api-offer/api-offer.service";

@Component({
	selector: 'app-main-request-send-offer-confirm-modal',
	standalone: true,
	imports: [
		CommonModule,
		MhpSvgIconComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpButtonComponent,
		ToCipPipe
	],
	templateUrl: './main-request-send-offer-confirm-modal.component.html',
})
export class MainRequestSendOfferConfirmModalComponent implements OnInit {

  public offers = input.required<Offer[]>();

	public exporter = input.required<Company>();
	public request = input.required<ImporterRequest>();
	public isOpen = model<boolean>(false);

	public packages = signal<Package[]>([]);

  public onSumbit = output({ alias: 'submit' });

	public clickedInside: boolean = false;
	public user: User | null = null;

	constructor(
		private _account: AccountService,
		private _apiPackage: ApiPackageService,
    private _apiOffer: ApiOfferService
	) {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public ngOnInit(): void {
		this._apiPackage.getByCIP13s(this.request().items!.map(item => item.packageCip13))
			.then((response) => {
				if(response.ok)
					this.packages.set(response.body!);
			})
			.catch((err: any) => console.error(err))
			.finally(() => {});
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if(e.keyCode === 27)
			this.isOpen.set(false);
	}

	@HostListener('document:click')
	public close(): void {
		if(!this.clickedInside)
			this.isOpen.set(false);

		this.clickedInside = false;
	}

  public submit() {
    // Submit all provided offers
    this.offers().filter(offer => offer.quantity !== 0).forEach(async (offer) => {
      const resp = await this._apiOffer.sendOffer(offer)
      if (!resp || !resp.ok) {
        console.error('Failed to send offer')
        return
      }
    })

    this.isOpen.set(false);
    this.onSumbit.emit();
  }
}
