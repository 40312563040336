import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline, saxBuildingsOutline, saxMoreOutline, saxSmsOutline } from '@ng-icons/iconsax/outline';
import { derivedAsync } from 'ngxtension/derived-async';
import { effectOnceIf } from 'ngxtension/effect-once-if';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { finalize, Subscription } from 'rxjs';
import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { InfiniteScrollDirective } from 'src/app/_common/directives/infinite-scroll.directive';
import { ManifestItemTypeEnum } from 'src/app/_common/enums/manifest-item-type.enum';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { Company } from 'src/app/_common/models/company.model';
import { ManifestItem } from 'src/app/_common/models/manifest-item.model';
import { Package } from 'src/app/_common/models/package.model';
import { ApiImporterService } from 'src/app/_common/services/api/api-importer/api-importer.service';
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { MhpPackageComponent } from '../../_common/components/mhp-package/mhp-package.component';
import { MainPackageRequestProductModalComponent } from '../main-package/main-package-request-product-modal/main-package-request-product-modal.component';
import { MainExportCatalogSearchComponent } from './main-export-catalog-search/main-export-catalog-search.component';

@Component({
	selector: 'app-main-export-catalog',
	imports: [
		CommonModule,
		InfiniteScrollDirective,
		MainExportCatalogSearchComponent,
		MainPackageRequestProductModalComponent,
		MhpButtonComponent,
		MhpPackageComponent,
		MhpSvgIconComponent,
		NgIconComponent,
		RouterModule,
		ScrollingModule,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxMoreOutline,
			saxSmsOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './main-export-catalog.component.html',
})
export class MainExportCatalogComponent {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly _companySvc = inject(CompanyService);
	private readonly _apiImporterSvc = inject(ApiImporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _router = inject(Router);
	protected readonly CompanyExtension = CompanyExtension;

	private readonly _paramMap = toSignal(this.activatedRoute.paramMap);
	private readonly _queryParamMap = toSignal(this.activatedRoute.queryParamMap);
	private readonly _productQueryString = 'product';

	public readonly exporter = computed<Company | null | undefined>(() => {
		const exporters = this._companySvc.allCompanies();
		return exporters.find(c => c.id === +this._paramMap()?.get('id')!);
	});

	public readonly itemSize = computed<number>(() => this.calculateItemSize(this.manifestItems()));
	public readonly manifestItems = signal<ManifestItem[]>([]);
	public readonly manifestItemsCount = computed<number>(() => this.manifestItems().length);
	public readonly manifestPackagesSearchTotalCount = derivedAsync<number | null>(() =>
		this.exporter() ?
			this._apiImporterSvc.countManifestPackages(this.exporter()!.id, {
				types: this._manifestItemTypes,
				cip13OrProductShortNamePart: this._search(),
			})
		:	null
	);
	public initialSearch: string | undefined;
	private readonly _search = computed<string | null>(() => {
		return this._queryParamMap()?.get(this._productQueryString) ?? null;
	});
	private _getManifestItemsSubscription: Subscription | null | undefined;
	private readonly _manifestItemTypes = [ManifestItemTypeEnum.Stock, ManifestItemTypeEnum.Quota];
	private readonly _wantedItemsCount = 500;
	private _hasAllManifestItems = false;

	public readonly requestProductModal = signal(false);
	public package: Package | null = null;
	public selectedManifestItem: ManifestItem | null = null;

	constructor() {
		effectOnceIf(
			() => this.initialSearch === undefined,
			() => {
				this.initialSearch = this._search() ?? '';
			}
		);

		explicitEffect([this.exporter, this._search], () => {
			this.loadManifestItems();
		});
	}

	public loadMore() {
		if (this._hasAllManifestItems) return;
		this.loadManifestItems(false);
	}

	public async requestManifestItem(manifestItem: ManifestItem) {
		this.selectedManifestItem = manifestItem;
		this.package = await this._apiPackageSvc.getByCIP13(manifestItem.packageCip13);
		this.requestProductModal.set(true);
	}

	public onSearchChange(search: string): void {
		// Update the URL with the search query or remove it if empty
		const queryParams = { [this._productQueryString]: search || null };
		this._router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams,
			queryParamsHandling: 'merge',
			replaceUrl: true,
		});
	}

	private loadManifestItems(isManifestItemsReset = true): void {
		const exporterId = this.exporter()?.id;
		if (!exporterId) {
			this.manifestItems.set([]);
			return;
		}

		const skippedItemsCount = isManifestItemsReset ? 0 : this.manifestItemsCount();
		this._getManifestItemsSubscription?.unsubscribe();
		this._getManifestItemsSubscription = this._apiImporterSvc
			.findExporterManifestItems(
				exporterId,
				{
					types: this._manifestItemTypes,
					cip13OrProductShortNamePart: this._search(),
				},
				skippedItemsCount,
				this._wantedItemsCount
			)
			.pipe(finalize(() => (this._getManifestItemsSubscription = null)))
			.subscribe(newItems => {
				this._hasAllManifestItems = newItems.length < this._wantedItemsCount;
				if (isManifestItemsReset) {
					this.manifestItems.set(newItems);
				} else {
					this.manifestItems.update(items => [...items, ...newItems]);
				}
			});
	}

	private calculateItemSize(manifestItems: ManifestItem[]): number {
		const headerSize = 50; // in px
		const longShortNameItemSize = 87; // in px
		const defaultItemSize = 72; // in px

		const longShortName = manifestItems.filter(s => s.packageShortName && s.packageShortName.length > 40);
		const manifestItemsCount = manifestItems.length;
		if (manifestItemsCount > 0) {
			return (
				(headerSize +
					longShortName.length * longShortNameItemSize +
					(manifestItemsCount - longShortName.length) * defaultItemSize) /
				manifestItemsCount
			);
		} else {
			return 0;
		}
	}
}
