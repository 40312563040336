<div class="update-screen">

	<div id="header" class="header">
		<div id="header-left">
			<app-mhp-button text="Back" type="secondary"
				[routerLink]="this.sitemap.updateStockQuota.route + '/stock/upload'" />
		</div>

		<div id="header-right">
			<app-mhp-button text="Confirm the import" type="primary" (click)="uploadStocks()"
				[hidden]="!this.entries().length" />
		</div>
	</div>

	<div class="main-container">
		<h2>Review my stock</h2>

		@if ( countErrors > 0 ) {
		<div class="alert alert-danger">
			<p>There are {{ countErrors }} errors in your file. Only {{validCount}} lines will
				be imported among a total of {{totalCount()}}.</p>
		</div>
		}

		<div class="table-container">

			<cdk-virtual-scroll-viewport class="min-h-[60vh]" itemSize="76" scrollWindow>
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th>Product</th>
							<th>Batch</th>
							<th>Expiration date</th>
							<th>Quantity</th>
							<th>Unit cost</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *cdkVirtualFor="let entry of this.entries(); let i = index">
							<td class="p-4">{{ i + 1 }}</td>
							<td class="p-4">
								<app-mhp-package [package]="{ cip13: entry.packageCip13, shortName: entry.batch!.name ?? undefined }" />
							</td>
							<td class="text-center">
								{{ entry.batch!.id }}
							</td>
							<td class="text-center">
								{{ entry.batch!.expiration }}
							</td>
							<td class="text-center">
								{{ entry.quantity }}
							</td>
							<td class="text-center">
								{{ entry.price }}
							</td>
							<td class="text-left">
								<span>
									{{ this.displayError(i) }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>