export const environment = {

	appInsights: {
		connectionString: 'InstrumentationKey=4112a1e7-a12a-4e56-9150-7cf36d64cbd1;IngestionEndpoint=https://francecentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://francecentral.livediagnostics.monitor.azure.com/;ApplicationId=91113a59-2a87-4e00-a3fb-02b12513603f'
	},

	production: false,
	API_URL: 'https://api.dev.medhubplace.com/api',

};
