@if(true) {
  <div class="main-request-item-per-company overflow-hidden">

    <div class="flex flex-row items-center p-4">
      <div class="flex flex-row items-center w-full space-x-4 cursor-pointer" (click)="this.expanded = !this.expanded">
        <app-mhp-company [company]="this.exporter()" />
      </div>

      <div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
        <app-mhp-button prefix="saxBook1Outline" text="Access the stock offer" type="secondary" />
        @if (
          this.user()?.function === this.userFunctions.Importer
          && this.readyToBookedOffers().length
          && this.filter() === 2
        ) {
          <app-mhp-button
            prefix="saxBookmark2Outline"
            text="Book all ready products ({{ this.readyToBookedOffers().length }})"
            type="primary"
            (click)="this.bookOffersModal = true"
          />
        }

        <button class="inline-flex flex-row items-center text-gray-900 dark:text-white" (click)="this.expanded = !this.expanded">
          <span>Offer details ({{ this.offerCountDisplay() }})</span>
          <ng-icon class="ml-2" [name]="this.expanded ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'" />
        </button>
      </div>
    </div>

    @if (this.expanded) {
      <table class="w-full">
        <thead class="border-t border-gray-200 dark:border-gray-700">
          <tr class="text-gray-500">
            <th class="p-4 text-sm text-left font-medium">Product</th>
            <th class="p-4 text-sm text-left font-medium">Batch</th>
            <th class="p-4 text-sm text-left font-medium">Expiration date</th>
            <th class="p-4 text-sm text-left font-medium">Qty</th>
            <th class="p-4 text-sm text-left font-medium">Unit cost</th>
            <th class="p-4 text-sm text-left font-medium">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="border-t">

          @switch(this.filter()) {
            @case (2) {
              @for (offer of this.offersFiltered(); track $index) {
                <tr
                  class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700"
                  [ngClass]="($index % 2 == 0) ? 'bg-gray-50 dark:bg-[#1c2634]' : 'bg-white dark:bg-gray-800'"
                  (click)="this.offerSelected = offer"
                >
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <div class="inline-flex flex-row items-center w-full space-x-4">
                      <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                        <app-mhp-svg-icon icon="pill" />
                      </div>
      
                      <div class="flex flex-col font-medium">
                        <span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
                        <span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
                      </div>
                    </div>
                  </td>

                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">{{ offer.batchNumber }}</td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    @if(offer | findDeclinedResponseOffer) { Offer declined }
                    @else if(offer | isOfferReadyToBook) { Ready to book }
                    @else if(offer | isOfferAcceptedByUser : this.user()?.function!) { Offer Accepted }
                    @else if(offer.parentOfferId) { Counter offer }
                    @else { Offer sent }
                  </td>
                  <td class="p-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                      @if(offer | findDeclinedResponseOffer) {}
                      @else if(offer | isOfferAcceptedByUser : this.user()?.function!) {
                        @if(
                          this.user()?.function === this.userFunctions.Importer
                          && (offer | isOfferReadyToBook)
                          && this.filter() === 2
                        ) {
                          <button
                            class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                            (click)="this.declineOfferModal = true"
                          >
                            <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                          </button>

                          <app-mhp-button
                            prefix="saxBookmark2Outline"
                            text="Book the product"
                            type="primary"
                            (click)="this.bookOfferModal = true"
                          />
                        }
                      }

                      @else if(xor(
                        xor(
                          this.user()?.function === this.userFunctions.Exporter && this.checkImporterResponseIsAccepted(offer),
                          !(this.currentCompany()!.id === offer.exporterId)
                        ),
                        this.user()?.function === this.userFunctions.Importer && this.checkExporterResponseIsAccepted(offer)
                      )) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.makeOfferModal = true"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }

                      @else {}
                    </div>
                  </td>
                </tr>
              }

              @empty {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">empty</td>
                </tr>
              }
            }

            @case (3) {
              @for (offer of this.bookedOffers(); track $index) {
                <tr
                  class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700"
                  (click)="this.offerSelected = offer"
                >
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <div class="inline-flex flex-row items-center w-full space-x-4">
                      <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                        <app-mhp-svg-icon icon="pill" />
                      </div>
      
                      <div class="flex flex-col font-medium">
                        <span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
                        <span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
                      </div>
                    </div>
                  </td>

                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">{{ offer.batchNumber }}</td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">Confirmed</td>
                  <td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                    </div>
                  </td>
                </tr>
              }
      
              @empty {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">empty</td>
                </tr>
              }
            }

            @case (4) {
              @for (offer of this.orderedOffers(); track $index) {
                <tr
                  class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700"
                  (click)="this.offerSelected = offer"
                >
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <div class="inline-flex flex-row items-center w-full space-x-4">
                      <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                        <app-mhp-svg-icon icon="pill" />
                      </div>

                      <div class="flex flex-col font-medium">
                        <span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
                        <span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
                      </div>
                    </div>
                  </td>

                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">{{ offer.batchNumber }}</td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">In shipping</td>
                  <td class="p-4 cursor-pointer text-end" (click)="this.displayOfferHistoryPanel()">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                    </div>
                  </td>
                </tr>
              }

              @empty {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">empty</td>
                </tr>
              }
            }
          }

        </tbody>
      </table>
    }

  </div>
}

@if (this.offerHistoryPanelDisplayed) {
  <app-main-request-offer-history
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    (stateClose)="this.offerHistoryPanelDisplayed = false"
  />
}

@if (this.makeOfferModal) {
  <app-main-request-make-offer-modal
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    (stateClose)="this.makeOfferModal = false"
  />
}

@else if (this.acceptOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    [type]="this.offerResponseTypes.Accepted"
    (stateClose)="this.acceptOfferModal = false"
  />
}

@else if (this.declineOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    [type]="this.offerResponseTypes.Declined"
    (stateClose)="this.declineOfferModal = false"
  />
}

@else if (this.bookOfferModal) {
  <app-main-request-confirm-book-offer
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    (stateClose)="this.bookOfferModal = false"
  />
}

@else if (this.bookOffersModal) {
  <app-main-request-confirm-book-offers
    [exporter]="this.exporter()"
    [offers]="this.readyToBookedOffers()"
    (stateClose)="this.bookOffersModal = false"
  />
}
