import { Component, HostListener, input, model, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from 'src/app/_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { MhpLabelComponent } from 'src/app/_common/components/mhp-label/mhp-label.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { Company } from 'src/app/_common/models/company.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { SiteMap, SITEMAP } from 'src/app/_common/sitemap';
import { Package } from "../../../_common/models/package.model";
import { ToCipPipe } from "../../../_common/pipes/to-cip/to-cip.pipe";
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { HttpResponse } from '@angular/common/http';
import { PackageItem } from 'src/app/_common/models/package-item.model';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { ApiImporterService } from 'src/app/_common/services/api/api-importer/api-importer.service';
import { MhpLoaderComponent } from "../../../_common/components/mhp-loader/mhp-loader.component";
import { Offer } from "../../../_common/models/offer.model";

@Component({
	selector: 'app-main-package-request-product-modal',
	standalone: true,
	imports: [
    RouterModule,
    MhpButtonComponent,
    MhpFromToComponent,
    MhpInputComponent,
    MhpLabelComponent,
    MhpSvgIconComponent,
    MhpLoaderComponent,
    ToCipPipe,
	],
	styleUrl: './main-package-request-product-modal.component.scss',
	templateUrl: './main-package-request-product-modal.component.html'
})
export class MainPackageRequestProductModalComponent implements OnInit {

	public sitemap: SiteMap = SITEMAP;

  public product = input.required<Package>();
  public request = model<Offer>();
	public exporter = input.required<Company>();
	public stock = input.required<ExporterStockEntry>();
	public isOpen = model(false);
	public price = model<string>('');
	public quantity = model<string>('');
	public requestId = model<string | null>(null);

	public clickedInside: boolean = false;
	public isLoading: boolean = false;
	public step: number = 1;
	public user: User | null = null;
	public requestTypes = RequestTypeEnum;

	constructor(
		private _account: AccountService,
		private _apiImporter: ApiImporterService
	) {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public ngOnInit(): void {
		this.price.set(this.stock().price!.toString());
		this.quantity.set(this.stock().quantity!.toString());
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if(e.keyCode === 27) {
			this.isOpen.set(false);
		}
	}

	@HostListener('document:click')
	public close(): void {
		if(!this.clickedInside) {
			this.isOpen.set(false);
		}

		this.clickedInside = false;
	}

	public submit(): void {
		this.isLoading = true;

		this._apiImporter.postRequest({
			type: this.requestTypes.RequestOffer,
			exporters: [ this.exporter() ],
			items: [{
				packageCip13: this.product().cip13!,
				batchNumber: this.stock().batch.id!,
				minExpiration: this.stock().batch.expiration!,
				quantity: +this.quantity()!,
				price: +this.price()!,
				packageName: this.product().shortName!
			}]
		})
			.then((response: HttpResponse<ImporterRequest | null>) => {
				if(response.ok) {
					this.step++;
					this.requestId.set(response.body?.id!);
				}
			})
			.catch((err) => console.error(err))
			.finally(() => this.isLoading = false);
	}

}
