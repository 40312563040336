import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { derivedAsync } from 'ngxtension/derived-async';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';
import { MhpCompanySelectorComponent } from '../_common/components/mhp-company-selector/mhp-company-selector.component';
import { MhpFromToComponent } from '../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpPackageComponent } from '../_common/components/mhp-package/mhp-package.component';
import { MhpSvgIconComponent } from '../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { Company } from '../_common/models/company.model';
import { ExporterStockEntry } from '../_common/models/exporter-stock-entry.model';
import { Package } from '../_common/models/package.model';
import { StockOffer } from '../_common/models/stock-offer.model';
import { AccountService } from '../_common/services/account/account.service';
import { ApiExporterService } from '../_common/services/api/api-exporter/api-exporter.service';
import { ApiPackageService } from '../_common/services/api/api-package/api-package.service';
import { ToastService } from '../_common/services/toast/toast.service';
import { SITEMAP } from '../_common/sitemap';

@Component({
	selector: 'app-new-stock-offer',
	imports: [
		CommonModule,
		MhpButtonComponent,
		MhpPackageComponent,
		RouterLink,
		MhpCompanySelectorComponent,
		MhpFromToComponent,
		MhpSvgIconComponent,
	],
	templateUrl: './new-stock-offer.component.html',
	styleUrl: './new-stock-offer.component.scss',
})
export class NewStockOfferComponent {
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _router = inject(Router);
	private readonly _toastSvc = inject(ToastService);
	protected readonly _accountSvc = inject(AccountService);
	protected userCompany = computed(() => this._accountSvc.user().company);

	protected readonly stocks = derivedAsync<ExporterStockEntry[]>(
		async () => {
			const stocks = await this._apiExporterSvc.getStock();
			return stocks.filter(i => i.quantity) ?? [];
		},
		{ initialValue: [] }
	);

	protected readonly packages = derivedAsync<Package[]>(
		async () => {
			const { body } = await this._apiPackageSvc.getByCIP13s(this.stocks().map(s => s.packageCip13));
			return body ?? [];
		},
		{ initialValue: [] }
	);

	protected page = signal<number>(0);
	protected selectedStocks = signal<Map<number, ExporterStockEntry>>(new Map());
	protected selectedCompanies = signal<Company[]>([]);

	/**
	 * Gets a fetched package by its CIP13.
	 * @param cip13 The CIP13 of the package.
	 * @protected
	 */
	protected getPackage(cip13: number): Package | undefined {
		return this.packages().find(p => p.cip13 === cip13);
	}

	/**
	 * @borrows SITEMAP
	 */
	protected readonly sitemap = SITEMAP;

	/**
	 * Toggles the stock selection at the given index.
	 * @param ix The index of the stock to toggle.
	 * @protected
	 */
	protected toggleStock(ix: number) {
		this.selectedStocks.update(s => {
			if (s.has(ix)) {
				s.delete(ix);
			} else {
				s.set(ix, this.stocks()[ix]);
			}

			return s;
		});
	}

	/**
	 * Selects all stocks.
	 * @protected
	 */
	protected selectAll() {
		const map = new Map();
		this.stocks().forEach((e, ix) => map.set(ix, e));
		this.selectedStocks.set(map);
	}

	/**
	 * Deselects all stocks.
	 * @protected
	 */
	protected deselectAll() {
		this.selectedStocks.set(new Map());
	}

	/**
	 * Constructs the stock offer DTO and submits it to the API.
	 * @protected
	 */
	protected async sendStockOffer() {
		const stockOffer = {
			exporterId: this.userCompany().id,
			importerIds: this.selectedCompanies().map(c => c.id),
			items: Array.from(this.selectedStocks().entries()).map(([, stock]) => {
				return { batch: stock.batch };
			}),
		} as StockOffer;

		this._apiExporterSvc.postStockOffer(stockOffer).then(r => {
			if (r.ok) {
				this._toastSvc.success('Your stock has been imported.');
				this._router.navigate([this.sitemap.catalog.route]);
			}
		});
	}
}
