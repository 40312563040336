<tr>
  <td><app-mhp-package [package]="{ cip13: offer().packageCip13, shortName: offer().shortName }" /></td>
  <td *ngIf="isImporter()"><app-mhp-company [company]="exporter()" /></td>
  <td>{{ batchNumber() }}</td>
  <td>{{ offer().expirationDate | date : 'd MMM y' : '' : "en-US" }}</td>
  <td>{{ offer().quantity }}</td>
  <td>{{ offer().price | currency : 'EUR': 'symbol' : '.2-2' }}</td>
  <td>{{ statusLabel() }}</td>
  <td><offers-actions [offer]="offer()" /></td>
	@if ( allowEdit() )
	{
	<td class="px-0"><div class="border-r-2">&nbsp;</div></td>
	<td>
		<button *ngIf="allowEditOffer()" class="flex" (click)="clickEditButton()">
			<ng-icon name="saxEditOutline" size="1.5rem" />
		</button>
	</td>
	}
	<td class="px-0"><div class="border-r-2">&nbsp;</div></td>
  <td>
    <button class="flex" (click)="clickHistoryButton()">
      <ng-icon name="saxMessageOutline" size="1.5rem" />
    </button>
  </td>
</tr>
