@if ( this.user() )
{
<div class="mhp-main-account">
	<h2 class="text-4xl font-semibold">My account</h2>

	<div class="mhp-main-account-container">
		<div class="mhp-main-account-left">
			<div class="flex flex-row justify-center">
				<div class="relative">
					<img
						class="border rounded-full h-60 w-60"
						[src]="CompanyExtension.getGravatarUrl(user(), 512)"
						[alt]="user().firstName" />

					<button
						class="absolute flex justify-center items-center h-12 w-12 bottom-3 right-3 border rounded-full hover:bg-gray-200	dark:hover:bg-gray-900">
						<ng-icon name="saxEditOutline" />
					</button>
				</div>
			</div>

			<div class="flex flex-col space-y-4 px-6 pb-6">
				<h3 class="text-xl ">Company</h3>
				<div class="flex flex-row space-x-4">
					<app-mhp-company [company]="user().company" />
				</div>
			</div>
		</div>

		<div class="mhp-main-account-right">
			<div class="flex flex-col space-y-4">
				<h3 class="text-xl ">Personnal informations</h3>
				<div class="flex flex-row space-x-4">
					<div class="flex flex-col space-y-2 w-1/2">
						<label class="ml-4 text-sm text-[var(--text-secondary)]">First name</label>
						<app-mhp-input
							[disabled]="true"
							[model]="user().firstName" />
					</div>
					<div class="flex flex-col space-y-2 w-1/2">
						<label class="ml-4 text-sm text-[var(--text-secondary)]">Last name</label>
						<app-mhp-input
							[disabled]="true"
							[model]="user().lastName" />
					</div>
				</div>

				<div class="flex flex-col space-y-2">
					<label class="ml-4 text-sm text-[var(--text-secondary)]">E-mail address</label>
					<app-mhp-input
						prefix="saxSmsOutline"
						[disabled]="true"
						[model]="user().email" />
				</div>
				<div class="flex flex-col space-y-2">
					<label class="ml-4 text-sm text-[var(--text-secondary)]">Phone number</label>
					<app-mhp-input
						prefix="saxCallOutline"
						[disabled]="true"
						[model]="user().phoneNumber" />
				</div>
			</div>

			<div class="flex flex-col space-y-4">
				<h3 class="text-xl ">Role</h3>

				<div class="flex flex-col space-y-2">
					<label class="ml-4 text-sm text-[var(--text-secondary)]">User function</label>
					<app-mhp-input
						prefix="saxPersonalcardOutline"
						[disabled]="true"
						[model]="user().function | toUserFunction" />
				</div>
			</div>

			<div class="flex flex-col space-y-4">
				<h3 class="text-xl ">Address</h3>
				<!-- <div class="flex flex-col space-y-2">
					<label class="ml-4 text-sm text-[var(--text-secondary)]">Recipient</label>
					<app-mhp-input
						[disabled]="true"
						[model]="user().address.recipient"
					/>
				</div> -->

				<div class="flex flex-row space-x-4">
					<div class="flex flex-col space-y-2">
						<label class="ml-4 text-sm text-[var(--text-secondary)]">Number</label>
						<app-mhp-input
							class="w-16"
							[disabled]="true"
							[model]="user().address.streetNumber" />
					</div>
					<div class="flex flex-col space-y-2 w-full">
						<label class="ml-4 text-sm text-[var(--text-secondary)]">Street</label>
						<app-mhp-input
							[disabled]="true"
							[model]="user().address.street" />
					</div>
				</div>

				<div class="flex flex-row space-x-4">
					<div class="flex flex-col space-y-2">
						<label class="ml-4 text-sm text-[var(--text-secondary)]">Zip Code</label>
						<app-mhp-input
							class="w-28"
							[disabled]="true"
							[model]="user().address.postalCode" />
					</div>
					<div class="flex flex-col space-y-2 w-full">
						<label class="ml-4 text-sm text-[var(--text-secondary)]">City</label>
						<app-mhp-input
							[disabled]="true"
							[model]="user().address.city" />
					</div>
				</div>

				<div class="flex flex-col space-y-2">
					<label class="ml-4 text-sm text-[var(--text-secondary)]">Country</label>
					<app-mhp-input
						[disabled]="true"
						[model]="user().address.country" />
				</div>
			</div>
		</div>
	</div>
</div>
}