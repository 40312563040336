import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OfferActionAcceptComponent } from './offer-action-accept/offer-action-accept.component';
import { OfferActionCounterComponent } from './offer-action-counter/offer-action-counter.component';
import { OfferActionDeclineComponent } from './offer-action-decline/offer-action-decline.component';
import { OfferActionOrderComponent } from './offer-action-order/offer-action-order.component';
import { OfferActionPurchaseComponent } from './offer-action-purchase/offer-action-purchase.component';

@Component({
	selector: 'offers-actions',
	templateUrl: './offer-actions.component.html',
	styleUrls: ['./offer-actions.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		OfferActionDeclineComponent,
		OfferActionAcceptComponent,
		OfferActionPurchaseComponent,
		OfferActionOrderComponent,
		OfferActionCounterComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferActionsComponent {
	private readonly accountService = inject(AccountService);

	readonly userFunction = computed(() => this.accountService.user()!.function);
	readonly offer = input.required<Offer>();

	readonly rejectPossible = computed(
		() =>
			!OfferExtension.isOfferDeclined(this.offer()) &&
			(OfferExtension.canImporterApprove(this.offer()) || this.userFunction() === UserFunctionEnum.Exporter)
	);

	readonly acceptPossible = computed(() => OfferExtension.isAcceptPossible(this.offer(), this.userFunction()));

	readonly counterOfferPossible = computed(() =>
		OfferExtension.isCounterOfferPossible(this.offer(), this.userFunction())
	);

	readonly purchasePossible = computed(() => OfferExtension.isPurchasePossible(this.offer(), this.userFunction()));

	readonly orderPossible = computed(() => OfferExtension.isOrderPossible(this.offer(), this.userFunction()));
}
