import { CommonModule } from '@angular/common';
import { Component, computed, contentChild, ElementRef, HostBinding, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxClockOutline, saxTimerOutline, saxWallet2Outline } from '@ng-icons/iconsax/outline';
import { ImporterRequestExtension } from '../../extensions/importer-request.extension';
import { RequestTask } from '../../models/request-task.model';
import { MhpButtonComponent } from '../mhp-button/mhp-button.component';

@Component({
	selector: 'app-mhp-task-card',
	standalone: true,
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			saxWallet2Outline,
			saxTimerOutline,
			saxClockOutline,
		}),
		provideNgIconsConfig({ size: '13px' }),
	],
	templateUrl: './mhp-task-card.component.html',
	styles: [
		`
			ng-icon {
				@apply mx-3;
			}

			span {
				@apply flex items-center text-sm h-[14px];
			}

			span:not(:first-child) {
				@apply border-l;
			}
		`,
	],
})
export class MhpTaskCardComponent {
	@HostBinding('class') class = 'flex p-4 gap-6 items-center border rounded-xl';

	public readonly title = input.required<string>();
	public readonly task = input.required<RequestTask>();

	public readonly reference = computed(() =>
		ImporterRequestExtension.getUIRequestId(this.task().id, this.task().userRequestId)
	);
	public readonly companyIcon = input<string>();
	public readonly action = contentChild<ElementRef<MhpButtonComponent>>('action');
}
