import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight1Outline } from '@ng-icons/iconsax/outline';

import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { Package } from 'src/app/_common/models/package.model';
import { ToCipPipe } from "../../../pipes/to-cip/to-cip.pipe";
import { MhpSvgIconComponent } from "../../mhp-svg-icon/mhp-svg-icon.component";

@Component({
	selector: 'app-mhp-search-modal-item',
	standalone: true,
	imports: [
		RouterModule,
		NgIconComponent,
		ToCipPipe,
		MhpSvgIconComponent
	],
	providers: [
		provideIcons({
			saxArrowRight1Outline
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	templateUrl: './mhp-search-modal-item.component.html'
})
export class MhpSearchModalItemComponent {

	@Input() public package!: Package;

	public sitemap: SiteMap = SITEMAP;

}
