@if (true) {
  <div class="main-request-offer-history-discussion-header">
    <div class="main-request-offer-history-discussion-header-info">
      <!-- <img
        class="mhp-avatar"
        [src]="getGravatarUrl(otherParticipant()?.displayName!)"
        [alt]="this.otherParticipant()?.displayName"
      /> -->

      <!-- <label>{{ otherParticipant()?.displayName }}</label> -->
    </div>
  </div>

  <div class="main-request-offer-history-discussion-messages">
		@for (message of []; track $index) {
			<!-- <app-main-messaging-conversation-message [message]="message" /> -->
		} @empty {
			<p>No messages yet.</p>
		}
  </div>

	<div class="main-request-offer-history-discussion-input">
		<app-mhp-input
			type="textarea"
			class="message-input"
			[placeholder]="'Type a message...'"
			[disabled]="true"
			[(value)]="draftMessage"
			(keydown.control.enter)="sendMessage()"
		/>

		<app-mhp-button
			class="send-button"
			[disabled]="!draftMessage() || draftMessage()!.length < 0"
			(click)="sendMessage()"
			prefix="saxSend2Outline"
			text="Send"
		/>
	</div>
}