<!-- TODO: refacto
 use one component for each page instead. using @if and @Switches in html make the code unmaintainable, per-company.html is a good example of unmaintainable html page.
@if and @swtiches can be replaced by component composition. Here is an example:

	selector: 'app-multi-slot',
	template: `
		<div class="header">
			<ng-content select="[slot='header']"></ng-content>
		</div>
		<div class="body">
			<ng-content select="[slot='body']"></ng-content>
		</div>
		<div class="footer">
			<ng-content select="[slot='footer']"></ng-content>
		</div>
usage:
<app-multi-slot>
	<h1 slot="header">Header Content</h1>
	<p slot="body">Body content goes here, and it can be anything including more complex Angular components.</p>
	<div slot="footer">Footer content, such as contact information or buttons.</div>
</app-multi-slot>
 -->

<div class="stock-offer-creation-screen">
	<div id="header" class="header">
		<div id="header-left">
			@if (page() === 0) {
			<app-mhp-button text="Cancel" type="secondary" [routerLink]="this.sitemap.catalog.route" />
			} @else {
			<app-mhp-button text="Back" type="secondary" (click)="page.set(page() - 1)" />
			}
		</div>

		<div id="header-right">
			<div><span>{{ this.selectedStocks().size }}</span> items selected</div>

			@switch (page()) {
			@case (0) {
			<app-mhp-button text="Continue" type="primary" (click)="page.set(page() + 1)"
				[disabled]="!selectedStocks().size" />
			} @case (1) {
			<app-mhp-button text="Continue" type="primary" (click)="page.set(page() + 1)"
				[disabled]="!selectedCompanies().length" />
			} @case (2) {
			<app-mhp-button text="Send stock offer" type="primary" (click)="sendStockOffer()" />
			}
			}
		</div>
	</div>

	@switch (page()) {
	@case (0) {
	<div class="main-container">
		<h2>Select the items you wish to add to your stock offer</h2>

		<div class="toolbar">
			<div class="filters">
				<!-- 				<app-mhp-button type="tertiary" suffix="saxArrowDown1Outline" text="Cost" /> -->
				<!-- 				<app-mhp-button type="tertiary" suffix="saxArrowDown1Outline" text="Stock" /> -->
				<!-- 				<app-mhp-button type="tertiary" suffix="saxArrowDown1Outline" text="Expiration date" /> -->
				<!-- 				<app-mhp-button type="tertiary" suffix="saxArrowDown1Outline" text="Sections" /> -->
			</div>

			<div class="actions">
				<app-mhp-button type="tertiary" prefix="saxTickSquareOutline" text="Select all" (click)="selectAll()" />
				<app-mhp-button type="tertiary" prefix="saxCloseSquareOutline" text="Deselect all" (click)="deselectAll()" />
			</div>
		</div>

		<div class="table-container">
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th>Product</th>
						<th>Batch</th>
						<th>Expiration date</th>
						<th>Stock</th>
						<th>Unit cost</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					@for (stock of stocks(); track $index;) {
					<tr>
						<th>{{ $index + 1 }}</th>
						<td><app-mhp-package [package]="getPackage(stock.packageCip13)!" /></td>
						<td>{{ stock.batch?.id ??'' }}</td>
						<td>{{ stock.batch?.expiration | date : 'dd/MM/yyyy' }}</td>
						<td>{{ stock.quantity }}</td>
						<td>{{ stock.price | currency : 'EUR' : 'symbol' : '.0-2' }}</td>
						<td><input type="checkbox" [checked]="selectedStocks().has($index)" (click)="toggleStock($index)" /></td>
					</tr>
					}
				</tbody>
			</table>
		</div>
	</div>
	}
	@case (1) {
	<div class="main-container max-w-2xl">
		<h2>Who would you like to send this stock offer to ?</h2>
		<app-mhp-company-selector [(selected)]="selectedCompanies" />
	</div>
	}
	@case (2) {
	<div class="main-container max-w-2xl">
		<h2>Confirm your stock offer before sending</h2>

		<div class="summary w-full">
			<p class="text-lg font-semibold">You would like to send a stock offer</p>
			<app-mhp-from-to [from]="userCompany()" [to]="this.selectedCompanies()" />

			<div class="section products">
				<h4>Products</h4>

				<div class="entries-info">
					<div inert class="icon">
						<app-mhp-svg-icon icon="pill" [size]="16" />
					</div>

					<p>{{ selectedStocks().size }} Products</p>
				</div>
			</div>

			<p class="footnote">
				The stock offer will be sent individually to each importer.
				They will not be able to see other responses than their own.
			</p>
		</div>
	</div>
	}
	}
</div>