<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-700 dark:bg-black bg-opacity-50 dark:bg-opacity-75" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="p-6 relative flex flex-col flex-start items-start transform overflow-hidden rounded-2xl bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
      >
        @if (showCloseButton()) {
          <div class="mb-6">
            <button class="flex flex-row justify-center item-center" (click)="handleClose()">
              <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
            </button>
          </div>
        }

        @if (title()) {
          <h3 class="text-xl font-semibold mb-8">{{ title() }}</h3>
        }

        <ng-content></ng-content>

        @if (actionButtonText()) {
          <div class="w-full mt-8">
            <app-mhp-button
              class="w-full"
              [size]="actionButtonSize()"
              [text]="actionButtonText()"
              [type]="actionButtonType()"
              [prefix]="actionButtonPrefix"
              (click)="action.emit()"
            />
          </div>
        }
      </div>
    </div>
  </div>
</div>
