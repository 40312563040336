<div class="mhp-messaging-conversation-message" [class.sender]="isCompanySender()" [class.receiver]="!isCompanySender()">
	<div class="info">
		<span class="sender">{{ isAccountSender() ? "You" : message().authorFullName }}</span>
		<span class="timestamp">{{ message().createdAt | date: 'short' }}</span>
	</div>

	<div class="bubble">
		<p>{{ message().content }}</p>
	</div>
</div>
