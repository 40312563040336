<div class="flex flex-col justify-center items-center pt-[15vh] w-full">

  <button
    class="fixed px-4 py-3 m-8 left-0 top-0 rounded-xl border border-gray-200 text-gray-900 bg-white dark:text-white dark:border-gray-700 dark:bg-gray-800"
    (click)="this.reset()"
    [routerLink]="[ this.sitemap.dashboard.route ]"
  >Cancel</button>

  @if (this.step) {
    <div class="fixed m-8 z-10 right-0 top-0 flex flex-row space-x-4">
      <!-- <button
        class="px-4 py-3 rounded-xl text-gray-900 bg-gray-100 hover:bg-gray-200 dark:text-white dark:bg-gray-900 dark:hover:bg-gray-950"
      >Save draft</button> -->

      @if (!(this.user?.function === this.userFunctions.Exporter && this.step < 2)) {
        <button
          class="px-4 py-3 rounded-xl text-gray-900 bg-gray-100 hover:bg-gray-200 dark:text-white dark:bg-gray-900 dark:hover:bg-gray-950"
          (click)="this.previousStep()"
        >Previous</button>
      }

      @if(this.step === 2 && this.user?.function === this.userFunctions.Exporter) {
        <button
          class="px-4 py-3 rounded-xl text-white bg-blue-500 hover:bg-blue-600"
          [disabled]="this.isLoading"
          (click)="this.submitStockOffer()"
        >Confirm your stock</button>
      }

      @else if (this.canContinue()) {
        <button
          class="px-4 py-3 rounded-xl text-white bg-blue-500 hover:bg-blue-600"
          [disabled]="this.isLoading"
          (click)="this.step === 4 ? this.submit() : this.nextStep()"
        >
          @if(this.step === 4) { Send order }
          @else { Continue }
        </button>
      }
    </div>
  }

  @switch (this.step) {
    @case(0) {
      <div class="flex flex-col justify-center">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">What would you like to request?</h2>

        <div class="flex flex-col space-y-4">
          <app-new-request-button
            title="Purchase order"
            text="You define a list of products, an exporter and commit to an order."
            (click)="this.updateRequestType(requestTypes.PurchaseOrder)"
          />

          <!-- true value for disabling button -->
          <app-new-request-button
            [disabled]="true"
            title="Request offer"
            [text]="true ? 'Coming soon...' : 'You define a list of products and invite the exporters of your choice to submit their best offers.'"
            (click)="this.updateRequestType(requestTypes.RequestOffer)"
          />

          <!-- <app-new-request-button
            title="Price request"
            text="You define a list of products and ask exporters to tell you the price before you decide to buy. "
            (click)="this.nextStep()"
          /> -->
        </div>
      </div>
    }

    @case (1) {
      <div class="flex flex-col justify-center min-w-[30rem]">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Import your list of products</h2>

        @if ((this.isFileInputFilled && this.csvFile)) {
          <div class="flex flex-row p-4 items-center rounded-2xl border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
            @if (this.csvFile) {
              <ng-icon class="mr-4 shrink-0 text-blue-600" name="saxTickCircleBold" />
            }

            @else {
              <app-mhp-svg-icon icon="spinner" />
            }

            <span class="w-full text-sm text-gray-900 dark:text-white">{{ this.csvFile.name }}</span>

            @if (this.csvFile) {
              <button
                class="flex flex-row justify-center items-center hover:text-red-600 dark:text-white"
                (click)="this.deleteCSVFile()"
              >
                <ng-icon name="saxTrashOutline" />
              </button>
            }
          </div>
        }

        @else {
          <label
            class="flex flex-col justify-center items-center px-4 py-6 cursor-pointer rounded-2xl border-2 border-dashed border-gray-400 hover:border-gray-500"
            for="file-input"
            (fileDropped)="this.inputDropedFileProcess($event)"
            appDragAndDrop
          >
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <ng-icon
                class="mb-6 text-gray-900 dark:text-white"
                name="saxImportOutline"
              />

              <span class="mb-2 font-semibold text-gray-900 dark:text-white">Drag and drop your file or click to browse</span>
              <span class="text-gray-500">Only CSV format is supported.</span>
            </div>

            <input
              class="hidden"
              [accept]="this.acceptedFiles"
              id="file-input"
              type="file"
              (change)="this.inputFileProcess($event.target)"
            />
          </label>
        }
      </div>
    }

    @case (2) {
      <div class="flex flex-col justify-center min-w-[30rem] pb-12">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Confirm your products list</h2>

        <div class="flex flex-col w-full">
          <span class="mb-6 text-lg font-semibold text-gray-900 dark:text-white">We have identified {{ this.packageItems().length }} products</span>

          <div class="overflow-x-auto border border-gray-200 dark:border-gray-700 rounded-2xl">
            <table class="min-w-[80vw] w-full text-sm text-left rtl:text-right text-gray-900 bg-white dark:bg-gray-800 dark:text-white">
              <thead class="text-xs text-gray-500">
                <tr>
                  <th class="py-4 pl-4 font-medium">#</th>
                  <th class="py-4 pl-4 font-medium">Product</th>
                  <th class="py-4 pl-4 font-medium">Batch</th>
                  <th class="py-4 pl-4 font-medium">Expiration date</th>
                  <th class="py-4 pl-4 font-medium">Quantity</th>
                  <th class="py-4 pl-4 font-medium">Unit cost</th>
                  <th class="py-4 pl-4 font-medium"></th>
                </tr>
              </thead>
              <tbody>

                @for (packageItem of this.packageItems(); track $index) {
                  <tr class="bg-white border-t dark:bg-gray-800 dark:border-gray-700 font-medium">
                    <td class="p-4">{{ $index + 1 }}</td>
                    <td class="p-4">
                      <div class="flex flex-row items-center space-x-4">
                        <div class="flex justify-center items-center h-11 w-11 shrink-0 border rounded-full dark:border-gray-700">
                          <app-mhp-svg-icon icon="pill" />
                        </div>
                        <div class="flex flex-col">
                          <span class="text-sm text-gray-500">{{ +packageItem.packageCip13 | toCip : 13 }}</span>
                          <span class="text-sm">{{ packageItem.packageName }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        [ngClass]="this.checkErrorCell(packageItem, $index) ? 'border-red-500' : ''"
                        type="text"
                        [value]="packageItem.batchNumber"
                        [(ngModel)]="packageItem.batchNumber"
                      />
                    </td>
                    <td class="text-center">
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        [ngClass]="this.checkErrorCell(packageItem, $index) ? 'border-red-500' : ''"
                        type="text"
                        [value]="packageItem.minExpiration"
                        [(ngModel)]="packageItem.minExpiration"
                      />
                    </td>
                    <td class="text-center">
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        [ngClass]="this.checkErrorCell(packageItem, $index) ? 'border-red-500' : ''"
                        type="text"
                        [value]="packageItem.quantity"
                        [(ngModel)]="packageItem.quantity"
                      />
                    </td>
                    <td class="text-center">
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        [ngClass]="this.checkErrorCell(packageItem, $index) ? 'border-red-500' : ''"
                        type="text"
                        [value]="packageItem.price"
                        [(ngModel)]="packageItem.price"
                      />
                    </td>
                    <td class="p-4 w-40 text-right shrink-0">
                      <button class="hover:text-red-600" (click)="this.deleteFromCSV($index)">
                        <ng-icon name="saxTrashOutline" />
                      </button>
                    </td>
                  </tr>
                }

                @empty {
                  <tr class="bg-white border-t dark:bg-gray-800 dark:border-gray-700 font-medium">
                    <td class="p-4">empty</td>
                  </tr>
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    }

    @case (3) {
      <div class="flex flex-col justify-center max-w-[30rem] pb-12">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">To whom would you like to send this purchase order?</h2>

        <app-mhp-exporter-selector
          description="You have already made requests to these exporters"
          [exporters]="this.exporters"
          [selected]="this.exporter"
          (exporterSelected)="this.updateExporter($event)"
        />
      </div>
    }

    @case (4) {
      <div class="flex flex-col justify-center max-w-[30rem] pb-12">
        <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Confirm and complete the information before to send</h2>

        @if(this.isLoading) { <app-mhp-loader /> }

        @else {
          <div class="flex flex-col p-6 space-y-8 border rounded-2xl border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
            <span class="font-semibold text-gray-900 dark:text-white">You would like to make a purchase order</span>

            <div class="flex flex-col space-y-6">
              <app-mhp-from-to [from]="this.user!" [to]="this.exporter" />
              <div class="flex flex-row px-4 py-3 items-center justify-center border rounded-xl border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
                <span class="w-full text-sm text-gray-500">Open to others if stock unsure, no commitment</span>
                <app-mhp-switch />
              </div>
            </div>

            <div class="flex flex-col space-y-8">
              <div class="flex flex-col space-y-4">
                <span class="text-sm text-gray-500">Products requested</span>
                <div class="flex flex-row items-center">
                  <div class="flex flex-row w-full space-x-4">
                    <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                      <app-mhp-svg-icon icon="pill" />
                    </div>
                    <div class="flex flex-col text-gray-900 dark:text-white">
                      <span>{{ this.packageItems().length }} references</span>
                      <span class="text-sm">{{ this.getPackageItemsSum() | currency : 'EUR': 'symbol' : '.0-2' }}</span>
                    </div>
                  </div>
                  <app-mhp-button
                    class="shrink-0"
                    text="See the list"
                    type="secondary"
                    (click)="this.goToStep(2)"
                  />
                </div>
              </div>

              <div class="flex flex-col space-y-4">
                <span class="text-sm text-gray-500">Deadline for responses</span>
                <app-mhp-input
                  type="date"
                />
              </div>

              <div class="flex flex-col space-y-4">
                <div class="flex flex-row items-center text-sm text-gray-500">
                  <span class="mr-1">Minimum order price</span>
                  <ng-icon name="saxInfoCircleOutline" size="1rem" />
                </div>
                <app-mhp-input
                  [min]="0"
                  [stepper]="true"
                  suffix="€"
                  type="number"
                />
              </div>

              <div class="flex flex-col space-y-4">
                <span class="text-sm text-gray-500">Comments (optional)</span>
                <app-mhp-input
                  placeholder="Enter your comment..."
                  type="textarea"
                />
              </div>
            </div>
          </div>
        }
      </div>
    }
  }

</div>
