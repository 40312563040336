import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, model, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowRight3Outline, saxBuildingsOutline, saxTruckOutline } from '@ng-icons/iconsax/outline';

import { MHPButton } from 'src/app/_common/components/mhp-button-group/mhp-button.interface';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { ImporterRequest } from '../../_common/models/importer-request.model';
import { Offer } from '../../_common/models/offer.model';

import { filter, from, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { OfferStatusTabEnum } from 'src/app/_common/enums/offer-status-tab.enum';
import { ImporterRequestExtension } from 'src/app/_common/extensions/importer-request.extension';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { Company } from 'src/app/_common/models/company.model';
import { RequestEventDispatcher } from 'src/app/_common/services/request/request.event.dispatcher';
import { RequestService } from 'src/app/_common/services/request/request.service';
import { MhpButtonGroupComponent } from '../../_common/components/mhp-button-group/mhp-button-group.component';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpStatusLabelComponent } from '../../_common/components/mhp-status-label/mhp-status-label.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';

import { MainRequestItemPerProductComponent } from './main-request-item-per-product/main-request-item-per-product.component';
import {
	MainRequestPreFillConfirmModalComponent,
	PreFillModalResponse,
} from './offer-modals/main-request-pre-fill-confirm-modal/main-request-pre-fill-confirm-modal.component';
import { MainRequestSendOfferConfirmModalComponent } from './offer-modals/main-request-send-offer-confirm-modal/main-request-send-offer-confirm-modal.component';
import { OffersLoadingComponent } from './offers-loading/offers-loading.component';
import { OffersSummaryComponent } from './offers-summary/offers-summary.component';
import { OffersWaitingComponent } from './offers-waiting/offers-waiting.component';

@Component({
	selector: 'app-main-request',
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		MhpButtonComponent,
		MhpButtonGroupComponent,
		MhpStatusLabelComponent,
		MainRequestItemPerProductComponent,
		MainRequestPreFillConfirmModalComponent,
		MainRequestSendOfferConfirmModalComponent,
		OffersLoadingComponent,
		MhpSvgIconComponent,
		OffersSummaryComponent,
		OffersWaitingComponent,
	],
	providers: [
		provideIcons({
			saxArrowRight3Outline,
			saxBuildingsOutline,
			saxTruckOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './main-request.component.html',
})
export class MainRequestComponent implements OnInit, OnDestroy {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly router = inject(Router);
	private readonly requestService = inject(RequestService);
	private readonly accountService = inject(AccountService);
	private readonly offerService = inject(OffersService);

	readonly RequestTypeEnum = RequestTypeEnum;
	readonly UserFunctionEnum = UserFunctionEnum;
	readonly OfferStatusTabEnum = OfferStatusTabEnum;
	readonly OfferExtension = OfferExtension;

	readonly importingItems = signal<number>(0);
	readonly totalItems = signal<number>(0);
	readonly isImporting = signal<boolean>(false);
	readonly user = computed<User>(() => this.accountService.user()!);

	displayedId = '';
	public request: ImporterRequest = null!;
	readonly prefillConfirmation = model<PreFillModalResponse>(PreFillModalResponse.None);

	readonly statusLabel: MHPButton<OfferStatusTabEnum>[] = [
		{ text: 'Pending', value: OfferStatusTabEnum.pending, ping: false },
		{ text: 'Required Actions', value: OfferStatusTabEnum.waiting, ping: false },
		{ text: 'Summary', value: OfferStatusTabEnum.summary, ping: false },
	];

	selectedStatus: OfferStatusTabEnum = OfferStatusTabEnum.pending;
	requestPreFillConfirmModal = false;
	sendOfferConfirmModal = false;

	get offersByProduct(): Record<string, Offer[]> {
		return this.offerService.currentRequestOffersByProduct();
	}

	get validNewOffersAmount(): number {
		return this.offerService.newOffers().filter(OfferExtension.isValidNewOffer).length;
	}

	get userFunction(): UserFunctionEnum {
		return this.user()!.function;
	}

	get requestOffers(): Offer[] {
		return this.offerService.currentRequestOffers();
	}

	get company(): Company {
		return this.request.exporters![0];
	}

	readonly pendingOffersPerProduct = computed(this.offerService.pendingOffersPerProduct);
	readonly inreviewOffers = computed(this.offerService.underReviewOffers);
	readonly confirmedOffers = computed(this.offerService.confirmedOffersOld);
	readonly orderedOffers = computed(this.offerService.orderedOffers);
	readonly editedOffers = computed(this.offerService.editedOffers);

	private readonly onDestroy$ = new Subject<void>();

	constructor() {
		effect(
			async () => {
				if (this.prefillConfirmation() === PreFillModalResponse.Confirmed) {
					this.prefillConfirmation.set(PreFillModalResponse.None);
					await this.prefillOffersWithStock();
				}
			},
			{ allowSignalWrites: true }
		);
	}

	ngOnInit() {
		this.activatedRoute.paramMap
			.pipe(
				map(paramMap => {
					this.updateSelectedStatusFromParams(paramMap);
					return paramMap.get('id');
				}),
				filter(id => id !== null && id != this.request?.id),
				switchMap(id => this.loadAndSubscribeToRequest(id!))
			)
			.subscribe();
	}

	private updateSelectedStatusFromParams(paramMap: ParamMap): void {
		const tab = paramMap.get('tab');
		if (tab) {
			this.selectedStatus = OfferStatusTabEnum[tab as keyof typeof OfferStatusTabEnum];
			this.setPing(this.selectedStatus, false);
		} else {
			this.selectedStatus = OfferStatusTabEnum.summary;
		}
	}

	private loadAndSubscribeToRequest(id: string): Observable<void> {
		return from(this.requestService.getRequestAndSubscribeToChanges(id)).pipe(
			map(dispatcher => {
				this.subscribeToNewOfferEvents(dispatcher);
				return dispatcher.request;
			}),
			filter((request: ImporterRequest) => !!request),
			tap((request: ImporterRequest) => {
				this.sortRequestItems(request);
				this.request = request;
				this.displayedId = ImporterRequestExtension.getUIRequestId(request);
			}),
			switchMap(request => from(this.offerService.initRequest(request)))
		);
	}

	private subscribeToNewOfferEvents(dispatcher: RequestEventDispatcher): void {
		dispatcher.newOfferEvent$.pipe(takeUntil(this.onDestroy$)).subscribe(async offer => {
			this.setNotificationBadgeOnButton(offer, true);
			this.offerService.onOfferUpdatedOrAdded(offer);
		});
	}

	private setNotificationBadgeOnButton(offer: Offer, value: boolean): void {
		if (!offer) return;

		if (this.selectedStatus !== OfferStatusTabEnum.waiting && OfferExtension.isActionPossible(offer, this.userFunction))
			this.setPing(OfferStatusTabEnum.waiting, value);

		if (this.selectedStatus !== OfferStatusTabEnum.summary) this.setPing(OfferStatusTabEnum.summary, value);
	}

	private setPing(status: OfferStatusTabEnum, value: boolean): void {
		const button = this.statusLabel.find(s => s.value === status);
		if (!button) throw Error(`Button ${status} not found`);
		button.ping = value;
	}

	private sortRequestItems(request: ImporterRequest): void {
		request.items = request.items.sort((a, b) => a.package.shortName!.localeCompare(b.package.shortName!));
	}

	public gotoTab(offerStatus: OfferStatusTabEnum | undefined, useRouter = false): boolean {
		if (offerStatus == undefined || this.selectedStatus === offerStatus) {
			return false;
		}

		if (useRouter) {
			this.router.navigate(['../' + OfferStatusTabEnum[offerStatus]], {
				relativeTo: this.activatedRoute,
			});
		} else {
			this.selectedStatus = offerStatus;
		}

		return true;
	}

	onOffersSent(): void {
		this.sendOfferConfirmModal = false;
		this.offerService.sendValidNewOffers();
	}

	async prefillOffersWithStock(): Promise<void> {
		try {
			this.importingItems.set(0);
			this.isImporting.set(true);
			await this.offerService.prefillOffersWithStock(
				this.request.items!,
				(l: number) => this.totalItems.set(l),
				() => this.importingItems.set(this.importingItems() + 1)
			);
		} finally {
			this.isImporting.set(false);
		}
	}

	async orderOffers() {
		await this.offerService.orderOffers(this.offerService.currentRequestOffers());
	}

	editBatches() {
		this.offerService.editOffers();
	}

	async validateEditedOffers() {
		await this.offerService.validateEditedOffers();
	}

	cancelEdit() {
		this.offerService.cancelEditingOffers();
	}

	ngOnDestroy() {
		if (this.request?.id) {
			this.requestService.unsubscribeToRequestChanges(this.request.id);
		}
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
