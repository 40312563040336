import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { saxMessageOutline } from '@ng-icons/iconsax/outline';
import { NotificationIndicatorDirective } from 'src/app/_common/directives/notification-indicator.directive';
import { Offer } from 'src/app/_common/models/offer.model';
import { MedEventService } from 'src/app/_common/services/medevent/medevent.service';

@Component({
	selector: 'offer-action-chat',
	templateUrl: './offer-action-chat.component.html',
	standalone: true,
	imports: [CommonModule, NgIconComponent, NotificationIndicatorDirective],
	providers: [
		provideIcons({
			saxMessageOutline,
		}),
	],
})
export class OfferActionChatComponent {
	offer = input.required<Offer>();
	onOpenHistory = output();

	readonly medEventService = inject(MedEventService);

	// Signal to track if there are unread messages
	hasUnreadMessages = computed(() => {
		return this.medEventService.hasUnreadMessagesForChat(this.offer().id)();
	});

	clickHistoryButton() {
		this.onOpenHistory.emit();
	}
}
