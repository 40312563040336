import { Routes } from '@angular/router';

import { SITEMAP } from './_common/sitemap';

import { isAdminGuard } from './_common/guards/is-admin/is-admin.guard';
import { isAuthenticatedGuard } from './_common/guards/is-authenticated/is-authenticated.guard';
import { isNotAuthenticatedGuard } from './_common/guards/is-not-authenticated/is-not-authenticated.guard';

import { MainAccountComponent } from './main/main-account/main-account.component';
import { MainDashboardComponent } from './main/main-dashboard/main-dashboard.component';
import { MainIndexComponent } from './main/main-index/main-index.component';
import { MainPackageComponent } from './main/main-package/main-package.component';
import { MainRequestComponent } from './main/main-request/main-request.component';
import { MainStockOfferComponent } from './main/main-stock-offer/main-stock-offer.component';
import { MainComponent } from './main/main.component';

import { NewRequestComponent } from './new-request/new-request.component';

import { AdminIndexComponent } from './admin/admin-index/admin-index.component';
import { AdminComponent } from './admin/admin.component';

import { AuthIndexComponent } from './auth/auth-index/auth-index.component';
import { AuthLoginComponent } from './auth/auth-login/auth-login.component';
import { AuthComponent } from './auth/auth.component';

import { isExporterGuard } from './_common/guards/is-exporter/is-exporter.guard';
import { isImporterGuard } from './_common/guards/is-importer/is-importer.guard';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NewStockOfferComponent } from './new-stock-offer/new-stock-offer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ReviewQuotaComponent } from './update-stock-quota/send-quota-file/review-quota.component';
import { SendQuotaFileComponent } from './update-stock-quota/send-quota-file/send-quota-file.component';
import { ReviewStockComponent } from './update-stock-quota/send-stock-file/review-stock.component';
import { SendStockFileComponent } from './update-stock-quota/send-stock-file/send-stock-file.component';
import { UpdateStockQuotaComponent } from './update-stock-quota/update-stock-quota.component';

export const routes: Routes = [
	{
		path: SITEMAP.main.path,
		component: MainComponent,
		children: [
			{
				path: '',
				component: MainIndexComponent,
			},
			{
				path: SITEMAP.account.path,
				canActivate: [isAuthenticatedGuard],
				component: MainAccountComponent,
			},
			{
				path: SITEMAP.dashboard.path,
				canActivate: [isAuthenticatedGuard],
				component: MainDashboardComponent,
			},
			{
				path: SITEMAP.stockOffer.path,
				canActivate: [isAuthenticatedGuard, isExporterGuard],
				component: MainStockOfferComponent,
			},
			{
				path: `${SITEMAP.package.path}/:cip`,
				canActivate: [isAuthenticatedGuard, isImporterGuard],
				component: MainPackageComponent,
			},
			{
				path: `${SITEMAP.request.path}/:id`,
				redirectTo: `${SITEMAP.request.path}/:id/waiting`,
			},
			{
				path: `${SITEMAP.request.path}/:id/:tab`,
				canActivate: [isAuthenticatedGuard],
				component: MainRequestComponent,
			},
		],
	},

	{
		path: SITEMAP.newRequest.path,
		canActivate: [isAuthenticatedGuard],
		component: NewRequestComponent,
	},

	{
		path: SITEMAP.newStockOffer.path,
		canActivate: [isAuthenticatedGuard, isExporterGuard],
		component: NewStockOfferComponent,
	},

	{
		path: SITEMAP.updateStockQuota.path,
		canActivate: [isAuthenticatedGuard, isExporterGuard],
		children: [
			{
				path: '',
				component: UpdateStockQuotaComponent,
			},
			{
				path: 'stock/upload',
				component: SendStockFileComponent,
			},
			{
				path: 'quota/upload',
				component: SendQuotaFileComponent,
			},
			{
				path: 'stock/review',
				component: ReviewStockComponent,
			},
			{
				path: 'quota/review',
				component: ReviewQuotaComponent,
			},
		],
	},

	{
		path: SITEMAP.admin.path,
		canActivate: [isAdminGuard],
		component: AdminComponent,
		children: [{ path: '', component: AdminIndexComponent }],
	},

	{
		path: SITEMAP.auth.path,
		canActivate: [isNotAuthenticatedGuard],
		component: AuthComponent,
		children: [
			{ path: '', component: AuthIndexComponent },
			{ path: SITEMAP.login.path, component: AuthLoginComponent },
		],
	},

	{ path: SITEMAP.maintenance.path, component: MaintenanceComponent },
	{ path: SITEMAP.forbidden.path, component: ForbiddenComponent },
	{ path: SITEMAP.unauthorized.path, component: UnauthorizedComponent },
	{ path: '**', component: NotFoundComponent },
];
