import { Component, model } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'app-mhp-switch',
	standalone: true,
	imports: [FormsModule],
	styleUrl: './mhp-switch.component.scss',
	templateUrl: './mhp-switch.component.html',
})
export class MhpSwitchComponent {
	public readonly value = model<boolean>(false);
}
