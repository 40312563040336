import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-mhp-svg-icon',
	imports: [CommonModule, MatIconModule],
	host: {
		'[style.height.px]': 'size()',
	},
	templateUrl: './mhp-svg-icon.component.html',
})
export class MhpSvgIconComponent {
	public readonly class = input<string>();
	public readonly icon = input.required<MhpSvgIconType>();
	public readonly size = input<number>(24);
	public readonly sizepx = computed<string>(() => this.size() + 'px');
}

export const MHP_SVG_ICON_TYPES = [
	'close',
	'jar-of-pills',
	'from-to',
	'spinner',
	'pill',
	'void-circle',
	'file',
	'search',
	'cardboard-box',
	'pie-chart',
	'empty-chat',
	'empty-request',
	'empty-offer',
	'empty-product',
] as const;

export type MhpSvgIconType = (typeof MHP_SVG_ICON_TYPES)[number];
