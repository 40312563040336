import { Component, EventEmitter, HostListener, inject, InjectionToken, Input, output, Output } from '@angular/core';

import { MhpSvgIconComponent } from "../../../_common/components/mhp-svg-icon/mhp-svg-icon.component";
import { MhpCompanyComponent } from "../../../_common/components/mhp-company/mhp-company.component";
import { MhpInputComponent } from "../../../_common/components/mhp-input/mhp-input.component";
import { MhpButtonComponent } from "../../../_common/components/mhp-button/mhp-button.component";
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import { Company } from 'src/app/_common/models/company.model';
import { CommonModule } from '@angular/common';
import { ImporterRequestItem } from 'src/app/_common/models/importer-request-item.model';
import { ToCipPipe } from "../../../_common/pipes/to-cip/to-cip.pipe";
import { Package } from 'src/app/_common/models/package.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { OfferStatusEnum } from 'src/app/_common/enums/offer-status.enum';
import { MhpLoaderComponent } from "../../../_common/components/mhp-loader/mhp-loader.component";

@Component({
	selector: 'app-main-request-accept-decline-offer-modal',
	standalone: true,
	imports: [
    CommonModule,
    MhpCompanyComponent,
    MhpInputComponent,
    MhpSvgIconComponent,
    MhpButtonComponent,
    MhpLoaderComponent
],
	templateUrl: './main-request-accept-decline-offer-modal.component.html'
})
export class MainRequestAcceptDeclineOfferModalComponent {

	private _apiOffer = inject(ApiOfferService);
	private _account = inject(AccountService);

	@Input() public exporter!: Company;
	@Input() public item!: ImporterRequestItem;
	@Input() public offer!: Offer;
	@Input() public package!: Package;
	@Input() public type!: OfferResponseTypeEnum;

	@Output() public stateClose: EventEmitter<boolean> = new EventEmitter();

	public onSubmit = output({ alias: 'submit' });

	public clickedInside: boolean = false;
	public isLoading: boolean = false;
	public offerResponseTypes = OfferResponseTypeEnum;
	public user: User | null = null;

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public submit(): void {
		this.isLoading = true;

		this._apiOffer.sendResponseOffer(this.offer.id, {
			status: OfferStatusEnum.Review,
			exporterResponse: {
				type: this.user?.function === UserFunctionEnum.Exporter ? this.type : OfferResponseTypeEnum.None,
				respondedAt: new Date().toISOString()
			},
			importerResponse: {
				type: this.user?.function === UserFunctionEnum.Importer ? this.type : OfferResponseTypeEnum.None,
				respondedAt: new Date().toISOString()
			},
		})
			.then((response) => {
				if(response.ok)
					this.close();
			})
			.catch((err: any) => console.error(err))
			.finally(() => this.isLoading = false);
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if(e.keyCode === 27)
			this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if(!this.clickedInside)
			this.stateClose.emit(true);

		this.clickedInside = false;
	}

}
