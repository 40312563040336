import { CommonModule } from '@angular/common';
import { Component, effect, input, output, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { debounceTime, skip, takeUntil } from 'rxjs';
import { MhpBaseComponent } from 'src/app/_common/components/mhp-base/mhp-base/mhp-base.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';

@Component({
	selector: 'app-main-export-catalog-search',
	standalone: true,
	imports: [CommonModule, FormsModule, MhpInputComponent],
	templateUrl: './main-export-catalog-search.component.html',
})
export class MainExportCatalogSearchComponent extends MhpBaseComponent {
	initialSearch = input<string>('');
	searchChange = output<string>();

	/**
	 * The product name to search for.
	 * @description This is not a model, because it is necessary to emit the output only
	 *  after a debounce time and if the product name has at least 4 characters.
	 */
	productName = signal<string>('');

	private _hasPreviousSearch = false;

	constructor() {
		super();

		effect(() => {
			const initialSearch = this.initialSearch();
			this.productName.set(initialSearch);
			this.setHasPreviousSearch(initialSearch);
		});

		toObservable(this.productName)
			.pipe(
				takeUntil(this.subscription$),
				skip(1), // skip initialization
				debounceTime(300)
			)
			.subscribe(value => this.onSearchChange(value));
	}

	onSearchChange(value: string): void {
		value = value?.trim() ?? '';

		// If no previous search, wait at least 4 characters before emitting the search
		if (this._hasPreviousSearch || value.length >= 4) {
			this.setHasPreviousSearch(value);
			this.searchChange.emit(value);
		}
	}

	private setHasPreviousSearch(search: string): void {
		this._hasPreviousSearch = !!search;
	}
}
