import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MainHeaderComponent } from './main-header/main-header.component';

@Component({
	selector: 'app-main',
	imports: [RouterModule, MainHeaderComponent],
	templateUrl: './main.component.html',
})
export class MainComponent {}
