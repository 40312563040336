import { Component, inject, Signal } from '@angular/core';
import { MhpSvgLogoComponent } from "../../_common/components/mhp-svg-logo/mhp-svg-logo.component";
import { MhpButtonComponent } from "../../_common/components/mhp-button/mhp-button.component";

@Component({
	selector: 'app-main-index',
	standalone: true,
  imports: [
    MhpSvgLogoComponent,
    MhpButtonComponent,

  ],
  styleUrl: './main-index.component.scss',
	templateUrl: './main-index.component.html',
})
export class MainIndexComponent {

}
