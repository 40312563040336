<div class="mhp-main-background">
  <div class="mhp-main-background-dark">
    <div class="mhp-main-background-dark-shape-1" aria-hidden="true">
      <div class="mhp-main-background-dark-shape-inner"></div>
    </div>
    <div class="mhp-main-background-dark-shape-2" aria-hidden="true">
      <div class="mhp-main-background-dark-shape-inner"></div>
    </div>
  </div>

  <div class="mhp-main-background-gradient"></div>
</div>

<router-outlet></router-outlet>
