<div class="flex flex-col justify-center items-center pt-[15vh] w-full">

	<button
		class="fixed px-4 py-3 m-8 left-0 top-0 rounded-xl border"
		(click)="this.reset()"
		[routerLink]="[ this.sitemap.dashboard.route ]">Cancel</button>


	<div class="fixed m-8 z-10 right-0 top-0 flex flex-row space-x-4">
		<!-- <button
			class="px-4 py-3 rounded-xl bg-gray-100 hover:bg-gray-200 dark:bg-gray-900 dark:hover:bg-gray-950"
		>Save draft</button> -->

		@if (this.canContinue()) {
		<button
			class="px-4 py-3 rounded-xl text-white bg-blue-500 hover:bg-blue-600"
			(click)="step === 4 ? this.submit() : this.nextStep()">
			@if (step === 4) { Send order }
			@else { Continue }
		</button>
		}
	</div>

	@switch (this.step) {
	@case (0) {
	<div class="flex flex-col justify-center">
		<h2 class="text-2xl text-center font-semibold mb-8">What would you like to request?</h2>

		<div class="flex flex-col space-y-4">
			<mhp-suggestions-card
				icon="file"
				iconBg="bg-[#54c7e320]"
				title="Purchase Order"
				text="You define a list of products, an exporter and commit to an order."
				(click)="this.updateRequestType(requestTypes.PurchaseOrder)" />

			<mhp-suggestions-card
				[disabled]="true"
				icon="search"
				iconBg="bg-[#3971ff20]"
				title="Request Offer"
				text="Coming soon..."
				(click)="this.updateRequestType(requestTypes.RequestOffer)" />
			<!-- text="You define a list of products and ask exporters to tell you the price before you decide to buy." -->


			<!-- <app-new-request-button
						title="Price request"
						text="You define a list of products and ask exporters to tell you the price before you decide to buy. "
						(click)="this.nextStep()"
					/> -->
		</div>
	</div>
	}

	@case (1) {
	<div class="flex flex-col justify-center min-w-[30rem]">
		<h2 class="text-2xl text-center font-semibold mb-8">Import your list of products</h2>
		<app-mhp-file-uploader [acceptedFiles]="['.csv']" (fileChange)="this.processingFiles($event)" />
	</div>

	<!-- Link to the template file -->
	<div class="flex justify-center items-center mt-4">
		<a [href]="purchaseOrderTemplateCsvPath" class="hover:underline">Download our template</a>
	</div>
	}

	@case (2) {
	<div class="flex flex-col justify-center min-w-[30rem] pb-12 px-12">
		<h2 class="text-2xl text-center font-semibold mb-8">Confirm your products list</h2>

		<div class="flex flex-col w-full">
			<span class="mb-6 text-lg font-semibold">We have identified {{ this.packageItems().length }} products</span>

			<div class="table-container">
				<table class="min-w-[80vw] w-full text-sm">
					<thead>
						<tr>
							<th>#</th>
							<th>Product</th>
							<th>Internal code</th>
							<th>Batch</th>
							<th>Min. expiration</th>
							<th>Quantity</th>
							<th>Multiple</th>
							<th>Min. quantity</th>
							<th>Unit cost</th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						@for (packageItem of this.packageItems(); track $index) {
						<tr>

							<!-- Index -->
							<td class="p-2 pl-6">{{ $index + 1 }}</td>

							<!-- Product name -->
							<td class="p-2">
								<app-mhp-package
									[package]="{ cip13: packageItem.package.cip13, shortName: packageItem.package.shortName}" />
							</td>

							<!-- Internal code -->
							<td class="p-2">
								<app-mhp-input type="text" [(model)]="packageItem.package.companyInternalCode" />
							</td>

							<!-- Batch number	-->
							<td class="p-2 text-center">
								<app-mhp-input type="text"
									[(model)]="packageItem.batchNumber" />
							</td>

							<!-- Min. expiration date -->
							<td class="p-2 text-center">
								<app-mhp-input type="text"
									[(model)]="packageItem.minExpiration" />
							</td>

							<!-- Quantity -->
							<td class="p-2 text-center">
								<app-mhp-input type="number"
									[model]="packageItem.quantity.toString()"
									(modelChange)="packageItem.quantity = $event ? +$event : 0" />
							</td>

							<!-- Multiple -->
							<td class="p-2 text-center">
								<app-mhp-input type="number"
									[model]="packageItem.multiple?.toString()"
									(modelChange)="packageItem.multiple = $event ? +$event : undefined" />
							</td>

							<!-- Min. quantity -->
							<td class="p-2 text-center">
								<app-mhp-input type="number"
									[model]="packageItem.minQuantity?.toString()"
									(modelChange)="packageItem.minQuantity = $event ? +$event : undefined" />
							</td>

							<!-- Unit cost -->
							<td class="p-2 text-center">
								<app-mhp-input type="number"
									[model]="packageItem.price?.toString()"
									(modelChange)="packageItem.price = $event ? +$event : undefined" />
							</td>

							<!-- Delete button -->
							<td class="p-2" class="actions">
								<button name="delete" title="Remove from list" (click)="this.deleteItem($index)">
									<ng-icon name="saxTrashOutline" />
								</button>
							</td>
						</tr>
						}

						@empty {
						<tr>
							<td class="p-2">Empty</td>
						</tr>
						}

					</tbody>
				</table>
			</div>
		</div>
	</div>
	}

	@case (3) {
	<div class="flex flex-col justify-center max-w-[30rem] pb-12">
		<h2 class="text-2xl text-center font-semibold mb-8">To whom would you like to send this purchase order?</h2>

		<!-- FIXME: Update to support multiple exporters -->
		@let exporter = this.exporter;
		<app-mhp-company-selector
			description="You have already made requests to these exporters"
			[multipleSelect]="false"
			[selected]="exporter ? [exporter] : []"
			(selectedChange)="this.updateExporter($event[0])" />
	</div>
	}

	@case (4) {
	<app-new-request-confirm
		[exporter]="this.exporter!" [packageItems]="this.packageItems()" [(userRequestId)]="this.userRequestId"
		[(expiryDate)]="this.expiryDate"
		[(minOrderPrice)]="this.minOrderPrice"
		[(deliveryTerms)]="this.deliveryTerms"
		[(paymentTerms)]="this.paymentTerms"
		(viewProductList)="this.setStep(2)"></app-new-request-confirm>
	}
	}


</div>