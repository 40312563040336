import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Company } from 'src/app/_common/models/company.model';

import { DetailedCompany } from 'src/app/_common/models/detailed-company.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiCompanyService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly COMPANY_ROUTE: string = 'company';

	constructor() {}

	public getAll(): Promise<Company[]> {
		return firstValueFrom(this.http.get<Company[]>(`${this.API_URL}/${this.COMPANY_ROUTE}/`));
	}

	public getById(id: number): Promise<DetailedCompany> {
		return firstValueFrom(this.http.get<DetailedCompany>(`${this.API_URL}/${this.COMPANY_ROUTE}/${id}`));
	}
}
