@if (this.package()) {
  <a
    class="flex flex-row items-center p-3 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
    [routerLink]="[ this.sitemap.package.route, this.package().cip13 ]"
  >
    <div class="mr-3 p-3 shrink-0 border rounded-full border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
      <app-mhp-svg-icon icon="pill" />
    </div>
    <div class="w-full">
      <h4 class="text-sm">{{ this.package().cip13 | toCip : 13 }}</h4>
      <h3 class="text-gray-900 dark:text-white">{{ this.package().shortName }}</h3>
    </div>
    <div class="shrink-0 flex justify-center items-center p-2 bg-gray-50 dark:bg-gray-900 rounded-md">
      <ng-icon class="text-gray-900 dark:text-white" name="saxArrowRight1Outline"></ng-icon>
    </div>
  </a>
}