import { Directive, HostListener, input, output } from '@angular/core';

@Directive({
	selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective {
	/** threshold in % when to emit before page end scroll */
	threshold = input(90);

	nearEnd = output();

	private _isNearEnd = false;

	@HostListener('window:scroll', ['$event.target'])
	onScroll(target: any) {
		const scrollHeight = target.scrollingElement?.scrollHeight ?? window.document.documentElement.scrollHeight;
		const scrollPercent = (window.scrollY / (scrollHeight - window.innerHeight)) * 100;
		if (scrollPercent > this.threshold()) {
			if (!this._isNearEnd) {
				this.nearEnd.emit();
				this._isNearEnd = true;
			}
		} else {
			this._isNearEnd = false;
		}
	}
}
