import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiAuthService {

	private API_URL: string = environment.API_URL;
	private AUTH_ROUTE: string = 'auth';

	constructor() {}

	public getLoginProvider(
		provider: "Microsoft" | "Google",
		redirectUri: string = window.location.origin
	): void {
		window.location.href = `${this.API_URL}/${this.AUTH_ROUTE}/login/${provider}?redirectUri=${encodeURIComponent(redirectUri)}`;
	}
}
