import { Component, HostListener, inject, input, model, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from 'src/app/_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { Company } from 'src/app/_common/models/company.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { Package } from '../../../_common/models/package.model';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { ApiImporterService } from 'src/app/_common/services/api/api-importer/api-importer.service';
import { MhpLoaderComponent } from '../../../_common/components/mhp-loader/mhp-loader.component';
import { Offer } from '../../../_common/models/offer.model';
import { PackageItem } from 'src/app/_common/models/package-item.model';

@Component({
	selector: 'app-main-package-request-product-modal',
	standalone: true,
	imports: [
		RouterModule,
		MhpButtonComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpSvgIconComponent,
		MhpLoaderComponent,
		ToCipPipe,
	],
	styleUrl: './main-package-request-product-modal.component.scss',
	templateUrl: './main-package-request-product-modal.component.html',
})
export class MainPackageRequestProductModalComponent implements OnInit {
	private readonly _accountSvc = inject(AccountService);
	private readonly _apiImporterSvc = inject(ApiImporterService);

	public readonly sitemap = SITEMAP;
	public readonly requestTypes = RequestTypeEnum;

	public readonly product = input.required<Package>();
	public readonly request = model<Offer>();
	public readonly exporter = input.required<Company>();
	public readonly stock = input.required<ExporterStockEntry>();
	public readonly isOpen = model(false);
	public readonly price = model<string>('');
	public readonly quantity = model<string>('');
	public readonly quota = model<string>('');
	public readonly requestId = model<string | null>(null);
	public readonly user = signal<User | null>(this._accountSvc.user());

	public clickedInside: boolean = false;
	public isLoading: boolean = false;
	public step: number = 1;

	constructor() {}

	public ngOnInit(): void {
		this.price.set(this.stock().price!.toString());
		this.quantity.set(this.stock().quantity!.toString());
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) {
			this.isOpen.set(false);
		}
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) {
			this.isOpen.set(false);
		}

		this.clickedInside = false;
	}

	public submit(): void {
		this.isLoading = true;

		const quantityStr = this.quantity();
		const quotaStr = this.quota();
		const price = this.price()!;

		this._apiImporterSvc
			.postRequest({
				type: this.requestTypes.RequestOffer,
				exporters: [this.exporter()],
				items: [
					{
						packageCip13: this.product().cip13!,
						batchNumber: this.stock().batch.id! || null,
						minExpiration: this.stock().batch.expiration,
						quantity: quantityStr ? +quantityStr : null,
						quota: quotaStr ? +quotaStr : null,
						price: price ? +price : null,
						packageName: this.product().shortName! || null,
					} as PackageItem,
				],
			})
			.then((response: HttpResponse<ImporterRequest | null>) => {
				if (response.ok) {
					this.step++;
					this.requestId.set(response.body?.id!);
				}
			})
			.catch(err => console.error(err))
			.finally(() => (this.isLoading = false));
	}
}
