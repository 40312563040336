import { Component, input, model } from '@angular/core';
import { MHPButton } from './mhp-button.interface';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-mhp-button-group',
	standalone: true,
	imports: [
		CommonModule
	],
	styleUrl: './mhp-button-group.component.scss',
	templateUrl: './mhp-button-group.component.html'
})
export class MhpButtonGroupComponent {

	public buttons = input<MHPButton[]>([]);
	public choice = model<MHPButton>();

	public choose(button: MHPButton): void {
		this.choice.set(button);
	}

}
