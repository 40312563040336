import { I18nPluralPipe, registerLocaleData } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withFetch,
	withInterceptors,
	withInterceptorsFromDi,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ApplicationConfig, inject, LOCALE_ID, provideAppInitializer, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideNgIconsConfig, withExceptionLogger } from '@ng-icons/core';

import { CredentialsInterceptor } from './_common/interceptors/credentials.interceptor';
import { LoadingInterceptor } from './_common/interceptors/loading.interceptor';
import { CompanyService } from './_common/services/company/company.service';
import { MedEventService } from './_common/services/medevent/medevent.service';
import { routes } from './app.routes';

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
	providers: [
		provideHttpClient(withFetch(), withInterceptors([CredentialsInterceptor]), withInterceptorsFromDi()),
		provideNgIconsConfig({}, withExceptionLogger()),
		provideRouter(routes),
		provideZoneChangeDetection({ eventCoalescing: true }),
		{ provide: LOCALE_ID, useValue: navigator.language },
		provideAnimationsAsync(),
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
		provideAppInitializer(() => {
			// theses services need to be created at startup to be ready ta make actions when the user is authenticated
			inject(MedEventService);
			inject(CompanyService);
		}),
		I18nPluralPipe
	],
};
