import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ExporterStock } from 'src/app/_common/models/exporter-stock.model';
import { firstValueFrom } from 'rxjs';
import { ExporterStockEntry } from "../../../models/exporter-stock-entry.model";
import { ExporterManifestEntry } from "../../../models/exporter-manifest-entry.model";

@Injectable({
	providedIn: 'root'
})
export class ApiExporterService {

	private API_URL: string = environment.API_URL;
	private EXPORTER_ROUTE: string = 'exporter';

	constructor(private http: HttpClient) {}

	public getById(exporterId: number): Promise<HttpResponse<ExporterStock | null>> {
		return firstValueFrom(this.http.get<ExporterStock | null>(
			`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}`,
			{ observe: 'response' }
		));
	}

	// [TODO]: Make the DTO
	public postStock(dto: any): Promise<HttpResponse<ExporterManifestEntry[] | null>> {
		return firstValueFrom(this.http.post<ExporterManifestEntry[] | null>(
			`${this.API_URL}/${this.EXPORTER_ROUTE}/stock`,
			dto,
			{ observe: 'response' }
		));
	}

	public getAllManifests(exporterId: number): Promise<HttpResponse<string[] | null>> {
		return firstValueFrom(this.http.get<string[] | null>(
			`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}/manifest`,
			{ observe: 'response' }
		));
	}

	public getManifestById(exporterId: number, manifestId: number): Promise<HttpResponse<ExporterStock | null>> {
		return firstValueFrom(this.http.get<ExporterStock | null>(
			`${this.API_URL}/${this.EXPORTER_ROUTE}/${exporterId}/manifest/${manifestId}`,
			{ observe: 'response' }
		));
	}
}
