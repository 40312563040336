import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { toDictionarySet } from 'src/app/_common/utils/toDictionary';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { OffersGroupForItemComponent } from '../offers-group-for-item/offers-group-for-item.component';
import { OffersGroupComponent } from '../offers-group/offers-group.component';

/**
 * Component to display all the offers waiting for the user to take action on them.
 */
@Component({
	selector: 'app-offers-waiting',
	templateUrl: './offers-waiting.component.html',
	standalone: true,
	imports: [CommonModule, OffersGroupComponent, OffersGroupForItemComponent, MhpSvgIconComponent],
})
export class OffersWaitingComponent {
	private readonly _accountService = inject(AccountService);

	readonly offersService = inject(OffersService);

	protected readonly UserFunctionEnum = UserFunctionEnum;

	readonly userFunction = computed<UserFunctionEnum>(() => this._accountService.userFunction());

	readonly Object = Object;

	readonly waitingOffersPerItem = computed<Record<string, Offer[]>>(() =>
		// remark: we use the shortName as key to automatically group offers by item AND so sort them by their name
		toDictionarySet(this.offersService.waitingForActionOffers(), offer => offer.shortName)
	);
}
