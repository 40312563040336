import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';
import { MhpSuggestionsCard } from '../_common/components/mhp-new-request-button/mhp-suggestions-card.component';
import { SITEMAP } from '../_common/sitemap';

@Component({
	selector: 'app-update-stock-quota',
	imports: [MhpButtonComponent, RouterLink, MhpSuggestionsCard],
	templateUrl: './update-stock-quota.component.html',
	styleUrl: './update-stock-quota.component.scss',
})
export class UpdateStockQuotaComponent {
	protected readonly sitemap = SITEMAP;
}
