import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Package } from 'src/app/_common/models/package.model';
import { ExporterStockEntry } from 'src/app/_common/models/exporter-stock-entry.model';
import { PackageBatch } from 'src/app/_common/models/package-batch.model';

@Injectable({
	providedIn: 'root'
})
export class ApiPackageService {

	private API_URL: string = environment.API_URL;
	private PACKAGE_ROUTE: string = 'package';

	constructor(private http: HttpClient) {}

	public getByCIP13(cip13: number): Promise<HttpResponse<Package | null>> {
		return firstValueFrom(this.http.get<Package | null>(
			`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}`,
			{ observe: 'response' }
		));
	}

  public getByCIP13s(cip13s: number[]): Promise<HttpResponse<Package[]>> {
    return firstValueFrom(this.http.post<Package[]>(
      `${this.API_URL}/${this.PACKAGE_ROUTE}`,
      cip13s,
      { observe: 'response' }
    ));
  }

	public getByCIP7(cip7: number): Promise<HttpResponse<Package | null>> {
		return firstValueFrom(this.http.get<Package | null>(
			`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip7}`,
			{ observe: 'response' }
		));
	}

	public getByCIP13AndBatch(cip13: number, batch: string): Promise<HttpResponse<PackageBatch | null>> {
		return firstValueFrom(this.http.get<PackageBatch | null>(
			`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}/batch/${batch}`,
			{ observe: 'response' }
		));
	}

	public getByCIP13WithStocks(cip13: number): Promise<HttpResponse<ExporterStockEntry[] | null>> {
		return firstValueFrom(this.http.get<ExporterStockEntry[] | null>(
			`${this.API_URL}/${this.PACKAGE_ROUTE}/${cip13}/stocks`,
			{ observe: 'response' }
		));
	}

}
