@if (this.user(); as user) {
  <div class="relative overflow-x-auto border border-gray-200 dark:border-gray-700 rounded-2xl">
    <table class="w-full text-sm text-left rtl:text-right text-gray-900 bg-white dark:bg-gray-800 dark:text-white">
      <thead class="text-xs text-gray-500">
        <tr>
          <th class="py-4 pl-4 font-medium">Reference</th>
          <th class="py-4 pl-4 font-medium">Status</th>
          <th class="py-4 pl-4 font-medium">Type</th>
          <th class="py-4 pl-4 font-medium">
            @switch (user.function) {
              @case (this.userFunctions.Exporter) { Issuer }
              @case (this.userFunctions.Importer) { Audience }
            }
          </th>
          <th class="py-4 pl-4 font-medium">Created at</th>
          <th class="py-4 pl-4 font-medium">Items</th>
          <!-- <th class="py-4 pl-4 font-medium"></th> -->
        </tr>
      </thead>
      <tbody>
  
        @for (request of this.requests(); track $index) {
          <tr
            class="cursor-pointer border-t w-full font-medium dark:border-gray-700"
            [ngClass]="($index % 2 == 0) ? 'bg-gray-50 dark:bg-[#1c2634]' : 'bg-white dark:bg-gray-800'"
            [routerLink]="[ this.sitemap.request.route, request.id ]"
          >
            <td class="p-4">
              <a [routerLink]="[ this.sitemap.request.route, request.id ]">#{{ request.id.substring(request.id.length - 10).toUpperCase() }}</a>
            </td>
            <td class="p-4">
              <app-mhp-status-label [status]="request.status + 1" />
            </td>
            <td class="p-4">
              <div class="flex flex-row items-center">
                @switch (request.type) {
                  @case (this.requestTypes.RequestOffer) { Request Offer }
                  @case (this.requestTypes.PurchaseOrder) { Purchase Order }
                }
  
                <!-- @if ([0].includes($index)) { <app-mhp-label class="ml-2" label="New" /> } -->
              </div>
            </td>
            <td class="p-4">
              @switch (user.function) {
                @case (this.userFunctions.Exporter) { <app-mhp-companies [companies]="[request.importer]" emptyTemplate="Open" /> }
                @case (this.userFunctions.Importer) { <app-mhp-companies [companies]="request.exporters ?? []" emptyTemplate="Open" /> }
              }
            </td>
            <td class="p-4">{{ request.createdAt | date : 'd MMM y' : '' : "en-US" }}</td>
            <td class="p-4 w-24">{{ request.items?.length }}</td>
            <!-- <td class="p-4 text-right">
              <button>
                <ng-icon name="saxMoreOutline" />
              </button>
            </td> -->
          </tr>
        }
  
      </tbody>
    </table>
  </div>
}
