<img src="{{ companyIcon() }}" class="size-12 rounded-full" alt="Company Icon">

<div class="w-full">
	<h4 class="title">{{ title() }}</h4>

	<div class="inline-flex gap-4 text-sm text-[var(--text-secondary)]">
		<span *ngIf="reference()">#{{ reference() }}</span>
		<span *ngIf="task().expiryDate"><ng-icon name="saxClockOutline" />Until {{ task().expiryDate | date:'dd MMMM y' : ''
			: 'en-US'
			}}</span>
		<span *ngIf="task().paymentTerms"><ng-icon name="saxWallet2Outline" />Net 60</span>
		<span *ngIf="task().deliveryTerms"><ng-icon name="saxTimerOutline" />EXW</span>
	</div>
</div>

<ng-content />