import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxTickCircleBold } from '@ng-icons/iconsax/bold';

import {
	MainRequestAcceptDeclineOfferModalComponent,
	ModalResponseTypeEnum,
} from '../../offer-modals/main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component';
import { OfferActionBase } from '../offer-action-base/offer-action-base';

@Component({
	selector: 'offer-action-accept',
	imports: [NgIconComponent, MainRequestAcceptDeclineOfferModalComponent, CommonModule],
	templateUrl: './offer-action-accept.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		provideIcons({
			saxTickCircleBold,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OfferActionAcceptComponent extends OfferActionBase {
	ModalResponseTypeEnum = ModalResponseTypeEnum;
	confirm = output();
}
