<div class="update-screen">
	<div id="header" class="header">
		<div id="header-left">
			<app-mhp-button text="Back" type="secondary"
				[routerLink]="this.sitemap.updateStockQuota.route +'/quota/upload'" />
		</div>

		<div id="header-right">
			<app-mhp-button text="Confirm the import" type="primary" (click)="uploadQuotas()"
				[hidden]="!this.entries().length" />
		</div>
	</div>

	<div class="main-container">
		<h2>Review my quotas</h2>

		<div class="table-container">
			<table class="quota-editor">
				<thead>
					<tr>
						<th>#</th>
						<th>Product</th>
						<th>Quota</th>
						<th>Actions</th>
					</tr>
				</thead>

				<tbody>
					@for (entry of entries(); track $index; ) {
					<tr>
						<td class="p-4">{{ $index + 1 }}</td>
						<td class="p-4">
							<app-mhp-package [package]="{ cip13: entry.cip13, shortName: entry.name }" />
						</td>
						<td class="text-center">
							<input
								id="package-{{$index}}-expiration"
								type="text"
								[value]="entry.quota"
								[(ngModel)]="entry.quota!" />
						</td>
						<td class="actions">
							<button name="delete" title="Remove from list" (click)="this.deleteItem($index)">
								<ng-icon name="saxTrashOutline" />
							</button>
						</td>
					</tr>
					}
				</tbody>
			</table>
		</div>
	</div>
</div>