<button
	class="flex justify-end shrink-0" (click)="onModalOpen()">
	<app-mhp-button
		class="w-40"
		prefix="saxNote2Outline"
		text="Counter offer"
		type="secondary" />
</button>

@let _company = exporter();
@if (_company && modalVisible) {
<app-main-request-make-offer-modal
	[exporter]="_company"
	[offer]="offer()"
	[item]="item()"
	(onConfirm)="counterOffer($event)"
	(stateClose)="onModalClose()" />
}