import { inject, Injectable, signal } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { SITEMAP } from '../../sitemap';
import { User } from '../../models/user.model';
import { ApiUserService } from '../api/api-user/api-user.service';
import { ApiAuthService } from '../api/api-auth/api-auth.service';
import { CompanyService } from '../company/company.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	private readonly _router = inject(Router);
	private readonly _authSvc = inject(ApiAuthService);
	private readonly _cookieSvc = inject(CookieService);
	private readonly _apiUserSvc = inject(ApiUserService);
	private readonly _companySvc = inject(CompanyService);

	private readonly sitemap = SITEMAP;
	private readonly userKey: string = 'user';

	private readonly _user = signal<User | null>(JSON.parse(sessionStorage.getItem(this.userKey)!));
	public readonly user = this._user.asReadonly();

	public updateAccount(user: User): void {
		this._user.set(user);
		sessionStorage.setItem(this.userKey, JSON.stringify(user));
	}

	public resetAccount(): void {
		this._user.set(null);
		sessionStorage.removeItem(this.userKey);
	}

	public async checkAuth(): Promise<void> {
		try {
			const response: HttpResponse<User> = await this._apiUserSvc.getUser();

			if (response.ok) {
				this.updateAccount(response.body!);
				await this._companySvc.updateCurrentCompany();
			} else {
				this.resetAccount();
			}
		} catch (err: unknown) {
			this.resetAccount();
		}
	}

	/**
	 * @description Sign out the user from the api server
	 */
	public async signOut(): Promise<void> {
		// Sign out the user from the api server
		const signOutResponse: HttpResponse<string> = await this._authSvc.signOut();
		if (signOutResponse.status !== 200) console.error('Sign out failed with status:', signOutResponse.status);

		// reset current logged user from the current service
		this.resetAccount();

		// remove access token from cookies
		this._cookieSvc.delete('.AspNetCore.Cookies');

		// reset the current company from company service
		this._companySvc.resetCompany();

		// navigate to the main page
		this._router.navigate([this.sitemap.main.route]);
	}
}
