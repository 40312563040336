import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';

@Component({
	selector: 'app-auth-index',
	standalone: true,
	imports: [],
	templateUrl: './auth-index.component.html',
})
export class AuthIndexComponent {

	public sitemap: SiteMap = SITEMAP;

	constructor(private router: Router) {

		if(this.router.url === this.sitemap.auth.route)
			this.router.navigate([ this.sitemap.login.route ]);

	}

}
