<div class="update-screen">

	<div id="header" class="header">
		<div id="header-left">
			<app-mhp-button text="Back" type="secondary" [routerLink]="this.sitemap.updateStockQuota.route + '/stock/upload'" />
		</div>

		<div id="header-right">
			<app-mhp-button text="Confirm the import" type="primary" (click)="uploadStocks()" [hidden]="!this.entries().length" />
		</div>
	</div>

	<div class="main-container">
		<h2>Review my stock</h2>

		@if ( countErrors > 0 ) {
			<div class="alert alert-danger">
				<p>There are {{ countErrors }} errors in your file. Only {{validCount}} lines will
					 be imported among a total of {{totalCount()}}.</p>
			</div>
		}

		<div class="table-container">

				<cdk-virtual-scroll-viewport itemSize="15" minBufferPx="100" maxBufferPx="200" class="flex-auto" style="height: 80vh; width: 100%;">

					<table cdk-table [dataSource]="this.entries()">

						<ng-container cdkColumnDef="id">
							<th cdk-header-cell *cdkHeaderCellDef>#</th>
								<td cdk-cell *cdkCellDef="let entry; let $index = index" class="p-4">{{ $index + 1 }}</td>
						</ng-container>

						<ng-container cdkColumnDef="product">
							<th cdk-header-cell *cdkHeaderCellDef>Product</th>
							<td cdk-cell *cdkCellDef="let entry" class="p-4">
								<app-mhp-package [package]="{ cip13: entry.packageCip13, shortName: entry.batch!.name ?? null }" />
							</td>
						</ng-container>

						<ng-container cdkColumnDef="batch">
							<th cdk-header-cell *cdkHeaderCellDef>Batch</th>
							<td cdk-cell *cdkCellDef="let entry" class="text-center">
								{{ entry.batch!.id }}
							</td>
						</ng-container>

						<ng-container cdkColumnDef="expirationDate">
							<th cdk-header-cell *cdkHeaderCellDef>Expiration date</th>
							<td cdk-cell *cdkCellDef="let entry" class="text-center">
								{{ entry.batch!.expiration }}
							</td>
						</ng-container>

						<ng-container cdkColumnDef="quantity">
							<th cdk-header-cell *cdkHeaderCellDef>Quantity</th>
							<td cdk-cell *cdkCellDef="let entry" class="text-center">
								{{ entry.quantity }}
							</td>
						</ng-container>

						<ng-container cdkColumnDef="unitCost">
							<th cdk-header-cell *cdkHeaderCellDef>Unit cost</th>
							<td cdk-cell *cdkCellDef="let entry" class="text-center">
								{{ entry.price }}
							</td>
						</ng-container>

						<ng-container cdkColumnDef="error">
							<th cdk-header-cell *cdkHeaderCellDef></th>
							<td cdk-cell *cdkCellDef="let entry; let i = index" class="text-left">

								<span>
									{{ this.displayError(i) }}
								</span>

							</td>
						</ng-container>

					  <!-- Header and Row Declarations -->
					  <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
					  <tr cdk-row *cdkRowDef="let row; columns: displayedColumns; let i = index" [formGroup]="this.rowForm(i)"></tr>

					</table>
				</cdk-virtual-scroll-viewport>


		</div>
	</div>
</div>
