export interface SiteMapProps { path: string; route: string; }

export interface SiteMap {

	main: SiteMapProps;
	account: SiteMapProps;
	dashboard: SiteMapProps;
	stockOffer: SiteMapProps;
	package: SiteMapProps;
	request: SiteMapProps;
	newRequest: SiteMapProps;

	admin: SiteMapProps;

	auth: SiteMapProps;
	login: SiteMapProps;

	maintenance: SiteMapProps;

	forbidden: SiteMapProps;
	unauthorized: SiteMapProps;

}

export const SITEMAP = {

	main: { path: "", route: "/" },
	account: { path: "account", route: "/account" },
	dashboard: { path: "dashboard", route: "/dashboard" },
	stockOffer: { path: "stock-offer", route: "/stock-offer" },
	package: { path: "product", route: "/product" },
	request: { path: "request", route: "/request" },
	newRequest: { path: "new-request", route: "/new-request" },

	admin: { path: "admin", route: "/admin" },

	auth: { path: "auth", route: "/auth" },
	login: { path: "login", route: "/auth/login" },

	maintenance: { path: "maintenance", route: "/maintenance" },

	forbidden: { path: "forbidden", route: "/forbidden" },
	unauthorized: { path: "unauthorized", route: "/unauthorized" }

} as const;
