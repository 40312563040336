<div class="mhp-company-selector-component">
  <div class="search">
    <div class="search-icon">
      <ng-icon name="saxSearchNormal1Outline" />
    </div>

    <input
      placeholder="Search for a company"
      type="text"
      [(ngModel)]="this.searchField"
      (ngModelChange)="this.search()" />

    @if (this.searchField) {
    <button class="search-icon" (click)="this.searchField = ''; this.search()">
      <ng-icon name="saxCloseCircleBold" />
    </button>
    }
  </div>

  <div class="layout bg-white dark:bg-gray-900/75">
    @if (description() || searchField) {
    <div class="search-results-header">
      @if (this.searchField) { Search results }
      @else { {{ this.description() }} }
    </div>
    }

    <div class="companies-list">
      @for (company of this.companyDisplay(); track $index) {
      <button class="company" [class.selected]="isCompanySelected(company.id)" (click)="this.toggleSelect(company)">
        <app-mhp-company [company]="company" />

        <div class="selection-icon">
          @if (isCompanySelected(company.id)) {
          <ng-icon class="text-blue-600" name="saxTickCircleBold" size="1.75rem" />
          } @else {
          <app-mhp-svg-icon icon="void-circle" />
          }
        </div>
      </button>
      }

      @empty {
      <span>No match results</span>
      }

    </div>
  </div>
</div>