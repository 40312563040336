@if (this.user(); as user) {
@if (this.requests().length) {
<div class="relative table-container">
	<table class="w-full text-left rtl:text-right ">
		<thead class="text-[var(--text-secondary)]">
			<tr>
				<th>Reference</th>
				<th>Status</th>
				<th>Type</th>
				<th>
					@switch (user.function) {
					@case (this.userFunctions.Exporter) { Issuer }
					@case (this.userFunctions.Importer) { Audience }
					}
				</th>
				<th>Created at</th>
				<th>Items</th>
				<!-- <th class="py-4 pl-4 "></th> -->
			</tr>
		</thead>
		<tbody>

			@for (request of this.requests(); track $index) {
			<tr
				class="cursor-pointer border-t w-full"
				[routerLink]="[ this.sitemap.request.route, request.id ]">
				<td class="text-[var(--text-secondary)]">
					<a [routerLink]="[ this.sitemap.request.route, request.id ]">#{{ getUIRequestId( request.id,
						request.userRequestId) }}</a>
				</td>
				<td>
					<app-mhp-status-label [status]="request.status" />
				</td>
				<td>
					<div class="flex flex-row items-center">
						@switch (request.type) {
						@case (this.requestTypes.RequestOffer) { Request Offer }
						@case (this.requestTypes.PurchaseOrder) { Purchase Order }
						}

						<!-- @if ([0].includes($index)) { <app-mhp-label class="ml-2" label="New" /> } -->
					</div>
				</td>
				<td>
					@switch (user.function) {
					@case (this.userFunctions.Exporter) { <app-mhp-companies [companies]="[request.importer]"
						emptyTemplate="Open" /> }
					@case (this.userFunctions.Importer) { <app-mhp-companies [companies]="request.exporters ?? []"
						emptyTemplate="Open" /> }
					}
				</td>
				<td>{{ request.createdAt | date : 'd MMM y' : '' : "en-US" }}</td>
				<td class="p-3 w-24">{{ request.itemsCount }}</td>
				<!-- <td class="p-3 text-right">
								<button>
									<ng-icon name="saxMoreOutline" />
								</button>
							</td> -->
			</tr>
			}

		</tbody>
	</table>
</div>
}

@else {
<div class="flex flex-col justify-center items-center h-[50vh]">
	<app-mhp-svg-icon icon="empty-request" [size]="80" />
	<p class="mt-6 opacity-50">You haven't made any requests yet</p>

	@if ([
	this.userFunctions.Importer,
	this.userFunctions.ImporterExporter
	].includes(user.function)) {
	<app-mhp-button
		class="mt-8"
		prefix="saxAddOutline"
		text="Make a request"
		type="primary"
		[routerLink]="[ this.sitemap.newRequest.route ]" />
	}
</div>
}
}