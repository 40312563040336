import { Component, HostListener, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpCompanyComponent } from '../../../_common/components/mhp-company/mhp-company.component';
import { MhpInputComponent } from '../../../_common/components/mhp-input/mhp-input.component';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import { Company } from 'src/app/_common/models/company.model';
import { ImporterRequestItem } from 'src/app/_common/models/importer-request-item.model';
import { Package } from 'src/app/_common/models/package.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { OfferStatusEnum } from 'src/app/_common/enums/offer-status.enum';
import { MhpLoaderComponent } from '../../../_common/components/mhp-loader/mhp-loader.component';
import { OffersService } from 'src/app/_common/services/offers/offers.service';

@Component({
	selector: 'app-main-request-accept-decline-offer-modal',
	standalone: true,
	imports: [CommonModule, MhpCompanyComponent, MhpSvgIconComponent, MhpButtonComponent, MhpLoaderComponent],
	templateUrl: './main-request-accept-decline-offer-modal.component.html',
})
export class MainRequestAcceptDeclineOfferModalComponent {
	private readonly _apiOfferSvc = inject(ApiOfferService);
	private readonly _offersSvc = inject(OffersService);
	private readonly _accountSvc = inject(AccountService);

	public readonly offerResponseTypes = OfferResponseTypeEnum;

	public readonly exporter = input.required<Company>();
	public readonly item = input.required<ImporterRequestItem>();
	public readonly offer = input.required<Offer>();
	public readonly package = input.required<Package>();
	public readonly type = input.required<OfferResponseTypeEnum>();

	public readonly stateClose = output<boolean>();
	public readonly onSubmit = output({ alias: 'submit' });

	public readonly user = signal<User | null>(this._accountSvc.user());

	public clickedInside: boolean = false;
	public isLoading: boolean = false;

	constructor() {}

	public submit(): void {
		this.isLoading = true;

		this._apiOfferSvc
			.sendResponseOffer(this.offer().id, {
				status: OfferStatusEnum.Review,
				exporterResponse: {
					type: this.user()?.function === UserFunctionEnum.Exporter ? this.type() : OfferResponseTypeEnum.None,
					respondedAt: new Date().toISOString(),
				},
				importerResponse: {
					type: this.user()?.function === UserFunctionEnum.Importer ? this.type() : OfferResponseTypeEnum.None,
					respondedAt: new Date().toISOString(),
				},
			})
			.then(response => {
				if (response.ok) {
					this._offersSvc.refresh();
					this.close();
				}
			})
			.catch((err: any) => console.error(err))
			.finally(() => (this.isLoading = false));
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
