import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { firstValueFrom } from 'rxjs';

import { Package } from 'src/app/_common/models/package.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiSearchService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly SEARCH_ROUTE: string = 'search';

	constructor() {}

	public searchPackages(search: string): Promise<HttpResponse<Package[]>> {
		return firstValueFrom(
			this.http.get<Package[]>(
				`${this.API_URL}/${this.SEARCH_ROUTE}/packages?query=${encodeURIComponent(search)}&page=1&pageSize=100`,
				{ observe: 'response' }
			)
		);
	}
}
