import { Component, inject, signal } from '@angular/core';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpFileUploaderComponent } from '../../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { MhpPackageComponent } from '../../_common/components/mhp-package/mhp-package.component';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiExporterService } from '../../_common/services/api/api-exporter/api-exporter.service';
import { Router, RouterLink } from '@angular/router';
import { TableHandler } from '../../_common/utils/table-handler';
import { SITEMAP } from '../../_common/sitemap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ExporterQuotaEntry } from '../../_common/models/exporter-quota-entry.model';
import { saxTrashOutline } from '@ng-icons/iconsax/outline';
import { QuotaTableService } from 'src/app/_common/services/table-services/table-quota-service';

interface UpdateQuotaProps {
	// TODO: Change step type from number to dedicated enum.
	step: number;
}

@Component({
	selector: 'review-quota',
	imports: [MhpButtonComponent, MhpPackageComponent, NgIcon, ReactiveFormsModule, FormsModule, RouterLink],
	providers: [
		provideIcons({
			saxTrashOutline,
		}),
	],
	templateUrl: './review-quota.component.html',
	styleUrls: ['../update-stock-quota.component.scss'],
})
export class ReviewQuotaComponent {
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _router = inject(Router);
	private readonly _tableSvc = inject(QuotaTableService);
	readonly sitemap = SITEMAP;
	readonly entries = this._tableSvc.items;

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Moves to the previous step.
	 * @remarks Shorthand for `setStep(step - 1)`.
	 */
	async backToUpload(): Promise<void> {
		await this._router.navigate([this.sitemap.newStockOffer + '/quota/upload']);
	}

	/**
	 * Checks if the current cell is valid or not.
	 * @param entry The line to check
	 * @param index The index of the line
	 */
	checkErrorCell(entry: ExporterQuotaEntry, index: number): boolean {
		const entries = [...this.entries()];

		// TODO: Add validation here

		return entries.splice(index, 1).includes(entry);
	}

	/**
	 * Deletes the item at the provided index.
	 * @param index The index of the item to delete
	 */
	deleteItem(index: number): void {
		this.entries().splice(index, 1);
	}

	/**
	 * Submits the stock quotas to the API.
	 */
	async uploadQuotas() {
		const entries = this.entries();

		if (entries.length) {
			const res = await this._apiExporterSvc.putStockQuotas(entries);

			if (res.ok) {
				await this._router.navigate([this.sitemap.stockOffer.route]);
			}
		}
	}
}
