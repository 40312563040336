import { Component, model } from '@angular/core';
import { RouterModule } from '@angular/router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MhpBaseModalComponent } from 'src/app/_common/components/mhp-base-modal/mhp-base-modal.component';

/**
 * Modal component for confirming pre-fill operation of stock data.
 * Features:
 * - Confirmation prompt for importing stock data
 * - Step tracking for multi-step process
 * - ESC key handling for cancellation
 * - Response tracking (None/Canceled/Confirmed)
 */
@Component({
	selector: 'app-main-request-pre-fill-confirm-modal',
	imports: [RouterModule, MhpBaseModalComponent],
	templateUrl: './main-request-pre-fill-confirm-modal.component.html',
})
export class MainRequestPreFillConfirmModalComponent extends MhpBaseModalComponent {
	/** Tracks modal open state */
	public readonly isOpen = model<boolean>();

	/** Tracks modal response state */
	public readonly response = model<PreFillModalResponse>();

	/** Current step in the process */
	public step: number = 0;

	constructor() {
		super();
		dayjs.extend(customParseFormat);
	}

	/** Handle confirmation action */
	public async confirm() {
		this.response.set(PreFillModalResponse.Confirmed);
		this.isOpen.set(false);
	}

	/** Move to next step */
	public nextStep(): void {
		this.step++;
	}

	/** Override base close behavior */
	override handleClose(): void {
		if (this.step !== 1) {
			this.response.set(PreFillModalResponse.Canceled);
			this.isOpen.set(false);
			this.stateClose.emit(true);
			this.close.emit();
		}
	}

	/** Override base ESC key handling */
	override triggerClosing(e: any): void {
		if (e.keyCode === 27 && this.step !== 1) {
			this.handleClose();
		}
	}
}

/** Possible modal response states */
export enum PreFillModalResponse {
	None,
	Canceled,
	Confirmed,
}
