import { CommonModule } from '@angular/common';
import { Component, computed, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsOutline, saxSearchNormal1Outline } from '@ng-icons/iconsax/outline';

import { Company } from '../../models/company.model';
import { MhpCompanyComponent } from "../mhp-company/mhp-company.component";
import { MhpSvgIconComponent } from "../mhp-svg-icon/mhp-svg-icon.component";
import { saxCloseCircleBold } from '@ng-icons/iconsax/bold';

@Component({
	selector: 'app-mhp-exporter-selector',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		NgIconComponent,
		MhpCompanyComponent,
		MhpSvgIconComponent
	],
	providers: [
		provideIcons({
			saxBuildingsOutline,
			saxCloseCircleBold,
			saxSearchNormal1Outline
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	styleUrl: './mhp-exporter-selector.component.scss',
	templateUrl: './mhp-exporter-selector.component.html'
})
export class MhpExporterSelectorComponent {

	public exporters = input<Company[]>([]);
	public description = input<string>('');
	public selected = input<Company | null>(null);

	public exporterSelected = output<Company>();

	public searchField: string | null = null;
	public exporterSearchResult = signal<Company[] | null>(null);
	public exporterDisplay = computed(() => this.exporterSearchResult() || this.exporters());

	public select(exporter: Company): void {
		this.selected.apply(exporter);
		this.exporterSelected.emit(exporter);
	}

	public search(): void {
		this.exporterSearchResult.set(
			this.exporters()
				.filter((item) => item.displayName!.toLowerCase().includes(this.searchField!.toLowerCase()))
		);
	}

}
