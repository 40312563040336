import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { saxTrashOutline } from '@ng-icons/iconsax/outline';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
} from '@angular/forms';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { NgIcon, provideIcons } from '@ng-icons/core';

import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { SITEMAP } from '../../_common/sitemap';
import { ExporterStockEntry } from '../../_common/models/exporter-stock-entry.model';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StockTableService } from 'src/app/_common/services/table-services/table-stock-service';
import { MhpPackageComponent } from 'src/app/_common/components/mhp-package/mhp-package.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { TableHandler } from '../../_common/utils/table-handler';
import { ExporterManifestEntry } from '../../_common/models/exporter-manifest-entry.model';
import { ToastService } from 'src/app/_common/services/toast/toast.service';

interface UpdateStockProps {
	step: number;
}

@Component({
	selector: 'review-stock.',
	imports: [
		CommonModule,
		CdkTableModule,
		MhpButtonComponent,
		ReactiveFormsModule,
		MhpPackageComponent,
		FormsModule,
		ReactiveFormsModule,
		ScrollingModule,
		RouterLink,
	],
	providers: [
		provideIcons({
			saxTrashOutline,
		}),
	],
	templateUrl: './review-stock.component.html',
	styleUrls: ['../update-stock-quota.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewStockComponent implements OnInit {
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _toastSvc = inject(ToastService);
	private readonly _router = inject(Router);
	readonly _tableSvc = inject(StockTableService);
	readonly _fb = inject(FormBuilder);
	readonly sitemap = SITEMAP;
	readonly entries = this._tableSvc.items;

	totalCount = computed(() => this._tableSvc.items().length);

	constructor() {
		dayjs.extend(customParseFormat);
	}

	ngOnInit(): void {
		// Create a form group for each entry
		this.formGroupArray = this._fb.array(
			this.entries().map(entry =>
				this._fb.group({
					stockEntry: [
						entry,
						[
							(control: AbstractControl): ValidationErrors | null => {
								const validationErrors: ValidationErrors = {};
								const entry = control.value;

								// validator for CIP 13
								if (!this._tableSvc.validCip13s()?.some(cip13 => cip13 === entry.packageCip13))
									validationErrors['invalidCip13'] = entry.packageCip13;

								// validator for expiration date
								if (!dayjs(entry.batch!.expiration, TableHandler.dateParsingFormats, true).isValid())
									validationErrors['invalidExpirationDate'] = entry.batch!.expiration;

								// validator for quantity
								if (entry.quantity <= 0 || entry.quantity % 1 != 0) validationErrors['quantity'] = entry.quantity;
								return validationErrors;
							},
						],
					],
				})
			)
		);

		this.countErrors = this.formGroupArray.controls.filter(control => control.invalid).length;
		this.validCount = this.entries().length - this.countErrors;
	}

	items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);

	displayedColumns: string[] = ['id', 'product', 'batch', 'expirationDate', 'quantity', 'unitCost', 'error'];

	formGroupArray!: FormArray;

	checkErrorCell(entry: ExporterStockEntry, index: number): boolean {
		const entries = [...this.entries()];
		return entries.splice(index, 1).includes(entry);
	}

	deleteItem(index: number): void {
		this.entries().splice(index, 1);
	}

	rowForm(i: number): FormGroup {
		const formGroups = this.formGroupArray.controls as FormGroup[];
		const formGroup = formGroups[i];

		if (!formGroup) throw new Error(`No form group found at index ${i}`);

		return formGroup;
	}

	countErrors: number = 0;
	validCount: number = 0;

	isInvalid(i: number): boolean {
		const controlResult = this.rowForm(i).get('stockEntry');
		if (!controlResult) throw new Error(`No control found at index ${i}`);

		return controlResult.invalid;
	}

	displayError(i: number): string {
		const controlResult = this.rowForm(i).get('stockEntry');
		if (!controlResult) throw new Error(`No control found at index ${i}`);
		if (!controlResult.errors) return '';

		if (controlResult.errors['invalidCip13']) {
			return `CIP13 ${controlResult.errors['invalidCip13']} inconnu`;
		}
		if (controlResult.errors['invalidExpirationDate']) {
			return `Date d'expiration ${controlResult.errors['invalidExpirationDate']} non valide`;
		}
		if (controlResult.errors['quantity']) {
			return `Quantité ${controlResult.errors['quantity']} non valide`;
		}
		return '';
	}

	/**
	 * Submits the stock quotas to the API.
	 */
	async uploadStocks() {
		const validEntries = this.formGroupArray.controls
			.filter(control => control.valid)
			.map(control => {
				let e = <ExporterManifestEntry>control.value['stockEntry'];
				e.batch.expiration = dayjs(e.batch.expiration, TableHandler.dateParsingFormats, true).format('YYYY-MM-DD');
				return e;
			});

		if (validEntries.length) {
			const res = await this._apiExporterSvc.postStock(validEntries);

			if (res.ok) {
				this._toastSvc.success('Your stock has been imported.');
				await this._router.navigate([this.sitemap.catalog.route]);
			}
		}
	}

	private onDestroy$ = new Subject<void>();

	ngOnDestroy(): void {
		this.formGroupArray.clear();
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
