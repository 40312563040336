<button
  class="flex justify-end text-blue-600 hover:text-blue-700"
  (click)="onModalOpen()">
  <ng-icon name="saxTickCircleBold" size="1.75rem" />
</button>

@if (modalVisible) {
<app-main-request-accept-decline-offer-modal
  [offer]="offer()"
  [type]="ModalResponseTypeEnum.Accepted"
  (onConfirm)="confirm.emit()"
  (stateClose)="onModalClose()" />
}