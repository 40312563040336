import { Component, input } from '@angular/core';

@Component({
	selector: 'app-mhp-progressbar',
	imports: [],
	styleUrl: './mhp-progressbar.component.scss',
	templateUrl: './mhp-progressbar.component.html',
})
export class MhpProgressbarComponent {
	public readonly progress = input<number>(0);
	public readonly showStats = input<boolean>(false);
}
