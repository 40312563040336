<offers-group-all-items
	title="Under review"
	description="Offers are under review and discussions may follow."
	[offers]="underReviewOffers()"
/>

<offers-group-all-items
	title="Accepted"
	[allowEdit]="isExporter()"
	description="Offers confirmed by both parties."
	[offers]="confirmedOffers()"
/>

<offers-group-all-items
	title="Rejected"
	description="Offers rejected."
	[offers]="declinedOffers()"
/>
