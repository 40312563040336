<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
	<div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

	<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
		<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
			<div
				class="p-6 relative flex flex-col items-start space-y-8 transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
				(click)="this.clickedInside = true"
			>
				<div class="flex flex-col items-start">
					<button
						class="flex flex-row mb-6 justify-center item-center"
						(click)="this.close()"
					>
						<app-mhp-svg-icon class="dark:stroke-white" icon="close" />
					</button>
	
					<div class="flex flex-col space-y-4">
						<h3 class="text-xl font-semibold text-gray-900 dark:text-white">Do you confirm that you want to book all these products?</h3>
						<p class="text-gray-600">By reserving a product, you undertake to order it as soon as you are ready.</p>
					</div>
				</div>

				@if (this.isLoading) {
					<div class="flex flex-col justify-center items-center w-full">
						<app-mhp-loader />
					</div>
				}

				@else {
					<app-mhp-from-to [from]="this.user()!" [to]="this.exporter()" />

					<div class="flex flex-col w-full space-y-6">
						<div class="flex flex-col">
							<span class="mb-4 text-sm text-gray-500">Products</span>
							<div class="flex flex-row items-center">
								<div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0 dark:border-gray-700">
									<app-mhp-svg-icon icon="pill" />
								</div>

								<span class="mr-4 text-gray-900 dark:text-white">{{ this.offers().length }} reference{{ this.offers().length > 1 ? 's' : '' }}</span>
							</div>
						</div>

						<!-- [TODO]: Preview to working on comment when is necessary -->
						<!-- <div class="flex flex-col">
							<span class="mb-4 text-sm text-gray-500">Comments (optional)</span>
							<app-mhp-input
								placeholder="Enter your comment..."
								type="textarea"
							/>
						</div> -->

						<app-mhp-button
							prefix="saxBookmark2Outline"
							size="large"
							text="Confirm and book these products"
							(click)="this.submit()"
						/>
					</div>
				}
			</div>
		</div>
	</div>
</div>
