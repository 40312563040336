import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Company } from '../../models/company.model';
import { toDictionary } from '../../utils/toDictionary';
import { AccountService } from '../account/account.service';
import { ApiCompanyService } from '../api/api-company/api-company.service';

/**
 * Provides session storage for company data.
 * @class CompanyService
 */
@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	private readonly _companyApiSvc = inject(ApiCompanyService);

	private readonly _accountSvc = inject(AccountService);

	private _allCompanies = signal<Company[]>([]);

	public allCompanies = computed(this._allCompanies);

	private _allCompaniesById = computed(() => toDictionary(this._allCompanies(), c => c.id));

	private _loadingPromise: Promise<void>;

	/**
	 * Get company by id.
	 * remark: may be null in the case the company list has not been loaded yet.
	 */
	getCompanyById(id: number): Company | null {
		const company = this._allCompaniesById()[id] || null;
		return company;
	}

	constructor() {
		let resolveLoading: () => void;
		this._loadingPromise = new Promise<void>(resolve => {
			resolveLoading = resolve;
		});

		effect(async () => {
			const isAuthentified = this._accountSvc.isAuthentified();

			if (isAuthentified) {
				// init all companies
				const allCompanies = await this._companyApiSvc.getAll();
				this._allCompanies.set(allCompanies);
				resolveLoading();
			}
		});
	}
}
