import { Component, HostListener, inject, input, model, OnInit, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from '../../../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from '../../../_common/components/mhp-input/mhp-input.component';
import { Company } from 'src/app/_common/models/company.model';
import { MhpLabelComponent } from '../../../_common/components/mhp-label/mhp-label.component';
import { ImporterRequestItem } from 'src/app/_common/models/importer-request-item.model';
import { Package } from 'src/app/_common/models/package.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { MhpLoaderComponent } from '../../../_common/components/mhp-loader/mhp-loader.component';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { OfferStatusEnum } from 'src/app/_common/enums/offer-status.enum';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';

@Component({
	selector: 'app-main-request-make-offer-modal',
	standalone: true,
	imports: [
		MhpButtonComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpLabelComponent,
		MhpSvgIconComponent,
		MhpLoaderComponent,
		CommonModule,
	],
	templateUrl: './main-request-make-offer-modal.component.html',
})
export class MainRequestMakeOfferModalComponent implements OnInit {
	private readonly _apiOfferSvc = inject(ApiOfferService);
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _offersSvc = inject(OffersService);

	public readonly exporter = input.required<Company>();
	public readonly item = input.required<ImporterRequestItem>();
	public readonly offer = input.required<Offer>();
	public readonly package = input.required<Package>();

	public readonly stateClose = output<boolean>();

	public readonly price = signal<string>('');
	public readonly quantity = signal<string>('');
	public readonly expirationDate = signal<string>(new Date().toDateString());
	public readonly user = signal<User | null>(this._accountSvc.user());

	public clickedInside: boolean = false;
	public importingItems: number = 0;
	public isLoading: boolean = false;
	public totalItems: number = 0;

	constructor() {}

	public ngOnInit(): void {
		this.price.set(this.offer().price.toString());
		this.quantity.set(this.offer().quantity.toString());
	}

	public submit(): void {
		this.isLoading = true;

		this._apiOfferSvc
			.sendCounterOffer(this.offer().id, {
				requestId: this.offer().requestId,
				itemId: this.item().itemId,
				exporterId: this._companySvc.currentCompany()?.id!,
				packageCip13: this.package().cip13,
				batchNumber: this.offer().batchNumber,
				minExpiration: this.expirationDate(),
				quantity: +this.quantity(),
				minQuantity: this.offer().minQuantity,
				multiple: this.offer().multiple,
				price: +this.price(),
				parentOffer: this.offer(),
				initiatedById: this.offer().exporterId,
				bookingDate: null,
				orderingDate: null,
			})
			.then(async response => {
				if (response.ok) {
					await this._apiOfferSvc.sendResponseOffer(response.body?.id!, {
						status: OfferStatusEnum.Review,
						exporterResponse:
							this.user()?.function === UserFunctionEnum.Exporter
								? {
										type: OfferResponseTypeEnum.Accepted,
										respondedAt: new Date().toISOString(),
								  }
								: null,
						importerResponse:
							this.user()?.function === UserFunctionEnum.Importer
								? {
										type: OfferResponseTypeEnum.Accepted,
										respondedAt: new Date().toISOString(),
								  }
								: null,
					});

					this._offersSvc.refresh();
					this.close();
				}
			})
			.catch((err: any) => console.error(err))
			.finally(() => (this.isLoading = false));
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
