@let _company = company();
<div *ngIf="_company" class="mhp-company-component">
	@if (_company.displayName; as name) {
	<img
		class="mhp-company-component-picture"
		[src]="CompanyExtension.getGravatarUrl(_company)"
		[title]="name"
		alt="Exporter" />
	}

	@else {
	<div class="mhp-company-component-picture">
		<ng-icon name="saxBuildingsOutline" />
	</div>
	}

	@if (!this.displayNameAsTooltip()) {
	<div>
		<span>{{ _company.displayName }}</span>

		@if (this.displayCountry()) {
		<span class="text-[var(--text-secondary)]">{{ _company.country }}</span>
		}
	</div>
	}
</div>