import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mhp-loader',
  standalone: true,
  imports: [],
  templateUrl: './mhp-loader.component.html',
})
export class MhpLoaderComponent {

  @Input() public text?: string = 'Loading...';

}
