<div class="mhp-stock-offer">
	<div class="flex flex-col w-full mb-6">
		<div class="flex flex-row">
			<h2 class="w-full text-[28px] font-semibold">My product catalog</h2>
			<div class="inline-flex text-right shrink-0 space-x-4">
				<app-mhp-button prefix="iconoirRefreshDouble" text="Update stock" type="tertiary"
					[routerLink]="this.sitemap.updateStockQuota.route" />
				<app-mhp-button prefix="iconoirPlus" text="Create a stock offer" type="tertiary"
					[routerLink]="this.sitemap.newStockOffer.route" />
				<!-- <app-mhp-button prefix="saxEyeOutline" text="See as importer" type="tertiary" /> -->
			</div>
		</div>
	</div>

	<div class="flex flex-row justify-between pt-8 pb-6">
		<div class="inline-flex items-center">
			<app-mhp-tabs [tabs]="this.tabs" [(choice)]="this.tabSelected" />
		</div>

		<div class="inline-flex items-center space-x-6">
			@if (stocksVisible() && quotasVisible()) {

			} @else if (stocksVisible()) {
			<a [href]="stockDumpUri()" target="_blank">
				<app-mhp-button prefix="saxExport1Outline" title="Download stock" type="tertiary" />
			</a>
			} @else if (quotasVisible()) {
			<a [href]="quotaDumpUri()" target="_blank">
				<app-mhp-button prefix="saxExport1Outline" title="Download quota" type="tertiary" />
			</a>
			}
		</div>
	</div>

	<!--

	Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136

	<div class="flex flex-row items-center space-x-4 mb-6">
		<app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Unit cost" type="tertiary" />
		<app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Stock" type="tertiary" />
		<app-mhp-button *ngIf="quotasVisible" suffix="saxArrowDown1Outline" text="Quota" type="tertiary" />
		<app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Expiration date" type="tertiary" />
		<app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Sections" type="tertiary" />
	</div> -->

	@if (hasManifestItems) {
	<div class="table-container">
		<cdk-virtual-scroll-viewport class="min-h-[60vh]" itemSize="76" scrollWindow appInfiniteScroll
			(nearEnd)="loadMore()">
			<table class="w-full text-sm text-left rtl:text-left">
				<thead class="text-[var(--text-secondary)]">
					<tr>
						<th class="pl-20">Product</th>
						<th *ngIf="quotasVisible()">Quota</th>
						<th *ngIf="stocksVisible()">Batch</th>
						<th *ngIf="stocksVisible()" class="min-w-28">Expiration date</th>
						<th *ngIf="stocksVisible()">Stock</th>
						<th>Allocated</th>
						<th *ngIf="stocksVisible()" class="min-w-20">Unit cost</th>
						<!-- <th class="py-3 pl-4 ">
							<div class="flex flex-row items-center">
								<span class="mr-1">Appears in the sections</span>
								<ng-icon name="saxInfoCircleOutline" size=".9rem" />
							</div>
						</th> -->
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *cdkVirtualFor="let item of this.viewItems()">
						<td class="inline-flex items-center space-x-4 w-max max-w-[30rem] flex-shrink">
							<app-mhp-package [package]="{ cip13: item.packageCip13, shortName: item.packageShortName }" />
						</td>
						<td *ngIf="quotasVisible()">{{ item.quota }}</td>
						<td *ngIf="stocksVisible()">{{ item.batch?.id }}</td>
						<td *ngIf="stocksVisible()">{{ item.batch?.expiration | date : 'd MMM y' : '' : "en-US" }}</td>
						<td *ngIf="stocksVisible()">{{ item.quantity }}</td>
						<td>{{ item.allocated }}</td>
						<td *ngIf="stocksVisible()">{{ item.price | currency : 'EUR' : 'symbol' : '.0-2' }}</td>
						<!-- <td class="py-3 pl-4 ">
								<div class="flex flex-row space-x-2">
									<span *ngFor="let section of item.sections" class="text-xs bg-gray-200 dark:bg-gray-800 rounded-full px-2 py-1">
										{{ section }}
									</span>
								</div>
							</td> -->
						<td class="p-4">
							<!-- Empty span prevents hitting the :empty selector (which displays dash) -->
							<span></span>

							<!-- Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136 -->
							<!-- <div class="flex flex-row space-x-4">
									<button>
										<ng-icon name="bootstrapPencilSquare" />
									</button>
									<button>
										<ng-icon name="saxTrashOutline" />
									</button>
								</div> -->

						</td>
					</tr>
				</tbody>
			</table>
		</cdk-virtual-scroll-viewport>
	</div>
	}

	@else {
	<div class="flex flex-col justify-center items-center h-[50vh]">
		<app-mhp-svg-icon icon="empty-product" [size]="80" />
		<p class="mt-6 text-[var(--text-secondary)]">You haven't imported any products in your catalog yet</p>

		@if ([
		this.userFunctions.Exporter,
		this.userFunctions.ImporterExporter
		].includes(userFunction())) {
		<app-mhp-button
			class="mt-8"
			prefix="saxAddOutline"
			text="Import my stock"
			type="primary"
			[routerLink]="[ this.sitemap.updateStockQuota.route ]" />

		<ul class="flex flew-row gap-8 list-none">
			<a class="mt-6 text-blue-600 hover:text-blue-700" href="/assets/download/update-stock.template.csv"
				download="stock.csv">Download our stock template</a>
			<a class="mt-6 text-blue-600 hover:text-blue-700" href="/assets/download/update-quota.template.csv"
				download="quota.csv">Download our quota template</a>
		</ul>
		}
	</div>
	}

</div>