<div class="update-screen">

	<div id="header" class="header">
		<div id="header-left">
			<app-mhp-button text="Cancel" type="secondary" [routerLink]="this.sitemap.catalog.route" />
		</div>

		<div id="header-right">
			<app-mhp-button text="Continue" type="primary" [routerLink]="this.sitemap.updateStockQuota.route + '/stock/review'" [hidden]="!file()" />
		</div>
	</div>

	<div class="main-container max-w-2xl">
		<h2>Import my stock</h2>

		<app-mhp-file-uploader
			[(file)]="this.file"
			(fileChange)="this.processFile($event)"
			[acceptedFiles]="this._tableSvc.acceptedFiles"
		/>

		<ul class="helper-links">
			<li><a href="assets/download/update-stock.template.csv" download="stock.csv">Download the template</a></li>
		</ul>
	</div>
</div>
