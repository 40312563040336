<offers-group-all-items
	title="Under review offers"
	description="Offers are under review and discussions may follow."
	[offers]="underReviewOffers()" />

<offers-group-all-items
	title="Accepted offers"
	description="Offers confirmed by both parties."
	[offers]="confirmedOffers()" />

<offers-group-all-items
	title="Rejected offers"
	description="Offers rejected by one of the parties."
	[offers]="declinedOffers()" />