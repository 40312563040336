import { Company } from '../models/company.model';

export namespace CompanyExtension {
	/**
	 * Get the exporter by ID
	 */
	export function getExporterById(exporters: Company[], id: number): Company {
		const exporter = exporters.find(exporter => exporter.id === id);
		if (!exporter) {
			throw new Error(`Exporter with ID ${id} not found`);
		}
		return exporter;
	}
}
