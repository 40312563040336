import { CommonModule } from '@angular/common';
import { Component, computed, effect, HostBinding, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { featherClock } from '@ng-icons/feather-icons';
import { saxBagTickOutline, saxFolder2Outline, saxMessageQuestionOutline } from '@ng-icons/iconsax/outline';
import { RequestStatusEnum } from '../../enums/request-status.enum';

@Component({
	selector: 'app-mhp-status-label',
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			saxBagTickOutline,
			saxFolder2Outline,
			featherClock,
			saxMessageQuestionOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './mhp-status-label.component.html',
})
export class MhpStatusLabelComponent {
	readonly RequestStatusEnum = RequestStatusEnum;

	readonly status = input.required<RequestStatusEnum>();

	readonly look = computed(() => {
		switch (this.status()) {
			case RequestStatusEnum.UnderReview:
				return {
					label: 'Offer under review',
					icon: 'featherClock',
					textColor: 'text-[#0A75C2] dark:text-[#6dbef8]',
					bgColor: 'bg-[#E0EFFB] dark:bg-[#092944]',
				};
			case RequestStatusEnum.InProgress:
				return {
					label: 'In progress',
					icon: 'saxBagTickOutline',
					textColor: 'text-[#1A7F5B] dark:text-[#80e5c2]',
					bgColor: 'bg-[#D7F4E1] dark:bg-[#103c20]',
				};
			case RequestStatusEnum.Completed:
				return {
					label: 'Closed',
					icon: 'saxFolder2Outline',
					textColor: 'text-[#6E6897] dark:text-[#c5c3d5]',
					bgColor: 'bg-[#EEEDF2] dark:bg-[#2e2b3b]',
				};
			default:
				return {
					label: 'Statut inconnu',
					icon: 'saxMessageQuestionOutline',
					textColor: 'text-[#1C274C] dark:text-[#b4bfe4]',
					bgColor: 'bg-[#F4F6F9] dark:bg-[#171f2b]',
				};
		}
	});

	private readonly class = computed(() => {
		return `inline-flex items-center gap-[10px] h-[32px] p-[10px] rounded-[10px] ${this.look().textColor} ${this.look().bgColor}`;
	});

	@HostBinding('class') boundClass = '';

	constructor() {
		effect(() => {
			this.boundClass = this.class();
		});
	}
}
