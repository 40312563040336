import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { toDictionarySet } from 'src/app/_common/utils/toDictionary';
import { OffersGroupForItemComponent } from '../offers-group-for-item/offers-group-for-item.component';
import { OffersGroupComponent } from '../offers-group/offers-group.component';

/**
 * Component to display all the offers waiting for the user to take action on them.
 */
@Component({
	selector: 'app-offers-waiting',
	templateUrl: './offers-waiting.component.html',
	standalone: true,
	imports: [CommonModule, OffersGroupComponent, OffersGroupForItemComponent],
})
export class OffersWaitingComponent {
	private readonly _accountService = inject(AccountService);

	private readonly _offersService = inject(OffersService);

	protected readonly UserFunctionEnum = UserFunctionEnum;

	readonly userFunction = computed<UserFunctionEnum>(() => this._accountService.user()!.function);

	Object = Object;

	readonly waitingOffers = computed<Offer[]>(() => {
		return this._offersService
			.currentRequestOffers()
			.filter(offer => OfferExtension.isActionPossible(offer, this.userFunction()));
	});

	readonly waitingOffersPerItem = computed<Record<string, Offer[]>>(() =>
		toDictionarySet(this.waitingOffers(), offer => offer.itemId)
	);
}
