import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import {
	saxArrowDown1Outline,
	saxArrowUp2Outline,
	saxArrowUp3Outline,
	saxMessageOutline,
	saxTrashOutline,
} from '@ng-icons/iconsax/outline';

import { bootstrapCopy, bootstrapInfoCircle, bootstrapPencilSquare } from '@ng-icons/bootstrap-icons';
import { saxCloseCircleBold, saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { Company } from 'src/app/_common/models/company.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { MhpCompanyComponent } from '../../../_common/components/mhp-company/mhp-company.component';
import { MhpLabelComponent } from '../../../_common/components/mhp-label/mhp-label.component';
import { ImporterRequestItem } from '../../../_common/models/importer-request-item.model';
import { Offer } from '../../../_common/models/offer.model';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { CompanyService } from '../../../_common/services/company/company.service';

import { MainRequestOfferHistoryComponent } from '../main-request-offer-history/main-request-offer-history.component';
import {
	MainRequestAcceptDeclineOfferModalComponent,
	ModalResponseTypeEnum,
} from '../offer-modals/main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component';
import { MainRequestMakeOfferModalComponent } from '../offer-modals/main-request-make-offer-modal/main-request-make-offer-modal.component';

/**
 * This component is used to display the offers for a specific product in the main request
 *
 * @refacto rename to MainRequestOffersForProductComponent
 */
@Component({
	selector: 'app-main-request-item-per-product',
	imports: [
		NgIconComponent,
		CurrencyPipe,
		DatePipe,
		ToCipPipe,
		MhpButtonComponent,
		MhpLabelComponent,
		MhpCompanyComponent,
		MhpSvgIconComponent,
		MainRequestAcceptDeclineOfferModalComponent,
		MainRequestMakeOfferModalComponent,
		FormsModule,
		MainRequestOfferHistoryComponent,
	],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxArrowUp3Outline,
			saxCloseCircleBold,
			saxMessageOutline,
			saxTickCircleBold,
			bootstrapPencilSquare,
			bootstrapCopy,
			saxTrashOutline,
			bootstrapInfoCircle,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styleUrl: './main-request-item-per-product.component.scss',
	templateUrl: './main-request-item-per-product.component.html',
})
export class MainRequestItemPerProductComponent {
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);
	private readonly _offersSvc = inject(OffersService);

	public readonly userFunctions = UserFunctionEnum;
	public readonly OfferResponseTypesEnum = OfferResponseTypeEnum;
	public readonly ModalResponseTypesEnum = ModalResponseTypeEnum;
	public readonly requestId = input.required<string>();
	public readonly item = input.required<ImporterRequestItem>();
	public readonly offers = input.required<Offer[]>();
	public readonly package = computed(() => this.item().package);
	public readonly currentCompany = computed(this._companySvc.currentCompany);
	public readonly exporters = computed(this._companySvc.allCompanies);
	public readonly user = computed<User | null>(this._accountSvc.user);

	public offerSelected: Offer | null = null;
	public expanded: boolean = true;
	public makeOfferModal: boolean = false;
	public acceptOfferModal: boolean = false;
	public declineOfferModal: boolean = false;
	public offerHistoryPanelDisplayed: boolean = false;

	constructor() {}

	public displayOfferHistoryPanel = () => (this.offerHistoryPanelDisplayed = true);

	public getExporter = (id: number): Company | undefined => CompanyExtension.getExporterById(this.exporters(), id);

	public readonly getOfferLabel: (o: Offer) => string = (offer: Offer) => (offer.batchNumber ? offer.batchNumber : '');

	/**
	 * Remove offer in parameter from the list of offers
	 * @param offerToDuplicate
	 */
	deleteOffer(offerToDelete: Offer): void {
		this._offersSvc.deletePendingOffer(offerToDelete);
	}

	/**
	 * Make a duplicate of the offer in parameter and then update the offers list
	 * @param offerToDuplicate
	 */
	duplicateOffer(offerToDuplicate: Offer): void {
		this._offersSvc.duplicateOffer(offerToDuplicate, 1);
	}

	/**
	 * Update the offer batchNumber and isToOrder according to the batchNumber input
	 * @param offer
	 * @param batchNumber
	 */
	updateBatchNumber(offer: Offer, batchNumber: string) {
		// https://github.com/MedHubCompany/MedHubPlace/issues/185
		offer.isToOrder = batchNumber.toUpperCase() === 'TO ORDER' || batchNumber == '';
		offer.batchNumber = offer.isToOrder ? '' : batchNumber;
	}
}
