import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SITEMAP, SiteMap } from '../_common/sitemap';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';

@Component({
	selector: 'app-not-found',
	standalone: true,
	imports: [
		RouterModule,
		MhpButtonComponent
	],
	templateUrl: './not-found.component.html',
})
export class NotFoundComponent {

	public sitemap: SiteMap = SITEMAP;

	constructor() {}
}
