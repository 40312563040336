import { Component, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBagTickOutline, saxClockOutline, saxEdit2Outline } from '@ng-icons/iconsax/outline';
import { RequestStatusEnum } from '../../enums/request-status.enum';

@Component({
	selector: 'app-mhp-status-label',
	standalone: true,
	imports: [
		NgIconComponent
	],
	providers: [
		provideIcons({
			saxBagTickOutline,
			saxClockOutline,
			saxEdit2Outline,
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	styleUrl: './mhp-status-label.component.scss',
	templateUrl: './mhp-status-label.component.html',
})
export class MhpStatusLabelComponent {

	public status = input<number>(1);

	public requestStatuses = RequestStatusEnum;

}
