import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxEditOutline } from '@ng-icons/iconsax/outline';
import { MainRequestEditOfferModalComponent } from '../../offer-modals/main-request-edit-offer-modal/main-request-edit-offer-modal.component';
import { OfferActionBase } from '../offer-action-base/offer-action-base';

@Component({
	selector: 'offer-action-edit',
	standalone: true,
	imports: [NgIconComponent, MainRequestEditOfferModalComponent],
	templateUrl: './offer-action-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		provideIcons({
			saxEditOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OfferActionEditComponent extends OfferActionBase {}
