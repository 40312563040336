<button [disabled]="this.disabled()">
	<div class="icon" [ngClass]="iconBg()">
		@if (icon()) {
			<app-mhp-svg-icon [icon]="icon()!" [size]="56"/>
		}
	</div>

	<div class="text">
		<div class="title">{{ title() }}</div>
		<div class="description">{{ text() }}</div>
	</div>

	<div>
		<ng-icon name="saxArrowRight3Outline" />
	</div>
</button>
