import { CommonModule } from '@angular/common';
import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxAddOutline, saxCallOutline, saxMinusOutline, saxPersonalcardOutline, saxProfileCircleOutline, saxSmsOutline } from '@ng-icons/iconsax/outline';

@Component({
	selector: 'app-mhp-input',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		NgIconComponent
	],
	providers: [
		provideIcons({
			saxAddOutline,
			saxCallOutline,
			saxMinusOutline,
			saxPersonalcardOutline,
			saxProfileCircleOutline,
			saxSmsOutline
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	styleUrl: './mhp-input.component.scss',
	templateUrl: './mhp-input.component.html'
})
export class MhpInputComponent {

	public disabled = input<boolean>(false);
	public max = input<number>();
	public min = input<number>();
	public placeholder = input<string>();
	public prefix = input<string>();
	public step = input<number>(1);
	public stepper = input<boolean>(false);
	public suffix = input<string>();
	public type = input<'date' | 'number' | 'text' | 'textarea'>('text');

	public value = model<string>();

	public decrease(): void {
		this.value.set(
			(
				!(this.type() === 'number')
				|| (typeof this.min === 'number' && (+this.value()! <= this.min))
			) ? `${+this.value()!}` : `${+this.value()! - this.step()}`
		);
	}

	public increase(): void {
		this.value.set(
			(
				!(this.type() === 'number')
				|| (typeof this.max === 'number' && (+this.value()! >= this.max))
			) ? `${+this.value()!}` : `${+this.value()! + this.step()}`
		);
	}

}
