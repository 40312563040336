import { Component, HostListener, inject, input, output, signal } from '@angular/core';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpLoaderComponent } from '../../../_common/components/mhp-loader/mhp-loader.component';
import { User } from 'src/app/_common/models/user.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { Company } from 'src/app/_common/models/company.model';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpFromToComponent } from '../../../_common/components/mhp-from-to/mhp-from-to.component';

@Component({
	selector: 'app-main-request-confirm-book-offers',
	standalone: true,
	imports: [MhpButtonComponent, MhpFromToComponent, MhpLoaderComponent, MhpSvgIconComponent],
	templateUrl: './main-request-confirm-book-offers.component.html',
})
export class MainRequestConfirmBookOffersComponent {
	private readonly _apiOfferSvc = inject(ApiOfferService);
	private readonly _offersSvc = inject(OffersService);
	private readonly _accountSvc = inject(AccountService);

	public readonly exporter = input.required<Company>();
	public readonly offers = input.required<Offer[]>();

	public readonly stateClose = output<boolean>();
	public readonly onSubmit = output({ alias: 'submit' });

	public readonly user = signal<User | null>(this._accountSvc.user());

	public clickedInside: boolean = false;
	public isLoading: boolean = false;

	constructor() {}

	public async submit(): Promise<void> {
		this.isLoading = true;

		try {
			const requests = this.offers().map(async (o: Offer) => {
				try {
					return await this._apiOfferSvc.sendBookOffer(o.id);
				} catch (message) {
					return console.error(message);
				}
			});

			const responses = await Promise.all(requests);
			const allRequestAreOk: boolean = responses.map(r => r!.ok).every(v => v);

			if (allRequestAreOk) {
				this._offersSvc.refresh();
				this.close();
			}
		} catch (err) {
			console.error(err);
		} finally {
			this.isLoading = false;
		}
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
