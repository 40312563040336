<div class="flex items-center overflow-hidden border rounded-[10px]">
	@if (prefix()) {
	<ng-icon [name]="prefix()" class="ml-4 shrink-0 text-[var(--text-secondary)]" />
	}

	@if (this.type() === 'textarea') {
	<textarea
		class="w-full text-base p-4 bg-transparent"
		[disabled]="this.disabled()"
		[(ngModel)]="this.model"
		[placeholder]="this.placeholder() || ''"
		[rows]="3"
		[value]="this.model()"></textarea>
	}
	@else {
	<input
		[class.pl-4]="prefix()"
		[class.pr-10]="suffix() || (type() === 'number' && stepper())"
		[attr.type]="type()"
		[attr.min]="min()"
		[attr.max]="max()"
		[attr.step]="step()"
		[attr.placeholder]="placeholder() || ''"
		[disabled]="disabled()"
		[value]="getInputValue()"
		(input)="onInput($event)"
		class="w-full no-stepper bg-transparent p-{{padding()}} dark:[color-scheme:dark]" />
	}

	@if (suffix()) {
	<span class="mr-4 shrink-0 text-[var(--text-secondary)]">{{ this.suffix() }}</span>
	}

	@if (type() === 'number' && stepper()) {
	<button class="mhp-input__button mhp-input__button-stepper" (click)="this.decrease()">
		<ng-icon name="saxMinusOutline" />
	</button>

	<button class="mhp-input__button mhp-input__button-stepper" (click)="this.increase()">
		<ng-icon name="saxAddOutline" />
	</button>
	}

	@if (clearable() && model()) {
	<button class="mhp-input__button mr-4" (click)="clear()">
		<ng-icon name="saxCloseCircleBold" />
	</button>
	}
</div>