import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '../../services/account/account.service';
import { SITEMAP } from '../../sitemap';

export const isNotAuthenticatedGuard: CanActivateFn = async (route, state) => {
	const router: Router = inject(Router);
	const _account: AccountService = inject(AccountService);

	if (!(await _account.checkIfUserAuthentified())) return true;

	router.navigate([SITEMAP.dashboard.route]);
	return false;
};
