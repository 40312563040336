@if( this.request !== null )
{
<div class="flex items-center">
	<app-mhp-button [routerLink]="sitemap.request.route + '/' + request.id" type="tertiary" text="Cancel"
		class="fixed m-8 left-0 top-0" />
	<div *ngIf="!displayFillModeDialog" class="flex fixed m-8 z-10 right-0 top-0 space-x-4">
		<app-mhp-button type="tertiary" text="Save & quit" (click)="quit()" />
		<app-mhp-button type="primary" text="Send the offer • {{ offerTotalAmount() | currency : 'EUR' }}"
			(click)="sendOfferConfirmModalDisplayed = true" />
	</div>
</div>

@if(displayFillModeDialog) {
<app-choose-fill-mode (prefill)="selectFillMode($event)" />
}

@else {
<div class="flex flex-col w-full px-24 my-8">

	<h1 class="flex w-full justify-center font-semibold text-[26px]">Review and send your offer</h1>

	@let colW = ['min-w-[300px]', 'w-2/12', 'w-3/12', 'w-2/12', 'w-2/12', 'w-1/12', 'w-2/12', 'min-w-[114px]'];

	@let otherCompany = offerService.otherCompany();

	<app-mhp-task-card *ngIf="otherCompany" class="mt-6 bg-white dark:bg-gray-900/75"
		[title]="otherCompany.displayName + ' sent you a purchase order for ' + request.items.length	+ ' products'"
		[task]="{ id: null!, userRequestId: this.displayedId }"
		[companyIcon]="CompanyExtension.getGravatarUrl(otherCompany)" />

	<div class="flex items-center mt-6">
		<!-- <app-mhp-button prefix="saxSortOutline" text="Order: " type="tertiary" /> -->

		<div class="flex w-full items-center justify-end gap-4">
			<app-mhp-progressbar class="w-16" [progress]="fillProgress()" />
			<span>{{ filledItemsCount() }}/{{ request.items.length }} filled</span>
		</div>
	</div>

	<div class="flex w-full items-center mt-6 border rounded-xl bg-white/60 dark:bg-gray-900/75">
		<table class="w-full">
			<thead>
				<tr>
					<th [ngClass]="colW[0]">Reference</th>
					<th [ngClass]="colW[1]">
						<div class="flex items-center">Batch
							<app-mhp-tooltip class="pl-1" message="Enter batch if stock. Leave blank if quota." />
						</div>
					</th>
					<th [ngClass]="colW[2]">
						<div class="flex items-center">Expiration date
							<app-mhp-tooltip class="pl-1" message="Enter expiration date if stock. Leave blank if quota." />
						</div>
					</th>
					<th [ngClass]="colW[3]">Quantity</th>
					<th [ngClass]="colW[4]">Unit cost</th>
					<th [ngClass]="colW[5]">Multiple</th>
					<th [ngClass]="colW[6]">&nbsp;</th>
					<th [ngClass]="colW[7]">&nbsp;</th>
				</tr>
			</thead>
		</table>
	</div>

	@for (item of request.items; track $index)
	{
	<div class="flex items-center mt-6 px-4 overflow-hidden border rounded-xl bg-white dark:bg-gray-900/75"
		(click)="packageSelected = item.package">

		<table class="item w-full">

			<thead>
				<tr class="italic">
					<th [ngClass]="colW[0]"></th>
					<th [ngClass]="colW[1]">{{ item.batchNumber ?? 'Not specified' }}</th>
					<th [ngClass]="colW[2]">{{ (item.minExpiration | date : 'd MMM y' : '' : "en-US") ?? 'Not specified' }}</th>
					<th [ngClass]="colW[3]">{{ item.quantity }} (minimum {{ item.minQuantity }})</th>
					<th [ngClass]="colW[4]">{{ item.price | currency : 'EUR' }}</th>
					<th [ngClass]="colW[5]">{{ item.multiple }}</th>
					<th [ngClass]="colW[6]">&nbsp;<!-- erreur --></th>
					<th [ngClass]="colW[7]">&nbsp;<!-- action --></th>
				</tr>
			</thead>

			<tbody>
				@let pendingOffersForProduct = pendingOffersPerProduct()[item.package.cip13];
				@for (offer of pendingOffersForProduct; track $index) {
				<tr (click)="offerSelected = offer">
					<td [ngClass]="colW[0]" *ngIf="$index === 0" [attr.rowspan]="pendingOffersForProduct.length">
						<app-mhp-package [package]="item.package" [icon]="null" class="block mr-14" />
					</td>
					<td [ngClass]="colW[1]"><app-mhp-input type="text" placeholder="Batch number" [model]="offer.batchNumber"
							(modelChange)="updateBatchNumber(offer, $event ?? '')" class="w-full" /></td>
					<td [ngClass]="colW[2]"><app-mhp-input type="date" [model]="offer.expirationDate"
							(modelChange)="setExpirationDate(offer, $event ?? '')" /></td>
					<td [ngClass]="colW[3]"><app-mhp-input type="number" [min]="0" [model]="formatNumber(offer.quantity)"
							(modelChange)="setQuantity(offer, $event ?? '')" /></td>
					<td [ngClass]="colW[4]"><app-mhp-input type="number" [min]="0" [model]="formatNumber(offer.price)"
							(modelChange)="setPrice(offer, $event ?? '')" /></td>
					<td [ngClass]="colW[5]"></td>
					<td [ngClass]="colW[6]">&nbsp;</td>
					<td [ngClass]="colW[7]">
						<div class="flex justify-end items-center space-x-4">
							<button class="flex items-center" (click)="offerService.duplicatePendingOffer(offer)"><ng-icon
									name="saxAddOutline" /> </button>
							<button class="flex items-center"> <ng-icon (click)="offerService.deletePendingOffer(offer)"
									name="saxTrashOutline" /> </button>
							<hr inert class="h-8 w-px border-r" />
							<button class="flex items-center" (click)="offerHistoryPanelDisplayed = true">
								@if(false) {
								<span class="absolute flex size-2 right-0 top-0">
									<span
										class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
									<span class="relative inline-flex rounded-full size-2 bg-blue-500"></span>
								</span>
								}
								<ng-icon size="1.5rem" name="saxMessageOutline" />
							</button>
						</div>
					</td>
				</tr>
				}
			</tbody>
		</table>
	</div>
	}
</div>
}


<app-main-request-offer-history *ngIf="offerHistoryPanelDisplayed" [offer]="offerSelected!"
	(stateClose)="offerHistoryPanelDisplayed = false" />


<app-main-request-send-offer-confirm-modal *ngIf="sendOfferConfirmModalDisplayed"
	[exporter]="this.request.importer"
	[request]="this.request"
	[validOffersCount]="filledItemsCount()"
	[(isOpen)]="sendOfferConfirmModalDisplayed"
	(submit)="sendOffer()" />
}