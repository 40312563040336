import { Component, computed, inject, input, Input, output, signal, Signal } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowDown1Outline, saxArrowUp2Outline, saxArrowUp3Outline } from '@ng-icons/iconsax/outline';

import { ToCipPipe } from "../../../_common/pipes/to-cip/to-cip.pipe";
import { MhpLabelComponent } from "../../../_common/components/mhp-label/mhp-label.component";
import { MhpButtonComponent } from "../../../_common/components/mhp-button/mhp-button.component";
import { saxCloseCircleBold, saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { Company } from 'src/app/_common/models/company.model';
import { MhpCompanyComponent } from "../../../_common/components/mhp-company/mhp-company.component";
import { MainRequestMakeOfferModalComponent } from "../main-request-make-offer-modal/main-request-make-offer-modal.component";
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { CommonModule } from '@angular/common';
import { ImporterRequestItem } from "../../../_common/models/importer-request-item.model";
import { Offer } from "../../../_common/models/offer.model";
import { CompanyService } from "../../../_common/services/company/company.service";
import { Package } from "../../../_common/models/package.model";
import { MainRequestAcceptDeclineOfferModalComponent } from "../main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component";
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import { getExporterById } from 'src/app/_common/utils/get-exporter.util';
import { FormsModule } from "@angular/forms";
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-main-request-item-per-product',
	standalone: true,
  imports: [
    CommonModule,
    NgIconComponent,
    ToCipPipe,
    MhpButtonComponent,
    MhpLabelComponent,
    MhpCompanyComponent,
		MhpSvgIconComponent,
    MainRequestAcceptDeclineOfferModalComponent,
    MainRequestMakeOfferModalComponent,
    FormsModule,
  ],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxArrowUp3Outline,
			saxCloseCircleBold,
			saxTickCircleBold
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	styleUrl: './main-request-item-per-product.component.scss',
	templateUrl: './main-request-item-per-product.component.html'
})
export class MainRequestItemPerProductComponent {

	private _account = inject(AccountService);
	private _company = inject(CompanyService);

	public item = input.required<ImporterRequestItem>();
	public offers = input.required<Offer[]>();
	public package = input.required<Package>();
	public needRefresh = output<boolean>();

	public offersFiltered = computed(() => this.offers().filter((offer: Offer) =>
		offer.packageCip13 === this.item().packageCip13
		&& (!this.item().batchNumber || offer.batchNumber === this.item().batchNumber)
	));

	public currentCompany = computed(() => this._company.currentCompany());
	public exporters = computed(() => this._company.allCompanies());
	public offerSelected: Offer | null = null;

	public expanded: boolean = !false;
	public makeOfferModal: boolean = false;
	public acceptOfferModal: boolean = false;
	public declineOfferModal: boolean = false;
	public user: User | null = null;

	public userFunctions = UserFunctionEnum;
	public offerResponseTypes = OfferResponseTypeEnum;

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public getExporter = (id: number): Company | undefined => getExporterById(this.exporters(), id);
}
