@if (true || this.offersFiltered().length > 0 || this.user?.function === this.userFunctions.Importer) {
  <div class="main-request-item-per-product">
    <div class="flex flex-row items-center p-4">
      <div class="flex flex-row items-center w-full space-x-4 cursor-pointer" (click)="this.expanded = !this.expanded">
        <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
          <app-mhp-svg-icon icon="pill" />
        </div>
        <div class="flex flex-col font-medium">
          <span class="text-sm text-gray-500">{{ this.item().packageCip13 | toCip : 13 }}</span>
          <span class="text-sm text-gray-900 dark:text-white">{{ this.package().shortName }}</span>
        </div>
      </div>

      <div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
        <app-mhp-label label="Qty min: {{ this.item().minQuantity }}" *ngIf="this.item().minQuantity" />
        <app-mhp-label label="Qty: {{ this.item().quantity }}" />
        <app-mhp-label label="Unit cost: {{ this.item().price | currency : 'EUR' : 'symbol' : '.2-2' }}" />
        <app-mhp-label label="Multiple: {{ this.item().multiple }}" *ngIf="this.item().multiple" />
        <app-mhp-label label="Expiration: {{ this.item().minExpiration | date : 'd MMM y' : '' : 'en-US' }}" />

        <button
          class="flex flex-row items-center text-gray-900 dark:text-white"
          (click)="this.expanded = !this.expanded"
        >
          See offers ({{ this.offersFiltered().length }})
          <ng-icon class="ml-2" [name]="this.expanded ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'" />
        </button>
      </div>
    </div>

    @if (this.expanded) {
      <table class="w-full">
        <thead class="border-t border-gray-200 dark:border-gray-700">
          <tr class="text-gray-500">

            @switch (this.user?.function) {
              @case (this.userFunctions.Exporter) {
                <th class="p-4 w-48 text-sm text-left font-medium">Batch</th>
                <th class="p-4 w-48 text-sm text-left font-medium">Expiration date</th>
                <th class="p-4 w-32 text-sm text-left font-medium">Qty</th>
                <th class="p-4 w-32 text-sm text-left font-medium">Unit cost</th>
                <th></th>
              }

              @case (this.userFunctions.Importer) {
                <th class="p-4 w-64 text-sm text-left font-medium">Exporter</th>
                <!-- <th class="p-4 text-sm text-left font-medium">Receipt of offer</th> -->
                <th class="p-4 w-48 text-sm text-left font-medium">Batch</th>
                <th class="p-4 w-48 text-sm text-left font-medium">Expiration date</th>
                <th class="p-4 w-32 text-sm text-left font-medium">Qty</th>
                <th class="p-4 w-32 text-sm text-left font-medium">Unit cost</th>
                <th></th>
              }
            }

          </tr>
        </thead>
        <tbody class="border-t">

          @for (offer of this.offersFiltered(); track $index) {
            <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">

              @switch (this.user?.function) {
                @case (this.userFunctions.Exporter) {
                  <td class="px-4 py-4">{{ offer.batchNumber }}</td>
                  <td class="px-4 py-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon hidden class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="px-4 py-4">
                    <span class="flex flex-row items-center">
                      <!-- Editable if staged as prefill -->
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        type="text"
                        [value]="offer.quantity"
                        [(ngModel)]="offer.quantity"
                      />
                    </span>
                  </td>
                  <td class="px-4 py-4">
                    <span class="flex flex-row items-center">
                      <!-- Editable if staged as prefill -->
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        type="text"
                        [value]="offer.price"
                        [(ngModel)]="offer.price"
                      />
                    </span>
                  </td>
                  <td class="px-4 py-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                      @if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.offerSelected = offer; this.makeOfferModal = true; this.needRefresh.emit(true)"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.offerSelected = offer; this.declineOfferModal = true; this.needRefresh.emit(true)"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.offerSelected = offer; this.acceptOfferModal = true; this.needRefresh.emit(true)"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }
                    </div>
                  </td>
                }

                @case (this.userFunctions.Importer) {
                  <td class="px-4 py-4">
                    <app-mhp-company [displayCountry]="true" [company]="this.getExporter(offer.exporterId)!" />
                  </td>
                  <!-- <td class="px-4 py-4 ">{{ item.requestId }}</td> -->
                  <td class="px-4 py-4">{{ offer.batchNumber }}</td>
                  <td class="px-4 py-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="px-4 py-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="px-4 py-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="px-4 py-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                      @if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.offerSelected = offer; this.makeOfferModal = true"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.offerSelected = offer; this.declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.offerSelected = offer; this.acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }
                    </div>
                  </td>
                }
              }

            </tr>
          }

          @empty {
            <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
              <td class="p-4">empty</td>
            </tr>
          }

        </tbody>
      </table>
    }
  </div>
}

@if (this.makeOfferModal) {
  <app-main-request-make-offer-modal
    [exporter]="this.getExporter(this.offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="this.offerSelected!"
    [package]="this.package()"
    (stateClose)="this.makeOfferModal = false; this.needRefresh.emit(true)"
  />
}

@else if (this.acceptOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.getExporter(this.offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="this.offerSelected!"
    [package]="this.package()"
    [type]="this.offerResponseTypes.Accepted"
    (stateClose)="this.acceptOfferModal = false; this.needRefresh.emit(true)"
  />
}

@else if (this.declineOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.getExporter(this.offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="this.offerSelected!"
    [package]="this.package()"
    [type]="this.offerResponseTypes.Declined"
    (stateClose)="this.declineOfferModal = false; this.needRefresh.emit(true)"
  />
}
