<div class="mhp-stock-offer">
  <div class="flex flex-col w-full mb-6">
    <div class="flex flex-row">
      <h2 class="w-full text-4xl font-semibold text-gray-900 dark:text-white">My product catalog</h2>
      <div class="flex flex-row text-right shrink-0 space-x-4">
        <app-mhp-button
          prefix="iconoirRefreshDouble"
          text="Update stock"
          type="tertiary"
          [routerLink]="[ this.sitemap.newRequest.route ]"
        />
        <!-- <app-mhp-button prefix="saxEyeOutline" text="See as importer" type="tertiary" /> -->
      </div>
    </div>
  </div>

  <div class="flex flex-row pt-8 pb-6">
    <div class="flex flex-row items-center w-full">
      <app-mhp-tabs
        [tabs]="this.tabs"
        [(choice)]="this.tabSelected"
      />
    </div>
  </div>

  <!--

	Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136

	<div class="flex flex-row items-center space-x-4 mb-6">
    <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Unit cost" type="tertiary" />
    <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Stock" type="tertiary" />
    <app-mhp-button *ngIf="quotasVisible" suffix="saxArrowDown1Outline" text="Quota" type="tertiary" />
    <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Expiration date" type="tertiary" />
    <app-mhp-button *ngIf="stocksVisible" suffix="saxArrowDown1Outline" text="Sections" type="tertiary" />
  </div> -->

  <div class="relative overflow-x-auto border border-gray-200 dark:border-gray-700 rounded-2xl">
    <table class="w-full text-sm text-left rtl:text-left text-gray-900 bg-white dark:bg-gray-800 dark:text-white">
      <thead class="text-xs text-gray-500">
        <tr>
          <th class="py-4 pl-4 font-medium">Product</th>
          <th *ngIf="quotasVisible" class="py-4 pl-4 font-medium">Quota</th>
          <th *ngIf="stocksVisible" class="py-4 pl-4 font-medium">Batch</th>
          <th *ngIf="stocksVisible" class="py-4 pl-4 font-medium">Expiration date</th>
          <th *ngIf="stocksVisible" class="py-4 pl-4 font-medium">Stock</th>
          <th *ngIf="quotasVisible" class="py-4 pl-4 font-medium">Ordered</th>
          <th *ngIf="stocksVisible" class="py-4 pl-4 font-medium">Unit cost</th>
          <!-- <th class="py-4 pl-4 font-medium">
            <div class="flex flex-row items-center">
              <span class="mr-1">Appears in the sections</span>
              <ng-icon name="saxInfoCircleOutline" size=".9rem" />
            </div>
          </th> -->
          <th class="p-4 font-medium"></th>
        </tr>
      </thead>
      <tbody>
        @for (item of this.offers()?.items; track $index) {
          <tr class="border-t dark:border-gray-700">
            <td class="p-4 font-medium">
              <div class="inline-flex flex-row items-center space-x-4 w-max">
                <div class="flex justify-center items-center h-11 w-11 shrink-0 border rounded-full dark:border-gray-700">
                  <app-mhp-svg-icon icon="pill" />
                </div>

                @if (getPackage(item.batch.cip13!); as product) {
                  <div class="flex flex-col">
                    <span class="text-sm text-gray-500">{{ +product.cip13 | toCip : 13 }}</span>
                    <span class="text-sm">{{ product.shortName }}</span>
                  </div>
                }
              </div>
            </td>
            <td *ngIf="quotasVisible" class="py-4 pl-4 font-medium">{{ item.quota ?? '-' }}</td>
            <td *ngIf="stocksVisible" class="py-4 pl-4 font-medium">{{ item.batch.id }}</td>
            <td *ngIf="stocksVisible" class="py-4 pl-4 font-medium">{{ item.batch.expiration | date : 'd MMM y' : '' : "en-US" }}</td>
            <td *ngIf="stocksVisible" class="py-4 pl-4 font-medium">{{ item.quantity }}</td>
            <td *ngIf="quotasVisible" class="py-4 pl-4 font-medium"></td>
            <td *ngIf="stocksVisible" class="py-4 pl-4 font-medium">{{ item.price | currency : 'EUR' : 'symbol' : '.0-2' }}</td>
            <!-- <td class="py-4 pl-4 font-medium">
              <div class="flex flex-row space-x-2">
                <span *ngFor="let section of item.sections" class="text-xs bg-gray-200 dark:bg-gray-700 dark:text-gray-500 rounded-full px-2 py-1">
                  {{ section }}
                </span>
              </div>
            </td> -->
            <td class="p-4 font-medium w-full">

							<!-- Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136 -->
              <!-- <div class="flex flex-row space-x-4">
                <button>
                  <ng-icon name="bootstrapPencilSquare" />
                </button>
                <button>
                  <ng-icon name="saxTrashOutline" />
                </button>
              </div> -->

            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
