import { Component, input } from '@angular/core';

import { CompanyExtension } from '../../extensions/company.extension';
import { Company } from '../../models/company.model';
import { User } from '../../models/user.model';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-mhp-from-to',
	imports: [MhpSvgIconComponent],
	templateUrl: './mhp-from-to.component.html',
	styleUrl: './mhp-from-to.component.scss',
})
export class MhpFromToComponent {
	public readonly from = input.required<User | Company>();
	public readonly to = input<Company[]>();

	public getIconUri(party?: User | Company): string {
		return CompanyExtension.getGravatarUrl(party, 64);
	}

	public getDisplayName(party?: User | Company): string {
		if (!party) {
			return 'Unknown';
		}

		return (party as User).firstName ?? (party as Company).displayName;
	}

	public getDisplayNames(parties: Company[]): string {
		return parties.map(p => p.displayName).join(', ');
	}
}
