import { Package } from '../../models/package.model';

export const bestSellers: Package[] = [
	{
		cip13: 3400926784102,
		cip7: 2678410,
		shortName: 'ELIQUIS 5 mg Cpr pell Plq/60',
		fullName: 'APIXABAN 5 mg cp (ELIQUIS 5 mg Cpr pell Plq/60)',
		startDate: '2014-01-17',
		endDate: null,
		newProduct: false,
		lastUpdated: '2024-08-27T22:01:35.092319+00:00',
		product: {
			cis: 61902218,
			shortName: 'ELIQUIS 5 mg cp pellic',
			fullName: 'APIXABAN 5 mg cp (ELIQUIS)',
			dosage: '5 mg',
			galenicForm: { id: 112, fullName: 'comprimé pelliculé', shortName: 'Cpr pell', class: 'GSDA089' },
			route: { id: 38, name: 'orale', systemic: true, topical: false },
			activeIngredients: ['apixaban'],
			branding: 'ELIQUIS',
			company: 'Bristol-Myers Squibb/Pfizer EEIG',
			startDate: '2014-01-17',
			endDate: null,
			lastUpdated: '2024-08-27T22:01:35.087173+00:00',
		},
	},
	{
		cip13: 3400926649975,
		cip7: 2664997,
		shortName: 'FORXIGA 10 mg Cpr pell Plq/30x1',
		fullName: 'DAPAGLIFLOZINE (propylèneglycol) 10 mg cp (FORXIGA 10 mg Cpr pell Plq/30x1)',
		startDate: '2020-04-06',
		endDate: null,
		newProduct: false,
		lastUpdated: '2024-08-28T08:34:37.554102+00:00',
		product: {
			cis: 67456363,
			shortName: "FORXIGA 10 mg cp pellic [ATUc extension d'indication]",
			fullName: 'DAPAGLIFLOZINE (propylèneglycol) 10 mg cp (FORXIGA)',
			dosage: '10 mg',
			galenicForm: { id: 112, fullName: 'comprimé pelliculé', shortName: 'Cpr pell', class: 'GSDA089' },
			route: { id: 38, name: 'orale', systemic: true, topical: false },
			activeIngredients: ['dapagliflozine'],
			branding: 'FORXIGA',
			company: 'AstraZeneca',
			startDate: '2021-07-05',
			endDate: '2021-10-27',
			lastUpdated: '2024-08-28T08:34:37.478042+00:00',
		},
	},
	{
		cip13: 3400941945670,
		cip7: 4194567,
		shortName: 'ELIQUIS 2,5 mg Cpr pell Plq/60',
		fullName: 'APIXABAN 2,5 mg cp (ELIQUIS 2,5 mg Cpr pell Plq/60)',
		startDate: '2012-07-20',
		endDate: null,
		newProduct: false,
		lastUpdated: '2024-08-27T22:01:35.092319+00:00',
		product: {
			cis: 69340279,
			shortName: 'ELIQUIS 2,5 mg cp pellic',
			fullName: 'APIXABAN 2,5 mg cp (ELIQUIS)',
			dosage: '2,5 mg',
			galenicForm: { id: 112, fullName: 'comprimé pelliculé', shortName: 'Cpr pell', class: 'GSDA089' },
			route: { id: 38, name: 'orale', systemic: true, topical: false },
			activeIngredients: ['apixaban'],
			branding: 'ELIQUIS',
			company: 'Bristol-Myers Squibb/Pfizer EEIG',
			startDate: '2012-07-20',
			endDate: null,
			lastUpdated: '2024-08-27T22:01:35.087172+00:00',
		},
	},
	{
		cip13: 3400938322446,
		cip7: 3832244,
		shortName: 'AVAMYS 27,5 µg/pulverisation Susp pulv nas Fl/120pulv',
		fullName:
			'FLUTICASONE FUROATE 27,5 µg/dose susp p pulv nasal (AVAMYS 27,5 µg/pulverisation Susp pulv nas Fl/120pulv)',
		startDate: '2008-08-22',
		endDate: null,
		newProduct: false,
		lastUpdated: '2024-08-28T08:34:38.224334+00:00',
		product: {
			cis: 66921927,
			shortName: 'AVAMYS 27,5 µg/pulverisation susp p pulv nasal',
			fullName: 'FLUTICASONE FUROATE 27,5 µg/dose susp p pulv nasal (AVAMYS)',
			dosage: '27,5µg/pulverisation',
			galenicForm: {
				id: 589,
				fullName: 'suspension pour pulvérisation nasale',
				shortName: 'Susp pvn',
				class: 'GLUA060',
			},
			route: { id: 35, name: 'nasale', systemic: false, topical: true },
			activeIngredients: ['fluticasone furoate'],
			branding: 'AVAMYS',
			company: 'Glaxo Group Ltd',
			startDate: '2008-08-22',
			endDate: null,
			lastUpdated: '2024-08-28T08:34:38.199565+00:00',
		},
	},
	{
		cip13: 3400927566479,
		cip7: 2756647,
		shortName: 'ULTIBRO BREEZHALER 85 µg/43 µg Pdr p inh (gélule) Plq/30+inhal',
		fullName:
			'GLYCOPYRRONIUM (bromure) 43 µg + INDACATEROL (maléate) 85 µg pdre p inhal en gél (ULTIBRO BREEZHALER 85 µg/43 µg Pdr p inh (gélule) Plq/30+inhal)',
		startDate: '2014-12-19',
		endDate: null,
		newProduct: false,
		lastUpdated: '2024-08-27T22:01:04.770438+00:00',
		product: {
			cis: 62553246,
			shortName: 'ULTIBRO BREEZHALER 85 µg/43 µg pdre p inhal en gél',
			fullName: 'GLYCOPYRRONIUM (bromure) 43 µg + INDACATEROL (maléate) 85 µg pdre p inhal en gél (ULTIBRO BREEZHALER)',
			dosage: '85 µg/43 µg',
			galenicForm: { id: 399, fullName: 'poudre pour inhalation en gélule', shortName: 'Pdr inhg', class: 'GSRD008' },
			route: { id: 44, name: 'inhalée', systemic: false, topical: true },
			activeIngredients: ['glycopyrronium bromure', 'indacatérol maléate'],
			branding: 'ULTIBRO BREEZHALER',
			company: 'Novartis Europharm Limited',
			startDate: '2014-12-19',
			endDate: null,
			lastUpdated: '2024-08-27T22:01:04.762898+00:00',
		},
	},
];
