import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideNgIconsConfig, withExceptionLogger } from '@ng-icons/core';

import { CredentialsInterceptor } from './_common/interceptors/credentials.interceptor';
import { LoadingInterceptor } from './_common/interceptors/loading.interceptor';
import { routes } from './app.routes';

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
	providers: [
		provideHttpClient(withInterceptors([CredentialsInterceptor]), withInterceptorsFromDi()),
		provideNgIconsConfig({}, withExceptionLogger()),
		provideRouter(routes),
		provideZoneChangeDetection({ eventCoalescing: true }),
		{ provide: LOCALE_ID, useValue: navigator.language },
		provideAnimationsAsync(),
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
	],
};
