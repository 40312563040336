import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsOutline, saxMoreOutline } from '@ng-icons/iconsax/outline';
import { MhpStatusLabelComponent } from "../../../_common/components/mhp-status-label/mhp-status-label.component";
import { RouterModule } from '@angular/router';
import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { MhpLabelComponent } from "../../../_common/components/mhp-label/mhp-label.component";
import { MhpCompanyComponent } from "../../../_common/components/mhp-company/mhp-company.component";
import { Company } from 'src/app/_common/models/company.model';
import { CommonModule } from '@angular/common';
import { ApiImporterService } from "../../../_common/services/api/api-importer/api-importer.service";
import { ImporterRequest } from "../../../_common/models/importer-request.model";
import { CompanyService } from "../../../_common/services/company/company.service";
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { RequestStatusEnum } from 'src/app/_common/enums/request-status.enum';
import { MhpCompaniesComponent } from "../../../_common/components/mhp-companies/mhp-companies.component";
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { ApiExporterService } from 'src/app/_common/services/api/api-exporter/api-exporter.service';

@Component({
	selector: 'app-main-dashboard-requests',
	standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgIconComponent,
    MhpLabelComponent,
    MhpStatusLabelComponent,
    MhpCompanyComponent,
    MhpCompaniesComponent
  ],
	providers: [
		provideIcons({
			saxMoreOutline,
			saxBuildingsOutline
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	templateUrl: './main-dashboard-requests.component.html',
})
export class MainDashboardRequestsComponent {

	private _account = inject(AccountService);
	private _apiImporter = inject(ApiImporterService);
	private _company = inject(CompanyService);

	public sitemap: SiteMap = SITEMAP;

	public exporters = input<Company[]>([]);

	public requests = signal<ImporterRequest[]>([]);
	public isLoading: boolean = false;
	public requestTypes = RequestTypeEnum;
	public user: User | null = null;
  userFunctions = UserFunctionEnum;

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);

		this.isLoading = true;

		const company: Company | null = this._company.currentCompany();

		if(company && this.user) {
			switch(this.user.function) {
				case UserFunctionEnum.Importer:
					this._apiImporter.getRequestsByImporter(company.id)
						.then(r => this.requests.set(r.body!))
						.catch(console.error)
						.finally(() => this.isLoading = false);

					break;

				case UserFunctionEnum.Exporter:
					this._apiImporter.getRequestsByExporter(company.id)
						.then(r => this.requests.set(r.body!))
						.catch(console.error)
						.finally(() => this.isLoading = false);

					break;
			}
		}
	}
}
