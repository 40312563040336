<div class="mhp-company-component">
  @if (this.company().displayName; as name) {
    <img
      class="mhp-company-component-picture"
      [src]="this.getGravatarUrl(name)"
      [title]="name"
      alt="Exporter"
    />
  }

  @else {
    <div class="mhp-company-component-picture">
      <ng-icon name="saxBuildingsOutline" />
    </div>
  }

  @if (!this.displayNameAsTooltip()) {
    <div>
      <span >{{ this.company().displayName }}</span>

      @if (this.displayCountry()) {
        <span class="text-[var(--text-secondary)]">{{ this.company().address.country }}</span>
      }
    </div>
  }
</div>

