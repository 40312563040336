@if (this.user(); as user) {
<div class="text-4xl font-semibold">
	<h2>Hello {{ user.firstName }}</h2>
</div>

@if (user.function === UserFunctions.Exporter) {
<app-main-dashboard-exporter-tasks />
} @else if (user.function === UserFunctions.Importer) {
<app-main-dashboard-importer-tasks />
}


<div class="flex w-full justify-between items-end">
	<span class="font-semibold">All requests</span>

	<app-mhp-button *ngIf="user.function === UserFunctions.Importer"
		prefix="saxAddOutline"
		text="New request"
		type="primary"
		[routerLink]="[ this.sitemap.newRequest.route ]" />
</div>

<app-main-dashboard-requests />
}