import { Injectable, inject } from '@angular/core';
import { saveAs } from 'file-saver';
import { UserFunctionEnum } from '../../enums/user-function.enum';
import { OfferExtension } from '../../extensions/offer.extension';
import { Offer } from '../../models/offer.model';
import { RequestItem } from '../../models/request-item.model';
import { CompanyService } from '../company/company.service';

interface ExportRow {
	group: string;
	product: string;
	cip13: number;
	internalCode?: string;
	seller?: string;
	batch: string;
	expirationDate: string;
	quantity: number;
	unitCost: number;
	status: string;
}

interface GroupedOffers {
	underReview: Offer[];
	confirmed: Offer[];
	declined: Offer[];
}

@Injectable({
	providedIn: 'root',
})
export class CsvExportService {
	private readonly companyService = inject(CompanyService);

	/**
	 * Export offers data to CSV file
	 * @param offers The offers to export
	 * @param userFunction The current user function (importer or exporter)
	 * @param requestDisplayId The display ID of the current request
	 */
	exportOffersToCsv(
		offers: Offer[],
		itemsDico: Record<string, RequestItem>,
		userFunction: UserFunctionEnum,
		requestDisplayId: string
	): void {
		if (!offers || offers.length === 0) {
			console.warn('No offers to export');
			return;
		}

		try {
			// Group offers by their status
			const groupedOffers = this.groupOffers(offers, userFunction);

			// Map the offers to the export format
			const exportData = this.mapOffersToExportFormat(groupedOffers, itemsDico, userFunction);

			// Generate CSV content
			const csvContent = this.generateCsv(exportData, userFunction);

			// Create a Blob with the CSV content
			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

			// Generate filename with request ID and current date with hour and minute
			const now = new Date();
			const date = now.toISOString().split('T')[0];
			const hours = String(now.getHours()).padStart(2, '0');
			const minutes = String(now.getMinutes()).padStart(2, '0');

			// Use user function in filename
			const userFunctionName = userFunction === UserFunctionEnum.Importer ? 'importer' : 'exporter';
			const filename = `offers-${userFunctionName}-request-${requestDisplayId}-${date}-${hours}h${minutes}.csv`;

			// Trigger download
			saveAs(blob, filename);
		} catch (error) {
			console.error('Error exporting offers to CSV:', error);
		}
	}

	/**
	 * Group offers by their status (Under review, Confirmed, Declined)
	 */
	private groupOffers(offers: Offer[], userFunction: UserFunctionEnum): GroupedOffers {
		return {
			underReview: offers.filter(o => OfferExtension.isInReview(o, userFunction)),
			confirmed: offers.filter(o => OfferExtension.isInConfirmed(o) || OfferExtension.isInShipping(o)),
			declined: offers.filter(o => OfferExtension.isOfferDeclined(o)),
		};
	}

	/**
	 * Map grouped offers to the export format
	 */
	private mapOffersToExportFormat(
		groupedOffers: GroupedOffers,
		itemsDico: Record<string, RequestItem>,
		userFunction: UserFunctionEnum
	): ExportRow[] {
		const result: ExportRow[] = [];

		// Process 'Under review' offers
		for (const offer of groupedOffers.underReview) {
			result.push(this.mapOfferToExportRow(offer, 'Under review', itemsDico, userFunction));
		}

		// Process 'Confirmed' offers
		for (const offer of groupedOffers.confirmed) {
			result.push(this.mapOfferToExportRow(offer, 'Confirmed', itemsDico, userFunction));
		}

		// Process 'Declined' offers
		for (const offer of groupedOffers.declined) {
			result.push(this.mapOfferToExportRow(offer, 'Declined', itemsDico, userFunction));
		}

		return result;
	}

	/**
	 * Map a single offer to an export row
	 */
	private mapOfferToExportRow(
		offer: Offer,
		group: string,
		itemsDico: Record<string, RequestItem>,
		userFunction: UserFunctionEnum
	): ExportRow {
		const exportRow: ExportRow = {
			group,
			product: offer.shortName,
			cip13: offer.packageCip13,
			batch: offer.batchNumber || (offer.isToOrder ? 'To order' : ''),
			expirationDate: offer.expirationDate ? new Date(offer.expirationDate).toLocaleDateString() : '',
			quantity: offer.quantity,
			unitCost: offer.price,
			status: OfferExtension.getOfferStatusLabel(offer, userFunction),
		};

		// Add seller column and internal code for importer users
		if (userFunction === UserFunctionEnum.Importer) {
			const company = this.companyService.getCompanyById(offer.exporterId);
			exportRow.seller = company?.displayName || `Company ${offer.exporterId}`;
			exportRow.internalCode = itemsDico[offer.itemId]?.package.companyInternalCode;
		}

		return exportRow;
	}

	/**
	 * Generate CSV content from data
	 */
	private generateCsv(data: ExportRow[], userFunction: UserFunctionEnum): string {
		if (data.length === 0) {
			return '';
		}

		// CSV header
		let header = 'Group;Product;Cip13;';

		// Add Seller column for importer users
		if (userFunction === UserFunctionEnum.Importer) {
			header += 'Internal code;Seller;';
		}

		header += 'Batch;Expiration date;Quantity;Unit cost;Status';

		// Map data to CSV rows
		const rows = data.map(row => {
			const rowData = [row.group, row.product, row.cip13];

			// Add seller if present (for importer users)
			if (userFunction === UserFunctionEnum.Importer && 'seller' in row) {
				rowData.push(row.internalCode || '');
				rowData.push(row.seller || '');
			}

			rowData.push(row.batch, row.expirationDate, row.quantity.toString(), row.unitCost.toString(), row.status);

			return rowData.join(';');
		});

		// Combine header and rows
		return [header, ...rows].join('\n');
	}
}
