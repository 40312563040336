import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { OffersGroupHeaderComponent } from '../offers-group/offers-group-header/offers-group-header.component';
import { OffersGroupComponent } from '../offers-group/offers-group.component';

@Component({
	selector: 'offers-group-all-items',
	templateUrl: './offers-group-all-items.component.html',
	standalone: true,
	imports: [CommonModule, OffersGroupComponent, OffersGroupHeaderComponent],
})
export class OffersGroupForItemComponent {
	readonly accountService = inject(AccountService);
	readonly offerService = inject(OffersService);
	offers = input.required<Offer[]>();
	title = input.required<string>();
	description = input.required<string>();
	allowEdit = input<boolean>(false);
	expanded = true;
}
