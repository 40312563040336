<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="p-6 relative flex flex-col flex-start items-start transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        (click)="this.clickedInside = true"
      >
        <div class="mb-8">
          <button
            class="flex flex-row mb-6 justify-center item-center"
            (click)="this.close()"
          >
            <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
          </button>

          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Can you confirm that you want to send to this offer?</h3>
        </div>

        <div class="mb-8">
          <app-mhp-from-to [from]="this.user!" [to]="this.exporter()" />
        </div>

        <div class="flex flex-col w-full space-y-6">
          <div class="flex flex-col">
            <span class="mb-4 text-sm text-gray-500">Products ({{ this.packages().length }})</span>
            <div class="overflow-hidden border rounded-2xl border-gray-200 dark:border-gray-700">
              <div class="p-4 overflow-y-auto max-h-64 space-y-4">

                @for (item of this.packages(); track $index) {
                  <div class="flex flex-row items-center">
                    <div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0 dark:border-gray-700">
                      <app-mhp-svg-icon icon="pill" />
                    </div>
  
                    <div class="flex flex-col">
                      <span class="mr-4 text-sm text-gray-500">{{ item.cip13 | toCip : 13 }}</span>
                      <span class="mr-4 text-sm text-gray-900 dark:text-white">{{ item.shortName }}</span>
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>

          @if (false) {
            <div class="flex flex-col">
              <span class="mb-2 text-sm text-gray-500">Total price</span>
              <span class="text-3xl font-medium text-gray-900 dark:text-white">{{ 30000 | currency : 'EUR' : 'symbol' : '.0-2' }}</span>
            </div>
          }

					<div class="flex flex-col">
						<span class="mb-4 text-sm text-gray-500">Message (optional)</span>
						<app-mhp-input
							placeholder="Introduce your offer to the importer..."
							type="textarea"
						/>
					</div>

          <app-mhp-button
            prefix="saxSend2Outline"
            size="large"
            text="Send offers ({{ this.packages().length }} products)"
            (click)="this.submit()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
