@if (true || this.offers().length > 0 || this.user()?.function === this.userFunctions.Importer) {
  <div class="main-request-item-per-product">
    <div class="main-request-item-per-product-header">
      <div class="flex flex-row items-center w-full space-x-4 cursor-pointer" (click)="this.expanded = !this.expanded">
        <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
          <app-mhp-svg-icon icon="pill" />
        </div>
        <div class="flex flex-col font-medium">
          <span class="text-sm text-gray-500">{{ this.item().packageCip13 | toCip : 13 }}</span>
          <span class="text-sm text-gray-900 dark:text-white">{{ this.package().shortName }}</span>
        </div>
      </div>

      <div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
        @if (this.item().minQuantity) {
          <app-mhp-label label="Qty min: {{ this.item().minQuantity }}" />
        }

        <app-mhp-label label="Qty: {{ this.item().quantity }}" />

        @if (this.item().price) {
          <app-mhp-label label="Unit cost: {{ this.item().price | currency : 'EUR' : 'symbol' : '.2-2' }}" />
        }

        @if (this.item().multiple) {
          <app-mhp-label label="Multiple: {{ this.item().multiple }}" />
        }

        <app-mhp-label label="Expiration: {{ this.item().minExpiration | date : 'd MMM y' : '' : 'en-US' }}" />

        <button
          class="flex flex-row items-center text-gray-900 dark:text-white"
          (click)="this.expanded = !this.expanded"
        >
          See offers ({{ this.offers().length }})
          <ng-icon class="ml-2" [name]="this.expanded ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'" />
        </button>
      </div>
    </div>

    @if (this.expanded) {
      <table class="w-full">
        <thead class="border-t border-gray-200 dark:border-gray-700">
          <tr class="text-gray-500">

            @switch (this.user()?.function) {
              @case (this.userFunctions.Exporter) {
								<th class="p-4 w-48 text-sm text-left font-medium">
									<span class="flex align-baseline space-x-2 icon-container ">
										<span>Batch</span>
										<ng-icon name="bootstrapInfoCircle" size="1.2rem" title="Batch number information" />
									</span>
								</th>
								<th class="p-4 w-48 text-sm text-left font-medium space-x-2">
									<span class="flex align-baseline space-x-2 icon-container">
										<span>Expiration date</span>
										<ng-icon name="bootstrapInfoCircle" size="1.2rem" title="Enter expiration date if you have a batch. Othewise leave blank." />
									</span>
								</th>
								<th class="p-4 w-32 text-sm text-left font-medium space-x-2">
									<span class="flex align-baseline">
										<span>Quantity</span>
									</span>
								</th>
								<th class="p-4 w-32 text-sm text-left">
									<span class="flex align-baseline space-x-2">
										<span>Unit cost</span>
									</span>
								</th>
                <th></th>
              }

              @case (this.userFunctions.Importer) {
                <th class="p-4 w-64 text-sm text-left font-medium">Exporter</th>
                <!-- <th class="p-4 text-sm text-left font-medium">Receipt of offer</th> -->
                <th class="p-4 w-48 text-sm text-left font-medium">Batch</th>
                <th class="p-4 w-48 text-sm text-left font-medium">Expiration date</th>
                <th class="p-4 w-32 text-sm text-left font-medium">Qty</th>
                <th class="p-4 w-32 text-sm text-left font-medium">Unit cost</th>
                <th></th>
              }
            }

          </tr>
        </thead>
        <tbody class="border-t">

          @for (offer of this.offers(); track $index) {
            <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700" (click)="this.offerSelected = offer">

              @switch (this.user()?.function) {
                @case (this.userFunctions.Exporter) {
                  <td class="p-4">
										<input
											class="m-0 p-2 w-32 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
											type="text"
											placeholder="Batch number"
											[ngModel]="offer.isToOrder ? this.ToOrderLabel : offer.batchNumber"
											(ngModelChange)="this.updateBatchNumber( offer, $event )"
										/>
									</td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon hidden class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
											<input
												class="m-0 p-2 w-36 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700 dark:[color-scheme:dark]"
												[class.text-gray-500]="offer.isToOrder"
												type="date"
												[disabled]="offer.isToOrder"
												[value]="offer.minExpiration"
												[(ngModel)]="offer.minExpiration"
											/>
                    </span>
                  </td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- Editable if staged as prefill -->
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        type="number"
                        [value]="offer.quantity"
                        [(ngModel)]="offer.quantity"
                      />
                    </span>
                  </td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- Editable if staged as prefill -->
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border border-gray-200 focus:border-blue-500 dark:border-gray-700"
                        type="number"
                        [value]="offer.price"
                        [(ngModel)]="offer.price"
                      />
                    </span>
                  </td>
                  <td class="p-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                      @if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.makeOfferModal = true"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }
                    </div>
                  </td>

									<td class="p-4 font-medium">
										<div class="flex flex-row space-x-4 justify-end">

											<button (click)="duplicateOffer( offer )">
												<ng-icon name="bootstrapCopy" />
											</button>

											<button>
												<ng-icon (click)="deleteOffer( offer )" name="saxTrashOutline" />
											</button>

										</div>
									</td>
                }

                @case (this.userFunctions.Importer) {
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <app-mhp-company [displayCountry]="true" [company]="this.getExporter(offer.exporterId)!" />
                  </td>
                  <!-- <td class="p-4 ">{{ item.requestId }}</td> -->
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										{{ offer.isToOrder ? this.ToOrderLabel : offer.batchNumber }}
									</td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="p-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                      @if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.makeOfferModal = true"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }
                    </div>
                  </td>
                }
              }
            </tr>
          }

          @empty {
            <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
              <td class="p-4">empty</td>
            </tr>
          }

        </tbody>
      </table>
    }
  </div>
}

@if (this.offerHistoryPanelDisplayed) {
  <app-main-request-offer-history
    [offer]="this.offerSelected!"
    [package]="this.package()"
    (stateClose)="this.offerHistoryPanelDisplayed = false"
  />
}

@if (this.makeOfferModal) {
  <app-main-request-make-offer-modal
    [exporter]="this.getExporter(this.offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="this.offerSelected!"
    [package]="this.package()"
    (stateClose)="this.makeOfferModal = false"
  />
}

@else if (this.acceptOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.getExporter(this.offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="this.offerSelected!"
    [package]="this.package()"
    [type]="this.offerResponseTypes.Accepted"
    (stateClose)="this.acceptOfferModal = false"
  />
}

@else if (this.declineOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.getExporter(this.offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="this.offerSelected!"
    [package]="this.package()"
    [type]="this.offerResponseTypes.Declined"
    (stateClose)="this.declineOfferModal = false"
  />
}
