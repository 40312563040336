import { Injectable, computed, inject, signal } from '@angular/core';

import { OfferActionEnum } from '../../enums/offer-action.enum';
import { OfferResponseTypeEnum } from '../../enums/offer-response-type.enum';
import { UserFunctionEnum } from '../../enums/user-function.enum';
import { OfferExtension } from '../../extensions/offer.extension';
import { CounterOffer } from '../../models/counter-offer.model';
import { ExporterStockEntry } from '../../models/exporter-stock-entry.model';
import { ImporterRequest } from '../../models/importer-request.model';
import { Offer } from '../../models/offer.model';
import { Package } from '../../models/package.model';
import { RequestItem } from '../../models/request-item.model';
import { toDictionary, toDictionarySet } from '../../utils/toDictionary';
import { AccountService } from '../account/account.service';
import { ApiExporterService } from '../api/api-exporter/api-exporter.service';
import { ApiOfferService } from '../api/api-offer/api-offer.service';
import { OfferActionToastService } from './offer-action-toast.service';

@Injectable({
	providedIn: 'root',
})
export class OffersService {
	private readonly _offerApiSvc = inject(ApiOfferService);
	private readonly _apiExporterSvc = inject(ApiExporterService);
	private readonly _accountSvc = inject(AccountService);
	private readonly _offerActionToastSvc = inject(OfferActionToastService);

	private _currentRequest: ImporterRequest = null!;

	private _currentRequestPackage: Record<number, Package> = {};

	readonly currentRequestOffers = signal<Offer[]>([]);

	private userCompany = computed(() => this._accountSvc.user().company);

	readonly waitingForActionOffers = computed<Offer[]>(() => {
		return this.currentRequestOffers().filter(offer =>
			OfferExtension.isActionPossible(offer, this._accountSvc.userFunction())
		);
	});

	readonly waitingForActionOffersCount = computed<number>(() => {
		return this.waitingForActionOffers().length;
	});

	readonly currentRequestItems = signal<RequestItem[]>([]);

	private readonly _newOffers = signal<Offer[]>([]);

	private readonly _editedOffers = signal<Offer[]>([]);

	readonly newOffers = computed<Offer[]>(this._newOffers);

	readonly editedOffers = computed<Offer[]>(this._editedOffers);

	readonly requestItemsDico = computed<Record<string, RequestItem>>(() => {
		return toDictionary(this._currentRequest.items, i => i.itemId!);
	});

	findItem(itemId: string) {
		return this.requestItemsDico()[itemId];
	}

	readonly currentRequestOffersByProduct = computed(() =>
		toDictionarySet(this.currentRequestOffers(), o => o.packageCip13 ?? 0)
	);

	pendingOffersPerProduct = computed<Record<number, Offer[]>>(() =>
		toDictionarySet(this.newOffers(), o => o.packageCip13 ?? 0)
	);

	underReviewOffers = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isInReview(o, this._accountSvc.userFunction()))
	);

	confirmedOffersOld = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isInConfirmed(o))
	);

	orderedOffers = computed<Offer[]>(() => this.currentRequestOffers().filter(o => OfferExtension.isInShipping(o)));

	confirmedOffers = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isInConfirmed(o) || OfferExtension.isInShipping(o))
	);

	/** Todo: case where there are several companies */
	otherCompany = computed(() =>
		this._accountSvc.userFunction() == UserFunctionEnum.Importer ?
			this._currentRequest?.exporters![0]
		:	this._currentRequest?.importer
	);

	// () => {
	// 	const orderedOffers = this.currentRequestOffers().filter(o => o.orderingDate);
	// 	return orderedOffers;
	// });

	constructor() {}

	/**
	 * Get offers for a request
	 * @param request the full request
	 */
	async initRequest(request: ImporterRequest): Promise<void> {
		this._currentRequest = request;

		// order request ites and group them by package
		this._currentRequestPackage = toDictionary(
			request.items.map(item => item.package),
			p => p.cip13
		);

		// short name of package is copied from the package
		const offers = request.offers.map(o => {
			return this.setOfferShortName(o);
		});

		this.currentRequestOffers.set(offers);

		this.currentRequestItems.set(request.items);

		this.resetEditedOffers(request.id, request.items);
	}

	private setOfferShortName(o: Offer) {
		o.shortName = this._currentRequestPackage[o.packageCip13]?.shortName ?? '';
		return o;
	}

	/**
	 * Reinit the currently edited offers
	 */
	private resetEditedOffers(requestId: string, requestItems: RequestItem[]): void {
		this._newOffers.set([]);
		this._editedOffers.set([]);

		// add an empty offer for each product
		this.createNewOffers(requestId, requestItems);
	}

	/**
	 * Create a new offer for the item in parameter
	 * @param cip13
	 * @returns the new offer
	 */
	createNewOfferForItem(requestId: string, item: RequestItem): Offer {
		return <Offer>{
			batchNumber: '',
			exporterId: this.userCompany().id,
			packageCip13: item.package.cip13,
			shortName: item.package.shortName ?? '',
			price: 0,
			quantity: 0,
			itemId: item.itemId,
			requestId,
			exporterResponse: OfferResponseTypeEnum.None,
			importerResponse: OfferResponseTypeEnum.None,
		};
	}

	/**
	 * Create new offers for the items in parameter
	 * @param requestId
	 * @param requestItems
	 */
	private createNewOffers(requestId: string, requestItems: RequestItem[]): void {
		const newOffers: Offer[] = [];
		for (const item of requestItems) {
			const newOffer = this.createNewOfferForItem(requestId, item);
			newOffer.isToOrder = true;
			newOffers.push(newOffer);
		}
		this._newOffers.set(newOffers);
	}

	/**
	 * Delete offer in parameter from the list of offers
	 * @param offerToDelete
	 */
	deletePendingOffer(offerToDelete: Offer): void {
		const newOffers = this._newOffers().filter(o => o !== offerToDelete);
		this._newOffers.set(newOffers);

		// if we delete the last one of a product, we must add a new one
		const offerItem = this._currentRequest.items.find(i => i.itemId === offerToDelete.itemId);

		if (!offerItem) throw new Error(`Item ${offerToDelete.itemId} of the offer not found in the request`);

		if (!this.pendingOffersPerProduct()[offerToDelete.packageCip13]) {
			const newOffer = this.createNewOfferForItem(offerToDelete.requestId, offerItem);
			this._newOffers.set([...this._newOffers(), newOffer]);
		}
	}

	/**
	 * Delete offer in parameter from the list of offers
	 * @param offerToDelete
	 */
	deleteConfirmedOffer(offerToDelete: Offer): void {
		const editOffers = this._editedOffers().filter(o => o !== offerToDelete);
		this._editedOffers.set(editOffers);
	}

	/**
	 * Duplicate offer in parameter from the list of offers
	 * @param offerToDelete
	 * @returns the duplicated offer
	 */
	duplicatePendingOffer(offerToDuplicate: Offer): void {
		const duplicateOffer = { ...offerToDuplicate };
		duplicateOffer.createdAt = undefined!;
		duplicateOffer.updatedAt = undefined!;
		duplicateOffer.id = undefined!;
		duplicateOffer.chatRoom = undefined!;
		const newOffers = [...this._newOffers(), duplicateOffer];
		this._newOffers.set(newOffers);
	}

	/**
	 * Prefill offers with stock
	 *
	 * @refacto prefill offers that are directly managed by the offer service instead of new ones each time
	 */
	async prefillOffersWithStock(requestItems: RequestItem[]): Promise<void> {
		// Get stocks and quotas
		const stockForRequestPromise = this._apiExporterSvc.getAvailableStocksForRequest(this._currentRequest.id);
		const quotasForRequestPromise = this._apiExporterSvc.getAvailableQuotasForRequest(this._currentRequest.id);

		// parallelize the requests
		const stockEntries = await stockForRequestPromise;
		const quotas = await quotasForRequestPromise;
		const emptyOffers: Offer[] = [];

		const itemsUsage = requestItems.map(i => {
			return { item: i, withStockQuota: false };
		});

		const cip13Dico = toDictionary(itemsUsage, i => i.item.package.cip13);

		// fill offers from the stocks
		const stockOffers = stockEntries.map(stockEntry => {
			const usedItem = cip13Dico[stockEntry.packageCip13];
			if (!usedItem) throw new Error(`Item ${stockEntry.packageCip13} not found found in the request`);
			usedItem.withStockQuota = true;

			const newOffer = this.createNewOfferForItem(this._currentRequest.id, usedItem.item);
			newOffer.packageCip13 = stockEntry.packageCip13;
			newOffer.shortName = usedItem.item.package.shortName ?? '';
			newOffer.batchNumber = stockEntry.batch?.id ?? null;
			newOffer.expirationDate = stockEntry.batch?.expiration ?? null;
			newOffer.quantity = stockEntry.quantity ?? 0;
			newOffer.price = stockEntry.price ?? 0;
			return newOffer;
		});

		// fill offers from the quotas
		const quotaOffers = quotas.map(quota => {
			const usedItem = cip13Dico[quota.packageCip13];
			if (!usedItem) throw new Error(`Item ${quota.packageCip13} not found in the request`);
			usedItem.withStockQuota = true;

			const newOffer = this.createNewOfferForItem(this._currentRequest.id, usedItem.item);
			newOffer.packageCip13 = quota.packageCip13;
			newOffer.shortName = usedItem.item.package.shortName ?? '';
			newOffer.batchNumber = null;
			newOffer.expirationDate = null;
			newOffer.quantity = quota.quota;
			newOffer.price = 0;
			return newOffer;
		});

		// fill the missing offers
		itemsUsage
			.filter(item => !item.withStockQuota)
			.forEach(i => {
				const newOffer = this.createNewOfferForItem(this._currentRequest.id, i.item);
				newOffer.packageCip13 = i.item.package.cip13;
				newOffer.shortName = i.item.package.shortName ?? '';
				newOffer.quantity = 0;
				newOffer.price = 0;
				emptyOffers.push(newOffer);
			});

		this._newOffers.set([...stockOffers, ...quotaOffers, ...emptyOffers]);
	}

	/**
	 * Fill the batch numbers of offers without batch number, and fill expiration date
	 */
	async importBatches(): Promise<void> {
		// Get response from API for stocks comparison
		const resp = await this._apiExporterSvc.getAvailableStocksForRequest(this._currentRequest.id);

		const emptyBatchOffers: Offer[] = this.currentRequestOffers().filter(
			offer => offer.confirmationDate && !offer.batchNumber
		);

		// Group stock entrie's packages by CIP13
		const lastUpdatedPackages: { [key: number]: ExporterStockEntry[] } = resp.reduce(
			(acc: { [key: number]: ExporterStockEntry[] }, packge) => {
				const cip13 = packge.packageCip13;
				if (acc[cip13]) acc[cip13].push(packge);
				else acc[cip13] = [packge];
				return acc;
			},
			{}
		);

		// fill the missing batch numbers of offers without batch number
		for (const emptyBatchOffer of emptyBatchOffers) {
			const cip13 = emptyBatchOffer.packageCip13 ?? 0;
			const foundPackages = lastUpdatedPackages[cip13];
			if (foundPackages) {
				const selectedPackage = OfferExtension.selectBatchNumberFromEntriesRule(foundPackages);

				if (selectedPackage) {
					emptyBatchOffer.batchNumber = selectedPackage.id!;
					emptyBatchOffer.expirationDate = selectedPackage.expiration;
				}
			}
		}
	}

	/**
	 * Add or update an offer
	 * @param offer
	 */
	onOfferUpdatedOrAdded(offer: Offer): void {
		console.log('reveived updated offer', offer);

		if (offer.requestId !== this._currentRequest.id)
			throw new Error('Cannot add or update offer with different request');

		if (!this._currentRequestPackage[offer.packageCip13])
			throw new Error('Cannot add an offer with a not existing item in the request');

		// set offer shorname
		offer.shortName = this._currentRequestPackage[offer.packageCip13].shortName ?? '';

		const offers = this.currentRequestOffers();

		// search the offer to replace
		let offerIndex = offers.findIndex(o => o.id === offer.id);

		// if it is a counter offer and that counter offer is not found, find the parent offer to replace
		const parentOfferId = (<CounterOffer>offer).parentOfferId;
		if (!!parentOfferId && offerIndex == -1) offerIndex = offers.findIndex(o => o.id === parentOfferId);

		// is there an offer to replace ?
		if (offerIndex != -1) {
			if (offer.packageCip13 !== offers[offerIndex].packageCip13)
				throw new Error('Cannot update offer with different package');

			// replace the offer
			this.currentRequestOffers.update(offers => {
				offers[offerIndex] = offer;
				return [...offers];
			});
			return;
		}
		// case: new offer
		else this.currentRequestOffers.update(offers => [...offers, offer]);
	}

	/**
	 * Send all offers to the server
	 */
	async sendValidNewOffers(): Promise<void> {
		let offersToSend = this.newOffers().filter(offer => OfferExtension.isValidNewOffer(offer));

		// 1. set to order empty batches
		offersToSend
			.filter(offer => !offer.batchNumber)
			.forEach(offer => {
				offer.isToOrder = true;
			});

		// 2. create declined offers for items that are not provided by the offers
		const declinedOffers = this.createDeclinedOffersForNotProvidedItems(offersToSend.map(offer => offer.packageCip13));
		offersToSend = [...offersToSend, ...declinedOffers];

		// 3. send them all
		await this._offerApiSvc.sendOffer(offersToSend);

		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.Create, offersToSend);
	}

	/**
	 * Create declined offers for items that are not provided by the offers
	 * @param products list of products provided by the offers
	 */
	private createDeclinedOffersForNotProvidedItems(products: number[]): Offer[] {
		const declinedOffers: Offer[] = [];

		// list all products provided by the offers
		const productsProvided = new Set(products);

		// list all products with already existing offers (declined or not)
		const productsWithExistingOffer = new Set(this.currentRequestOffers().map(offer => offer.packageCip13));

		// list all products without an existing offer and not provided by this offer
		const itemsWithoutOffer = this._currentRequest.items.filter(
			item => !productsProvided.has(item.package.cip13) && !productsWithExistingOffer.has(item.package.cip13)
		);

		const currentDatetime = new Date().toISOString();

		for (const item of itemsWithoutOffer) {
			const newDeclinedOffer = this.createNewOfferForItem(this._currentRequest.id, item);
			newDeclinedOffer.exporterResponded = currentDatetime;
			newDeclinedOffer.exporterResponse = 2; // declined
			declinedOffers.push(newDeclinedOffer);
		}

		return declinedOffers;
	}

	/**
	 * Order the list of offers
	 */
	async orderOffers(offers: Offer[]) {
		const orderedOffers = await Promise.all(
			offers.filter(offer => OfferExtension.isReadyToOrder(offer)).map(offer => this._offerApiSvc.orderOffer(offer.id))
		);
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.Order, orderedOffers);
	}

	/**
	 * Order the list of offers
	 */
	editOffers(): void {
		const editedOffers: Offer[] = [];

		for (const offer of this.confirmedOffersOld()) {
			editedOffers.push({ ...offer });
		}

		this._editedOffers.set(editedOffers);
	}

	/**
	 * Cancel editing offers
	 */
	cancelEditingOffers() {
		this._editedOffers.set([]);
	}

	/**
	 * Validate edited offers, reset edited offers and do a refresh of offers from API
	 */
	async validateEditedOffers(): Promise<void> {
		await Promise.all(
			this.editedOffers().map(offer => {
				// send the offer to update it
				this._offerApiSvc.sendOffer([offer]);

				// set as confirmed if there is a batch number to increase the quantity in stocks
				if (offer.batchNumber && offer.isToOrder && !offer.confirmationDate) {
					this._offerApiSvc.sendToPurchaseOffer(offer.id);
				}
			})
		);
		this._editedOffers.set([]);
	}

	/** Update an offer and if there is a batch number, send it purchase */
	async updateOffer(offer: Offer): Promise<void> {
		// send the offer to update it
		await this._offerApiSvc.sendOffer([offer]);

		// set as confirmed if the offer is ready to purchase
		if (OfferExtension.isReadyToPurchase(offer)) {
			await this._offerApiSvc.sendToPurchaseOffer(offer.id);
		}

		const updatedOffer = await this._offerApiSvc.getOffer(offer.id);
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.Update, [updatedOffer]);
	}

	/**
	 * Validate an offer for shipping
	 */
	async validateBatch(offer: Offer): Promise<void> {
		const validatedOffer = await this._offerApiSvc.validateBatch(offer.id);
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.ValidateBatch, [validatedOffer]);
	}

	/**
	 * Book the list of offers
	 */
	async purchaseOffers(offersToPurchase: Offer[]): Promise<void> {
		const purchasedOffers = await Promise.all(
			offersToPurchase.map(offer => this._offerApiSvc.sendToPurchaseOffer(offer.id))
		);
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.Purchase, purchasedOffers);
	}

	/**
	 * Return the list of offers declined
	 */
	readonly declinedOffers = computed<Offer[]>(() =>
		this.currentRequestOffers().filter(o => OfferExtension.isOfferDeclined(o))
	);

	/**
	 * accept some offers
	 */
	async acceptOffers(offers: Offer[]): Promise<void> {
		const acceptedOffers = await Promise.all(offers.map(offer => this._offerApiSvc.acceptOffer(offer.id)));
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.Accept, acceptedOffers);
	}

	/**
	 * Decline some offers
	 */
	async declineOffers(offers: Offer[]): Promise<void> {
		const declinedOffers = await Promise.all(offers.map(offer => this._offerApiSvc.declineOffer(offer.id)));
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.Decline, declinedOffers);
	}

	/**
	 * Send a counter offer on an offer, with a specified quantity and price
	 */
	async sendCounterOffer(offer: Offer, quantity: number, price: number) {
		const counterOffer = await this._offerApiSvc.sendCounterOffer(offer.id, <CounterOffer>{
			requestId: offer.requestId,
			exporterId: this.userCompany().id!,
			packageCip13: offer.packageCip13,
			batchNumber: offer.batchNumber,
			expirationDate: offer.expirationDate,
			itemId: offer.itemId,
			quantity: quantity,
			minQuantity: offer.minQuantity,
			multiple: offer.multiple,
			price: price,
			parentOffer: offer,
			initiatedById: offer.exporterId,
			confirmationDate: null,
			orderingDate: null,
			isToOrder: offer.isToOrder,
		});
		this._offerActionToastSvc.showSuccessfulActionMessage(OfferActionEnum.CounterOffer, [counterOffer]);
	}

	/**
	 * Check if the offer is accepter by the user
	 */
	isOfferAccepted(offer: Offer): boolean {
		return OfferExtension.isOfferAccepted(offer, this._accountSvc.userFunction());
	}

	/**
	 * Check if the offer is accepted by the exporter
	 */
	getImporterRequestItem(itemId: string): RequestItem {
		const item = this._currentRequest.items.find(i => i.itemId === itemId);
		if (!item) throw new Error('Item not found');
		return item;
	}
}
