import { Pipe, PipeTransform } from '@angular/core';
import { UserFunctionEnum } from '../../enums/user-function.enum';

@Pipe({
	name: 'toUserFunction',
	standalone: true
})
export class ToUserFunctionPipe implements PipeTransform {

	transform(value: number): string {
		switch(value) {
			case UserFunctionEnum.Importer: return 'Importer';
			case UserFunctionEnum.Exporter: return 'Exporter';
			case UserFunctionEnum.ImporterExporter: return 'Importer & Exporter';
			case UserFunctionEnum.Broker: return 'Broker';
			case UserFunctionEnum.BrokerImporter: return 'Broker & Importer';
			case UserFunctionEnum.BrokerExporter: return 'Broker & Exporter';
		}

		return '';
	}

}
