import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { saxImportOutline, saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ImporterRequestDto } from '../_common/dto/importer-request.dto';

import { SITEMAP } from '../_common/sitemap';

import { MhpCompanySelectorComponent } from '../_common/components/mhp-company-selector/mhp-company-selector.component';
import { MhpFileUploaderComponent } from '../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { MhpInputComponent } from '../_common/components/mhp-input/mhp-input.component';
import { MhpSuggestionsCard } from '../_common/components/mhp-new-request-button/mhp-suggestions-card.component';
import { MhpPackageComponent } from '../_common/components/mhp-package/mhp-package.component';
import { PackageDto } from '../_common/dto/package.dto';
import { RequestItemDto } from '../_common/dto/request-item.dto';
import { RequestTypeEnum } from '../_common/enums/request-type.enum';
import { UserFunctionEnum } from '../_common/enums/user-function.enum';
import { ImporterRequestExtension } from '../_common/extensions/importer-request.extension';
import { Company } from '../_common/models/company.model';
import { ApiImporterService } from '../_common/services/api/api-importer/api-importer.service';
import { ApiPackageService } from '../_common/services/api/api-package/api-package.service';
import { CompanyService } from '../_common/services/company/company.service';
import { ToastService } from '../_common/services/toast/toast.service';
import { TableHandler } from '../_common/utils/table-handler';
import { NewRequestConfirmComponent } from './new-request-confirm/new-request-confirm.component';

interface NewRequestProps {
	exporter?: Company;
	requestType?: RequestTypeEnum;
	step?: number;
	userRequestId?: string;
}

@Component({
	selector: 'app-new-request',
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgIconComponent,
		MhpSuggestionsCard,
		MhpCompanySelectorComponent,
		MhpInputComponent,
		MhpPackageComponent,
		MhpFileUploaderComponent,
		NewRequestConfirmComponent,
	],
	providers: [
		DatePipe,
		provideIcons({
			saxImportOutline,
			saxInfoCircleOutline,
			saxTickCircleBold,
			saxTrashOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	templateUrl: './new-request.component.html',
})
export class NewRequestComponent implements OnInit {
	private readonly router = inject(Router);
	private readonly _companySvc = inject(CompanyService);
	private readonly _apiImporter = inject(ApiImporterService);
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _toastSvc = inject(ToastService);

	protected _tableSvc: TableHandler<RequestItemDto> = new TableHandler<RequestItemDto>(
		this._apiPackageSvc,
		'new-request',
		row =>
			<RequestItemDto>{
				batchNumber: row['Batch number'] || null,
				minExpiration: row['Min expiration date'],
				quantity: +(row['Quantities'] ?? '').replace(/[^\d,.]/g, '') || null,
				minQuantity: +(row['Qty min'] ?? '').replace(/[^\d,.]/g, '') || null,
				multiple: +(row['Multiple'] ?? '').replace(/[^\d,.]/g, '') || null,
				price: +(row['Price'] ?? '').replace(/[^\d,.]/g, '') || null,
				package: <PackageDto>{
					cip13: +row['CIP 13'],
					companyInternalCode: row['Internal code'],
					shortName: row['Product name'],
				},
			}
	);

	protected readonly sitemap = SITEMAP;
	protected readonly requestTypes = RequestTypeEnum;
	protected readonly userFunctions = UserFunctionEnum;
	protected get packageItems() {
		return this._tableSvc.items;
	}

	step: number = 0;
	requestType: RequestTypeEnum = RequestTypeEnum.PurchaseOrder;
	exporter?: Company;
	userRequestId?: string;
	expiryDate?: string;
	minOrderPrice?: number;
	deliveryTerms?: string;
	paymentTerms?: string;
	dayjs = dayjs;

	protected readonly exporters = this._companySvc.allCompanies;

	// TODO: make exporter not nullable in order to get a more robust code
	csvFileUploaded: boolean = false;
	getUIRequestId = ImporterRequestExtension.getUIRequestId;

	/// downloadable template url for stock csv file
	readonly purchaseOrderTemplateCsvPath = '/assets/download/purchase-order.template.csv';

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Initializes the component, and the table service
	 */
	ngOnInit(): void {
		this._tableSvc.initFromLocalStorage();
	}

	async submit(): Promise<void> {
		const request = await this._apiImporter.postRequest(<ImporterRequestDto>{
			type: this.requestType!,
			userRequestId: this.userRequestId || null,
			minOrderPrice: this.minOrderPrice,
			deliveryTerms: this.deliveryTerms,
			paymentTerms: this.paymentTerms,
			expiryDate: this.expiryDate,
			exporters: this.exporter ? [this.exporter] : [],
			items: this.packageItems().map((packageItem: RequestItemDto) => {
				packageItem.minExpiration = dayjs(packageItem.minExpiration, TableHandler.dateParsingFormats, true).format(
					'YYYY-MM-DD'
				);
				return packageItem;
			}),
		});
		const exporterName: string = request.exporters![0].displayName!;

		this.reset();
		this.router.navigate([this.sitemap.dashboard.route]);

		this._toastSvc.success(
			`Your request #${this.getUIRequestId(request.id, request.userRequestId)} has been sent to ${exporterName}.`
		);
	}

	updateExporter(exporter: Company): void {
		this.exporter = exporter;
	}

	processingFiles(file: File | null): void {
		if (file) {
			this._tableSvc.processFile(file);
			this.nextStep();
		}

		this.csvFileUploaded = !!file;
	}

	deleteItem(index: number): void {
		this.packageItems().splice(index, 1);
	}

	updateRequestType(requestType: number): void {
		this.requestType = requestType;
		this.nextStep();
	}

	setStep(step: number): void {
		this.step = step;
	}

	nextStep(): void {
		if (this.step === 4) return;
		this.step++;
	}

	previousStep(): void {
		if (this.step === 0) return;
		this.step--;
	}

	reset(): void {
		this._tableSvc.clearCsvFile();
		this.step = 0;
		this.requestType = RequestTypeEnum.PurchaseOrder;
	}

	canContinue(): boolean {
		switch (this.step) {
			case 1:
				return this.csvFileUploaded;
			case 2:
				return !!this.packageItems().length;
			case 3:
				return !!this.exporter;
			case 4:
				return true;
		}

		return false;
	}
}
