import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ImporterRequestDto } from 'src/app/_common/dto/importer-request.dto';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';

import { ManifestItemSearchCriteria } from 'src/app/_common/models/manifest-item-search-criteria.model';
import { ManifestItem } from 'src/app/_common/models/manifest-item.model';
import { environment } from 'src/environments/environment';
import { ImporterTasks } from '../../../models/importer-tasks.model';

/**
 * Service handling API operations for importer entities.
 *
 * Provides methods for:
 * - Creating and retrieving importer requests
 * - Managing importer tasks
 * - Searching exporter manifest items
 * - Counting manifest packages
 *
 * Note: All operations are only available to and used by authorized importers.
 */
@Injectable({
	providedIn: 'root',
})
export class ApiImporterService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly IMPORTER_ROUTE: string = 'importer';

	constructor() {}

	/**
	 * Creates a new importer request.
	 * Only available to importers.
	 */
	postRequest(dto: ImporterRequestDto): Promise<ImporterRequest> {
		return firstValueFrom(this.http.post<ImporterRequest>(`${this.API_URL}/${this.IMPORTER_ROUTE}/request`, dto));
	}

	/**
	 * Retrieves requests for the importer with the specified ID.
	 * Only available to importers.
	 */
	getRequests(): Promise<ImporterRequest[]> {
		return firstValueFrom(this.http.get<ImporterRequest[]>(`${this.API_URL}/${this.IMPORTER_ROUTE}/requests`));
	}

	/**
	 * Gets tasks assigned to the importer.
	 * Only available to importers.
	 */
	getTasks(): Promise<ImporterTasks> {
		return firstValueFrom(this.http.get<ImporterTasks>(`${this.API_URL}/${this.IMPORTER_ROUTE}/tasks`));
	}

	/**
	 * Searches for manifest items from a specific exporter based on provided criteria.
	 * Only available to importers.
	 */
	findExporterManifestItems(
		exporterId: number,
		searchCriteria: ManifestItemSearchCriteria,
		skippedItemsCount: number,
		wantedItemsCount: number
	): Observable<ManifestItem[]> {
		let params = new HttpParams()
			.append('skippedItemsCount', skippedItemsCount.toString())
			.append('wantedItemsCount', wantedItemsCount.toString());

		searchCriteria.types.forEach(type => {
			params = params.append('types', type);
		});

		if (searchCriteria?.cip13OrProductShortNamePart) {
			params = params.append('cip13OrProductShortNamePart', searchCriteria.cip13OrProductShortNamePart);
		}

		return this.http.get<ManifestItem[]>(`${this.API_URL}/${this.IMPORTER_ROUTE}/${exporterId}/manifest/items`, {
			params,
		});
	}

	/**
	 * Counts the number of manifest packages from a specific exporter that match the search criteria.
	 * Only available to importers.
	 */
	countManifestPackages(exporterId: number, searchCriteria: ManifestItemSearchCriteria): Observable<number> {
		let params = new HttpParams();

		searchCriteria.types.forEach(type => {
			params = params.append('types', type);
		});

		if (searchCriteria.cip13OrProductShortNamePart) {
			params = params.append('cip13OrProductShortNamePart', searchCriteria.cip13OrProductShortNamePart);
		}

		return this.http.get<number>(`${this.API_URL}/${this.IMPORTER_ROUTE}/${exporterId}/manifest/items/packages/count`, {
			params,
		});
	}
}
