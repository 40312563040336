@if (this.package()) {
  <a
    class="flex flex-row items-center p-3 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
    [routerLink]="[ this.sitemap.package.route, this.package().cip13 ]"
  >
    <div class="mr-3 p-3 shrink-0 border rounded-full ">
      <app-mhp-svg-icon icon="pill" [size]="16"/>
    </div>
    <div class="w-full">
      <h4 class="text-sm">{{ this.package().cip13 | toCip : 13 }}</h4>
      <h3 >{{ this.package().shortName }}</h3>
    </div>
    <div class="shrink-0 flex justify-center items-center p-2 bg-gray-50 dark:bg-gray-900 rounded-md">
      <ng-icon  name="saxArrowRight1Outline"></ng-icon>
    </div>
  </a>
}
