import { Component, computed, HostListener, inject, input, model, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from 'src/app/_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from 'src/app/_common/components/mhp-input/mhp-input.component';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { PackageDto } from 'src/app/_common/dto/package.dto';
import { RequestItemDto } from 'src/app/_common/dto/request-item.dto';
import { RequestTypeEnum } from 'src/app/_common/enums/request-type.enum';
import { Company } from 'src/app/_common/models/company.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { ApiImporterService } from 'src/app/_common/services/api/api-importer/api-importer.service';
import { MessagingService } from 'src/app/_common/services/messaging/messaging.service';
import { SITEMAP } from 'src/app/_common/sitemap';
import { OfferResponseTypeEnum } from '../../../_common/enums/offer-response-type.enum';
import { Offer } from '../../../_common/models/offer.model';
import { Package } from '../../../_common/models/package.model';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { ApiOfferService } from '../../../_common/services/api/api-offer/api-offer.service';
import { MainPackageRequest } from './main-package-request-product-modal.model';

@Component({
	selector: 'app-main-package-request-product-modal',
	imports: [RouterModule, MhpButtonComponent, MhpFromToComponent, MhpInputComponent, MhpSvgIconComponent, ToCipPipe],
	styleUrl: './main-package-request-product-modal.component.scss',
	templateUrl: './main-package-request-product-modal.component.html',
})
export class MainPackageRequestProductModalComponent implements OnInit {
	private readonly _accountSvc = inject(AccountService);
	private readonly _apiImporterSvc = inject(ApiImporterService);
	private readonly _offerSvc = inject(ApiOfferService);
	private readonly _messagingService = inject(MessagingService);

	readonly sitemap = SITEMAP;
	readonly requestTypes = RequestTypeEnum;

	readonly product = input.required<Package>();
	readonly exporter = input.required<Company>();
	readonly request = input.required<MainPackageRequest>();
	readonly isOpen = model(false);
	readonly user = computed<User>(this._accountSvc.user);

	price: string = '';
	quantity: string = '';
	quota: string = '';
	messageToExporter: string = '';
	requestId: string | null = null;
	step: number = 1;

	ngOnInit(): void {
		this.price = this.request().price?.toString() ?? '0';
		this.quantity = this.request().quantity?.toString() ?? this.request().quota?.toString() ?? '0';
	}

	@HostListener('document:keydown', ['$event'])
	triggerClosing(e: any): void {
		if (e.keyCode === 27) {
			this.close();
		}
	}

	close(): void {
		this.isOpen.set(false);
	}

	async submit() {
		const createdRequest = await this._apiImporterSvc.postRequest({
			userRequestId: null,
			type: this.requestTypes.RequestOffer,
			exporters: [this.exporter()],
			minOrderPrice: undefined,
			deliveryTerms: '',
			paymentTerms: '',
			expiryDate: undefined,
			items: [
				<RequestItemDto>{
					batchNumber: this.request().batch?.id! || null,
					minExpiration: this.request().batch?.expiration ?? null,
					quantity: this.quantity ? +this.quantity : null,
					quota: this.quota ? +this.quota : null,
					price: this.price ? +this.price : null,
					package: <PackageDto>{
						cip13: this.product().cip13!,
						shortName: this.product().shortName,
					},
				},
			],
		});

		if (createdRequest.items.length !== 1)
			throw new Error(`Invalid request ${createdRequest.id} items, there should be exactly one item`);

		this.requestId = createdRequest.id;
		const requestItem = createdRequest.items[0];

		const newOffer = <Offer>{
			requestId: createdRequest.id,
			itemId: requestItem.itemId,
			exporterId: this.exporter().id,
			isToOrder: !!this.request().quantity || !requestItem.batchNumber,
			packageCip13: requestItem.package.cip13,
			batchNumber: requestItem.batchNumber ?? null,
			expirationDate: requestItem.minExpiration,
			quantity: requestItem.quantity,
			minQuantity: 0,
			multiple: 0,
			price: requestItem.price ?? 0,
			importerResponse: OfferResponseTypeEnum.Accepted,
			importerResponded: new Date(Date.now()).toISOString(),
		};

		this._messagingService.createChatRoomFromOffer(newOffer, this.messageToExporter);

		await this._offerSvc.sendOffer([newOffer]);

		this.step++;
	}
}
