import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { letsArrowRight } from '@ng-icons/lets-icons/regular';

@Component({
	selector: 'mhp-value-change',
	templateUrl: './mhp-value-change.component.html',
	styles: [
		`
			:host {
				display: flex;
				gap: 6px;
				align-items: start;
			}
		`,
	],
	standalone: true,
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			letsArrowRight,
		}),
	],
})
export class MhpValueChangeComponent {
	formerValueVisible = input(false);
}
