import { Component, inject, OnInit, ViewContainerRef } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MhpLoaderComponent } from './_common/components/mhp-loader/mhp-loader.component';
import { MHP_SVG_ICON_TYPES } from './_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { ToastComponent } from './toast/toast.component';

@Component({
	selector: 'app-root',
	imports: [RouterModule, MatIconModule, ToastComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	private readonly _viewContainerRef = inject(ViewContainerRef);
	private readonly _iconRegistry = inject(MatIconRegistry);
	private readonly _sanitizer = inject(DomSanitizer);

	constructor() {
		for (let iconName of MHP_SVG_ICON_TYPES) {
			const iconUrl = `/assets/icons/${iconName}.svg`;
			const safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(iconUrl);
			this._iconRegistry.addSvgIcon(iconName, safeUrl);
		}
	}

	ngOnInit(): void {
		this._viewContainerRef.createComponent(MhpLoaderComponent);
	}
}
