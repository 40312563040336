<app-mhp-base-modal
  [title]="type() === offerResponseTypes.Accepted ? 'Are you sure you want to accept this offer?' : 'Are you sure you want to decline this offer?'"
  [actionButtonText]="type() === offerResponseTypes.Accepted ? 'Yes, I accept this offer' : 'Yes, I decline this offer'"
  [actionButtonPrefix]="'saxSend2Outline'"
  (action)="submitConfirm()"
  (close)="handleClose()"
>
  <div class="flex flex-col w-full space-y-6">
    <div class="flex flex-col w-full space-y-8">
      <div class="flex flex-col w-full space-y-6 p-4 border rounded-2xl">
        <app-mhp-company [company]="exporter()" />
        <span>{{ offer().shortName }}</span>
        <div class="rounded-2xl overflow-hidden">
          <table class="w-full">
            <thead>
              <tr class="text-[var(--text-secondary)] bg-gray-100 dark:bg-gray-950">
                <th class="py-2">Batch</th>
                <th class="py-2">Expiration date</th>
                <th class="py-2">Qty</th>
                <th class="py-2">Unit cost</th>
              </tr>
            </thead>
            <tbody>
              <tr class="opacity-90 bg-gray-50 dark:bg-gray-900">
                <td class="py-6">{{ getOfferLabel() }}</td>
                <td class="py-6">{{ offer().expirationDate | date : 'd MMM y' : '' : "en-US" }}</td>
                <td class="py-6">{{ offer().quantity }}</td>
                <td class="py-6">{{ offer().price | currency : 'EUR': 'symbol' : '.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</app-mhp-base-modal>
