import { Component, input, Input } from '@angular/core';

@Component({
	selector: 'app-mhp-svg-icon',
	standalone: true,
	imports: [],
	templateUrl: './mhp-svg-icon.component.html'
})
export class MhpSvgIconComponent {

	public class = input<string>();
	public icon = input.required<string>();

}
