<div class="mhp-exporter-selector-component">
  <div class="mhp-exporter-selector-component-search">
    <div class="flex justify-center items-center text-gray-500 dark:text-white">
      <ng-icon name="saxSearchNormal1Outline" />
    </div>
    <input
      class="w-full text-gray-900 dark:text-white"
      placeholder="Search for an exporter"
      type="text"
      [(ngModel)]="this.searchField"
      (ngModelChange)="this.search()"
    />

    @if (this.searchField) {
      <button
        class="flex justify-center items-center text-gray-500 dark:text-white"
        (click)="this.searchField = ''; this.search()"
      >
        <ng-icon name="saxCloseCircleBold" />
      </button>
    }
  </div>

  <div class="mhp-exporter-selector-component-layout">
    <span class="text-gray-900 dark:text-white">
      @if (this.searchField) { Search results }
      @else { {{ this.description() }} }
    </span>

    <div class="flex flex-col space-y-4">

      @for (exporter of this.exporterDisplay(); track exporter) {
        <button
          class="flex flex-row space-x-4 p-4 items-center border rounded-xl text-gray-900 dark:text-white"
          [ngClass]="this.selected()?.id === exporter.id ? 'border-blue-600 dark:border-blue-600' : 'border-gray-200 dark:border-gray-700'"
          (click)="this.select(exporter)"
        >
          <app-mhp-company class="w-full" [company]="exporter" />
          <div class="flex justify-center items-center shrink-0">
            @if (this.selected()?.id === exporter.id) {
              <ng-icon class="text-blue-600" name="saxTickCircleBold" size="1.75rem" />
            }

            @else {
              <app-mhp-svg-icon icon="void-circle" />
            }
          </div>
        </button>
      }

      @empty {
        <span class="text-gray-900 dark:text-white">No match results</span>
      }

    </div>
  </div>
</div>