<div class="flex flex-col justify-center mt-10">
  <h2 class="text-2xl text-center font-semibold mb-8 text-gray-900 dark:text-white">Make an offer</h2>

  <div class="flex flex-col space-y-4">
    <mhp-suggestions-card
      icon="magic-stars"
      iconBg="bg-[#54c7e320]"
      title="Auto-filled with stock & quotas"
      text="Just review, modify and send the offer."
      (click)="choosePrefill()" />

    <mhp-suggestions-card
      icon="pen"
      iconBg="bg-[#3971ff20]"
      title="Manually"
      text="You enter line by line what you want to offer."
      (click)="chooseManual()" />
  </div>
</div>