import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Company } from 'src/app/_common/models/company.model';

@Injectable({
	providedIn: 'root',
})
export class ApiCompanyService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly COMPANY_ROUTE: string = 'company';

	constructor() {}

	public getAll(): Promise<HttpResponse<Company[]>> {
		return firstValueFrom(this.http.get<Company[]>(`${this.API_URL}/${this.COMPANY_ROUTE}/`, { observe: 'response' }));
	}

	public getById(id: number): Promise<HttpResponse<Company | null>> {
		return firstValueFrom(
			this.http.get<Company | null>(`${this.API_URL}/${this.COMPANY_ROUTE}/${id}`, { observe: 'response' })
		);
	}

	public getOwn(): Promise<HttpResponse<Company | null>> {
		return firstValueFrom(
			this.http.get<Company | null>(`${this.API_URL}/${this.COMPANY_ROUTE}/own`, { observe: 'response' })
		);
	}
}
