<div class="flex-1 flex flex-col my-8 lg:my-12 mx-8 lg:mx-12">
	<a class="self-start flex" [routerLink]="[ this.sitemap.main.route ]">
		<app-mhp-svg-logo class="h-10 w-auto"></app-mhp-svg-logo>
	</a>

	<div class="flex-auto flex flex-col justify-center mx-auto w-80 lg:w-[28.5rem]">
		<router-outlet></router-outlet>
	</div>

	<div class="text-sm text-[var(--text-secondary)] font-normal text-center">By using MedHubPlace, you agree to the
		<a class="text-sm text-[var(--text-primary)] font-semibold" href="https://medhubplace.com" target="_blank">Terms of
			Service & Privacy Policy</a>
	</div>
</div>

<div class="hidden lg:flex flex-1 bg-[#000B29] flex-col items-center justify-center">
	<div class="text-white text-3xl font-semibold text-center mx-36 z-10">Every day, more than 20,000 pharmaceutical
		products from France are available on MedHubPlace.</div>

	<svg class="absolute top-20 right-52" xmlns="http://www.w3.org/2000/svg" width="105" height="120"
		viewBox="0 0 105 120" fill="none">
		<rect y="31.6569" width="8" height="8" transform="rotate(-45 0 31.6569)" fill="url(#paint0_linear_4892_145847)" />
		<rect x="66.5996" y="117.79" width="2.72047" height="2.72047" transform="rotate(-45 66.5996 117.79)"
			fill="url(#paint1_linear_4892_145847)" />
		<rect x="101" y="1.92366" width="2.72047" height="2.72047" transform="rotate(-45 101 1.92366)" fill="#3970FF" />
		<defs>
			<linearGradient id="paint0_linear_4892_145847" x1="4" y1="31.6569" x2="4" y2="39.6569"
				gradientUnits="userSpaceOnUse">
				<stop stop-color="#3970FF" />
				<stop offset="1" stop-color="#4CB3E6" />
			</linearGradient>
			<linearGradient id="paint1_linear_4892_145847" x1="67.9598" y1="117.79" x2="67.9598" y2="120.511"
				gradientUnits="userSpaceOnUse">
				<stop stop-color="#3970FF" />
				<stop offset="1" stop-color="#4CB3E6" />
			</linearGradient>
		</defs>
	</svg>


	<div class="absolute top-0 right-0 overflow-hidden w-[21rem] h-full">
		<svg class="absolute -top-9" xmlns="http://www.w3.org/2000/svg" width="487" height="683" viewBox="0 0 487 683"
			fill="none">
			<g filter="url(#filter0_f_5447_194110)">
				<path d="M80 603L286.063 80H393.554L80 603Z" fill="#3970FF" />
				<path d="M151.531 587.364L319.422 161.955H407L151.531 587.364Z" fill="#7BCEEA" />
			</g>
			<defs>
				<filter id="filter0_f_5447_194110" x="0" y="0" width="487" height="683" filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_5447_194110" />
				</filter>
			</defs>
		</svg>
	</div>
</div>