import { Component, computed, inject, input, signal } from '@angular/core';
import { ToCipPipe } from "../../../_common/pipes/to-cip/to-cip.pipe";
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowDown1Outline, saxArrowUp2Outline, saxBuildingsOutline, saxMoreOutline } from '@ng-icons/iconsax/outline';
import { MhpStatusLabelComponent } from "../../../_common/components/mhp-status-label/mhp-status-label.component";
import { RouterModule } from '@angular/router';
import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';
import { MhpLabelComponent } from "../../../_common/components/mhp-label/mhp-label.component";
import { Company } from 'src/app/_common/models/company.model';
import { MhpCompanyComponent } from "../../../_common/components/mhp-company/mhp-company.component";
import { CommonModule } from '@angular/common';
import { ApiPackageService } from "../../../_common/services/api/api-package/api-package.service";
import { Product } from "../../../_common/models/product.model";
import { Package } from "../../../_common/models/package.model";
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-main-dashboard-products',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		NgIconComponent,
		ToCipPipe,
		MhpLabelComponent,
		MhpStatusLabelComponent,
		MhpCompanyComponent,
		MhpSvgIconComponent
	],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxBuildingsOutline,
			saxMoreOutline,
		}),
		provideNgIconsConfig({ size: '1.25rem' })
	],
	templateUrl: './main-dashboard-products.component.html',
})
export class MainDashboardProductsComponent {
  private _apiPackage = inject(ApiPackageService);

	public sitemap: SiteMap = SITEMAP;

	public exporters = input<Company[]>([]);

	public products = signal<Package[]>([]);
	public requests: number[] = [ ...Array(4).keys() ];
	public expanses: boolean[] = [];

	constructor() {
		this.products().map(() => this.expanses.push(false));

    this._apiPackage.getByCIP13s([ 3400926784102, 3400921604696, 3400930151099 ])
      .then(response => {
        this.products.set(response.body!);
      })
      .catch(console.error);
	}

}
