import { Component, computed, inject, input, model, signal } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowDown1Outline, saxArrowUp2Outline, saxArrowUp3Outline } from '@ng-icons/iconsax/outline';

import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { saxCloseCircleBold, saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { Company } from 'src/app/_common/models/company.model';
import { MhpCompanyComponent } from '../../../_common/components/mhp-company/mhp-company.component';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { CommonModule } from '@angular/common';
import { ImporterRequestItem } from '../../../_common/models/importer-request-item.model';
import { Offer } from '../../../_common/models/offer.model';
import { CompanyService } from '../../../_common/services/company/company.service';
import { Package } from '../../../_common/models/package.model';
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import { MainRequestAcceptDeclineOfferModalComponent } from '../main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component';
import { MainRequestMakeOfferModalComponent } from '../main-request-make-offer-modal/main-request-make-offer-modal.component';
import { ResponseOffer } from 'src/app/_common/models/response-offer.model';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { FindDeclinedResponseOfferPipe } from '../../../_common/pipes/find-declined-response-offer/find-declined-response-offer.pipe';
import { IsOfferReadyToBookPipe } from '../../../_common/pipes/is-offer-ready-to-book/is-offer-ready-to-book.pipe';
import { IsOfferAcceptedByUserPipe } from '../../../_common/pipes/is-offer-accepted-by-user/is-offer-accepted-by-user.pipe';
import { xor } from 'src/app/_common/utils/xor.util';
import { MainRequestOfferHistoryComponent } from '../main-request-offer-history/main-request-offer-history.component';
import { MainRequestConfirmBookOfferComponent } from '../main-request-confirm-book-offer/main-request-confirm-book-offer.component';
import { MainRequestConfirmBookOffersComponent } from '../main-request-confirm-book-offers/main-request-confirm-book-offers.component';

@Component({
	selector: 'app-main-request-item-per-company',
	standalone: true,
	imports: [
		CommonModule,
		NgIconComponent,
		ToCipPipe,
		MhpButtonComponent,
		MhpCompanyComponent,
		MhpSvgIconComponent,
		MainRequestAcceptDeclineOfferModalComponent,
		MainRequestMakeOfferModalComponent,
		MainRequestOfferHistoryComponent,
		MainRequestConfirmBookOfferComponent,
		MainRequestConfirmBookOfferComponent,
		MainRequestConfirmBookOffersComponent,
		FindDeclinedResponseOfferPipe,
		IsOfferReadyToBookPipe,
		IsOfferAcceptedByUserPipe,
	],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxArrowUp3Outline,
			saxCloseCircleBold,
			saxTickCircleBold,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	styleUrl: './main-request-item-per-company.component.scss',
	templateUrl: './main-request-item-per-company.component.html',
})
export class MainRequestItemPerCompanyComponent {
	private readonly _accountSvc = inject(AccountService);
	private readonly _companySvc = inject(CompanyService);

	public readonly offerResponseTypes = OfferResponseTypeEnum;
	public readonly userFunctions = UserFunctionEnum;
	public readonly xor = xor;

	public readonly exporter = input.required<Company>();
	public readonly items = input.required<ImporterRequestItem[]>();
	public readonly filter = input.required<number>();
	public readonly offers = model.required<(Offer & ImporterRequestItem & Package)[]>();

	public readonly offersFiltered = computed<(Offer & ImporterRequestItem & Package)[]>(() =>
		this.user()!.function === this.userFunctions.Importer
			? this.offers().filter(
					o => o && (o.parentOfferId || o.responses.length) && !(o.confirmationDate || o.orderingDate)
			  )
			: this.offers()
	);

	public readonly bookedOffers = computed<(Offer & ImporterRequestItem & Package)[]>(() =>
		this.offers().filter(o => o.confirmationDate && !o.orderingDate)
	);

	public readonly orderedOffers = computed<(Offer & ImporterRequestItem & Package)[]>(() =>
		this.offers().filter(o => o.orderingDate)
	);

	public readonly currentCompany = computed(() => this._companySvc.currentCompany());
	public readonly exporters = computed(() => this._companySvc.allCompanies());
	public readonly packages = model<Package[]>([]);
	public readonly user = signal<User | null>(this._accountSvc.user());

	public readonly readyToBookedOffers = computed<(Offer & ImporterRequestItem & Package)[]>(() =>
		this.offers().filter(
			o =>
				!(o.confirmationDate || o.orderingDate) &&
				o.responses.find(r => r.importerResponse?.type === OfferResponseTypeEnum.Accepted) &&
				o.responses.find(r => r.exporterResponse?.type === OfferResponseTypeEnum.Accepted) &&
				!(
					o.responses.find(r => r.importerResponse?.type === OfferResponseTypeEnum.Declined) ||
					o.responses.find(r => r.exporterResponse?.type === OfferResponseTypeEnum.Declined)
				)
		)
	);

	public readonly offerCountDisplay = computed<number>(() =>
		this.filter() === 2
			? this.offersFiltered().length
			: this.filter() === 3
			? this.bookedOffers().length
			: this.filter() === 4
			? this.orderedOffers().length
			: 0
	);

	public acceptOfferModal: boolean = false;
	public declineOfferModal: boolean = false;
	public makeOfferModal: boolean = false;
	public bookOfferModal: boolean = false;
	public bookOffersModal: boolean = false;
	public offerHistoryPanelDisplayed: boolean = false;
	public expanded: boolean = true;
	public exporterSelected: Company | null = null;
	public offerSelected: Offer | null = null;

	constructor() {}

	public checkExporterResponseIsAccepted = (offer: Offer & ImporterRequestItem & Package): ResponseOffer | undefined =>
		offer.responses.find((ro: ResponseOffer) => ro.exporterResponse?.type === this.offerResponseTypes.Accepted);

	public checkImporterResponseIsAccepted = (offer: Offer & ImporterRequestItem & Package): ResponseOffer | undefined =>
		offer.responses.find((ro: ResponseOffer) => ro.importerResponse?.type === this.offerResponseTypes.Accepted);

	public displayOfferHistoryPanel = () => (this.offerHistoryPanelDisplayed = true);
	public getPackageByCIP13 = (cip13: number) => this.packages().find(p => p.cip13 === cip13);
	public getItemByCIP13 = (cip13: number) => this.items().find(i => i.packageCip13 === cip13);
}
