import { Component, input } from '@angular/core';

@Component({
	selector: 'app-mhp-svg-icon',
	standalone: true,
	imports: [],
	templateUrl: './mhp-svg-icon.component.html',
})
export class MhpSvgIconComponent {
	public readonly class = input<string>();
	public readonly icon = input.required<string>();
	public readonly size = input<number>(16);
}
