import { CommonModule } from '@angular/common';
import { Component, computed, HostBinding, inject, input, model, output } from '@angular/core';

import { RequestItemDto } from 'src/app/_common/dto/request-item.dto';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpFromToComponent } from '../../_common/components/mhp-from-to/mhp-from-to.component';
import { MhpInputComponent } from '../../_common/components/mhp-input/mhp-input.component';
import { MhpLabeledFieldComponent } from '../../_common/components/mhp-labeled-field/mhp-labeled-field.component';
import { MhpSvgIconComponent } from '../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { Company } from '../../_common/models/company.model';

@Component({
	selector: 'app-new-request-confirm',
	imports: [
		CommonModule,
		MhpButtonComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpSvgIconComponent,
		MhpLabeledFieldComponent,
	],
	templateUrl: './new-request-confirm.component.html',
})
export class NewRequestConfirmComponent {
	@HostBinding('class') class = 'flex flex-col justify-center max-w-[30rem] pb-12';

	private readonly _accountSvc = inject(AccountService);

	exporter = input.required<Company>();
	packageItems = input.required<RequestItemDto[]>();
	userRequestId = model.required<string | undefined>();
	expiryDate = model<string>();
	minOrderPrice = model<number | null>();
	deliveryTerms = model<string>();
	paymentTerms = model<string>();

	userRequestIdChange = output<string>();
	viewProductList = output();

	readonly packageItemsSum = computed(() =>
		this.packageItems().length == 0 ?
			0
		:	Math.round(
				this.packageItems()
					.map((item: RequestItemDto) => (item.price ?? 0) * (item.quantity ?? 0))
					.reduce((a, b) => a + b) * 100
			) / 100
	);

	protected readonly user = computed(this._accountSvc.user);
}
