import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import * as iconsaxOutline from '@ng-icons/iconsax/outline';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { ApiAuthService } from 'src/app/_common/services/api/api-auth/api-auth.service';

import { SITEMAP } from 'src/app/_common/sitemap';

@Component({
	selector: 'app-auth-login',
	imports: [RouterModule, MhpSvgIconComponent, NgIconComponent],
	providers: [provideIcons(iconsaxOutline), provideNgIconsConfig({ size: '1.07rem' })],
	styleUrl: './auth-login.component.scss',
	templateUrl: './auth-login.component.html',
})
export class AuthLoginComponent {
	private readonly _authSvc = inject(ApiAuthService);

	public readonly sitemap = SITEMAP;

	public provide(provider: 'Microsoft' | 'Google'): void {
		this._authSvc.getLoginProvider(provider, `${window.location.origin}/dashboard`);
	}

	public bookDemo(): void {
		window.open('https://medhubplace.com', '_blank');
	}
}
