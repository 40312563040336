import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxTruckOutline } from '@ng-icons/iconsax/outline';
import { MhpButtonComponent } from 'src/app/_common/components/mhp-button/mhp-button.component';
import { MainRequestConfirmOffersComponent } from '../../offer-modals/main-request-confirm-offers/main-request-confirm-offers.component';
import { OfferActionBase } from '../offer-action-base/offer-action-base';

@Component({
	selector: 'offer-action-order',
	standalone: true,
	imports: [MhpButtonComponent, MainRequestConfirmOffersComponent, CommonModule],
	templateUrl: './offer-action-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		provideIcons({
			saxTruckOutline,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class OfferActionOrderComponent extends OfferActionBase {
	async onConfirm() {
		await this.offerService.orderOffers(this.offers());
	}
}
