import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppInsightsService } from './_common/services/app-insights/app-insights.service';
import { ThemeColorService } from './_common/services/theme-color/theme-color.service';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterModule
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {

	constructor(
		private _appInsights: AppInsightsService,
		private _themeColor: ThemeColorService
	) {}

}
