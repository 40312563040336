import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RequestItem } from 'src/app/_common/models/request-item.model';
import { MhpLabelComponent } from '../../../../_common/components/mhp-label/mhp-label.component';

/**
 * Component to display the header of a group of offers for an item.
 */
@Component({
	selector: 'offers-group-header-for-item',
	standalone: true,
	styles: [
		`
			:host {
				@apply flex flex-row items-center p-4 border-b;
			}
		`,
	],
	imports: [CommonModule, MhpLabelComponent],
	templateUrl: 'offers-group-header-for-item.component.html',
})
export class OffersGroupHeaderForIteComponent {
	item = input.required<RequestItem>();
}
