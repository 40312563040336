import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserFunctionEnum } from '../../enums/user-function.enum';
import { User } from '../../models/user.model';
import { SITEMAP } from '../../sitemap';
import { AccountService } from '../../services/account/account.service';

export const isImporterGuard: CanActivateFn = (route, state) => {

	const router: Router = inject(Router);
	const _account: AccountService = inject(AccountService);

	const user: User | null = _account.getUser();

	if(user && [UserFunctionEnum.Importer, UserFunctionEnum.ImporterExporter].includes(user.function))
		return true;

	router.navigate([ SITEMAP.dashboard.route ]);
	return false;

};
