import { CommonModule } from '@angular/common';
import { Component, computed, HostListener, inject, input, model, output } from '@angular/core';

import { Company } from 'src/app/_common/models/company.model';
import { ImporterRequest } from 'src/app/_common/models/importer-request.model';
import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { MhpBaseModalComponent } from '../../../../_common/components/mhp-base-modal/mhp-base-modal.component';
import { MhpFromToComponent } from '../../../../_common/components/mhp-from-to/mhp-from-to.component';

/**
 * Modal component for confirming offer submission.
 * Features:
 * - Displays a confirmation dialog for sending offers
 * - Shows the exporter and user details
 * - Displays valid offers count
 * - Handles submission confirmation
 */
@Component({
	selector: 'app-main-request-send-offer-confirm-modal',
	imports: [CommonModule, MhpFromToComponent, MhpBaseModalComponent],
	templateUrl: './main-request-send-offer-confirm-modal.component.html',
})
export class MainRequestSendOfferConfirmModalComponent {
	private readonly _accountSvc = inject(AccountService);

	/** Required exporter company details */
	public readonly exporter = input.required<Company>();

	/** Required request details */
	public readonly request = input.required<ImporterRequest>();

	/** Required count of valid offers */
	public readonly validOffersCount = input.required<number>();

	/** Event emitted on submission */
	public readonly onSumbit = output({ alias: 'submit' });

	/** Modal open state */
	public readonly isOpen = model<boolean>(false);

	/** Current user details */
	public readonly user = computed<User>(this._accountSvc.user);

	/** Handle submit confirmation */
	public submit() {
		this.isOpen.set(false);
		this.onSumbit.emit();
	}

	/** Handle ESC key for closing */
	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.isOpen.set(false);
	}

	/** Handle close action */
	public close(): void {
		this.isOpen.set(false);
	}
}
