import { Component, HostListener, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import {
	saxArrowDown1Outline,
	saxLogout1Outline,
	saxNotificationOutline,
	saxProfileCircleOutline,
	saxSmsOutline,
} from '@ng-icons/iconsax/outline';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';

import { User } from 'src/app/_common/models/user.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { SITEMAP } from 'src/app/_common/sitemap';

@Component({
	selector: 'app-main-header-account',
	imports: [RouterModule, NgIconComponent],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxLogout1Outline,
			saxNotificationOutline,
			saxProfileCircleOutline,
			saxSmsOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styleUrl: './main-header-account.component.scss',
	templateUrl: './main-header-account.component.html',
})
export class MainHeaderAccountComponent {
	private readonly _accountSvc = inject(AccountService);

	public readonly sitemap = SITEMAP;

	public readonly user = input.required<User>();

	public clickInside: boolean = false;
	public isSubMenuOpen: boolean = false;
	public newMessage: boolean = false;
	public newNotification: boolean = true;

	readonly CompanyExtension = CompanyExtension;

	constructor() {}

	public signOut(): void {
		this._accountSvc.signOut();
	}

	public toggleMenu(): void {
		this.isSubMenuOpen = !this.isSubMenuOpen;
		setTimeout(() => (this.clickInside = false), 100);
	}

	@HostListener('document:click')
	public closeSubMenu(): void {
		if (!this.clickInside) this.isSubMenuOpen = false;
	}
}
