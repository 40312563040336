import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CounterOffer } from 'src/app/_common/models/counter-offer.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiOfferService {
	private readonly http = inject(HttpClient);

	private readonly API_URL: string = environment.API_URL;
	private readonly OFFER_ROUTE: string = 'offer';

	constructor() {}

	getOffer(offerId: string): Promise<Offer> {
		return firstValueFrom(this.http.get<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}`));
	}

	sendToPurchaseOffer(offerId: string): Promise<Offer> {
		return firstValueFrom(this.http.post<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/confirmed`, null));
	}

	orderOffer(offerId: string): Promise<Offer> {
		return firstValueFrom(this.http.post<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/ordered`, null));
	}

	acceptOffer(offerId: string): Promise<Offer> {
		return firstValueFrom(this.http.post<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/accept`, null));
	}

	sendOffer(offers: Offer[]): Promise<void> {
		return firstValueFrom(this.http.post<void>(`${this.API_URL}/${this.OFFER_ROUTE}`, offers));
	}

	declineOffer(offerId: string): Promise<Offer> {
		return firstValueFrom(this.http.post<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/decline`, null));
	}

	sendCounterOffer(uuid: string, counterOffer: CounterOffer): Promise<CounterOffer> {
		return firstValueFrom(
			this.http.post<CounterOffer>(`${this.API_URL}/${this.OFFER_ROUTE}/${uuid}/counter`, counterOffer)
		);
	}

	validateBatch(offerId: string): Promise<Offer> {
		return firstValueFrom(this.http.post<Offer>(`${this.API_URL}/${this.OFFER_ROUTE}/${offerId}/validate-batch`, null));
	}
}
