<app-mhp-base-side-modal (close)="this.handleClose()">
  <div slot="header">
    <div class="flex flex-row items-center w-full space-x-6 mb-6">
      <div class="p-3 shrink-0 border rounded-full">
        <app-mhp-svg-icon icon="pill" [size]="16" />
      </div>
      <div class="flex flex-col-reverse">
        <h2 class="pr-4 w-full text-2xl font-semibold line-clamp-1 overflow-ellipsis">{{ offer().shortName }}</h2>
        <h3 class="text-sm text-[var(--text-secondary)]">{{ offer().packageCip13| toCip : 13 }}</h3>
      </div>
    </div>

    <hr class="-mx-6" />
  </div>

  @switch (this.tabSelected.value) {
  @case (mainRequestOfferHistoryTabs.History) {
  <div class="main-request-offer-history-aside-history">
    This feature will come soon.
  </div>
  }

  @case (mainRequestOfferHistoryTabs.Discussion) {
  @let room = this.offerRoom();
  @if( room ) {
  <app-main-request-offer-history-discussion
    class="flex flex-col h-full"
    [room]="room" />
  }
  }
  }
</app-mhp-base-side-modal>