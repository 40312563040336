import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiAuthService } from 'src/app/_common/services/api/api-auth/api-auth.service';

import { SITEMAP, SiteMap } from 'src/app/_common/sitemap';

@Component({
  selector: 'app-auth-login',
  standalone: true,
  imports: [
    RouterModule
  ],
  styleUrl: './auth-login.component.scss',
  templateUrl: './auth-login.component.html'
})
export class AuthLoginComponent {

  public sitemap: SiteMap = SITEMAP;

  constructor(private _auth: ApiAuthService) {}

  public provide(provider: "Microsoft" | "Google"): void {
    this._auth.getLoginProvider(provider, `${window.location.origin}/dashboard`);
  }

}
