<div class="table-container">
	<ng-content select="[slot='header']"></ng-content>
	<div class="overflow-hidden" *ngIf="expanded()" @expandCollapse>
<table class="w-full">
		<thead>
			<tr>
				<th>Product</th>
				<th *ngIf="isImporter()">Seller</th>
				<th>Batch</th>
				<th class="w-32">Expiration date</th>
				<th>Qty</th>
				<th>Unit cost</th>
				<th>Status</th>
				<th>Actions</th>
				@if ( allowEdit() )
				{
					<th>&nbsp;</th>
					<th>Edit</th>
				}
				<th>&nbsp;</th>
				<th>History</th>
			</tr>
		</thead>
		<tbody>
				<offer-row *ngFor="let offer of offersSortedByName()"
					[offer]="offer"
					[allowEdit]="allowEdit()"
					(onOpenHistory)="offerHistory=offer"
					(onEdit)="offerToEdit=offer">
				</offer-row>
		</tbody>
	</table>
</div>
</div>

@if (offerHistory) {
	<app-main-request-offer-history
		[offer]="offerHistory"
		(stateClose)="offerHistory = null"
	/>
}

@if (offerToEdit) {
<app-main-request-edit-offer-modal
  [offer]="offerToEdit"
  (stateClose)="offerToEdit = null" />
}
