<app-mhp-base-modal
  [title]="'Confirm import of your stock for this request?'"
  [showCloseButton]="false"
  [actionButtonText]="'Confirm and import my stock'"
  (action)="this.nextStep(); this.confirm()"
  (close)="this.handleClose()"
>
  <span class="text-center text-sm text-[var(--text-secondary)]">
    It's simple: we use your stock as the default answer to this request, and you can always modify the offers one by one.
  </span>
</app-mhp-base-modal>
