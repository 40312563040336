import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SITEMAP } from '../_common/sitemap';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';

@Component({
	selector: 'app-forbidden',
	imports: [RouterModule, MhpButtonComponent],
	templateUrl: './forbidden.component.html',
})
export class ForbiddenComponent {
	public readonly sitemap = SITEMAP;

	constructor() {}
}
