import { inject, Injectable } from '@angular/core';
import { ExporterQuotaEntry } from '../../models/exporter-quota-entry.model';
import { TableHandler } from '../../utils/table-handler';
import { ApiPackageService } from '../api/api-package/api-package.service';
import { derivedAsync } from "ngxtension/derived-async";

@Injectable({
	providedIn: 'root',
})
export class QuotaTableService extends TableHandler<ExporterQuotaEntry> {
	constructor() {
		super(
			inject(ApiPackageService),
			'update-quota',
			row =>
				<ExporterQuotaEntry>{
					cip13: +row['CIP 13'],
					name: row['Product name'],
					quota: +row['Quota'],
				}
		);
	}

	readonly validCip13s = derivedAsync<number[]>(async () => {
		return await this._apiPackageSvc.validateCip13s(
			this.items()
				.map(entry => entry.cip13)
				.filter(cip13 => !!cip13)
		);
	});
}
