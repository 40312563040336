<div class="mhp-search-modal-component" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="mhp-search-modal-component-container" (click)="this.clickedInside = true">
        <div>
          <input
            class="text-lg w-full mb-6 mhp-search-input bg-transparent"
            placeholder="Find a product, CIP"
            type="text"
            [(ngModel)]="this.searchText"
            (ngModelChange)="this.parseSearch()"
            (change)="this.search()"
            appAutoFocus
          />
        </div>
        <div class="pt-6 text-[var(--text-secondary)]">
          <span class="text-sm">

            @if(this.isSearching) { Search results ({{ this.searchPackages().length }}) }
            @else { Best sellers }

          </span>
          <div class="flex flex-col mt-3 max-h-[21.25rem] overflow-y-auto">

            @for (package of this.searchPackages(); track package.cip13) {
              <app-mhp-search-modal-item [package]="package" (click)="this.close()"></app-mhp-search-modal-item>
            }

            @empty {
              <div class="flex flex-col items-center justify-center py-8 space-y-4">
                <app-mhp-svg-icon icon="empty-product" [size]="80"/>
                <p class="text-[var(--text-secondary)]">The product you are looking for is not available.</p>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
