import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SITEMAP } from '../_common/sitemap';
import { MhpButtonComponent } from '../_common/components/mhp-button/mhp-button.component';

@Component({
	selector: 'app-unauthorized',
	standalone: true,
	imports: [RouterModule, MhpButtonComponent],
	templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent {
	public readonly sitemap = SITEMAP;
}
