import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toCip',
	standalone: true,
})
export class ToCipPipe implements PipeTransform {
	private toCIP13(value: string): string {
		return value
			.split('')
			.map((c: string, i: number) => ([4, 7, 10, 11].includes(i) ? `${c} ` : c))
			.join('');
	}

	private toCIP7(value: string): string {
		return value
			.split('')
			.map((c: string, i: number) => ([2, 5].includes(i) ? `${c}${i === 2 ? ' ' : '-'}` : c))
			.join('');
	}

	transform(value: number, format: number): string {
		return format === 13 ? this.toCIP13(`${value}`) : this.toCIP7(`${value}`);
	}
}
