import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { MhpCompanyComponent } from 'src/app/_common/components/mhp-company/mhp-company.component';
import { MhpPackageComponent } from 'src/app/_common/components/mhp-package/mhp-package.component';
import { MhpValidationFieldComponent } from 'src/app/_common/components/mhp-validation-field/mhp-validation-field.component';
import { MhpValueChangeComponent } from 'src/app/_common/components/mhp-value-change/mhp-value-change.component';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { CounterOffer } from 'src/app/_common/models/counter-offer.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { AccountService } from 'src/app/_common/services/account/account.service';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { OfferActionChatComponent } from '../../offer-actions/offer-action-chat/offer-action-chat.component';
import { OfferActionsComponent } from '../../offer-actions/offer-actions.component';

@Component({
	selector: 'offer-row',
	templateUrl: './offer-row.component.html',
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
	imports: [
		CommonModule,
		MhpPackageComponent,
		MhpCompanyComponent,
		OfferActionsComponent,
		OfferActionChatComponent,
		MhpValidationFieldComponent,
		MhpValueChangeComponent,
	],
})
export class OffersRowComponent {
	offer = input.required<Offer>();
	onOpenHistory = output();

	readonly companyService = inject(CompanyService);
	readonly accountService = inject(AccountService);
	readonly offerService = inject(OffersService);

	item = computed(() => this.offerService.findItem(this.offer().itemId));
	isImporter = computed(() => this.accountService.userFunction() === UserFunctionEnum.Importer);
	showValidation = computed(
		() =>
			this.isImporter() &&
			!this.offerService.isOfferAccepted(this.offer()) &&
			!OfferExtension.isOfferDeclined(this.offer())
	);
	batchNumber = computed(() => (this.offer().batchNumber ? this.offer().batchNumber : 'To order'));
	statusLabel = computed(() => OfferExtension.getOfferStatusLabel(this.offer(), this.accountService.userFunction()));
	exporter = computed(() => this.companyService.getCompanyById(this.offer().exporterId));
	counterOffer = computed(() => {
		const offer = this.offer();
		return this.isCounterOfferType(offer) ? offer : undefined;
	});
	formerPriceVisible = computed(
		() =>
			!!this.counterOffer() &&
			!OfferExtension.importerAndExporterAccepted(this.offer()) &&
			this.counterOffer()?.parentOffer?.price !== this.offer().price
	);
	formerQuantityVisible = computed(
		() =>
			!!this.counterOffer() &&
			!OfferExtension.importerAndExporterAccepted(this.offer()) &&
			this.counterOffer()?.parentOffer?.quantity !== this.offer().quantity
	);

	private isCounterOfferType(offer: Offer): offer is CounterOffer {
		return (offer as CounterOffer).parentOfferId !== undefined;
	}

	// Validators
	isExpirationDateValid = computed(() => {
		if (!this.offer().expirationDate || !this.item()) return false;
		const expirationDate = new Date(this.offer().expirationDate as string);
		const minExpirationDate = new Date(this.item().minExpiration as string);
		return expirationDate >= minExpirationDate;
	});

	isQuantityValid = computed(() => {
		if (!this.item()) return false;
		return (
			this.offer().quantity <= (this.item().quantity ?? Number.MAX_SAFE_INTEGER) &&
			this.offer().quantity >= (this.item().minQuantity ?? 0) &&
			this.offer().quantity % (this.item().multiple ?? 1) === 0
		);
	});

	isPriceValid = computed(() => {
		if (!this.item()) return false;
		const price = this.item()?.price ?? 0;
		return price == 0 || this.offer().price <= price;
	});
}
