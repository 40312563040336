import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Company } from '../../models/company.model';
import { ApiCompanyService } from '../api/api-company/api-company.service';

/**
 * Provides session storage for company data.
 * @class CompanyService
 */
@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	private readonly _companyApiSvc = inject(ApiCompanyService);
	private readonly companyKey = 'company';

	public readonly currentCompany = signal<Company | null>(JSON.parse(sessionStorage.getItem(this.companyKey)!));
	public readonly allCompanies = signal<Company[]>([]);

	private readonly onCompanySet = effect(() => {
		sessionStorage.setItem(this.companyKey, JSON.stringify(this.currentCompany()));
	});

	constructor() {
		this._companyApiSvc
			.getAll()
			.then(companies => {
				if (companies.ok) {
					this.allCompanies.set(companies.body!);
				}
			})
			.catch((err: any) => console.error(err))
			.finally(() => {});
	}

	public async updateCurrentCompany() {
		const newCompany = await this._companyApiSvc.getOwn();

		if (newCompany.ok) {
			this.currentCompany.set(newCompany.body!);
		}
	}

	public resetCompany(): void {
		sessionStorage.removeItem(this.companyKey);
	}
}
