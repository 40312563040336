import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SiteMap, SITEMAP } from '../_common/sitemap';
import { MhpSvgLogoComponent } from "../_common/components/mhp-svg-logo/mhp-svg-logo.component";

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    RouterModule,
    MhpSvgLogoComponent
  ],
  styleUrl: './auth.component.scss',
  templateUrl: './auth.component.html',
})
export class AuthComponent {

  public sitemap: SiteMap = SITEMAP;

  constructor() {}

}
