import { CommonModule } from '@angular/common';
import { Component, HostBinding, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { featherThumbsDown, featherThumbsUp } from '@ng-icons/feather-icons';

@Component({
	selector: 'mhp-validation-field',
	templateUrl: './mhp-validation-field.component.html',
	standalone: true,
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			featherThumbsUp,
			featherThumbsDown,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
})
export class MhpValidationFieldComponent {
	@HostBinding('class') class = 'flex gap-2';

	isValid = input<boolean>(true);
	showIcon = input<boolean>(false);

	// Helper method for icon colors
	getIconColor(isValid: boolean): string {
		// todo add color to the theme after migrating to tailwind 4
		return isValid ? '#25AF7D' : '#E5536D';
	}
}
