import {
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	inject,
	OnInit,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppInsightsService } from './_common/services/app-insights/app-insights.service';
import { ThemeColorService } from './_common/services/theme-color/theme-color.service';
import { MhpLoaderComponent } from './_common/components/mhp-loader/mhp-loader.component';
import { ComponentInjectorService } from './_common/services/component-injector/component-injector.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { MHP_SVG_ICON_TYPES } from './_common/components/mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-root',
	imports: [RouterModule, MatIconModule],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	private readonly _appInsightsSvc = inject(AppInsightsService);
	private readonly _themeColorSvc = inject(ThemeColorService);
	private readonly _viewContainerRef = inject(ViewContainerRef);
	private readonly _iconRegistry = inject(MatIconRegistry);
	private readonly _sanitizer = inject(DomSanitizer);

	constructor() {
		for (let iconName of MHP_SVG_ICON_TYPES) {
			const iconUrl = `/assets/icons/${iconName}.svg`;
			const safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(iconUrl);
			this._iconRegistry.addSvgIcon(iconName, safeUrl);
		}
	}

	ngOnInit(): void {
		const componentRef = this._viewContainerRef.createComponent(MhpLoaderComponent);
		this.injectInternalLoaderComponent(componentRef);
	}

	/**
	 * Inject the component InternalLoaderComponent in the parent
	 * @param componentRef
	 */
	injectInternalLoaderComponent(componentRef: ComponentRef<unknown>): void {
		const injector = componentRef.injector;
		const componentInjector = injector.get(ComponentInjectorService as Type<ComponentInjectorService>);
		componentInjector.appendComponent(MhpLoaderComponent);
	}
}
