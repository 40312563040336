import { Component, HostListener, model } from '@angular/core';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { RouterModule } from '@angular/router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export enum PreFillModalResponse {
	None,
	Canceled,
	Confirmed,
}

@Component({
	selector: 'app-main-request-pre-fill-confirm-modal',
	standalone: true,
	imports: [RouterModule, MhpButtonComponent],
	templateUrl: './main-request-pre-fill-confirm-modal.component.html',
})
export class MainRequestPreFillConfirmModalComponent {
	public readonly isOpen = model<boolean>();
	public readonly response = model<PreFillModalResponse>();

	public clickedInside: boolean = false;
	public step: number = 0;

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Triggered on confirmation action for the dialog box
	 */
	public async confirm() {
		this.response.set(PreFillModalResponse.Confirmed);
		this.isOpen.set(false);
	}

	public nextStep(): void {
		this.step++;
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27 && this.step !== 1) {
			this.response.set(PreFillModalResponse.Canceled);
			this.isOpen.set(false);
		}
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside && this.step !== 1) {
			this.response.set(PreFillModalResponse.Canceled);
			this.isOpen.set(false);
		}

		this.clickedInside = false;
	}
}
