<div class="flex flex-col">

  <!-- @for (product of this.products(); track $index) {
    <div class="flex flex-col mb-4 border rounded-2xl overflow-hidden bg-white dark:border-gray-700 dark:bg-gray-800">
      <div
        class="flex flex-row p-4 items-center cursor-pointer"
        (click)="this.expanses[$index] = !this.expanses[$index]"
      >
        <div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0 dark:border-gray-700">
          <app-mhp-svg-icon icon="pill" />
        </div>
        <div class="flex flex-col w-full">
          <span class="text-sm text-gray-500">{{ product.cip13 | toCip : 13 }}</span>
          <span class="text-gray-900 dark:text-white">{{ product.shortName }}</span>
        </div>
        <div class="flex flex-row text-sm items-center shrink-0">
          <span class="text-gray-500 mr-8">{{ 20 }} ordered / {{ 100 }} expected</span>
          <span class="mr-2 text-gray-900 dark:text-white">See offers ({{ 1 }})</span>
          <ng-icon
            class="text-gray-900 dark:text-white"
            [name]="this.expanses[$index] ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'"
          />
        </div>
      </div>

      @if (this.expanses[$index]) {
        <table class="w-full text-sm text-left rtl:text-right border-t text-gray-900 bg-white dark:bg-gray-800 dark:text-white">
          <thead class="text-xs text-gray-500">
            <tr>
              <th class="py-4 pl-4 font-medium">Reference</th>
              <th class="py-4 pl-4 font-medium">Status</th>
              <th class="py-4 pl-4 font-medium">Type</th>
              <th class="py-4 pl-4 font-medium">Expected</th>
              <th class="py-4 pl-4 font-medium">In progress</th>
              <th class="py-4 pl-4 font-medium">Ordered</th>
              <th class="py-4 pl-4 font-medium">Exporters</th>
              <th class="py-4 pl-4 font-medium"></th>
            </tr>
          </thead>
          <tbody>

            @for (request of this.exporters(); track $index) {
              <tr
                class="border-t font-medium dark:bg-gray-800 dark:border-gray-700"
                [ngClass]="($index % 2 == 0) ? 'bg-gray-50' : 'bg-white'"
              >
                <td class="py-4 px-4">
                  <a [routerLink]="[ this.sitemap.request.route, '9283987' ]">#9283987</a>
                </td>
                <td class="py-4 px-4">
                  <app-mhp-status-label [status]="3"></app-mhp-status-label>
                </td>
                <td class="py-4 px-4">
                  <div class="flex flex-row items-center">
                    Price request
                    @if ([0].includes($index)) { <app-mhp-label class="ml-2" label="New" /> }
                  </div>
                </td>
                <td class="py-4 px-4 w-24">{{ 100 }}</td>
                <td class="py-4 px-4 w-24">{{ 50 }}</td>
                <td class="py-4 px-4 w-24">{{ 25 }}</td>
                <td class="py-4 px-4">
                  <app-mhp-company [company]="request" />
                </td>
                <td class="py-4 px-4 text-right">
                  <button>
                    <ng-icon name="saxMoreOutline" />
                  </button>
                </td>
              </tr>
            }

          </tbody>
        </table>
      }
    </div>
  } -->

  <div class="text-center py-8">
    <p class="dark:text-white">This feature will come soon.</p>
  </div>

</div>
