<div class="mhp-main-index">
  <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:py-38">
    <div class="text-center">
      <h1 class="mhp-main-index-title">
        <app-mhp-svg-logo [showBranding]="true" class="h-32 w-100" />
        <span class="sr-only">MedHubPlace</span>
      </h1>
      <p class="mhp-main-index-paragraphe">
        MedHubPlace revolutionizes pharma trade by connecting buyers and suppliers across Europe.
        Our platform offers seamless sourcing, instant communication, dynamic auctions,
        and relationship management to build stronger partnerships and grow your business.
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a href="https://medhubplace.com" target="_blank">
          <app-mhp-button text="Visit our website" />
        </a>
      </div>
    </div>
  </div>
</div>

