<div class="mhp-package">
	@if (exporter()) {
	<div class="flex flex-col gap-6">
		<div class="flex flex-col w-full">
			<div class="flex flex-row items-center mb-6 text-[var(--text-secondary)]">
				<a [routerLink]="['/dashboard']">MedHubPlace</a>
				<ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
				<a>Find product results</a>
				<ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
				<a>{{ exporter()?.displayName }}</a>
			</div>

			<div class="flex flex-row mb-2 gap-4">
				<div class="flex items-center justify-center">
					<img
						class="border rounded-full"
						[src]="CompanyExtension.getGravatarUrl(exporter() || undefined)"
						[title]="exporter()?.displayName"
						alt="Exporter" />
				</div>
				<div class="flex flex-col items-start justify-center">
					<h1 class="w-full text-4xl font-semibold">{{ exporter()?.displayName }}</h1>
					<h2 class="text-[var(--text-secondary)]">{{ exporter()?.country }}</h2>
				</div>
			</div>
		</div>

		<app-main-export-catalog-search
			[initialSearch]="initialSearch!"
			(searchChange)="onSearchChange($event)">
		</app-main-export-catalog-search>

		<div class="text-xl font-semibold">
			{{ manifestPackagesSearchTotalCount() | i18nPlural : { '=0': '0 product', '=1': '1 product', 'other': '# products'
			} }}
		</div>

		<div class="table-container">
			<cdk-virtual-scroll-viewport
				[class.min-h-64]="manifestItemsCount() === 0"
				[itemSize]="itemSize()"
				minBufferPx="400"
				maxBufferPx="700"
				scrollWindow
				appInfiniteScroll
				(nearEnd)="loadMore()">
				<table class="w-full text-sm text-left rtl:text-left">
					<thead class="text-[var(--text-secondary)]">
						<tr>
							<th class="pl-20">Product</th>
							<th>Batch</th>
							<th class="min-w-28">Expiration date</th>
							<th>Stock</th>
							<th>Availability</th>
							<th class="min-w-20">Unit cost</th>
							<!-- <th class="py-3 pl-4 ">
										<div class="flex flex-row items-center">
											<span class="mr-1">Appears in the sections</span>
											<ng-icon name="saxInfoCircleOutline" size=".9rem" />
										</div>
									</th> -->
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *cdkVirtualFor="let item of this.manifestItems()">
							<td class="inline-flex items-center space-x-4 w-max max-w-[30rem] flex-shrink">
								<app-mhp-package [package]="{ cip13: item.packageCip13, shortName: item.packageShortName! }" />
							</td>
							<td>{{ item.batch?.id }}</td>
							<td>{{ item.batch?.expiration | date: 'd MMM y' : '' : 'en-US' }}</td>
							<td>{{ item.quantity }}</td>
							<td>{{ item.quota }}</td>
							<td>{{ item.price ? (item.price | currency: 'EUR' : 'symbol' : '.0-2') : 'Make an offer' }}</td>
							<td class="p-4">
								<!-- Hidden: https://github.com/MedHubCompany/MedHubPlace/issues/136 -->
								<div class="flex flex-row space-x-4">
									<app-mhp-button
										class="shrink-0"
										text="Request product"
										type="primary"
										prefix="saxAddOutline"
										(click)="requestManifestItem(item!)" />
								</div>
							</td>
						</tr>
						@if (manifestItemsCount() === 0) {
						<tr>
							<td colspan="100">
								<div class="flex flex-col gap-6 my-8 text-center text-[var(--text-secondary)]">
									<app-mhp-svg-icon icon="empty-search" [size]="48" />
									We did not find any results for this search
								</div>
							</td>
						</tr>
						}
					</tbody>
				</table>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
	} @else {
	The company you are looking for is not available.
	}
</div>

@if (requestProductModal()) {
<app-main-package-request-product-modal
	[(isOpen)]="requestProductModal"
	[exporter]="exporter()!"
	[product]="package!"
	[request]="selectedManifestItem!" />
}