import { Component, computed, inject, input, model, OnInit, output, signal } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxArrowDown1Outline, saxArrowUp2Outline, saxArrowUp3Outline } from '@ng-icons/iconsax/outline';

import { ToCipPipe } from "../../../_common/pipes/to-cip/to-cip.pipe";
import { MhpLabelComponent } from "../../../_common/components/mhp-label/mhp-label.component";
import { MhpButtonComponent } from "../../../_common/components/mhp-button/mhp-button.component";
import { saxCloseCircleBold, saxTickCircleBold } from '@ng-icons/iconsax/bold';
import { Company } from 'src/app/_common/models/company.model';
import { MhpCompanyComponent } from "../../../_common/components/mhp-company/mhp-company.component";
import { AccountService } from 'src/app/_common/services/account/account.service';
import { User } from 'src/app/_common/models/user.model';
import { UserFunctionEnum } from 'src/app/_common/enums/user-function.enum';
import { CommonModule } from '@angular/common';
import { ImporterRequestItem } from "../../../_common/models/importer-request-item.model";
import { Offer } from "../../../_common/models/offer.model";
import { CompanyService } from "../../../_common/services/company/company.service";
import { Package } from "../../../_common/models/package.model";
import { OfferResponseTypeEnum } from 'src/app/_common/enums/offer-response-type.enum';
import { MainRequestAcceptDeclineOfferModalComponent } from "../main-request-accept-decline-offer-modal/main-request-accept-decline-offer-modal.component";
import { MainRequestMakeOfferModalComponent } from "../main-request-make-offer-modal/main-request-make-offer-modal.component";
import { ApiPackageService } from 'src/app/_common/services/api/api-package/api-package.service';
import { ApiOfferService } from 'src/app/_common/services/api/api-offer/api-offer.service';
import { ResponseOffer } from 'src/app/_common/models/response-offer.model';
import { MhpSvgIconComponent } from 'src/app/_common/components/mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-main-request-item-per-company',
	standalone: true,
	imports: [
		CommonModule,
		NgIconComponent,
		ToCipPipe,
		MhpButtonComponent,
		MhpLabelComponent,
		MhpCompanyComponent,
		MhpSvgIconComponent,
		MainRequestAcceptDeclineOfferModalComponent,
		MainRequestMakeOfferModalComponent,
	],
	providers: [
		provideIcons({
			saxArrowDown1Outline,
			saxArrowUp2Outline,
			saxArrowUp3Outline,
			saxCloseCircleBold,
			saxTickCircleBold
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	styleUrl: './main-request-item-per-company.component.scss',
	templateUrl: './main-request-item-per-company.component.html'
})
export class MainRequestItemPerCompanyComponent implements OnInit {

	private _account = inject(AccountService);
	private _company = inject(CompanyService);
	private _apiPackage = inject(ApiPackageService);
	private _apiOffer = inject(ApiOfferService);

	public exporter = input.required<Company>();
	public items = input.required<ImporterRequestItem[]>();
	public filter = input.required<number>()
	public offers = input.required<(Offer & ImporterRequestItem & Package)[]>();
	public offersFiltered = signal<(Offer & ImporterRequestItem & Package & { response: ResponseOffer[] })[]>([]);
	public bookedOffers = signal<(Offer & ImporterRequestItem & Package & { response: ResponseOffer[] })[]>([]);
	public orderedOffers = signal<(Offer & ImporterRequestItem & Package & { response: ResponseOffer[] })[]>([]);
	public currentCompany = computed(() => this._company.currentCompany());
	public exporters = computed(() => this._company.allCompanies());
	public packages = model<Package[]>([]);
	public needRefresh = output<boolean>();

	public acceptOfferModal: boolean = false;
	public declineOfferModal: boolean = false;
	public expanded: boolean = !false;
	public exporterSelected: Company | null = null;
	public makeOfferModal: boolean = false;
	public offerSelected: Offer | null = null;
	public user: User | null = null;

	public offerResponseTypes = OfferResponseTypeEnum;
	public userFunctions = UserFunctionEnum;

	constructor() {
		this._account.currentUser.subscribe((user: User | null) => this.user = user);
	}

	public ngOnInit(): void {
		this._apiPackage.getByCIP13s([ ...this.offers().map((o) => o.cip13) ])
			.then((response) => {
				if(response.ok)
					this.packages.set(response.body!);
			})
			.catch((err: any) => console.error(err));

		this.offers().map((o) => {
			this._apiOffer.getResponseOffersByOfferId(o.id)
				.then((response) => {
					const body = response.body!;

					if(body.length !== 2)
						this.offersFiltered().push({ ...o, response: body });
		
					else if(body.length === 2)
						this.bookedOffers().push({ ...o, response: body });

				})
				.catch((err: any) => console.error(err));
		});
	}

	public checkExporterResponseIsAccepted = (offer: (Offer & ImporterRequestItem & Package & { response: ResponseOffer[] })): ResponseOffer | undefined =>
		offer.response.find((ro: ResponseOffer) => ro.exporterResponse?.type === this.offerResponseTypes.Accepted);

	public checkImporterResponseIsAccepted = (offer: (Offer & ImporterRequestItem & Package & { response: ResponseOffer[] })): ResponseOffer | undefined =>
		offer.response.find((ro: ResponseOffer) => ro.importerResponse?.type === this.offerResponseTypes.Accepted);

	public getPackageByCIP13 = (cip13: number) => this.packages().find((p) => p.cip13 === cip13);
	public getItemByCIP13 = (cip13: number) => this.items().find((i) => i.packageCip13 === cip13);

}
