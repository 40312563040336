import csv from 'csvtojson';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, Signal, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxImportOutline, saxInfoCircleOutline, saxTrashOutline } from '@ng-icons/iconsax/outline';
import { saxTickCircleBold } from '@ng-icons/iconsax/bold';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { SITEMAP, SiteMap } from '../_common/sitemap';

import { User } from '../_common/models/user.model';
import { Company } from '../_common/models/company.model';
import { PackageItem } from '../_common/models/package-item.model';
import { ImporterRequest } from '../_common/models/importer-request.model';
import { AccountService } from '../_common/services/account/account.service';
import { ApiCompanyService } from '../_common/services/api/api-company/api-company.service';
import { ApiImporterService } from '../_common/services/api/api-importer/api-importer.service';
import { ToCipPipe } from "../_common/pipes/to-cip/to-cip.pipe";
import { DragAndDropDirective } from '../_common/directives/drag-and-drop/drag-and-drop.directive';

import { NewRequestButtonComponent } from "./new-request-button/new-request-button.component";
import { MhpExporterSelectorComponent } from "../_common/components/mhp-exporter-selector/mhp-exporter-selector.component";
import { MhpFromToComponent } from "../_common/components/mhp-from-to/mhp-from-to.component";
import { MhpSwitchComponent } from "../_common/components/mhp-switch/mhp-switch.component";
import { MhpButtonComponent } from "../_common/components/mhp-button/mhp-button.component";
import { MhpInputComponent } from "../_common/components/mhp-input/mhp-input.component";
import { MhpLoaderComponent } from "../_common/components/mhp-loader/mhp-loader.component";
import { UserFunctionEnum } from '../_common/enums/user-function.enum';
import { MhpSvgIconComponent } from "../_common/components/mhp-svg-icon/mhp-svg-icon.component";
import { RequestTypeEnum } from '../_common/enums/request-type.enum';
import { ApiExporterService } from '../_common/services/api/api-exporter/api-exporter.service';
import { ExporterStockEntry } from '../_common/models/exporter-stock-entry.model';
import { ExporterManifestEntry } from '../_common/models/exporter-manifest-entry.model';

@Component({
	selector: 'app-new-request',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgIconComponent,
		ToCipPipe,
		DragAndDropDirective,
		NewRequestButtonComponent,
		MhpButtonComponent,
		MhpExporterSelectorComponent,
		MhpFromToComponent,
		MhpInputComponent,
		MhpSwitchComponent,
		MhpLoaderComponent,
		MhpSvgIconComponent
	],
	providers: [
		DatePipe,
		provideIcons({
			saxImportOutline,
			saxInfoCircleOutline,
			saxTickCircleBold,
			saxTrashOutline
		}),
		provideNgIconsConfig({ size: '1.5rem' })
	],
	templateUrl: './new-request.component.html'
})
export class NewRequestComponent {

	private csvDataKey: string = 'new-request-csv-data';
	private csvFileKey: string = 'new-request-csv-file';
	private exporterKey: string = 'new-request-exporter';
	private stepKey: string = 'new-request-step';
	private requestTypeKey: string = 'new-request-type';

	public sitemap: SiteMap = SITEMAP;

	public acceptedFiles: string[] = [ '.csv' ];
	public exporters: Company[] = [];
	public exporter!: Company;
	public csvFile: File | null = null;
	public csvFileUploaded: boolean = false;
	public isFileInputFilled: boolean = false;
	public isLoading: boolean = false;
	public packageItems: Signal<PackageItem[]> = signal([]);
	public step: number = 0;
	public requestType: number | null = null;
	public requestTypes = RequestTypeEnum;
	public user: User | null = null;
	public userFunctions = UserFunctionEnum;

  private readonly dateParsingFormats = [
    "DD/MM/YYYY",
    "DD/MM/YY",
    "D/M/YYYY",
    "D/M/YY",
    "MM/YYYY",
    "MM/YY",
    "M/YYYY",
    "M/YY",
    "YYYY-MM-DD"
  ];

	constructor(
		private datePipe: DatePipe,
		private router: Router,
		private _account: AccountService,
		private _apiCompany: ApiCompanyService,
		private _apiExporter: ApiExporterService,
		private _apiImporter: ApiImporterService
	) {
    dayjs.extend(customParseFormat);

		this._account.currentUser.subscribe((user: User | null) => {
			this.user = user;

			if(this.user && this.user.function === UserFunctionEnum.Exporter)
				this.step = 1;
		});

		if(localStorage.getItem(this.requestTypeKey))
			this.requestType = +localStorage.getItem(this.requestTypeKey)!;

		if(localStorage.getItem(this.stepKey))
			this.step = +localStorage.getItem(this.stepKey)!;

		if(localStorage.getItem(this.csvDataKey))
			this.packageItems = signal(JSON.parse(localStorage.getItem(this.csvDataKey)!));

		if(localStorage.getItem(this.csvFileKey))
			this.csvFileUploaded = JSON.parse(localStorage.getItem(this.csvFileKey)!);

		if(localStorage.getItem(this.exporterKey))
			this.exporter = JSON.parse(localStorage.getItem(this.exporterKey)!);

		this._apiCompany.getAll()
			.then((response: HttpResponse<Company[]>) => {
				if(response && response.ok)
					this.exporters = response.body!;
			});
	}

	private updateCSVStorage(): void {
		localStorage.setItem(this.csvDataKey, JSON.stringify(this.packageItems()));
	}

	public submitStockOffer(): void {
		this.isLoading = true;

		this._apiExporter.postStock(this.packageItems().map((packageItem: PackageItem): ExporterManifestEntry => {
			return {
				batch: {
					id: packageItem.batchNumber,
					cip13: packageItem.packageCip13,
					expiration: dayjs(packageItem.minExpiration, this.dateParsingFormats, true).format("YYYY-MM-DD")
				},
				quantity: packageItem.quantity,
				price: packageItem.price
			}
		}))
			.then((response: HttpResponse<ExporterManifestEntry[] | null>) => {
				if(response.ok) {
					this.reset();
					this.router.navigate([ this.sitemap.stockOffer.route ]);
				}
			})
			.catch((err) => console.error(err))
			.finally(() => this.isLoading = false);
	}

	public submit(): void {
		this.isLoading = true;

		this._apiImporter.postRequest({
			type: this.requestType!,
			exporters: [ this.exporter ],
			items: this.packageItems().map((packageItem: PackageItem) => {
				packageItem.minExpiration = dayjs(packageItem.minExpiration, this.dateParsingFormats, true).format("YYYY-MM-DD");
				return packageItem;
			})
		})
			.then((response: HttpResponse<ImporterRequest | null>) => {
				if(response.ok) {
					this.reset();
					this.router.navigate([ this.sitemap.request.route, response.body!.id ])
				}
			})
			.catch((err) => console.error(err))
			.finally(() => this.isLoading = false);
	}

	public getPackageItemsSum = (): number => Math.round((this.packageItems()
		.map((item: PackageItem) => item.price * item.quantity)
		.reduce((a, b) => a + b)) * 100) / 100;

	public updateExporter(exporter: Company): void {
		this.exporter = exporter;
		localStorage.setItem(this.exporterKey, JSON.stringify(exporter));
	}

	public processingFiles(files: FileList): void {
		if(files && files.length > 0) {
			this.isFileInputFilled = true
			let file: File | null = files.item(0);

			if(file) {
				this.csvFile = file;
				this.csvFileUploaded = true;
				localStorage.setItem(this.csvFileKey, JSON.stringify(this.csvFileUploaded));

				let reader: FileReader = new FileReader();
				reader.readAsText(this.csvFile);
				reader.onload = (e: ProgressEvent<FileReader>) => {
					let csvStr: string = reader.result as string;

					csv({
						noheader: false,
						output: 'json'
					})
						.fromString(csvStr
							.replaceAll(',', '.')
							.replaceAll(';', ',')
						)
						.then((csvRow) => {
							this.packageItems = signal(csvRow.map((item): PackageItem => {
								return {
									packageCip13: +item['CIP 13'],
									packageName: item['Product name'],
									quantity: +item['Quantities'],
									batchNumber: item['Batch Number'],
									minExpiration: item['Expiration date'],
									price: +item['Price']
								}
							}));

							this.updateCSVStorage();
							this.nextStep();
						});
				}
			}
		}
	}

	public checkErrorCell(packageItem: PackageItem, index: number): boolean {
		const packageItems: PackageItem[] = [ ...this.packageItems() ];

		return packageItems.splice(index, 1).includes(packageItem);
	}

	public inputDropedFileProcess(files: FileList): void {
		this.processingFiles(files);
		console.log(files);
	}

	public inputFileProcess(eventTarget: EventTarget | null): void {
		if(eventTarget) {
			const files = (<HTMLInputElement>eventTarget).files;
			this.processingFiles(files!);
		}
	}

	public deleteFromCSV(index: number): void {
		this.packageItems().splice(index, 1);
		this.updateCSVStorage();
	}

	public deleteCSVFile(): void {
		this.csvFile = null;
		this.csvFileUploaded = false;
		this.isFileInputFilled = false;
		this.packageItems = signal([]);
		this.requestType = null;

		localStorage.removeItem(this.csvDataKey);
		localStorage.removeItem(this.csvFileKey);
		localStorage.removeItem(this.requestTypeKey);
	}

	public updateRequestType(requestType: number): void {
		this.requestType = requestType;
		localStorage.setItem(this.requestTypeKey, requestType.toString());

		this.nextStep();
	}

	public nextStep(): void {
		if(this.step === 4)
			return;

		this.step++;
		localStorage.setItem(this.stepKey, this.step.toString());
	}

	public previousStep(): void {
		if(this.step === 0)
			return;

		this.step--;
		localStorage.setItem(this.stepKey, this.step.toString());
	}

	public goToStep(step: number): void {
		this.step = step;
		localStorage.setItem(this.stepKey, this.step.toString());
	}

	public reset(): void {
		localStorage.removeItem(this.csvDataKey);
		localStorage.removeItem(this.csvFileKey);
		localStorage.removeItem(this.exporterKey);
		localStorage.removeItem(this.stepKey);
	}

	public canContinue(): boolean {
		if(this.step === 3 && this.user?.function === this.userFunctions.Exporter)
			return false;

		switch(this.step) {
			case 1: return this.csvFileUploaded;
			case 2: return this.packageItems().length > 0;
			case 3: return typeof this.exporter === 'object';
			case 4: return true;
		}

		return false;
	}

}
