import { ChangeDetectionStrategy, Component, inject, NgZone, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { saxTrashOutline } from '@ng-icons/iconsax/outline';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { provideIcons } from '@ng-icons/core';

import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { SITEMAP } from '../../_common/sitemap';
import { MhpFileUploaderComponent } from '../../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StockTableService } from 'src/app/_common/services/table-services/table-stock-service';
import { LoaderService } from 'src/app/_common/services/loader/loader.service';

interface UpdateStockProps {
	step: number;
}

@Component({
	selector: 'send-stock-file',
	imports: [
		MhpButtonComponent,
		RouterLink,
		MhpFileUploaderComponent,
		ReactiveFormsModule,
		FormsModule,
		ScrollingModule,
	],
	providers: [
		provideIcons({
			saxTrashOutline,
		}),
	],
	templateUrl: './send-stock-file.component.html',
	styleUrls: ['../update-stock-quota.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendStockFileComponent {
	private readonly _router = inject(Router);
	readonly _tableSvc = inject(StockTableService);
	readonly _ngZone = inject(NgZone);
	readonly sitemap = SITEMAP;
	readonly file = signal<File | null>(null);

	private readonly _loaderService = inject(LoaderService);

	constructor() {
		dayjs.extend(customParseFormat);
	}

	processFile(file: File | null) {
		if (file) {
			this._tableSvc.processFile(file);
		}
	}
}
