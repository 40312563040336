@if (true || this.offers().length > 0 || this.user()?.function === userFunctions.Importer) {
  <div class="main-request-item-per-product">
    <div class="main-request-item-per-product-header">
      <div class="flex flex-row items-center w-full space-x-4 cursor-pointer" (click)="expanded = !expanded">
        <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0">
          <app-mhp-svg-icon icon="pill" [size]="16"/>
        </div>
        <div class="flex flex-col ">
          <span class="text-sm text-[var(--text-secondary)]">{{ this.item().packageCip13 | toCip : 13 }}</span>
          <span class="text-sm">{{ package().shortName }}</span>
        </div>
      </div>

      <div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
        @if (this.item().minQuantity) {
          <app-mhp-label label="Qty min: {{ this.item().minQuantity }}" />
        }

        <app-mhp-label label="Qty: {{ this.item().quantity }}" />

        @if (this.item().price) {
          <app-mhp-label label="Unit cost: {{ this.item().price | currency : 'EUR' : 'symbol' : '.2-2' }}" />
        }

        @if (this.item().multiple) {
          <app-mhp-label label="Multiple: {{ this.item().multiple }}" />
        }

        <app-mhp-label label="Expiration: {{ this.item().minExpiration | date : 'd MMM y' : '' : 'en-US' }}" />

        <button
          class="flex flex-row items-center"
          (click)="expanded = !expanded"
        >
          See offers ({{ this.offers().length }})
          <ng-icon class="ml-2" [name]="expanded ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'" />
        </button>
      </div>
    </div>

    @if (expanded) {
      <table class="w-full">
        <thead class="border-t">
          <tr class="text-[var(--text-secondary)]">

            @switch (this.user()?.function) {
              @case (userFunctions.Exporter) {
								<th class="w-48">
									<span class="flex align-baseline space-x-2 icon-container">
										<span>Batch</span>
										<ng-icon name="bootstrapInfoCircle" size="1.2rem" title="Batch number, left empty means the product is to order" />
									</span>
								</th>
								<th class="w-48 space-x-2">
									<span class="flex align-baseline space-x-2 icon-container">
										<span>Expiration date</span>
										<ng-icon name="bootstrapInfoCircle" size="1.2rem" title="Enter expiration date if you have a batch. Othewise leave blank." />
									</span>
								</th>
								<th class="w-32 space-x-2">
									<span class="flex align-baseline">
										<span>Quantity</span>
									</span>
								</th>
								<th class="w-32">
									<span class="flex align-baseline space-x-2">
										<span>Unit cost</span>
									</span>
								</th>
                <th class="w-full"></th>
              }

              @case (userFunctions.Importer) {
                <th class="w-64">Exporter</th>
                <!-- <th class="p-4 text-sm text-left ">Receipt of offer</th> -->
                <th class="w-48">Batch</th>
                <th class="w-48">Expiration date</th>
                <th class="w-32">Qty</th>
                <th class="w-32">Unit cost</th>
                <th></th>
              }
            }

          </tr>
        </thead>
        <tbody class="relative border-t">

          @for (offer of this.offers(); track $index) {
            <tr class="border-t" (click)="offerSelected = offer">

              @switch (this.user()?.function) {
                @case (userFunctions.Exporter) {
                  <td class="p-4">
										<input
											class="m-0 p-2 w-32 text-center rounded-xl border focus:border-blue-500 "
											type="text"
											placeholder="Batch number"
											[ngModel]="this.getOfferLabel(offer)"
											(ngModelChange)="this.updateBatchNumber( offer, $event )"
										/>
									</td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon hidden class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
											<input
												class="m-0 p-2 w-36 text-center rounded-xl border focus:border-blue-500 dark:[color-scheme:dark]"
												type="date"
												[value]="offer.expirationDate"
												[(ngModel)]="offer.expirationDate"
											/>
                    </span>
                  </td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- Editable if staged as prefill -->
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border focus:border-blue-500"
                        type="number"
                        [value]="offer.quantity"
                        [(ngModel)]="offer.quantity"
                      />
                    </span>
                  </td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- Editable if staged as prefill -->
                      <input
                        class="m-0 p-2 w-24 text-center rounded-xl border focus:border-blue-500"
                        type="number"
                        [value]="offer.price"
                        [(ngModel)]="offer.price"
                      />
                    </span>
                  </td>
                  <td class="p-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4">
                      @if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="makeOfferModal = true"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }
                    </div>
                  </td>

									<td class="p-4 ">
										<div class="flex flex-row justify-end space-x-4">

											<button (click)="this.duplicateOffer( offer )">
												<ng-icon name="bootstrapCopy" />
											</button>

											<button>
												<ng-icon (click)="this.deleteOffer( offer )" name="saxTrashOutline" />
											</button>

											<hr inert="" class="h-8 w-px border-r">

											<button class="flex flex-row items-center">
												<ng-icon size="1.5rem" name="saxMessageOutline" (click)="this.displayOfferHistoryPanel()" />
											</button>
										</div>
									</td>
                }

                @case (userFunctions.Importer) {
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <app-mhp-company [displayCountry]="true" [company]="getExporter(offer.exporterId)!" />
                  </td>
                  <!-- <td class="p-4 ">{{ item.requestId }}</td> -->
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
										{{ this.getOfferLabel(offer) }}
									</td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.expirationDate | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="p-4 cursor-pointer" (click)="this.displayOfferHistoryPanel()">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="p-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4">
                      @if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="makeOfferModal = true"
                        />

                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>

                        <hr inert class="h-8 w-px border-r" />
                      }

                      <button class="relative flex flex-row items-center" (click)="this.displayOfferHistoryPanel()">
												@if(false) {
													<span class="absolute flex size-2 right-0 top-0">
														<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
														<span class="relative inline-flex rounded-full size-2 bg-blue-500"></span>
													</span>
												}

                        <ng-icon size="1.5rem" name="saxMessageOutline" />
											</button>
                    </div>
                  </td>
                }
              }
            </tr>
          }

          @empty {
            <td colspan="6">
              <div class="flex flex-col items-center justify-center w-full my-6">
                <app-mhp-svg-icon icon="empty-product" [size]="80"/>
                <p class="mt-4 text-[var(--text-secondary)]">You haven't received any offers yet</p>
              </div>
            </td>
          }

        </tbody>
      </table>
    }
  </div>
}

@if (offerHistoryPanelDisplayed) {
   <app-main-request-offer-history
    [offer]="offerSelected!"
    (stateClose)="offerHistoryPanelDisplayed = false"
  />
}

@if (makeOfferModal) {
  <app-main-request-make-offer-modal
    [exporter]="this.getExporter(offerSelected?.exporterId!)!"
    [item]="this.item()"
    [offer]="offerSelected!"
    (stateClose)="makeOfferModal = false"
  />
}

@else if (acceptOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [offer]="offerSelected!"
    [type]="ModalResponseTypesEnum.Accepted"
    (stateClose)="acceptOfferModal = false"
  />
}

@else if (declineOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [offer]="offerSelected!"
    [type]="ModalResponseTypesEnum.Declined"
    (stateClose)="declineOfferModal = false"
  />
}
