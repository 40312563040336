import { inject, Injectable } from '@angular/core';
import { ExporterStockEntry } from '../../models/exporter-stock-entry.model';
import { TableHandler } from '../../utils/table-handler';
import { ApiPackageService } from '../api/api-package/api-package.service';

@Injectable({
	providedIn: 'root',
})
export class StockTableService extends TableHandler<ExporterStockEntry> {
	constructor() {
		super(
			inject(ApiPackageService),
			'update-stock',
			row =>
				<ExporterStockEntry>{
					batch: {
						id: row['Batch number'],
						name: row['Product name'],
						expiration: row['Expiration date'],
					},
					packageCip13: +row['CIP 13'],
					quantity: +row['Quantities'] || null,
					price: +row['Price'] || null,
				}
		);
	}
}
