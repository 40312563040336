import crypto from 'crypto-js';
import { Company } from '../models/company.model';
import { User } from '../models/user.model';

export namespace CompanyExtension {
	/**
	 * Get the exporter by ID
	 */
	export function getGravatarUrl(party?: User | Company, size: number = 64): string {
		if (!party) return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';

		const hashInput =
			'email' in party ?
				`${party.identityProvider}${party.email}` // party is User here
			:	party.displayName;

		const trimmedInput: string = hashInput.trim().toLowerCase();
		const hash: string = crypto.SHA256(trimmedInput).toString();

		return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=identicon`;
	}
}
