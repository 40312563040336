import { computed, Directive, inject, input } from '@angular/core';
import { Offer } from 'src/app/_common/models/offer.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';
import { OffersService } from 'src/app/_common/services/offers/offers.service';

@Directive()
export class OfferActionBase {
	lengthText = computed(() => (this.offers().length > 1 ? '(' + this.offers().length + ')' : ''));
	offers = input.required<Offer[]>();
	offer = computed(() => this.offers()[0]);
	modalVisible = false;
	exporter = computed(() => this.companyService.getCompanyById(this.offers()[0].exporterId));

	private readonly companyService = inject(CompanyService);
	protected readonly offerService = inject(OffersService);

	onModalOpen() {
		this.modalVisible = true;
	}

	onModalClose() {
		console.log('onModalClose');
		this.modalVisible = false;
	}
}
