import { Component, inject, signal } from '@angular/core';
import { MhpButtonComponent } from '../../_common/components/mhp-button/mhp-button.component';
import { MhpFileUploaderComponent } from '../../_common/components/mhp-file-uploader/mhp-file-uploader.component';
import { MhpPackageComponent } from '../../_common/components/mhp-package/mhp-package.component';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiExporterService } from '../../_common/services/api/api-exporter/api-exporter.service';
import { Router, RouterLink } from '@angular/router';
import { TableHandler } from '../../_common/utils/table-handler';
import { SITEMAP } from '../../_common/sitemap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ExporterQuotaEntry } from '../../_common/models/exporter-quota-entry.model';
import { saxTrashOutline } from '@ng-icons/iconsax/outline';
import { ApiPackageService } from '../../_common/services/api/api-package/api-package.service';
import { QuotaTableService } from 'src/app/_common/services/table-services/table-quota-service';

interface UpdateQuotaProps {
	// TODO: Change step type from number to dedicated enum.
	step: number;
}

@Component({
	selector: 'send-quota-file',
	imports: [MhpButtonComponent, MhpFileUploaderComponent, ReactiveFormsModule, FormsModule, RouterLink],
	providers: [
		provideIcons({
			saxTrashOutline,
		}),
	],
	templateUrl: './send-quota-file.component.html',
	styleUrls: ['../update-stock-quota.component.scss'],
})
export class SendQuotaFileComponent {
	private readonly _apiPackageSvc = inject(ApiPackageService);
	private readonly _router = inject(Router);
	readonly _tableSvc = inject(QuotaTableService);
	readonly sitemap = SITEMAP;
	readonly entries = this._tableSvc.items;

	readonly file = signal<File | null>(null);

	constructor() {
		dayjs.extend(customParseFormat);
	}

	/**
	 * Processes a file when uploaded.
	 * @param file The file to process
	 * @protected
	 */
	async processFile(file: Blob | null): Promise<void> {
		if (!file) return;

		this._tableSvc.processFile(file);
	}
}
