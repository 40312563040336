<div class="mhp-package">

	@if (package()) {

	<div class="flex flex-col">
		<div class="flex flex-col w-full mb-6">
			<div class="flex flex-row items-center mb-6 text-[var(--text-secondary)]">
				<a [routerLink]="[ '/dashboard' ]">MedHubPlace</a>
				<ng-icon class="mx-2 mt-1" name="saxArrowRight3Outline" />
				<a>Find product results</a>
			</div>

			<div class="flex flex-row mb-2">
				<h2 class="w-full text-4xl font-semibold">{{ package()?.shortName }}</h2>

				<!-- #85 Hide temporarily the filtering and view characteristics button, please :-), as below -->

				<!-- <div class="text-right shrink-0">
						<app-mhp-button prefix="saxDocumentTextOutline" text="View characteristics" type="tertiary" />
					</div> -->

			</div>

			<h3 class="text-[var(--text-secondary)]">{{ package()?.cip13! | toCip : 13 }}</h3>
		</div>

		<!-- #85 Hide temporarily the filtering and view characteristics button, please :-), as below -->
		<!--
			<div class="flex flex-row items-center space-x-4 mb-10">
				<app-mhp-button suffix="saxArrowDown1Outline" text="Exporter" type="tertiary" />
				<app-mhp-button suffix="saxArrowDown1Outline" text="Expiration date" type="tertiary" />
				<app-mhp-button suffix="saxArrowDown1Outline" text="Country from" type="tertiary" />
				<app-mhp-button suffix="saxArrowDown1Outline" text="Sort" type="tertiary" />
			</div> -->

		<div class="flex flex-col">
			<div class="flex flex-row items-center mb-6">
				<h5 class="text-xl font-semibold w-full">
					{{ productsSum() }} units in stocks from {{ distinctExporters() | i18nPlural : { '=1': '# exporter', 'other':
					'# exporters' } }}
				</h5>

				<!-- <app-mhp-view-switch
						class="shrink-0"
						(choice)="view = $event"
					/> -->
			</div>

			@if (view() === 1) {
			<div class="relative overflow-x-auto border rounded-2xl table-container">
				<table class="w-full text-sm text-left rtl:text-right ">
					<thead class="text-xs text-[var(--text-secondary)]">
						<tr>
							<th>Exporter</th>
							<th>Stock</th>
							<th>Availability</th>
							<th>Batch</th>
							<th>Expiration date</th>
							<th>Unit cost</th>
							<th></th>
						</tr>
					</thead>
					<tbody>

						<!--	https://github.com/orgs/MedHubCompany/projects/1/views/6?pane=issue&itemId=84722179	-->
						@for (stockOrQuota of stocks(); track $index) {
						@let quota = getQuota(stockOrQuota);
						@let stock = castToStock(stockOrQuota);
						@let batchNumber = stock?.batch?.id;

						<tr class="border-t">
							<td class="py-4 px-4">
								<app-mhp-company
									class="cursor-pointer"
									[company]="getExporter(stockOrQuota.exporterId)!"
									[displayCountry]="false"
									[routerLink]="[this.sitemap.exportCatalog.route, stockOrQuota.exporterId]" />
							</td>
							<!-- #85 If I don't have a batch number, my line will only have the quotas. -->
							<td class="p-4">{{ batchNumber ? stock?.quantity : '' }}</td>
							<td class="p-4">{{ quota?.quota }}</td>
							<td class="p-4">{{ batchNumber ?? ''}}</td>
							<td class="p-4">{{ batchNumber ? (stock?.batch?.expiration | date: 'd MMM y' : '' : 'en-US') : null }}
							</td>
							<td class="p-4">{{ stock && stock.exporterId && stock.price ? (stock.price | currency : 'EUR' : 'symbol' :
								'.0-2') : 'Make an offer' }}</td>
							<td class="p-4">
								<div class="flex flex-row justify-end items-center space-x-4">
									<!-- Hidden as requested: https://github.com/MedHubCompany/MedHubPlace/issues/232 -->
									<!--
												<app-mhp-button
													prefix="saxSmsOutline"
													type="secondary"
												/>
												-->

									<app-mhp-button *ngIf="stock"
										class="shrink-0"
										text="Request product"
										type="primary"
										prefix="saxAddOutline"
										(click)="requestStock(stock!)" />
									<!-- <button class="flex flex-row justify-center items-center shrink-0">
													<ng-icon size="1.5rem" name="saxMoreOutline" />
												</button> -->
								</div>
							</td>
						</tr>
						}

					</tbody>
				</table>
			</div>
			}

			@else if (view() === 2) {
			<span>Coming soon...</span>
			}
		</div>
	</div>
	}


	@else {
	The product you are looking for is not available.
	}

</div>

@if (requestProductModal()) {
<app-main-package-request-product-modal
	[(isOpen)]="requestProductModal"
	[exporter]="selectedExporter!"
	[product]="package()!"
	[request]="selectedManifestItem!" />
}
