<ng-icon name="featherInfo"></ng-icon>
<div
	class="absolute bg-[var(--text-primary)] text-white dark:bg-white/75 dark:text-gray-900
		p-4 rounded-[10px] text-sm z-10 w-[300px] whitespace-normal top-[calc(100%+8px)] left-1/2 -translate-x-1/2 
		transition-all duration-300 ease-in-out opacity-0 invisible group-hover:opacity-100 group-hover:visible"
	[class.opacity-100]="isVisible"
	[class.visible]="isVisible">
	{{ message() }}
	<div class="absolute -top-[7px] left-1/2 -translate-x-1/2">
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
			<path d="M4.76314 0L9.52628 7.5H0L4.76314 0Z" fill="var(--text-primary)" />
		</svg>
	</div>
</div>