import { Component, inject, input, OnInit, signal } from '@angular/core';

import { MHPTab } from 'src/app/_common/components/mhp-tabs/mhp-tab.interface';
import { MainRequestOfferHistoryTabsEnum } from 'src/app/_common/enums/main-request-offer-history-tabs.enum';
import { ChatRoom } from 'src/app/_common/models/chat-room.model';
import { Offer } from 'src/app/_common/models/offer.model';
import { MessagingService } from 'src/app/_common/services/messaging/messaging.service';
import { MhpBaseSideModalComponent } from '../../../_common/components/mhp-base-side-modal/mhp-base-side-modal.component';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { ToCipPipe } from '../../../_common/pipes/to-cip/to-cip.pipe';
import { MainRequestOfferHistoryDiscussionComponent } from './main-request-offer-history-discussion/main-request-offer-history-discussion.component';

/**
 * Side modal component for displaying offer history and discussion.
 * Features:
 * - Tabs for history and discussion views
 * - Real-time chat room integration
 * - Package information display
 * - Automatic chat room creation/connection
 */
@Component({
	selector: 'app-main-request-offer-history',
	imports: [ToCipPipe, MhpSvgIconComponent, MainRequestOfferHistoryDiscussionComponent, MhpBaseSideModalComponent],
	templateUrl: './main-request-offer-history.component.html',
	styleUrl: './main-request-offer-history.component.scss',
})
export class MainRequestOfferHistoryComponent extends MhpBaseSideModalComponent implements OnInit {
	private readonly _messagingSvc = inject(MessagingService);

	/** Required offer details */
	public readonly offer = input.required<Offer>();

	/** Available tabs enum */
	public readonly mainRequestOfferHistoryTabs = MainRequestOfferHistoryTabsEnum;

	/** Chat room signal */
	public readonly offerRoom = signal<ChatRoom | null>(null);

	/** Available tab definitions */
	public readonly tabs: MHPTab<MainRequestOfferHistoryTabsEnum>[] = [
		// History tab temporarily disabled
		// { text: 'History', value: MainRequestOfferHistoryTabsEnum.History },
		{ text: 'Discussion', value: MainRequestOfferHistoryTabsEnum.Discussion },
	];

	/** Currently selected tab */
	public tabSelected: MHPTab<MainRequestOfferHistoryTabsEnum> = this.tabs[0];

	constructor() {
		super();
	}

	/** Initialize chat room */
	public async ngOnInit(): Promise<void> {
		this.offerRoom.set(await this._messagingSvc.getOrCreateChatRoomFromOffer(this.offer()));
	}
}
