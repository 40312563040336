@if (this.user) {
  <div class="mhp-main-account">
    <div class="mhp-main-account-left">
      <div class="relative">
        <img
          class="border rounded-full h-60 w-60 border-gray-200 dark:border-gray-700"
          [src]="this.getGravatarUrl(this.user.email, 512)"
          [alt]="this.user.firstName"
        />

        <button class="absolute flex justify-center items-center h-12 w-12 bottom-3 right-3 border rounded-full border-gray-200 bg-white hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-900">
          <ng-icon class="text-gray-900 dark:text-white" name="saxEditOutline" />
        </button>
      </div>

      <hr class="mhp-separator" />

      <div class="flex flex-col space-y-4">
        <h3 class="text-xl font-medium">Company</h3>
        <div class="flex flex-row space-x-4">
          <app-mhp-company [company]="this.company" />
        </div>
      </div>
    </div>

    <div class="mhp-main-account-right">
      <div class="flex flex-col space-y-4">
        <h3 class="text-xl font-medium">Personnal informations</h3>
        <div class="flex flex-row space-x-4">
          <div class="flex flex-col space-y-2">
            <label class="ml-4 text-sm text-gray-500">Firstname</label>
            <app-mhp-input
              [disabled]="true"
              [value]="this.user.firstName"
            />
          </div>
          <div class="flex flex-col space-y-2">
            <label class="ml-4 text-sm text-gray-500">Lastname</label>
            <app-mhp-input
              [disabled]="true"
              [value]="this.user.lastName"
            />
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <label class="ml-4 text-sm text-gray-500">E-mail address</label>
          <app-mhp-input
            prefix="saxSmsOutline"
            [disabled]="true"
            [value]="this.user.email"
          />
        </div>
        <div class="flex flex-col space-y-2">
          <label class="ml-4 text-sm text-gray-500">Phone number</label>
          <app-mhp-input
            prefix="saxCallOutline"
            [disabled]="true"
            [value]="this.user.phoneNumber"
          />
        </div>
      </div>

      <hr class="mhp-separator" />

      <div class="flex flex-col space-y-4">
        <h3 class="text-xl font-medium">Role</h3>
        
        <div class="flex flex-col space-y-2">
          <label class="ml-4 text-sm text-gray-500">User function</label>
          <app-mhp-input
            prefix="saxPersonalcardOutline"
            [disabled]="true"
            [value]="this.user.function | toUserFunction"
          />
        </div>
      </div>

      <hr class="mhp-separator" />

      <div class="flex flex-col space-y-4">
        <h3 class="text-xl font-medium">Address</h3>
        <div class="flex flex-col space-y-2">
          <label class="ml-4 text-sm text-gray-500">Recipient</label>
          <app-mhp-input
            [disabled]="true"
            [value]="this.user.address.recipient"
          />
        </div>

        <div class="flex flex-row space-x-4">
          <div class="flex flex-col space-y-2">
            <label class="ml-4 text-sm text-gray-500">Number</label>
            <app-mhp-input
              class="w-16"
              [disabled]="true"
              [value]="this.user.address.streetNumber"
            />
          </div>
          <div class="flex flex-col space-y-2 w-full">
            <label class="ml-4 text-sm text-gray-500">Street</label>
            <app-mhp-input
              [disabled]="true"
              [value]="this.user.address.street"
            />
          </div>
        </div>

        <div class="flex flex-row space-x-4">
          <div class="flex flex-col space-y-2">
            <label class="ml-4 text-sm text-gray-500">Zip Code</label>
            <app-mhp-input
              class="w-28"
              [disabled]="true"
              [value]="this.user.address.postalCode"
            />
          </div>
          <div class="flex flex-col space-y-2 w-full">
            <label class="ml-4 text-sm text-gray-500">City</label>
            <app-mhp-input
              [disabled]="true"
              [value]="this.user.address.city"
            />
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <label class="ml-4 text-sm text-gray-500">Country</label>
          <app-mhp-input
            [disabled]="true"
            [value]="this.user.address.country"
          />
        </div>
      </div>
    </div>
  </div>
}