import { I18nPluralPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { derivedAsync } from 'ngxtension/derived-async';
import { CompanyExtension } from 'src/app/_common/extensions/company.extension';
import { MhpButtonComponent } from '../../../_common/components/mhp-button/mhp-button.component';
import { MhpSvgIconComponent } from '../../../_common/components/mhp-svg-icon/mhp-svg-icon.component';
import { MhpTaskCardComponent } from '../../../_common/components/mhp-task-card/mhp-task-card.component';
import { ImporterRequestExtension } from '../../../_common/extensions/importer-request.extension';
import { ImporterTasks } from '../../../_common/models/importer-tasks.model';
import { ApiImporterService } from '../../../_common/services/api/api-importer/api-importer.service';
import { CompanyService } from '../../../_common/services/company/company.service';

@Component({
	selector: 'app-main-dashboard-importer-tasks',
	standalone: true,
	imports: [MhpTaskCardComponent, MhpButtonComponent, I18nPluralPipe, MhpSvgIconComponent],
	templateUrl: './main-dashboard-importer-tasks.component.html',
	styleUrl: './main-dashboard-importer-tasks.component.scss',
})
export class MainDashboardImporterTasksComponent {
	protected readonly _companySvc = inject(CompanyService);
	private readonly _importerSvc = inject(ApiImporterService);
	private readonly _router = inject(Router);

	protected readonly tasks = derivedAsync(async () => {
		return (await this._importerSvc.getTasks()) ?? <ImporterTasks>{};
	});

	protected getCompanyAvatar(companyId: number) {
		const company = this._companySvc.getCompanyById(companyId);
		return CompanyExtension.getGravatarUrl(company || undefined);
	}

	protected readonly getUIRequestId = ImporterRequestExtension.getUIRequestId;

	protected getTasksCount(tasks?: ImporterTasks) {
		return tasks?.pendingRequestActions.length ?? 0;
	}

	protected async navigateToRequest(reqId: string) {
		await this._router.navigate(['/request', reqId, 'waiting']);
	}
}
