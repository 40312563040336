<tr>
	<td [ngClass]="isImporter() ? 'w-3/12' : 'w-4/12'"><app-mhp-package
			[package]="{ cip13: offer().packageCip13, shortName: offer().shortName }" /></td>
	<td *ngIf="isImporter()" class="w-1/12"><app-mhp-company [company]="exporter()" /></td>
	<!-- remark: adds 2/12 for importer -->
	<td class="w-1/12">{{ batchNumber() }}</td>
	<td class="w-2/12">
		<mhp-validation-field *ngIf="offer().expirationDate" [isValid]="isExpirationDateValid()"
			[showIcon]="showValidation()">
			{{ offer().expirationDate | date : 'd MMM y' : '' : "en-US" }}
		</mhp-validation-field>
	</td>
	<td class="w-1/12">
		<mhp-validation-field [isValid]="isQuantityValid()" [showIcon]="showValidation()">
			<mhp-value-change
				[formerValueVisible]="formerQuantityVisible()">
				<span slot="formerValue">{{ counterOffer()?.parentOffer?.quantity }}</span>
				<span slot="newValue">{{ offer().quantity }}</span>
			</mhp-value-change>
		</mhp-validation-field>
	</td>
	<td class="w-1/12">
		<mhp-validation-field [isValid]="isPriceValid()" [showIcon]="showValidation()">
			<mhp-value-change [formerValueVisible]="formerPriceVisible()">
				<span slot="formerValue">{{ counterOffer()?.parentOffer?.price | currency : 'EUR': 'symbol' : '.2-2' }}</span>
				<span slot="newValue">{{ offer().price | currency : 'EUR': 'symbol' : '.2-2' }}</span>
			</mhp-value-change>
		</mhp-validation-field>
	</td>
	<td [ngClass]="isImporter() ? 'w-1/12' : 'w-2/12'">{{ statusLabel() }}</td>
	<td class="w-2/12"><offers-actions [offer]="offer()" /></td>
	<td class="px-0 w-0">
		<div class="w-0 border-r-2">&nbsp;</div>
	</td>
	<td class="w-16"><offer-action-chat [offer]="offer()" (onOpenHistory)="onOpenHistory.emit()"></offer-action-chat></td>
</tr>