import { inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { ApiUserService } from '../api/api-user/api-user.service';
import { User } from '../../models/user.model';

import { SITEMAP, SiteMap } from '../../sitemap';
import { CompanyService } from "../company/company.service";

@Injectable({
	providedIn: 'root'
})
export class AccountService {
  _router = inject(Router);
  _cookie = inject(CookieService);
  _apiUser = inject(ApiUserService);
  _company = inject(CompanyService);


	private sitemap: SiteMap = SITEMAP;
	private userKey: string = 'user';

	private approvalUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(JSON.parse(sessionStorage.getItem(this.userKey)!));
	public currentUser: Observable<User | null> = this.approvalUser.asObservable();

	public updateAccount(user: User): void {
		this.approvalUser.next(user);
		sessionStorage.setItem(this.userKey, JSON.stringify(user));
	}

	public resetAccount(): void {
		this.approvalUser.next(null);
		sessionStorage.removeItem(this.userKey);
	}

	public getUser = (): User | null => this.approvalUser.getValue();

	public async checkAuth(): Promise<void> {
		try {
			const response: HttpResponse<User> = await this._apiUser.getUser();

      if (response.ok) {
        this.updateAccount(response.body!);
        await this._company.updateCurrentCompany();
      } else {
        this.resetAccount();
      }
    }

		catch (err: unknown) { this.resetAccount(); }
	}

	public signOut(): void {
		this.resetAccount();
		this._cookie.delete('.AspNetCore.Cookies');
		this._router.navigate([ this.sitemap.main.route ]);
    this._company.resetCompany();
	}

}
