import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { featherInfo } from '@ng-icons/feather-icons';

@Component({
	selector: 'app-mhp-tooltip',
	standalone: true,
	imports: [CommonModule, NgIconComponent],
	providers: [
		provideIcons({
			featherInfo,
		}),
		provideNgIconsConfig({ size: '1rem' }),
	],
	templateUrl: './mhp-tooltip.component.html',
})
export class MhpTooltipComponent {
	message = input<string>('');
	isVisible = false;

	@HostBinding('class') class = 'relative inline-block cursor-help h-4 group';

	@HostListener('mouseenter')
	onMouseEnter() {
		this.isVisible = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.isVisible = false;
	}
}
