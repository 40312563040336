import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { MhpBaseModalComponent } from 'src/app/_common/components/mhp-base-modal/mhp-base-modal.component';
import { MhpCompanyComponent } from 'src/app/_common/components/mhp-company/mhp-company.component';

import { OfferExtension } from 'src/app/_common/extensions/offer.extension';
import { Offer } from 'src/app/_common/models/offer.model';
import { CompanyService } from 'src/app/_common/services/company/company.service';

/**
 * Modal component for accepting or declining offers.
 * Features:
 * - Display offer details including exporter, package info, and price
 * - Confirmation dialog for accept/decline actions
 * - Detailed offer information display
 * - Response tracking
 */
@Component({
	selector: 'app-main-request-accept-decline-offer-modal',
	imports: [CommonModule, MhpCompanyComponent, MhpBaseModalComponent],
	templateUrl: './main-request-accept-decline-offer-modal.component.html',
})
export class MainRequestAcceptDeclineOfferModalComponent extends MhpBaseModalComponent {
	private readonly _companySvc = inject(CompanyService);

	/** Possible response types */
	readonly offerResponseTypes = ModalResponseTypeEnum;

	/** Required exporter company details */
	readonly exporter = computed(() => this._companySvc.getCompanyById(this.offer().exporterId));

	/** Required offer details */
	readonly offer = input.required<Offer>();

	/** Required response type (accept/decline) */
	readonly type = input.required<ModalResponseTypeEnum>();

	/** Format offer label */
	readonly getOfferLabel = () => OfferExtension.getOfferLabel(this.offer());

	constructor() {
		super();
	}

	/** Override close action to add any additional logic */
	override handleClose(): void {
		super.handleClose();
	}
}

/** Possible modal response types */
export enum ModalResponseTypeEnum {
	None = 0,
	Accepted = 1,
	Declined = 2,
}
