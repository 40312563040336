import { Pipe, PipeTransform } from '@angular/core';

import { OfferResponseTypeEnum } from '../../enums/offer-response-type.enum';
import { ImporterRequestItem } from '../../models/importer-request-item.model';
import { Offer } from '../../models/offer.model';
import { Package } from '../../models/package.model';
import { ResponseOffer } from '../../models/response-offer.model';
import { UserFunctionEnum } from '../../enums/user-function.enum';

@Pipe({
	name: 'isOfferAcceptedByUser',
	standalone: true,
})
export class IsOfferAcceptedByUserPipe implements PipeTransform {
	public transform(offer: Offer & ImporterRequestItem & Package, userFunctions: UserFunctionEnum): boolean {
		if (userFunctions === UserFunctionEnum.Importer)
			return offer.responses.find((o: ResponseOffer) => o.importerResponse?.type === OfferResponseTypeEnum.Accepted)
				? true
				: false;

		if (userFunctions === UserFunctionEnum.Exporter)
			return offer.responses.find((o: ResponseOffer) => o.exporterResponse?.type === OfferResponseTypeEnum.Accepted)
				? true
				: false;

		return false;
	}
}
