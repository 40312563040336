import { Component, HostListener, inject, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AutoFocusDirective } from '../../directives/auto-focus.directive';
import { Package } from '../../models/package.model';
import { ApiPackageService } from '../../services/api/api-package/api-package.service';
import { ApiSearchService } from '../../services/api/api-search/api-search.service';
import { SITEMAP } from '../../sitemap';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';
import { bestSellers } from './best-sellers'; // best sellers packages for demo
import { MhpSearchModalItemComponent } from './mhp-search-modal-item/mhp-search-modal-item.component';

@Component({
	selector: 'app-mhp-search-modal',
	imports: [FormsModule, AutoFocusDirective, MhpSearchModalItemComponent, MhpSvgIconComponent],
	styleUrl: './mhp-search-modal.component.scss',
	templateUrl: './mhp-search-modal.component.html',
})
export class MhpSearchModalComponent {
	private readonly _searchSvc = inject(ApiSearchService);
	private readonly _packageSvc = inject(ApiPackageService);

	public readonly sitemap = SITEMAP;

	public readonly stateClose = output<boolean>();

	public readonly searchPackages = signal<Package[]>(bestSellers);

	public searchText: string = '';
	public clickedInside: boolean = false;
	public isSearching: boolean = false;

	public async search(): Promise<void> {
		if (!this.searchText) return;

		this.searchPackages.set([]);
		this.isSearching = true;

		try {
			this.searchPackages.set(
				(this.searchText.match(/[0-9]{7}/) ? [await this._packageSvc.getByCIP7(+this.searchText)]
				: this.searchText.match(/[0-9]{13}/) ? [await this._packageSvc.getByCIP13(+this.searchText)]
				: (await this._searchSvc.searchPackages(this.searchText)).body) || []
			);
		} catch (err: unknown) {}
	}

	public parseSearch(): void {
		if (this.searchText.match(/^[0-9]{5} ([0-9]{3} ){2}[0-9] [0-9]/g))
			this.searchText = this.searchText.split(' ').join('');
	}

	@HostListener('document:keydown', ['$event'])
	public triggerClosing(e: any): void {
		if (e.keyCode === 27) this.stateClose.emit(true);
	}

	@HostListener('document:click')
	public close(): void {
		if (!this.clickedInside) this.stateClose.emit(true);

		this.clickedInside = false;
	}
}
