<div class="mhp-tabs-component">

	@for (tab of this.tabs(); track tab) {
		<button
			class="mhp-tabs-component-button"
			[ngClass]="this.choice() === tab ? 'active' : ''"
			(click)="this.choose(tab)"
		>
			{{ tab.text }}

			@if (tab.ping) {
				<span class="relative flex h-2 w-2 ml-3">
					<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
					<span class="relative inline-flex rounded-full h-full w-full bg-blue-500"></span>
				</span>
			}
		</button>
	}

</div>