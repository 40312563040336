<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="p-6 relative flex flex-col flex-start items-start transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-md sm:max-w-lg"
        (click)="this.clickedInside = true"
      >
        <!-- @if (this.step !== 1) {
          <div class="mb-6">
            <button
              class="flex flex-row justify-center item-center"
              (click)="this.close()"
            >
              <app-mhp-svg-icon class="dark:stroke-white" icon="close" />
            </button>
          </div>
        } -->

        <div class="flex flex-col justify-center items-center space-y-8 w-full">
          <div class="flex flex-col space-y-4">
            <span class="text-center text-xl font-semibold text-gray-900 dark:text-white">Confirm import of your stock for this request?</span>
            <span class="text-center text-sm text-gray-500">It's simple: we use your stock as the default answer to this request, and you can always modify the offers one by one.</span>
          </div>

          <app-mhp-button
            class="w-full"
            size="large"
            text="Confirm and import my stock"
            type="primary"
            (click)="this.nextStep(); this.confirm()"
          />
        </div>

      </div>
    </div>
  </div>
</div>
