import { effect, Injectable, signal } from '@angular/core';
import { ToastTypeEnum } from '../../enums/toast-type.enum';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	public readonly isClosed = signal<boolean>(true);
	public readonly text = signal<string>('');
	public readonly type = signal<ToastTypeEnum>(ToastTypeEnum.Success);

	private timeoutID: NodeJS.Timeout | undefined;

	constructor() {
		effect(() => {
			if (this.text()) {
				clearTimeout(this.timeoutID);
				this.isClosed.set(false);
				this.delayToClose();
			}
		});
	}

	public delayToClose(): void {
		this.timeoutID = setTimeout(() => this.close(), 15000);
	}

	public close(): void {
		this.isClosed.set(true);
		this.text.set('');
	}

	public success(text: string): void {
		this.text.set(text);
		this.type.set(ToastTypeEnum.Success);
	}

	public failure(text: string): void {
		this.text.set(text);
		this.type.set(ToastTypeEnum.Failure);
	}
}
