export interface MedEvent {
	id: string;
	type: MedEventType;
	objectId: string;
	createdAt: string;
}

export enum MedEventType {
	UnreadMessage,
	OfferChange,
	NewRequest,
	NewPurchaseOrder,
}
