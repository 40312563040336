<app-mhp-base-modal
  [title]="'Making a counter-offer'"
  [actionButtonText]="'Confirm my offer'"
  [actionButtonPrefix]="'saxSend2Outline'"
  (action)="this.submitConfirm()"
  (close)="this.close()"
>
  <div class="flex flex-col w-full space-y-6">
    <div class="mb-8">
      <app-mhp-from-to [from]="this.user()!" [to]="[this.exporter()]" />
    </div>

    <div class="flex flex-col">
      <span class="mb-4 text-sm text-[var(--text-secondary)]">Products</span>
      <div class="flex flex-row items-center">
        <div class="flex justify-center items-center h-11 w-11 mr-4 border rounded-full shrink-0">
          <app-mhp-svg-icon icon="pill" [size]="16"/>
        </div>

        @if (this.item().package) {
          <span class="mr-4">{{ this.item().package.shortName }}</span>
        }
      </div>
    </div>

    <div class="flex flex-col">
      <div class="flex flex-row items-center mb-4">
        <span class="w-full text-sm text-[var(--text-secondary)]">Quantity</span>
        <app-mhp-label class="shrink-0" label="Requested: {{ this.item().quantity }}" />
      </div>
      <app-mhp-input
        [min]="1"
        [stepper]="true"
        suffix="Units"
        type="number"
        [(value)]="this.quantity"
      />
    </div>

    <div class="flex flex-col">
      <div class="flex flex-row items-center mb-4">
        <span class="w-full text-sm text-[var(--text-secondary)]">Unit price</span>
        <app-mhp-label class="shrink-0" label="Requested: {{ this.item().price | currency : 'EUR' : 'symbol' : '.2-2' }}" />
      </div>
      <app-mhp-input
        [min]="1"
        [stepper]="true"
        suffix="€"
        type="number"
        [(value)]="this.price"
      />
    </div>
  </div>
</app-mhp-base-modal>
