import { CommonModule } from '@angular/common';
import { booleanAttribute, Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxCloseCircleBold } from '@ng-icons/iconsax/bold';
import {
	saxAddOutline,
	saxCallOutline,
	saxMinusOutline,
	saxPersonalcardOutline,
	saxProfileCircleOutline,
	saxSearchNormal1Outline,
	saxSmsOutline,
} from '@ng-icons/iconsax/outline';

@Component({
	selector: 'app-mhp-input',
	imports: [CommonModule, FormsModule, NgIconComponent],
	providers: [
		provideIcons({
			saxAddOutline,
			saxCallOutline,
			saxCloseCircleBold,
			saxMinusOutline,
			saxPersonalcardOutline,
			saxProfileCircleOutline,
			saxSearchNormal1Outline,
			saxSmsOutline,
		}),
		provideNgIconsConfig({ size: '1.5rem' }),
	],
	styleUrl: './mhp-input.component.scss',
	templateUrl: './mhp-input.component.html',
	standalone: true,
})
export class MhpInputComponent {
	public readonly disabled = input<boolean>(false);
	public readonly clearable = input<boolean, unknown>(false, { transform: booleanAttribute });
	public readonly max = input<number>();
	public readonly min = input<number>();
	public readonly placeholder = input<string>();
	public readonly prefix = input<string>();
	public readonly step = input<number>(1);
	public readonly stepper = input<boolean>(false);
	public readonly suffix = input<string>();
	public readonly type = input<'date' | 'number' | 'text' | 'textarea'>('text');
	public readonly padding = input<number>(2);

	public readonly model = model<string | null | undefined>('');

	public clear(): void {
		this.model.set('');
	}

	public onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		this.model.set(input.value || null);
	}

	public getInputValue(): string {
		return this.model() || '';
	}

	public decrease(): void {
		if (this.type() !== 'number') return;

		const currentValue = Number(this.model() || '0');
		if (!isNaN(currentValue)) {
			const minValue = this.min() ?? -Infinity;
			const step = this.step() ?? 1;
			if (currentValue > minValue) {
				this.model.set((currentValue - step).toString());
			}
		}
	}

	public increase(): void {
		if (this.type() !== 'number') return;

		const currentValue = Number(this.model() || '0');
		if (!isNaN(currentValue)) {
			const maxValue = this.max() ?? Infinity;
			const step = this.step() ?? 1;
			if (currentValue < maxValue) {
				this.model.set((currentValue + step).toString());
			}
		}
	}
}
