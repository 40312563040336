<!-- Exporter has one single group that contains all offers with a required action -->

<offers-group *ngIf="userFunction() === UserFunctionEnum.Exporter" [allowEdit]="true" [offers]="waitingOffers()" />

<!-- Importer has one group per item, with all offers from different exporters for that item -->

@if (userFunction() === UserFunctionEnum.Importer && Object.keys(waitingOffersPerItem()).length > 0)
{
@for( entry of waitingOffersPerItem() | keyvalue; track entry.key )
{
<offers-group-for-item [offers]="entry.value" class="block mb-4"/>
}
}
