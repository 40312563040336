import { Component, input } from '@angular/core';
import { NgIconComponent, provideIcons, provideNgIconsConfig } from '@ng-icons/core';
import { saxBuildingsOutline } from '@ng-icons/iconsax/outline';
import { Company } from "../../models/company.model";

import { getGravatarUrl } from "../../utils/get-gravatar-url.util";
import { NgOptimizedImage } from "@angular/common";

@Component({
	selector: 'app-mhp-company',
	standalone: true,
  imports: [
    NgIconComponent,
    NgOptimizedImage
  ],
	providers: [
		provideIcons({
			saxBuildingsOutline
		}),
		provideNgIconsConfig({ size: '1rem' })
	],
	styleUrl: './mhp-company.component.scss',
	templateUrl: './mhp-company.component.html'
})
export class MhpCompanyComponent {
  public company = input.required<Company>();
	public displayCountry = input(false);
  public displayNameAsTooltip = input(false);

	public getGravatarUrl = (email: string, size: number = 64): string => getGravatarUrl(email, size);

}
