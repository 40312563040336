@if(true) {
  <div class="main-request-item-per-company">

    <div class="flex flex-row items-center p-4">
      <div class="flex flex-row items-center w-full space-x-4 cursor-pointer" (click)="this.expanded = !this.expanded">
        <app-mhp-company [company]="this.exporter()" />
      </div>

      <div class="flex flex-row items-center shrink-0 space-x-4 text-sm">
        <app-mhp-button prefix="saxBook1Outline" text="Access the stock offer" type="secondary" />
        <app-mhp-button hidden prefix="saxBookmark2Outline" text="Book all ready products (n)" type="primary" />

        <button class="inline-flex flex-row items-center text-gray-900 dark:text-white" (click)="this.expanded = !this.expanded">
          <span>Offer details ({{ this.offersFiltered().length }})</span>
          <ng-icon class="ml-2" [name]="this.expanded ? 'saxArrowUp2Outline' : 'saxArrowDown1Outline'" />
        </button>
      </div>
    </div>

    @if (this.expanded) {
      <table class="w-full">
        <thead class="border-t border-gray-200 dark:border-gray-700">
          <tr class="text-gray-500">
            <th class="p-4 text-sm text-left font-medium">Product</th>
            <th class="p-4 text-sm text-left font-medium">Batch</th>
            <th class="p-4 text-sm text-left font-medium">Expiration date</th>
            <th class="p-4 text-sm text-left font-medium">Qty</th>
            <th class="p-4 text-sm text-left font-medium">Unit cost</th>
            <th class="p-4 text-sm text-left font-medium">Status</th>
            <th class="p-4 text-sm text-left font-medium"></th>
            <th></th>
          </tr>
        </thead>
        <tbody class="border-t">

          @switch(this.filter()) {
            @case (2) {
              @for (offer of this.offersFiltered(); track $index) {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">
                    <div class="inline-flex flex-row items-center w-full space-x-4">
                      <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                        <app-mhp-svg-icon icon="pill" />
                      </div>
      
                      <div class="flex flex-col font-medium">
                        <span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
                        <span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
                      </div>
                    </div>
                  </td>
      
                  <td class="p-4">{{ offer.batchNumber }}</td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                    </span>
                  </td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.quantity }}
                    </span>
                  </td>
                  <td class="p-4">
                    <span class="flex flex-row items-center">
                      <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                      {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                    </span>
                  </td>
                  <td class="p-4">
                    Offer sent
                  </td>
                  <td class="p-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                      @if(
                        this.user?.function === this.userFunctions.Exporter && this.checkImporterResponseIsAccepted(offer)
                      ) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.offerSelected = offer; this.makeOfferModal = true"
                        />
      
                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.offerSelected = offer; this.declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.offerSelected = offer; this.acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }

                      @else if (!(this.currentCompany()!.id === offer.exporterId)) {
                        <app-mhp-button
                          prefix="saxNote2Outline"
                          text="Counter offer"
                          type="tertiary"
                          (click)="this.offerSelected = offer; this.makeOfferModal = true"
                        />
      
                        <button
                          class="flex flex-row items-center justify-center text-red-500 hover:text-red-600"
                          (click)="this.offerSelected = offer; this.declineOfferModal = true"
                        >
                          <ng-icon name="saxCloseCircleBold" size="1.75rem" />
                        </button>
                        <button
                          class="flex flex-row items-center justify-center text-blue-600 hover:text-blue-700"
                          (click)="this.offerSelected = offer; this.acceptOfferModal = true"
                        >
                          <ng-icon name="saxTickCircleBold" size="1.75rem" />
                        </button>
                      }

                      @else {}
                    </div>
                  </td>
                </tr>
              }
      
              @empty {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">empty</td>
                </tr>
              }
            }

            @case (3) {
              @for (offer of this.bookedOffers(); track $index) {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="px-4 py-4">
                    <div class="inline-flex flex-row items-center w-full space-x-4">
                      <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                        <app-mhp-svg-icon icon="pill" />
                      </div>
      
                      <div class="flex flex-col font-medium">
                        <span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
                        <span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
                      </div>
                    </div>
                  </td>
      
                  <td class="px-4 py-4">{{ offer.batchNumber }}</td>
                  <td class="px-4 py-4">
                      <span class="flex flex-row items-center">
                        <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                        {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                      </span>
                  </td>
                  <td class="px-4 py-4">
                      <span class="flex flex-row items-center">
                        <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                        {{ offer.quantity }}
                      </span>
                  </td>
                  <td class="px-4 py-4">
                      <span class="flex flex-row items-center">
                        <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                        {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                      </span>
                  </td>
                  <td>Booked</td>
                  <td class="px-4 py-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                    </div>
                  </td>
                </tr>
              }
      
              @empty {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">empty</td>
                </tr>
              }
            }

            @case (4) {
              @for (offer of this.orderedOffers(); track $index) {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="px-4 py-4">
                    <div class="inline-flex flex-row items-center w-full space-x-4">
                      <div class="flex justify-center items-center h-11 w-11 border rounded-full shrink-0 dark:border-gray-700">
                        <app-mhp-svg-icon icon="pill" />
                      </div>
      
                      <div class="flex flex-col font-medium">
                        <span class="text-sm text-gray-500">{{ offer.packageCip13 | toCip : 13 }}</span>
                        <span class="text-sm text-gray-900 dark:text-white">{{ offer.shortName }}</span>
                      </div>
                    </div>
                  </td>
      
                  <td class="px-4 py-4">{{ offer.batchNumber }}</td>
                  <td class="px-4 py-4">
                      <span class="flex flex-row items-center">
                        <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                        {{ offer.minExpiration | date : 'd MMM y' : '' : "en-US" }}
                      </span>
                  </td>
                  <td class="px-4 py-4">
                      <span class="flex flex-row items-center">
                        <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                        {{ offer.quantity }}
                      </span>
                  </td>
                  <td class="px-4 py-4">
                      <span class="flex flex-row items-center">
                        <!-- <ng-icon class="mr-1 text-red-600" name="saxArrowUp3Outline" /> -->
                        {{ offer.price | currency : 'EUR': 'symbol' : '.2-2' }}
                      </span>
                  </td>
                  <td>Ordered</td>
                  <td class="px-4 py-4 text-end">
                    <div class="flex flex-row justify-end items-center space-x-4 w-full">
                    </div>
                  </td>
                </tr>
              }
      
              @empty {
                <tr class="border-t text-gray-900 border-gray-200 dark:text-white dark:border-gray-700">
                  <td class="p-4">empty</td>
                </tr>
              }
            }
          }

        </tbody>
      </table>
    }

  </div>
}

@if (this.makeOfferModal) {
  <app-main-request-make-offer-modal
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    (stateClose)="this.makeOfferModal = false; this.needRefresh.emit(true)"
  />
}

@else if (this.acceptOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    [type]="this.offerResponseTypes.Accepted"
    (stateClose)="this.acceptOfferModal = false; this.needRefresh.emit(true)"
  />
}

@else if (this.declineOfferModal) {
  <app-main-request-accept-decline-offer-modal
    [exporter]="this.exporter()"
    [item]="this.getItemByCIP13(this.offerSelected?.packageCip13!)!"
    [offer]="this.offerSelected!"
    [package]="this.getPackageByCIP13(this.offerSelected?.packageCip13!)!"
    [type]="this.offerResponseTypes.Declined"
    (stateClose)="this.declineOfferModal = false; this.needRefresh.emit(true)"
  />
}
