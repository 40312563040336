import { Injectable, inject, model, signal } from '@angular/core';

import { Offer } from '../../models/offer.model';
import { ApiOfferService } from '../api/api-offer/api-offer.service';
import { HttpResponse } from '@angular/common/http';
import { CompanyService } from '../company/company.service';
import { ImporterRequestItem } from '../../models/importer-request-item.model';

// [TODO]: Refactor code for getting requestId

@Injectable({
	providedIn: 'root',
})
export class OffersService {
	private readonly _offerApiSvc = inject(ApiOfferService);
	private readonly _companySvc = inject(CompanyService);

	public readonly currentRequestId = signal<string>('');
	public readonly currentOffers = signal<Offer[]>([]);

	constructor() {}

	public async getOffersByRequestId(requestId: string): Promise<Offer[]> {
		const offers: HttpResponse<Offer[]> = await this._offerApiSvc.getOffersByRequest(requestId);
		this.currentRequestId.set(requestId);
		this.currentOffers.set(offers.body || []);

		return offers.body || [];
	}

	public async refresh(): Promise<Offer[]> {
		const offers: HttpResponse<Offer[]> = await this._offerApiSvc.getOffersByRequest(this.currentRequestId());
		this.currentOffers.set(offers.body || []);

		return offers.body || [];
	}

	/**
	 * Create a new offer for the item in parameter
	 * @param cip13
	 * @returns the new offer
	 */
	public createNewOfferForItem(requestId: string, item: ImporterRequestItem): Offer {
		return <Offer>{
			batchNumber: '',
			exporterId: this._companySvc.currentCompany()!.id,
			packageCip13: item.packageCip13,
			price: 0,
			quantity: 0,
			itemId: item.itemId,
			requestId,
		};
	}
}
