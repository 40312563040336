import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-index',
  standalone: true,
  imports: [],
  templateUrl: './admin-index.component.html',
})
export class AdminIndexComponent {

}
