import { Component, input } from '@angular/core';

import { Company } from '../../models/company.model';
import { User } from '../../models/user.model';
import { getGravatarUrl } from '../../utils/get-gravatar-url.util';
import { MhpSvgIconComponent } from '../mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-mhp-from-to',
	standalone: true,
	imports: [MhpSvgIconComponent],
	templateUrl: './mhp-from-to.component.html',
})
export class MhpFromToComponent {
	public readonly from = input<User>();
	public readonly to = input<Company>();

	public getGravatarUrl = (email: string, size: number = 64): string => getGravatarUrl(email, size);
}
