import {
	ComponentRef,
	Directive,
	ElementRef,
	EmbeddedViewRef,
	Input,
	OnChanges,
	OnDestroy,
	Renderer2,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';
import { NotificationIndicatorComponent } from '../components/mhp-notification-indicator/mhp-notification-indicator.component';

@Directive({
	selector: '[appNotificationIndicator]',
	standalone: true,
})
export class NotificationIndicatorDirective implements OnChanges, OnDestroy {
	@Input() appNotificationIndicator: boolean = false;
	private notifIndicatorRef: ComponentRef<NotificationIndicatorComponent> | null = null;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private viewContainerRef: ViewContainerRef
	) {
		// Make sure host element is positioned relatively for absolute positioning to work
		this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('appNotificationIndicator' in changes) {
			this.updateNotificationIndicator();
		}
	}

	ngOnDestroy(): void {
		this.removeNotificationIndicator();
	}

	private updateNotificationIndicator(): void {
		if (this.appNotificationIndicator) {
			this.createNotificationIndicator();
		} else {
			this.removeNotificationIndicator();
		}
	}

	private createNotificationIndicator(): void {
		if (this.notifIndicatorRef) return;

		// Create component using ViewContainerRef
		this.notifIndicatorRef = this.viewContainerRef.createComponent(NotificationIndicatorComponent);

		// Get DOM element from component
		const notifIndicatorElement = (this.notifIndicatorRef.hostView as EmbeddedViewRef<any>).rootNodes[0];

		// Append to the host element
		this.renderer.appendChild(this.el.nativeElement, notifIndicatorElement);
	}

	private removeNotificationIndicator(): void {
		if (this.notifIndicatorRef) {
			this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.notifIndicatorRef.hostView));
			this.notifIndicatorRef = null;
		}
	}
}
