import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { ToCipPipe } from '../../pipes/to-cip/to-cip.pipe';
import { MhpSvgIconComponent, MhpSvgIconType } from '../mhp-svg-icon/mhp-svg-icon.component';

@Component({
	selector: 'app-mhp-package',
	imports: [CommonModule, MhpSvgIconComponent, ToCipPipe],
	templateUrl: './mhp-package.component.html',
	styleUrl: './mhp-package.component.scss',
})
export class MhpPackageComponent {
	readonly package = input.required<{ cip13: number; shortName: string | undefined }>();

	readonly icon = input<MhpSvgIconType | null>('pill');
}
