import { Component, inject, output } from '@angular/core';
import { OffersService } from 'src/app/_common/services/offers/offers.service';
import { MhpSuggestionsCard } from '../../_common/components/mhp-new-request-button/mhp-suggestions-card.component';

@Component({
	selector: 'app-choose-fill-mode',
	templateUrl: './choose-fill-mode.component.html',
	standalone: true,
	imports: [MhpSuggestionsCard],
})
export class ChooseFillModeComponent {
	prefill = output<boolean>();

	offersService = inject(OffersService);

	chooseManual() {
		this.prefill.emit(false);
	}
	choosePrefill() {
		this.prefill.emit(true);
	}
}
