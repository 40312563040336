@if (this.company()) {
  <div class="mhp-company-component">
    @if (this.company().displayName; as name) {
      <img
        class="mhp-company-component-picture"
        [src]="this.getGravatarUrl(name)"
        [title]="name"
        alt="Exporter"
      />
    }

    @else {
      <div class="mhp-company-component-picture">
        <ng-icon name="saxBuildingsOutline" />
      </div>
    }

    @if (!this.displayNameAsTooltip()) {
      <div class="flex flex-col">
        <span class="text-gray-900 dark:text-white">{{ this.company().displayName }}</span>

        @if (this.displayCountry()) {
          <span class="text-gray-500">{{ this.company().address.country }}</span>
        }
      </div>
    }
  </div>
}

